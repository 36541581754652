import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';

import { findCommKrnm, getCommcode } from '../../utils/commcode'
import { CContainer } from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import { ProductStore } from '../../store/product/ProductStore';
import { dateFormat } from '../../utils/dateUtils';
import InsertProduct from './popup/InsertProduct';
import { getUserName } from '../../utils/userUtils';
import ProductListSearch from './ProductListSearch';
import { AppStore } from '../../store/AppStore';
import {showToast} from "../../common/utils";

const ProductList = () => {
    const gridRef = useRef();
    const [lgList, setLGList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [productGroupList, setProductGroupList] = useState([]);
    // const [productSeq, setProductSeq] = useState('');

    useEffect(() => {
        getProductGroupList();
    }, []);

    /* product group 조회 -> 대분류 배열에 담아주기 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        setProductGroupList(result);
        setLGList(result.filter(v => v.parentSeq === null));
    }

    /* 제품 조회 */
    const getProductLst = async () => {
        // if(productSeq){
        //     setProductSeq('');
        // }

        const result = await axios.get('/product/list', { params: ProductStore.productSearchCondition });
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
            return;
        }
        ProductStore.setProductList(result);
        setProductList(result);
    }

    const columnDefs=[
        {field: "lg", headerName: "대분류", width: 120},
        {field: "productName", headerName: "제품명", width: 200},
        {field: "model", headerName: "모델명", width: 180},
        {
            field: "brandCode",
            headerName: "제조사",
            width: 120,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('61').map(v => v.value),
            },
            valueFormatter: v => findCommKrnm(v.value, '61'),
        },
        {field: "assetCnt", headerName: "자산수량", width: 110},
        {field: "goodsCnt", headerName: "상품수량", width: 110},
        {field: "operatingRate", headerName: "가동률", valueFormatter: v => v.value ? v.value+'%' : '0%'},
        {field: "isDeleted", headerName: "사용여부", valueFormatter: v => v.value === 0 ? '사용' : '미사용', width: 110},
        {field: "spec", headerName: "스펙", width: 400},
        {field: "remark", headerName: "비고", width: 200},
        {field: "creator", headerName: "입력자", width: 100, valueGetter: v => getUserName(v.data.creator)},
        {field: "createdDate", headerName: "입력일자", width: 120, valueGetter: v => dateFormat(v.data.createdDate)},
        {field: "updater", headerName: "수정자", width: 100, valueGetter: v => getUserName(v.data.updater)},
        {field: "updatedDate", headerName: "수정일자", width: 120, valueGetter: v => !v.data.updatedDate ? '' : dateFormat(v.data.updatedDate)},
    ];

    const openProductDetail = (e) => {
        ProductStore.data.productSeq = e.data.productSeq;
        AppStore.toggleInsertProduct();
    }

    return (
        <>
            {/* 검색조건 */}
            <ProductListSearch productGroupList={productGroupList} lgList={lgList} getProductLst={getProductLst} />
            <CContainer>
                {/* ag grid */}
                <AgGridContainer
                    gridRef={gridRef}
                    height={60}
                    rowData={ProductStore.productList}
                    columnDefs={columnDefs}
                    isCheckBox={true}
                    seqColumn={'productSeq'}
                    originList={productList}
                    useCsvDownload={true}
                    rowDoubleClickCallback={(e) => openProductDetail(e)}
                />
            </CContainer>
            {/* 추가 modal */}
            <InsertProduct getProductLst={getProductLst} productSeq={ProductStore.data.productSeq} />
        </>
    );
};

export default observer(ProductList);

import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const RentalListStore = observable({
    rentalList: [],
    searchCondition: { searchDate: '', contractNo: '', customerNo: '', customerName: '', billingAddressSeq: null, billingAddressName: '', contractType: '',
                        saleUserSeq: null, saleUserName:'', supportUserSeq: null, supportUserName: '', assetNo: '', managementNo: ''},

    setRentalList(v){
        this.rentalList = v;
    },
    setSearchCondition(v) {
        LUtils.assign(this.searchCondition, v);
    },
});

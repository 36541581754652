import { useRef } from "react";
import { observer } from "mobx-react-lite";
import SignatureCanvas from 'react-signature-canvas';
import { Row, Col } from "react-bootstrap";
import axios from 'axios';

import { CContainer, CSaveBtn, CCancelBtn, CInputGroup, CCol } from '../../../components/CustomContainer';
import { MULTIPART_HEADER } from "../../../common/constants";
import { AppStore } from "../../../store/AppStore";
import "./confirmPageStyle.css";
import { ConfirmStore } from "./ConfirmStore";

const ConfirmSignature = ({ shipSeq, getShipData, isDetectMobileDevice }) => {
  const signatureRef = useRef();
  const {deliveryDriver, receiveUserName} = ConfirmStore;

  const clearSignature = () => {
    signatureRef.current.clear();
  }

  const handleSave = async () => {
    if(!deliveryDriver) {
      alert('인도인을 입력해주세요.');
      return;
    }
    if(!receiveUserName) {
      alert('인수인을 입력해주세요.');
      return;
    }

    const canvas = signatureRef.current.getTrimmedCanvas();
    const image = canvas.toDataURL('image/png');

    const formData = new FormData();
    const file = dataURLtoFile(image, 'sign_image.png');
    formData.append('file', file);
    formData.append('shipSeq', shipSeq);
    formData.append('deliveryDriver', deliveryDriver);
    formData.append('receiveUserName', receiveUserName);

    const result = await axios.put('ship/confirm/signature/upload', formData, { headers: MULTIPART_HEADER });
    alert('저장 되었습니다.');
    getShipData();

    AppStore.toggleModal();
  };

  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  return (
    <CContainer paddingTop={'pt-1'}>
      <Row className={isDetectMobileDevice ? '' : 'mb-4'}>
        <CCol lg={6}>
          <CInputGroup
                label={'인도인'} labelId={'deliveryDriver'}
                value={deliveryDriver}
                onChange={(e)=> ConfirmStore.deliveryDriver=e.target.value}
                labelClassName='input-required'
            />
        </CCol>
        <CCol lg={6}>
          <CInputGroup
                label={'인수인'} labelId={'receiveUserName'}
                value={receiveUserName}
                onChange={(e)=> ConfirmStore.receiveUserName=e.target.value}
                labelClassName='input-required'
            />
        </CCol>
      </Row>
      <div className="popup">
      <p style={{color: 'red', marginBottom: 0}}>* 인수인 서명을 입력해주세요.</p>
        <Row style={{ border: '1px solid black', backgroundColor: '#fff' }}>
          <div className={isDetectMobileDevice ? "isMobileContainer" : "signatureContainer"}>
            <div className="canvasContainer">
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{className: 'sigCanvas canvasStyle'}}
                clearOnResize={false}
                throttle={10}
              />
            </div>
          </div>
        </Row>
        <Row>
          <Col className='d-flex flex-row-reverse mt-4'>
            <CSaveBtn onClick={handleSave} />
            <CCancelBtn onClick={clearSignature} title={'초기화'}/>
          </Col>
        </Row>
      </div>
    </CContainer>
  );
};

export default observer(ConfirmSignature);

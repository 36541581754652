import {useEffect, useState} from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../../store/AppStore";
import Modal from '../../../components/Modal'
import {CCol, CContainer, CIconBtn, CInputDoubleGroup, CSelectGroup} from "../../../components/CustomContainer";
import {Row} from "react-bootstrap";
import {callConfirm} from "../../../utils";
import {showToast} from "../../../common/utils";
import CustomerSearch from "../../../components/searchModal/CustomerSearch";
import { getCommcode } from "../../../utils/commcode";

const WorkLogPopup =({ callbackFn=null, ...props })=> {
    const [logSeq, setLogSeq] = useState('');
    const [contents, setContents] = useState('');
    const [customerNo, setCustomerNo] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [workType, setWorkType] = useState('');

    useEffect(()=> {
        setLogSeq(props.logSeq);
        setContents(props.contents);
        setCustomerNo(props.customerNo);
        setCustomerName(props.customerName);
        setWorkType(props.workType);
    }, [props.logSeq])

    const saveLog = async (isComplete) => {
        if(!workType){
            showToast('업무타입을 입력해 주세요.');
            return;
        }
        if(!contents){
            showToast('업무 내용을 입력해 주세요.');
            return;
        }

        if(!await callConfirm(`${logSeq ? '수정' : '저장'}하시겠습니까?`)) return;
        let result = '';
        const params = {
            customerNo: customerNo,
            customerName: customerName,
            contents: contents,
            workType: workType,
            isComplete: isComplete
        }

        if(logSeq){
            result = await axios.put(`/workLog/${logSeq}`, params);
        }else{
            result = await axios.post('/workLog', params);
        }

        callbackFn && callbackFn();
    }

    const logComplete =async()=> {
        await saveLog(1);
    }

    const getCustomerInfo =async(data) => {
        setCustomerName(data.customerName);
        setCustomerNo(data.customerNo);
    }

    const handleWorkType =(value)=> {
        setWorkType(value);
        if(value === '3'){
            setContents('현장 : \n관리번호 : \n단가 : ');
        }
    }

    const refresh =()=> {
        setLogSeq('');
        setContents('');
        setCustomerNo('');
        setCustomerName('');
    }

    return (
        <Modal
            title={'업무일지'}
            show={AppStore.isOpenWorkLogPopup}
            onHide={() => AppStore.toggleWorkLogPopup()}
            toggleValue={AppStore.isOpenWorkLogPopup}
            className={'search-modal-h5'}
            size={'md'}
            onExit={refresh}
        >
            <CContainer>
                <Row>
                    <CCol lg={6}>
                        <CSelectGroup label={'업무타입'} labelId={'workType'}
                                placeholder={'업무타입'}
                                options={[{name: '전체', value: ''}].concat(getCommcode('63'))}
                                value={workType}
                                onChange={(v) => handleWorkType(v.target.value)}
                                disabled={logSeq}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12}>
                        <CInputDoubleGroup
                            label={'고객명'}
                            labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            disabled={true} disabled2={false}
                            onChange2={(e)=> setCustomerName(e.target.value)}
                            onCallbackBtn={()=> !logSeq && AppStore.toggleCustomerSearchModal()}
                            value={customerNo || ''}
                            value2={customerName || ''}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12}>
                        <textarea
                            style={{height: 290, width: '100%', padding: 15, borderRadius: 5, borderWidth: 1}}
                            value={contents}
                            onChange={v => setContents(v.target.value)}
                            placeholder={'업무 내용을 자유롭게 입력해 주세요.'}
                        />
                    </CCol>
                </Row>
                <Row>
                <CCol lg={12}>
                        <CIconBtn style={{width: '46%'}} variant={'success'} title={'저장'} onClick={() => saveLog()}/>
                        <CIconBtn style={{width: '46%'}}  variant={'primary'} title={'업무 완료'} onClick={() => logComplete()}/>
                    </CCol>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo}/>
        </Modal>
    )
}

export default observer(WorkLogPopup);

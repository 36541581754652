import {useEffect, useRef, useState} from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import { CommcodeSMOptions } from "../index";
import AgGridContainer from "../AgGridContainer";
import {ContractDetailStore} from "../../store/contract/ContractDetailStore";
import {showToast} from "../../common/utils";
import { ConsumablePopupStore } from "../../store/asset/ConsumablePopupStore";


const StockConsumableSearch =({ callbackFn=null, ...props })=> {
    const gridRef = useRef();

    const [searchList, setSearchList] = useState([]);
    const [productName, setProductName] = useState('');
    const [model, setModel] = useState('');
    const [brandCode, setBrandCode] = useState('');

    useEffect(() => {
    }, []);

    /* 제조사 값 설정 */
    const onChangeBrandName = (v) => {
        setBrandCode(v.target.value);
    }

    /* 조회 */
    const onSearch = async () => {
        const params = {
            model,
            brandCode,
            isAvailableStorage : true
        };
        const result = await axios.get('/stock/consumable', { params });
        setSearchList(result);
    }

    const selectedRow = (e) => {
        if(!props.isAddConsumable && ContractDetailStore.productConsumableList.length > 0 && ContractDetailStore.productConsumableList.findIndex(v => v.consumableProductSeq === e.data.productSeq) > -1) {
            showToast(`이미 추가된 소모품 입니다. [${e.data.productName}]`);
            return;
        }
        if(props.isAddConsumable && ConsumablePopupStore.consumableList.length > 0 && ConsumablePopupStore.consumableList.findIndex(v => v.consumableProductSeq === e.data.productSeq) > -1) {
            showToast(`이미 추가된 소모품 입니다. [${e.data.productName}]`);
            return;
        }

        callbackFn([e.data]);
        AppStore.toggleStockConsumableSearchModal();
    }

    const callBackGridData = ({ selectedList }) => {
        // 동일한 제품코드가 있는지 체크
        if(!props.isAddConsumable && ContractDetailStore.productConsumableList.length > 0){
            for(let data of selectedList){
                for(let row of ContractDetailStore.productConsumableList){
                    if(data.productSeq === row.consumableProductSeq){
                        showToast(`이미 추가된 소모품 입니다. [${data.productName}]`);
                        return false;
                    }
                }
            }
        }

        if(props.isAddConsumable && ConsumablePopupStore.consumableList.length > 0){
            for(let data of selectedList){
                for(let row of ConsumablePopupStore.consumableList){
                    if(data.productSeq === row.consumableProductSeq){
                        showToast(`이미 추가된 소모품 입니다. [${data.productName}]`);
                        return false;
                    }
                }
            }
        }

        callbackFn && callbackFn(selectedList);
        AppStore.toggleStockConsumableSearchModal();
    }

    /* 초기화 */
    const refresh = () => {
        setSearchList([]);
    }

    return (
        <Modal
            title={'소모품재고조회'}
            show={AppStore.isStockConsumableSearch}
            onHide={() => AppStore.toggleStockConsumableSearchModal()}
            toggleValue={AppStore.isStockConsumableSearch}
            className={'search-modal-h7'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'소모품명'}
                            labelId={'productName'}
                            value={productName}
                            onChange={(v)=> setProductName(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={brandCode} onChange={onChangeBrandName} disabled={props.productCode}>
                                <option value={''}>- 제조사 -</option>
                                { <CommcodeSMOptions commKind={'18'} />}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3} style={{paddingTop: 3}}>
                        <Form.Check
                            inline
                            label="임대가능 재고만 보기"
                            name="availableStock"
                            type={'radio'}
                            id='stock'
                            value={'stock'}
                            defaultChecked={true}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={35}
                    rowData={searchList}
                    columnDefs={[
                        {field: "productSeq", headerName: "제품SEQ", minWidth: 110},
                        {field: "productCode", headerName: "제품코드", minWidth: 110, hide: true},
                        {field: "productName", headerName: "소모품명", minWidth: 120},
                        {field: "model", headerName: "모델명", minWidth: 160},
                        {field: "amount", headerName: "단가", minWidth: 130, valueFormatter: v => v.value?.toLocaleString()},
                        {field: "stockQty", headerName: "재고수량", minWidth: 130},
                        //{field: "storageQty", headerName: "입고수량", minWidth: 130},
                        {field: "releaseQty", headerName: "출고수량", minWidth: 130},
                        {field: "spec", headerName: "스펙", minWidth: 200},
                    ]}
                    callBackGridData={callBackGridData}
                    selectBtnInfo={{isUsed: true}}
                    btnTitles={{save: '선택'}}
                    isCheckBox={true}
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(StockConsumableSearch);

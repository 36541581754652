import axios from "axios"
import * as Utils from "."
import { COMM_CODE_LIST } from "../common/constants"
import LUtils from "./lodashUtils"

/* 공통코드 localStorage에 저장 */
const refreshCommcode = async () => {
        try {
            const result = await axios.get("/commCode/all");
            Utils.setLocalStorage(COMM_CODE_LIST, result);
        } catch (error) {
            console.log('[commcode search error]', error);
        }
    }

/* 공통코드 조회 - commKind 생략시 중분류 */
const getCommcode = (commKind = '0', fullRowData=false) => {
        const commCodeList = Utils.getLocalStorage(COMM_CODE_LIST);
        if (!LUtils.isEmpty(commCodeList)) {
            const fullRowList = LUtils.filter(commCodeList, v => v.commKind === commKind).sort((a, b)=> a.seq - b.seq);
            return fullRowData ? fullRowList : fullRowList.map(v => ({name: v.commKrnm, value: v.commCode}));
        } else {
            refreshCommcode();
            return [];
        }
    };

/* 공통코드 한글명 조회 - commKind 생략하면 중분류 */
const findCommKrnm = (commCode, commKind='0') => {
    const commCodeList = Utils.getLocalStorage(COMM_CODE_LIST);

    const commcodeObj = LUtils.find(commCodeList, v => v.commCode == commCode && v.commKind == commKind);
    // TODO: commcodeObj값이 없을 경우 처리 로직 추가되어야 함
    return LUtils.get(commcodeObj, 'commKrnm', '');
}


export {
    refreshCommcode,
    getCommcode,
    findCommKrnm
}

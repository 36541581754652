import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import {decrypt, showToast} from "../../common/utils";
import AgGridContainer from "../AgGridContainer";
import { useLocation } from 'react-router-dom';

const CustomerBillingAddressSearch =({ customerNo, callbackFn=null, ...props})=> {
    const location = useLocation();
    
    const [customerName, setCustomerName] = useState('');
    const [managerName, setManagerName] = useState('');
    const [billingAddressList, setBillingAddressList] = useState([]);

    const checkerCustomerNo =async () => {
        if ((!customerNo || customerNo.length === 0) && ['/contract/creation','/contract/detail'].includes(location.pathname)) {
            showToast('고객 선택을 먼저 해야 합니다.');
            AppStore.toggleCustomerBillingAddressSearchModal()
            return false;
        }
        
        if(customerNo && customerNo.length > 0){
            await onSearchBillingAddressList();
        }
    }

    const onSearchBillingAddressList = async () => {
        const result = await axios.get('/customer/tabs/billing_address/list', { params: { customerNo, customerName, managerName } });
        setBillingAddressList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleCustomerBillingAddressSearchModal();
    }

    const refresh = () => {
        setCustomerName('');
        setBillingAddressList([]);
    }

    return (
        <Modal title={'청구지'}
                onShow={checkerCustomerNo}
                onHide={() => AppStore.toggleCustomerBillingAddressSearchModal()}
                toggleValue={AppStore.isOpenCustomerBillingAddressSearch}
                className={'search-modal-h5'}
                onExit={refresh}
        >
            <CContainer search>
                <Row>
                    {/*
                    <CCol lg={3}>
                        <CInputGroup label={'고객번호'} value={customerNo} disabled={true} />
                    </CCol>
                    */}
                    <CCol lg={3}>
                        <CInputGroup label={'고객명'} value={customerName} onChange={(v)=> setCustomerName(v.target.value)} enterKeyHint={onSearchBillingAddressList} />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'담당자명'} value={managerName} onChange={(v)=> setManagerName(v.target.value)} enterKeyHint={onSearchBillingAddressList} />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearchBillingAddressList} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={25}
                    rowData={billingAddressList}
                    columnDefs={
                        [
                            {field: "customerNo", headerName: "고객번호", hide: true, width: 100},
                            {field: "customerName", headerName: "고객명", width: 200},
                            {field: "billingAddressSeq", headerName: "청구지순번", width: 110},
                            {field: "billingAddressName", headerName: "청구지명", width: 150},
                            {field: "managerName", headerName: "청구담당자", width: 110},
                            {field: "email", headerName: "이메일", width: 150},
                            {field: "telephone", headerName: "전화번호", width: 150},
                            {field: "phoneNumber", headerName: "핸드폰", valueFormatter: v => v.data.phoneNumber ?? decrypt(v.data.phoneNumber), width: 150},
                            {field: "address", headerName: "주소", width: 200},
                            {field: "addressDetail", headerName: "상세주소", width: 200},
                        ]
                    }
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(CustomerBillingAddressSearch);

import { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../../store/AppStore";
import { CContainer } from "../../../components/CustomContainer";
import { showToast } from "../../../common/utils";
import AgGridContainer from "../../../components/AgGridContainer";
import Modal from "../../../components/Modal";
import { ContractDetailStore } from "../../../store/contract/ContractDetailStore";
import { ICON_TRASH } from "../../../common/constants";
import LUtils from "../../../utils/lodashUtils";
import { callConfirm } from "../../../utils";
import ConsumablesSearch from "../../../components/searchModal/ConsumablesSearch";

const ContractAssetConsumPopup =({ callbackFn=null, column='', ...props })=> {
    const consumGridRef = useRef();
    const consumRef = useRef();

    const {searchContract, searchContractProduct, checkRowSeq, selProductList} = props;

    useEffect(()=> {
        if(checkRowSeq && AppStore.isOpenContractAssetConsumPopup){
            searchProductConsum();
        }
    }, [checkRowSeq, AppStore.isOpenContractAssetConsumPopup]);

    /* 계약 제품 소모품 조회 */
    const searchProductConsum = async() => {
        const result = await axios.get(`/contract/product/consum/${ContractDetailStore.contractNo}/${checkRowSeq}`);

        result.map(v => {
            if(v.monthRentPrice && v.qty){
                return Object.assign(v, {totalRentPrice: v.monthRentPrice * v.qty});
            }
        })

        ContractDetailStore.setProductConsumList(result);
    }


    const handleConsumQtyEdit =async(e)=> {
        if(e.data.maxQty < e.newValue) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        } else {
            e.data.qty = e.newValue;
        }

        consumGridRef.current.api.redrawRows();
    }
        
    const handleConsumRemarkEdit =async(e)=> {
        e.data.remark = e.newValue;
        consumGridRef.current.api.redrawRows();
    }

    /* 소모품 그리드 저장,수정 */
    const callBackConsumSave = async ({ updatedList, createdList }) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);

        // 재고수량 확인
        let checkQty = 0;
        if (isUpdate) {
            updatedList.map(v => {
                if(v.maxQty < v.qty){
                    checkQty++;
                }
            });
        } else if (isCreate) {
            createdList.map(v => {
                if(v.maxQty < v.qty){
                    checkQty++;
                }
            });
        }

        if (!isUpdate && !isCreate) {
            showToast('저장할 내용이 없습니다.');
            return false;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        isCreate && await axios.post(`/contract/product/consum/${ContractDetailStore.contractNo}`, createdList);
        isUpdate && await axios.put(`/contract/product/consum/${ContractDetailStore.contractNo}`, updatedList);

        showToast('저장 되었습니다.');

        searchContract(ContractDetailStore.contractNo);
        searchContractProduct(ContractDetailStore.contractNo);
        searchProductConsum();
    }

    /* 소모품 추가 콜백 함수 */
    const callbackConsumListSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        let newSeq = ContractDetailStore.productConsumList.length + 1;
        const product = ContractDetailStore.contractProductList.find(v => v.seq === checkRowSeq);

        for(let consum of info){
            let seq = newSeq++;
            const additionalRowInfo = {
                addRowId: seq
                ,consumRowNumber: seq
                ,seq: seq
                ,contractProductSeq: product.seq
                ,productSeq: product.productSeq
                ,productCode: product.productCode
                ,productName: product.productName
                ,model: product.model
                ,consumProductSeq: consum.productSeq
                ,consumProductName: consum.productName
                ,consumModel: consum.model
                ,consumProductCode: consum.productCode // consumProductCode에도 담아줌(같은 제품 또 넣을 경우 비교하기 위해)
                //,assetNo: consum.assetNo
                // ,consumStorageNo: consum.consumStorageNo
                ,qty: 1
                ,maxQty: consum.stockQty
                ,isSelected: true, isCreated: true /* 추가하려면 isCreated를 반드시 추가해 준다. */
            };
            ContractDetailStore.pushProductConsumList(additionalRowInfo);
        }
    }

    /* 소모품 선택항목 삭제 */
    const removeConsumSelectedRows = async ({selectedList}) => {
        if (LUtils.isEmpty(selectedList)) {
            showToast('삭제 할 항목이 없습니다.');
            return false;
        }

        if (!await callConfirm('삭제 하시겠습니까?')) {
            return false;
        }

        await axios.delete(`/contract/product/consum/${ContractDetailStore.contractNo}`, {data: selectedList});

        showToast('삭제 되었습니다.');
        searchContract(ContractDetailStore.contractNo);
        searchContractProduct(ContractDetailStore.contractNo);
        searchProductConsum();
    }
        
    /* 선택 버튼 옵션 - 삭제 */
    const consumSelectBtnInfo = {
        isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
        title: '선택 삭제',
        callbackFn: e => removeConsumSelectedRows(e),
        icon: ICON_TRASH
    };


    const customConsumAddBtnInfo = [
        {
            isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
            callbackFn: () => AppStore.toggleConsumablesSearchModal(),
            title: '소모품추가',
            icon: 'fi-rr-add',
            //color: '#FE2E64'
        }
    ];

    const getCellChange = (e) => {
        const { field } = e.colDef;

        // 숫자만 입력 가능한 컬럼 검사
        const numberColumns = ['monthRentPrice', 'qty'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), v => isNaN(v))) {
            showToast('숫자만 입럭 가능합니다.');
            e.data[field] = 0;
        }

        // 총 렌탈료 계산
        if (LUtils.includes(['monthRentPrice', 'qty'], field)) {
            e.data.totalRentPrice = e.data.monthRentPrice * e.data.qty;
        }

        // 수량 검사
        if (field === 'qty') {
            if (Number(e.data.qty) > Number(e.data.maxQty)) {
                showToast(`가능 수량을 초과 했습니다. 가능 수량 [${e.data.maxQty}]`);
                e.data.qty = e.data.maxQty;
                e.data.totalRentPrice = e.data.monthRentPrice * e.data.maxQty;
            }
        }

        // 그리드 전체 다시 그리기
        consumGridRef.current.api.redrawRows();
    }

    return (
        <Modal
            title={'소모품내역'}
            show={AppStore.isOpenContractAssetConsumPopup}
            onHide={() => AppStore.toggleOpenContractAssetConsumPopup()}
            toggleValue={AppStore.isOpenContractAssetConsumPopup}
            className={'search-modal-h6'}
            onExit={() => ContractDetailStore.setProductConsumList([])}
        >
            <CContainer>
                <p>모델명에 추가된 소모품내역 입니다. 소모품을 추가할 경우 소모품추가 버튼을 클릭하여 소모품을 추가하세요</p>
                <AgGridContainer
                    gridRef={consumGridRef}
                    ref={consumRef}
                    height={40}
                    rowData={ContractDetailStore.productConsumList}
                    columnDefs={[
                        {field: "contractProductSeq", headerName: "contract_product의 제품 순번", width: 70, hide: true},
                        /* 사용안함 s */
                        {field: "productSeq", headerName: "제품시퀀스", hide: true},
                        {field: "productCode", headerName: "제품코드", width: 120, hide: true},
                        {field: "productName", headerName: "제품명", width: 200, hide: true},
                        {field: "model", headerName: "장비 모델명", width: 200, hide: true},
                        /* 사용안함 e */
                        {field: "consumRowNumber", headerName: "소모품 순번", width: 115},
                        {field: "consumSeq", headerName: "소모품 실제 순번", width: 100, headerClass: 'grid-column-required', hide: true},
                        {field: "consumProductSeq", headerName: "추가 옵션 productSeq", width: 200, headerClass: 'grid-column-required', hide: true},
                        {field: "consumProductName", headerName: "소모품명", headerClass: 'grid-column-required', width: 170},
                        {field: "consumModel", headerName: "소모품 모델명", width: 200, headerClass: 'grid-column-required', hide: true},
                        {
                            field: "qty",
                            headerName: "소모품 수량",
                            width: 115,
                            onCellValueChanged: handleConsumQtyEdit,
                            headerClass: 'grid-column-required',
                            editable: true
                        },
                        {field: "maxQty", headerName: "최대수량", hide: true},
                        {
                            field: "monthRentPrice",
                            headerName: "대당 월렌탈료",
                            width: 140,
                            editable: ContractDetailStore.detailMode !== 'read',
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                        },
                        {
                            field: "lastMonthPrice",
                            headerName: "대당 마지막달 요금",
                            width: 150,
                            editable: ContractDetailStore.detailMode !== 'read',
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: ContractDetailStore.departmentCode === '1' || ContractDetailStore.contractType === '3' || ContractDetailStore.contractType === '4'
                        },
                        {field: "consumAssetNo", headerName: "자산번호", width: 120, headerClass: 'grid-column-required', hide: true},
                        {field: "consumStorageNo", headerName: "입고번호", width: 120, hide: true},
                        // {
                        //     field: "consumStorageNo",
                        //     headerName: "입고번호",
                        //     minWidth: 140,
                        //     flex: 1,
                        //     headerClass: 'grid-column-editable',
                        //     onCellValueChanged: handleConsumRemarkEdit,
                        //     editable: true
                        // },
                        {
                            field: "remark",
                            headerName: "비고",
                            minWidth: 200,
                            flex: 1,
                            headerClass: 'grid-column-editable',
                            onCellValueChanged: handleConsumRemarkEdit,
                            editable: true
                        },
                    ]}
                    seqColumn={'agId'}
                    isCheckBox={true}
                    originList={[]}
                    useUpdated={ContractDetailStore.detailMode !== 'read'}
                    callBackGridData={callBackConsumSave}
                    selectBtnInfo ={consumSelectBtnInfo}
                    customBtnInfo={customConsumAddBtnInfo}
                    getCellChange={getCellChange}
                />
            </CContainer>
            {/* 소모품 재고 조회 팝업 */}
            <ConsumablesSearch callbackFn={callbackConsumListSearch} selProductList={selProductList}/>
        </Modal>
    )
}

export default observer(ContractAssetConsumPopup);

import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";
import {ContractDetailStore} from "./ContractDetailStore";

export const ContractTabsStore = observable({
    contractTab: 'contractInfo',    // contractInfo, extend, finish, transfer, contractHistory, contractAssetList, billing
    bottomTab: 'product',  //product, maintenance
    contractHistoryList: [],
    contractBillingSchedule: [],
    contractAssetBillingList: [],
    originContractAssetBillingList: [],
    contractAssetList: [],
    originContractAssetList: [],
    contractAssetHistoryList: [],

    /* action */
    setContractTab(v) {
        this.contractTab = v;
    },
    setBottomTab(v){
        this.bottomTab = v;
    },
    setContractHistoryList(v) {
        this.contractHistoryList = v;
    },
    setContractAssetBillingList(v){
        this.contractAssetBillingList = v;
    },
    setOriginContractAssetBillingList(v){
        this.originContractAssetBillingList = v;
    },
    setContractBillingSchedule(v){
        this.contractBillingSchedule = v;
    },
    setContractAssetList(v){
        this.contractAssetList = v;
    },
    setOriginContractAssetList(v){
        this.originContractAssetList = v
    },
    setContractAssetHistoryList(v){
        this.contractAssetHistoryList = v;
    }
});

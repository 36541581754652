import { observable } from "mobx";


export const BoardStore = observable({

    orgBoardList: [],
    boardList: '',
    boardReplyList: '',
    orgBlackList: [],
    blackList: '',
    blackReplyList: '',

    setOrgBoardList(v){
        this.orgBoardList = v;
    },
    setBoardList(v){
        this.boardList = v;
    },
    setOrgBlackList(v){
        this.orgBlackList = v;
    },
    setBlackList(v){
        this.blackList = v;
    },
    setBlackReplyList(v){
        this.blackReplyList = v;
    },

    data: {
        boardSeq: null,
        boardType: '',
        subject: '',
        contents: '',
        attachFileUrl: '',
        attachFileName: '',
        readCount: 0,
        likeCount: 0,
        isDel: 0,
        creator: null,
        creatorName: '',
        createdDate: null,
        updater: '',
        imageUrl: '',
        boardLikeSeq: 0,
    },

    reply: {
        boardReplySeq: null,
        boardSeq: null,
        comment: '',
        creator: null,
        creatorName: '',
        createdDate: null,
        updater: null,
        replyEditMode: false,
        boardReplyLikeSeq: 0,
        boardReplyLikeCount: 0
    },

    boardInit() {
        this.data.boardSeq = null,
        this.data.boardType = '',
        this.data.subject = '',
        this.data.contents = '',
        this.data.attachFileUrl = '',
        this.data.attachFileName = '',
        this.data.readCount = null,
        this.data.likeCount = null,
        this.data.isDel = null,
        this.data.creator = null,
        this.data.creatorName = '',
        this.data.createdDate = null,
        this.data.updater = '',
        this.data.imageUrl = '',
        this.data.boardLikeSeq = 0
    },

    replyInit() {
        this.reply.boardReplySeq = null,
        this.reply.comment = '',
        this.reply.creator = null,
        this.reply.creatorName = '',
        this.reply.createdDate = null,
        this.reply.boardReplyLikeSeq = 0,
        this.reply.boardReplyLikeCount = 0
    }

});

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Row, Col, Button,  } from "react-bootstrap";

import { Subject } from "../../../components/CustomContainer";
import { MULTIPART_HEADER } from "../../../common/constants";
import { dateKoreaFormat, today } from "../../../utils/dateUtils";
import { findCommKrnm } from "../../../utils/commcode";
import Regex from "../../../common/regex";
import { decrypt, isMobile, isDetectMobile, encrypt } from "../../../common/utils";
import { ContractDocumentStore } from "../../../store/contract/ContractDocumentStore";
import Modal from "../../../components/Modal";
import ContractSignaturePopup from "./ContractSignaturePopup";
import { AppStore } from "../../../store/AppStore";
import PreviewImage from "../../../components/preview/PreviewImage";
import html2pdf from 'html2pdf.js';
import { callAlert } from "../../../utils";
import { setAxiosAuthorization } from "../../../utils/axiosSetup";
import ConfirmMailModal from "./ConfirmMailModal";
import "./signaturePopupStyle.css";

const isMobileDevice = isMobile();
const isDetectMobileDevice = isDetectMobile();

const ContractLinkPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [emptyList, setEmptyList] = useState([]);
    const store = ContractDocumentStore;
    const contentRef = useRef();
    const signatureRef = useRef();
    const [initCount, setInitCount] = useState(0);
    const [isSendMail, setIsSendMail] = useState(false);
    const [rows, setRows] = useState(6);
    const [documentRows, setDocumentRows] = useState(8);

    useEffect(() => {
        const jwt = localStorage.getItem("JWT_CONTRACT_DOCUMENT");
        AppStore.jwt = jwt;
        setAxiosAuthorization();

        getExpiration()
        .then(result => {
            if(result) {
                callAlert('유효기간이 만료되었습니다.');
                navigate(`/expiration/${params.contractNo}`, { replace: true });
                return;
            }else {
                if (!jwt) {
                    callAlert('로그인 후 접근 가능합니다.');
                    navigate(`/contract/document/${params.contractNo}/${params.seq}/index`, { replace: true });
                } else {
                    getContract().then(() => {searchContractProduct()});
                }
            }
        });

        const calculateRows = () => {
            const lines = store.data.specialRemark.split('\n');
            let totalRows = lines.length;
      
            lines.forEach(line => {
                const extraRows = Math.floor(line.length / 100);
                totalRows += extraRows;
            });
            return totalRows > 6 ? totalRows : 6;
        };
        const calculateDocumentRows = () => {
            const lines = store.data.specialRemark.split('\n');
            let totalRows = lines.length;
      
            lines.forEach(line => {
                const extraRows = Math.floor(line.length / 50);
                totalRows += extraRows;
            });
            return totalRows > 8 ? totalRows : 8;
        };
    
        setRows(calculateRows());
        setDocumentRows(calculateDocumentRows());
    }, [store.data.specialRemark])

    useEffect(() => {
        if (initCount === 2) {
            saveContract();
        }
    }, [store.data.customerSignatureBase64Url]);

    /* 링크 만료 조회 */
    const getExpiration = async () => {
        const result = await axios.get(`/contract/document/${params.contractNo}/${params.seq}`);
        return result;
    }

    /* 계약 조회 */
    const getContract = async () => {
        const result = await axios.get(`/contract/document/${params.contractNo}`);
        store.data = result;
        setInitCount(initCount + 1);
    }

    /* 계약 제품 정보 조회 */
    const searchContractProduct = async () => {
        const result = await axios.get(`/contract/product/document/${params.contractNo}`);
        store.contractProductList = result;

        // 빈 배열 리스트
        if (result.length < 10) {
            const list = [];
            for (let i = 0; i < 10 - result.length; i++) {
                list.push(0);
            }
            setEmptyList(list);
        }
    }

    /* 서명 버튼 클릭 */
    const handleSignatureClick = () => {
        if(!store.data.contractManager) {
            return alert("계약 담당자 이름을 입력해주세요.");
        }
        AppStore.toggleModal();
    };

    /* 서명 저장 */
    const handleSave = async (isChecked) => {
        const { contractNo, contractManager, contractManagerBelong, contractManagerPhone } = store.data;

        const canvas = signatureRef.current.getTrimmedCanvas();
        const image = canvas.toDataURL('image/png');

        const formData = new FormData();
        const file = dataURLtoFile(image, 'sign_image.png');
        formData.append('file', file);
        formData.append('contractNo', contractNo);
        formData.append('name', contractManager);
        formData.append('belong', contractManagerBelong);
        formData.append('phone', contractManagerPhone);

        // await axios.post('/contract/document/signature', {contractNo: contractNo, name: contractManager, belong: contractManagerBelong, phone: contractManagerPhone}, { headers: 'application/json' });
        await axios.put('/contract/document/signature/upload', formData, { headers: MULTIPART_HEADER })
            .then(() => {
                getContract();
            }).then(() => {
                AppStore.toggleModal();
                setIsSendMail(isChecked);
            })
    };

    /* show loding screen */
    const showLoadingScreen = () => {
        document.querySelector('.overlay').style.display = 'flex';
    }

    /* hide loding screen */
    const hideLoadingScreen = () => {
        document.querySelector('.overlay').style.display = 'none';
        window.location.reload();
    }

    const saveContract = async () => {
        showLoadingScreen();
        
        const productListRows = store.contractProductList.map((v, i) => {
            return (
                `<tr key=${i}>
                    <td class='p-1 border' style='font-size: 10px;'>${i + 1}</td>
                    <td class='p-1 border' style='font-size: 10px;'>${v.model}</td>
                    <td class='p-1 border' style='font-size: 10px;'>${v.managementNo}</td>
                    <td class='p-1 border' style='font-size: 10px;'>${v.serialNo}</td>
                    <td class='p-1 border' style='font-size: 10px;'>${v.qty}</td>
                    <td class='p-1 border' style='font-size: 10px; text-align: right;'>${(v.monthRentPrice + (v.rentDays*v.dayRentPrice))?.toLocaleString()}</td>
                </tr>`
            );
        });

        const emptyListRows = emptyList.map((v, i) => {
            return (
                `<tr key=${i}>
                    <td class='p-1 border'>${i + 1 + (10 - emptyList.length)}</td>
                    <td class='p-1 border'></td>
                    <td class='p-1 border'></td>
                    <td class='p-1 border'></td>
                    <td class='p-1 border'></td>
                    <td class='p-1 border'></td>
                </tr>`
            );
        });


        const content = `
            <div class="content-div" style="padding-left: 22px; padding-right: 22px; padding-top: 20px;">
                <div style="display: flex; justify-content: space-between; align-items: center;border-bottom-width: 1px; margin-bottom: 10px;">
                    <h3 style="text-align: left; margin: 0px">렌 탈 계 약 서</h3>
                    <div style="display: flex; align-items: center;">
                        <img src="data:image/jpeg;base64,${store.data.logoImageBase64Url}" style="width: 60px; height: auto; marginRight: 10px;" />
                        <span style="float: left; font-size: 14px; font-weight: bold;">${store.data.companyName}</span>
                    </div>
                </div>
                <table class="table" style="border-width: 1px;">
                    <tbody>
                        <tr>
                            <td class='p-1 border' valign='middle' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>계약번호</td>
                            <td class='p-1 border' valign='middle' style='width: 18%; text-align: center; font-size: 10px;'>${store.data.contractNo}</td>
                            <td class='p-1 border' valign='middle' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>계약일자</td>
                            <td class='p-1 border' valign='middle' style='width: 17%; text-align: center; font-size: 10px;'>${dateKoreaFormat(store.data.contractDate)}</td>
                            <td class='p-1 border' valign='middle' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>계약담당자</td>
                            <td class='p-1 border' valign='middle' style='width: 12%; text-align: center; font-size: 10px;'>${store.data.saleUserName}</td>
                            <td class='p-1 border' valign='middle' style='width: 9%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>연락처</td>
                            <td class='p-1 border' valign='middle' style='width: 14%; text-align: center; font-size: 10px;'>${store.data.telephone ? store.data.telephone : decrypt(store.data.phoneNumber)}</td>
                        </tr>                            
                    </tbody>
                </table>
                <table class="table" style="border-width: 1px;">
                    <tbody>
                        <tr>
                            <td class="p-1 border" colspan="2" style="text-align: center; font-size: 10px;"><b>임대인</b></td>
                            <td class="p-1 border" colspan="2" style="text-align: center; font-size: 10px;"><b>임차인</b></td>
                        </tr>
                        <tr>
                            <td class="p-1 border" style="width: 10%; background-color: #D8D8D8; text-align: center; vertical-align: middle; font-size: 10px;">회 사 명</td>
                            <td class="p-1 border" style="width: 40%; font-size: 10px;">
                                <div style="display: flex; justify-content: space-between; align-items: center; font-size: 10px; position: relative;">
                                    <span style='font-size: 10px;'>${store.data.companyName}</span>
                                    <span style="margin-right: 20px; font-size: 10px;">
                                        <div style="position: absolute; display: inline-block; top: -5px;">
                                            <img style="height: 30px;" src="data:image/jpeg;base64,${store.data.companySignatureBase64Url}" alt="임대인 서명" />
                                            <span style="position: absolute; bottom: 0; right: 0; padding: 2px 4px; display: flex; justify-content: center; align-items: center; height: 100%; font-size: 10px; color: gray;">(인)</span>
                                        </div>
                                    </span>
                                </div>
                            </td>
                            <td class="p-1 border" style="width: 10%; background-color: #D8D8D8; text-align: center; vertical-align: middle; font-size: 10px;">회 사 명</td>
                            <td class="p-1 border" style="width: 40%; vertical-align: middle; font-size: 10px;">${store.data.customerName}</td>
                        </tr>
                        <tr>
                            <td class="p-1 border" style="width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;">주소</td>
                            <td class="p-1 border" style="width: 25%; font-size: 10px; vertical-align: middle; font-size: 10px;">${store.data.companyAddress}</td>
                            <td class="p-1 border" style="width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;">주소</td>
                            <td class="p-1 border" style="width: 25%; font-size: 9px vertical-align: middle; font-size: 10px;">${store.data.customerAddress}</td>
                        </tr>
                        <tr>
                            <td class="p-1 border" style="width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;">사업자번호</td>
                            <td class="p-1 border" style="width: 25%; font-size: 10px;">${Regex.makeBusinessNumber(store.data.companyBusinessNumber)}</td>
                            <td class="p-1 border" style="width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;">사업자번호</td>
                            <td class="p-1 border" style="width: 25%; font-size: 10px;">${Regex.makeBusinessNumber(store.data.businessNumber)}</td>
                        </tr>
                        <tr>
                            <td class="p-1 border" style="width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;">대표자명</td>
                            <td class="p-1 border" style="width: 25%; font-size: 10px;">${store.data.companyOwner}</td>
                            <td class="p-1 border" style="width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;">대표자명</td>
                            <td class="p-1 border" style="width: 25%; font-size: 10px;">${store.data.owner}</td>
                        </tr>
                    </tbody>
                </table>
                <h6>렌탈 품목 및 요금</h6>
                <table class="table" style="border-width: 1px; text-align: center;">
                    <thead>
                        <tr>
                            <th class="border" style="width: 10%; font-size: 10px;">순번</th>
                            <th class="border" style="width: 20%; font-size: 10px;">모델명</th>
                            <th class="border" style="width: 20%; font-size: 10px;">관리번호</th>
                            <th class="border" style="width: 20%; font-size: 10px;">시리얼번호</th>
                            <th class="border" style="width: 10%; font-size: 10px;">수량</th>
                            <th class="border" style="width: 20%; font-size: 10px;">임대료</th>
                        </tr>
                    </thead>
                  
                    <tbody>
                        ${productListRows.join('')}
                        ${emptyListRows.join('')}
                        <tr>
                            <td class='p-1 border' colspan='5' style='text-align: right;'>
                                <span style='margin-right: 10px; font-weight: bold; font-size: 10px;'>소계 (VAT별도)</span>
                            </td>
                            <td class='p-1 border' style='vertical-align: middle; text-align: right; font-size: 10px;'>${(store.contractProductList.reduce((total, v) => total + v.monthRentPrice + (v.rentDays*v.dayRentPrice), 0))?.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td class='p-1 border' colspan='5' style='text-align: right;'>
                                <span style='margin-right: 10px; font-weight: bold; font-size: 10px;'>합계 (VAT포함)</span>
                            </td>
                            <td className='p-1 border' style='vertical-align: middle; text-align: right; font-weight: bold; font-size: 10px;'>${((store.contractProductList.reduce((total, v) => total + v.monthRentPrice + (v.rentDays*v.dayRentPrice), 0)) * 1.1)?.toLocaleString()}원</td>
                        </tr>
                    </tbody>
                  
                </table>
                <h6>계약정보</h6>
                <table class="table" style="border-width: 1px;">
                    <tbody>
                        <tr>
                            <td class='p-1 border' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>계약개시일</td>
                            <td class='p-1 border' style='width: 23%; text-align: left; font-size: 10px;'>${dateKoreaFormat(store.data.contractStartDate)}</td>
                            <td class='p-1 border' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>청구방법</td>
                            <td class='p-1 border' style='width: 23%; text-align: left; font-size: 10px;'>${findCommKrnm(store.data.paymentType, '17')}</td>
                            <td class='p-1 border' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>결제일</td>
                            <td class='p-1 border' style='width: 23%; text-align: left; font-size: 10px;'>${findCommKrnm(store.data.dueDateType, '22')} ${store.data.dueDateDay === 0 ? '말일' : store.data.dueDateDay} 결제</td>
                        </tr>
                        <tr>
                            <td class='p-1 border' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>설치장소</td>
                            <td class='p-1 border' style='width: 25%; font-size: 10px;' colspan='5'>${store.data.installPlace || ''}</td>
                        </tr>
                        <tr>
                            <td class='p-1 border' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>반납장소</td>
                            <td class='p-1 border' style='width: 25%; font-size: 10px;' colspan='5'>${store.data.companyReturnAddress||''}</td>
                        </tr>
                        <tr>
                            <td class='p-1 border' style='width: 10%; background-color: #D8D8D8; text-align: center; font-size: 10px;'>운송비</td>
                            <td class='p-1 border' style='width: 25%;' colspan='5'>
                                <span style='font-size: 10px;>${store.data.deliveryPrice?.toLocaleString()||0}원</span>
                                <div class="form-check form-check-inline" style="margin-left: 15px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" onchange="" ${store.data.isDeliveryPrice && store.data.chargeDelivery === '1' ? 'checked' : ''}>
                                    <label class="form-check-label" for="inlineCheckbox1" style='font-size: 10px;'>고객부담</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="2" onchange="" ${store.data.isDeliveryPrice && store.data.deliveryKind === '1' ? 'checked' : ''}>
                                    <label class="form-check-label" for="inlineCheckbox2" style='font-size: 10px;'>왕복</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="3" onchange="" ${store.data.isDeliveryPrice && store.data.deliveryKind === '2' ? 'checked' : ''}>
                                    <label class="form-check-label" for="inlineCheckbox3" style='font-size: 10px;'>편도</label>
                                </div>
                                <div class="form-check form-check-inline" style="margin-left: 15px;">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="4" onchange="" ${store.data.isDeliveryPrice && store.data.chargeDelivery === '2' ? 'checked' : ''}>
                                    <label class="form-check-label" for="inlineCheckbox1" style='font-size: 10px;'>당사부담</label>
                                </div>
                                </td>
                        </tr>
                    </tbody>
                </table>
                <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                    <div style="width: 48%; padding-right: 10px; border-right: 1px solid #cececf;">
                        <h6>특약사항</h6>
                        <div style="padding: 0; font-size: 9px; white-space: pre-line; word-wrap: break-word; overflow-y: auto; height: ${9*1.2*documentRows}px; line-height: 1.2;">
                            ${store.data.specialRemark || '별도 특약사항 없음'}
                        </div>
                    </div>
                    <div style="width: 48%;">
                        <h6>확인사항</h6>
                        <table style="width: 100%; font-size: 9px;">
                            <caption style="padding: 0; font-size: 9px;">*렌탈 물건의 소유권은 ${store.data.companyName}에 있습니다.</caption>
                            <caption style="padding: 0; font-size: 9px;">*현장 및 사용자 요청에 따른 옵션장착으로 인한 사고는 고객이 책임집니다.</caption>
                            <caption style="padding: 0; font-size: 9px;">*렌탈 물건 인도 및 인수 시 상/하차 업무 및 그에따른 부대비용은 고객이 부담합니다.</caption>
                            <caption style="padding: 0; font-size: 9px;">*렌탈료 연체 또는 렌탈 물건의 임의처분 및 전대 등 소유권 제한, 침해가 있으면 계약은 강제 해지되고 물건은 반환될 수 있습니다.</caption>
                            <caption style="padding: 0; font-size: 9px;">*렌탈료 연체 또는 물건 반환 지연에 따른 지연손해금 등에따른 불이익을 받으실 수 있습니다.</caption>
                            <caption style="padding: 0; font-size: 9px;">*계약기간 중 물건의 현장이동이 있을 경우 고객은 회사에 고지 의무를 다하며 회사와 협의가 필요합니다.</caption>
                        </table>
                    </div>
                </div>

                <h6 style="margin-top: 10px;">계약대리인</h6>
                <table class="table" style="border-width: 1px; width: 100%; margin-bottom: 20px;">
                    <tbody>
                        <tr>
                            <td class="p-1 border" style="text-align: center; vertical-align: middle; width: 10%; background-color: #D8D8D8; font-size: 10px;">계약 담당자</td>
                            <td class="p-1 border" style="text-align: left; vertical-align: middle; width: 22%; font-size: 10px;">${store.data.contractManager}</td>
                            <td class="p-1 border" style="text-align: center; vertical-align: middle; width: 10%; background-color: #D8D8D8; font-size: 10px;">소속</td>
                            <td class="p-1 border" style="text-align: left; vertical-align: middle; width: 22%; font-size: 10px;">${store.data.contractManagerBelong}</td>
                            <td class="p-1 border" style="text-align: center; vertical-align: middle; width: 10%; background-color: #D8D8D8; font-size: 10px;">연락처</td>
                            <td class="p-1 border" style="text-align: left; vertical-align: middle; width: 22%; font-size: 10px;">${decrypt(store.data.contractManagerPhone)}</td>
                        </tr>
                        <tr>
                            <td class="p-1" colSpan="3" style="text-align: center; vertical-align: middle; height: 80px; border: 1px solid transparent;"></td>
                            <td class="p-1" style="text-align: left; vertical-align: middle; font-weight: bold; border: 1px solid transparent; font-size: 10px;">담당자대리인</td>
                            <td class="p-1" colSpan="2" style="text-align: center; vertical-align: middle; border: 1px solid transparent">
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <span style='font-size: 10px;'>${store.data.contractManager}</span>
                                    <span style="margin-right: 20px; font-size: 10px;">
                                        <div style="position: relative; margin-left: auto;">
                                            <img style="height: 50px; font-size: 10px;" src="data:image/jpeg;base64,${store.data.customerSignatureBase64Url}" alt="임차인 서명" />
                                            <span style="position: absolute; bottom: 0; right: 0; padding: 2px 4px; display: flex; justify-content: center; align-items: center; height: 100%;">(서명)</span>
                                        </div>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style="margin-top: 100px;">
                    <div style="border-width: 1px; width: 100%; height: 40px; padding-top: 9px; text-align: center; background-color: #D8D8D8;">
                        <h5>렌탈계약규정</h5>
                    </div>
                    <br/>
                    <div class="report-contract-agree d-flex justify-content-between no-line-height" style="line-height: normal;">
                        <div style="width: 49%;">
                            <label>제 1조(목적)</label>
                            <p>본 규정은 앞면 기재의 렌탈물건(이하 '물건'이라 함)을 임대한 자(이하 '회사'라 함)와 물건을 임차한 자(이하 '고객'이라 함) 사이에 체결된 임대차 계약상의 권리,의무에 관한 사항에 관하여 규정함을 목적으로 한다.</p>
                            <label>제 2조(렌탈기간)</label>
                            <p>1.렌탈 기간은 회사가 고객에게 물건을 인도한 날로부터 계산을 시작하고, 물건을 반환한 날을 렌탈 기간 종료일로 한다.</p>
                            <p>2.렌탈 기간 만료 후 고객으로부터 회사에 서면 또는 유선으로 반납통보가 없으면 렌탈 기간이 자동으로 연장되어 물건을 사용하는 것으로 간주하며 본 계약 사항을 같이 적용한다.</p>
                            <label>제 3조(렌탈료)</label>
                            <p>1.앞 기재의 계약렌탈료는 1개월 단위 또는 1단위의 렌탈료로서 고객은 앞면 기재의 계약렌탈료를 매월 지급하기로 하고 이의 지급기일 및 지급방법은 앞면에 기재된 바에 의한다.</p>
                            <p>2.고객이 본 조 제1항 또는 개별 계약으로 정한 렌탈료에 대하여 지급기한을 도뢰한 경우 고객이 지급기한을 도뢰한 렌탈료를 회사에게 지급전까지 회사는 고객의 발주 및 출고 요청에 대하여 거절할 수 있다.</p>
                            <label>제 4조(물건의 인도)</label>
                            <p>1.회사는 고객이 지정하는 장소에서 물건을 인도하기로 하며 고객은 물건을 인수하면 바로 현장에서 물건을 검사하고 앞면 기재의 현장 담당자 또는 인수담당자가 서명날인 함으로써 정상적인 상태에서 그 물건을 인수한 것임을 확인한다.</p>
                            <p>2.물건의 인도 및 인수에 필요한 운반비 및 현장에서 필요한 물건의 상/하차 업무 및 그에따른 기타 부대비용은 고객이 부담한다.</p>
                            <label>제 5조(물건의 담보책임)</label>
                            <p>1.회사는 고객에게 물건 인도 시 물건이 정상적인 성능을 갖추고 있는 것을 담보 하고 물건의 사용 목적의 적합성에 대하여는 책임지지 않는다.</p>
                            <p>2.렌탈 기간 동안 물건과 관련된 인명사고, 재산상의 피해 등 발생되는 모든 사고는 고객의 책임으로 한다.</p>
                            <p>3.물건의 인도 후 24시간 이내에 고객은 회사에 물건의 성능결함에 대해 서면 및 유선으로 통지하지 않는 경우 물건이 정상적인 성능을 갖추고 인도된 것으로 간주한다.</p>
                            <p>4.전한과 같은 인도 후 회사는 고객 또는 제3자에게 발생한 손해를 책임지지 않는다.</p>
                            <label>제 6조(물건의 유지보수)</label>
                            <p>1.회사는 렌탈 기간 중 고객의 요청이 있으면 물건이 정상적인 상태로 사용할 수 있도록 일반적인 사용으로 인한 마모와 고객의 사용, 설치, 보관 등으로 인하여 발생한 하자에 대한 유지보수를 제공하며 원활한 유지보수를 위해 대행업체를 선정할 수 있다.</p>
                            <p>2.고객은 전항의 유지보수 제공에 문제가 있는 경우 유선 또는 서면으로 회사에 통보해야 하고 통보가 없는 경우에는 정상적인 유지보수 업무가 제공되고 서비스를 받은것으로 간주한다. 또한 물건의 유지보수 완료 후 24시간 이내에 그 유지보수상의 문제를 통보하지 않는 경우 그 유지보수는 정상적으로 완료된 것으로 간주한다.</p>
                            <label>제 7조(물건의 사용보관)</label>
                            <p>1.고객은 물건의 사용, 보관함에 있어 주의의무를 다하여야 하며, 사용 및 보관에 필요한 비용을 부담하여야 한다.</p>
                            <p>2.고객은 물건을 양도,전대,개조하지 못함은 물론 물건을 앞면 기재의 설치장소 외 곳으로 이동시키지 못하며, 물건에 부착된 회사의 소유권표시, 교정표식 등을 제거,훼손해서는 안된다.</p>
                            <p>3.고객의 필요에 의하여 물건의 이동 및 개조를 할 경우 사전에 회사에게 서면으로 통보한 후 회사의 승낙을 득해야 하며 개조 시 물건에 부착된 부속품 등의 종속물은 모두 회사의 소유로 한다.</p>
                            <label>제 8조(물건의 멸실,훼손 및 미반환)</label>
                            <p>1.물건이 멸실(도난,수리불능,소유권의 침해를 포함), 훼손(화재,파손,소유권의 제한,부속품의 부족을 포함), 미반환의 경우 고객은 회사에게 대체물건의 구입비용 또는 물건의 수리비용을 손해배상금으로 지급해야 한다.</p>
                            <p>2.고객은 물건의 훼손에 의한 수리기간동안 물건의 사용여부에 관계없이 렌탈 기간 중에는 렌탈료를 지급해야한다. 렌탈 기간 만료 이후 물건을 수리할 경우에는 그 수리에 피요한 기간 및 물건을 재구매할 경우에는 관련 제반 업무 등에 소요되는 기간 동안의 영업손해를 청구할 수 있다.</p>
                            <p>3.고객은 물건반환 시 물건의 오염도에 따라 별도의 세차비용을 부담하여야 한다.</p>
                        </div>
                        <div style="width: 49%;">
                            <label>제 9조(계약의 중도해지)</label>
                            <p>1.고객은 렌탈 기간 중 이라도 고객의 서면신청으로 회사가 지정하는 장소에 정상적인 상태의 물건을 반환하고 본 계약을 해지할 수 있다.</p>
                            <p>2.전항의 경우 앞면 기재의 계약렌탈료를 일할하여 실사용 기간으로 계산한다.</p>
                            <p>3.회사 또는 고객은 물건성능의 결함으로 수리에 과대한 비용 또는 시간이 필요 할 때에는 그 내용을 상대방에게 통지하여 본 계약을 해지할 수 있다.</p>
                            <label>제 10조(계약의 강제해지)</label>
                            <p>1.고객에게 다음 각 호의 사유가 발생하는 경우 회사는 계약을 해지할 수 있다.</p>
                            <p>1)렌탈료의 납부를 2회 이상 연체할 경우</p>
                            <p>2)어음 또는 수표가 부도 되어 은행의 거래 정지 처분이 있거나 조세공과금의 체납으로 독촉 또는 체납처분을 받을 경우</p>
                            <p>3)사업이 휴/폐업되거나 회사가 해산한 때 또는 파산, 회생절차 개시 등을 신청하거나 기타 신용을 상실한 경우</p>
                            <p>4)물건을 회사의 동의 없이 제 3자에게 양도,담보제공,처분,임대 또는 점유를 이전한 경우</p>
                            <p>5)회사에 제공한 담보재산 및 기타 재산에 대하여 제3자로부터 압류, 경매 또는 기타 강제집행 등을 당한 경우</p>
                            <p>6)고객이 개인 혹은 개인 사업자일 때 사망한 경우 또는 피성년후견,피한정후견의 선고를 받은 경우</p>
                            <p>7)허위자료의 제출 등 정당하지 않은 방법으로 계약을 한 사실이 발견된 경우</p>
                            <p>8)기타 고객이 본 계약의 채무를 이행할 수없을 것으로 인정되는 상당한 사유가 있는 경우</p>
                            <p>2.고객은 회사가 파산또는 청산될 때에 계약을 해지할 수 있다.</p>
                            <label>제 11조(기한 이익 상실)</label>
                            <p>1.제9조,제10조에 따른 계약 해지의 경우 고객에 대한 기한의 이익은 상실되고 계약 해지로 인하여 발생하는 금원 전부를 고객은 회사에게 즉시 지급하여야 한다.</p>
                            <label>제 12조(물건의 반환과 지연 손해금)</label>
                            <p>1.고객은 렌탈 기간 만료일 18시까지 물건의 성능이 정상적인 상태로 회사가 지정하는 장소에 고객의 비용으로 회사에 반환한다. 단 본 계약이 해제 또는 해지되면 고객은 해제 또는 해지 당일 18시까지 물건을 전기의 방법으로 반환하여야 한다.</p>
                            <p>2.고객은 물건의 반환을 지연한 때에는 반환기한의 다음날부터 반환완료일까지의 추가 렌탈료를 지급해야 한다. 이 경우 앞면에 기재된 렌탈료를 일할 계산하여 적용한다.</p>
                            <p>3.렌탈 물건의 반환이 지체되고 있는 도중 렌탈 장비와 관련하여 발생하는 손해애 관하여는 고객에게 책임이 있다.</p>
                            <label>제 13조(지연배상금)</label>
                            <p>고객이 본 계약에 의한 금전 채무의 이행을 지연할 경우 회사는 연이율 20%의 지연배상금을 고객에게 청구 할 수 있다.</p>
                            <label>제 14조(고객의 통지의무)</label>
                            <p>고객은 거래에 필요한 명칭,상호,대표자,주소 등의 신고사항을 변경하거나 사업장의 이전,물건설치장소의 변동 등 기타 본 계약의 이행에 영향을 미치는 사유가 발생하였을때는 사유 발생 즉시 회사에 통지하여야 하며 고객의 통지 불이행으로 인하여 발생하는 손실에 대하여 고객이 전적으로 책임을 지기호 한다.</p>
                            <label>제 15조(신용정보의 제공)</label>
                            <p>회사는 본 계약과 관련하여 고객 및 연대보증인의 객관적 거래 사실에 대한 신용 정보를 신용정보기관이나 계약 관련 기관에 제공할 수 있다.</p>
                            <label>제 16조(관할법원)</label>
                            <p>본 계약에 관한 분쟁이 발생하여 소송할 필요가 있을 때는 소를 제기하는 자의 주소지를 관할하는 법원을 관할법원으로 한다.</p>
                            <label>제 17조(특약사항)</label>
                            <p>본 계약에 정하지 않은 사항이나 기타 본 계약의 규정과 다른 사항을 특약사항으로 약정 할 수 있으며 특약사항에 기재되지 않은 사항에 대하여는 회사와 고객이 따로 서면 합의함으로써 그 효력이 발생한다.</p>
                            <label>제 18조(보험)</label>
                            <p>보험가입은 고객의 선택적 사항이며 보험가입 요청 시 보험료는 추가로 고객이 부담한다.</p>
                        </div>
                    </div>
                </div>
            </div>
        `;

        const fileName = `${store.data.customerName}_${today()}_${store.data.contractNo}_계약서.pdf`
        if (!content) {
            console.error('Ref not found');
            return;
        }

        const options = {
            filename: fileName,
            margin: 5,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }

        const body = document.querySelector('body');
        body.style.lineHeight = '0.3';

        const pdfBlob = await html2pdf().set(options).from(content).toPdf().output('blob').then(data => {
            return data
        });
        await deleteStyle(body);

        const blob = new Blob([pdfBlob], { type: 'application/pdf' });
        // FormData를 사용하여 파일을 생성
        const formData = new FormData();
        formData.append('file', new Blob([blob]), fileName);
        formData.append('contractNo', store.data.contractNo);
        formData.append('seq', params.seq);
        formData.append('companyCode', store.data.companyCode);
        if(isSendMail) {
            formData.append('subject', store.data.companyName+'장비 임대 렌탈 계약서('+store.data.contractNo+')');
            formData.append('content', '안녕하세요.\n\n'+store.data.companyName+'입니다.\n\n장비임대 계약서를 전달드립니다.\n\n'+'계약번호 : '+store.data.contractNo+'\n계약서명일자 : '+store.data.documentCreatedDate);
            formData.append('businessNumber', store.data.businessNumber.replace(/-/gi, ''));
        }

        await axios.put('contract/document/upload', formData, { headers: MULTIPART_HEADER })
            .then(() => hideLoadingScreen());
        alert('저장 및 메일 발송했습니다.');
        hideLoadingScreen();
    }

    const deleteStyle = async (body) => {
        body.style.lineHeight = 'normal';
    }

    const dataURLtoFile = (dataURL, filename) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const fileDownload = () => {
        const url = store.data.contractDocUrl; // 파일의 URL
    
        // 파일 다운로드
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', store.data.contractDocFileName + '.pdf'); // 다운로드할 파일 이름 및 확장자 설정
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // 다운로드 후 링크 삭제
            })
            .catch(error => console.error('Error downloading file:', error));
    };

    return (
        <>
            <div className="overlay" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'none', justifyContent: 'center', alignItems: 'center', zIndex: '9999'}}>
                <div style={{color: 'white', fontSize: '24px', fontWeight: 'bold'}}>계약서를 저장 중 입니다. 잠시만 기다려 주세요.</div>
            </div>
            <Row style={{ textAlign: 'center' }}>
                <Col>
                    <Subject>
                        <h2 className="text-second">렌 탈 계 약 서</h2>
                    </Subject>
                </Col>
            </Row>
            <div className="start-div" style={{ paddingLeft: 22, paddingRight: 22, paddingTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', borderBottomWidth: 1, marginBottom: 10 }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={store.data.logoImageUrl} style={{ width: '60px', height: 'auto', marginRight: '10px' }} />
                        <span style={{ float: 'left', fontSize: 16, fontWeight: 'bold' }}>{store.data.companyName}</span>
                    </div>
                    {store.data.customerSignatureBase64Url && <Button style={{ marginBottom: 10 }} onClick={fileDownload} >계약서 다운로드</Button>}
                </div>
                <table className="table" style={{ borderWidth: 1 }}>
                    <tbody>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>계약번호</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '18%', fontSize: 12 }}>{store.data.contractNo}</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>계약일자</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '17%', fontSize: 12 }}>{dateKoreaFormat(store.data.contractDate)}</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>계약담당자</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '12%', fontSize: 12 }}>{store.data.saleUserName}</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '9%', backgroundColor: '#D8D8D8', fontSize: 12 }}>연락처</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '14%', fontSize: 12 }}>{store.data.telephone ? store.data.telephone : decrypt(store.data.phoneNumber)}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="table" style={{ borderWidth: 1 }}>
                    <tbody>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} colSpan={2} style={{fontSize: 12, fontWeight: 'bold'}}><b>임대인</b></td>
                            <td className='p-1 border' align={'center'} valign={'middle'} colSpan={2} style={{fontSize: 12, fontWeight: 'bold'}}><b>임차인</b></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>회 사 명</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '40%', fontSize: 12 }}>
                                <div className="d-flex justify-content-between align-items-center" style={{position: 'relative'}}>
                                    <span style={{fontSize: 12}}>{store.data.companyName}</span>
                                    <span style={{ marginRight: 20 }}>
                                        {store.data.companySignatureUrl && 
                                            <>
                                                <div style={{ position: 'absolute', display: 'inline-block', top: '-5px' }}>
                                                    <img src={store.data.companySignatureUrl} style={{ marginLeft: 0, width: '25px', height: 'auto' }} />
                                                </div>
                                                <span style={{ bottom: 0, right: 0, backgroundColor: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: 'gray' }}>(인)</span>
                                            </>
                                        }
                                    </span>
                                </div>
                            </td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>회 사 명</td>
                           <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '40%', fontSize: 12  }}>{store.data.customerName}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>주소</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12 }}>{store.data.companyAddress}</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>주소</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12 }}>{store.data.customerAddress}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>사업자번호</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12  }}>{Regex.makeBusinessNumber(store.data.companyBusinessNumber)}</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>사업자번호</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12  }}>{Regex.makeBusinessNumber(store.data.businessNumber)}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>대표자명</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12  }}>{store.data.companyOwner}</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>대표자명</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12  }}>{store.data.owner}</td>
                        </tr>
                    </tbody>
                </table>
                <h6>렌탈 품목 및 요금</h6>
                <table className="table" style={{ borderWidth: 1, textAlign: 'center' }}>
                    <thead>
                        <tr>
                            <th className='border' style={{ width: '10%', fontSize: 12  }}>순번</th>
                            <th className='border' style={{ width: '20%', fontSize: 12  }}>모델명</th>
                            <th className='border' style={{ width: '20%', fontSize: 12  }}>관리번호</th>
                            <th className='border' style={{ width: '20%', fontSize: 12  }}>시리얼번호</th>
                            <th className='border' style={{ width: '10%', fontSize: 12  }}>수량</th>
                            <th className='border' style={{ width: '20%', fontSize: 12  }}>임대료</th>
                        </tr>
                    </thead>
                    <tbody>
                        {store.contractProductList.map((v, i) => {
                            return (
                                <tr key={i}>
                                    <td className='p-1 border' style={{ fontSize: 12 }}>{i + 1}</td>
                                    <td className='p-1 border' style={{ fontSize: 12 }}>{v.model}</td>
                                    <td className='p-1 border' style={{ fontSize: 12 }}>{v.managementNo}</td>
                                    <td className='p-1 border' style={{ fontSize: 12 }}>{v.serialNo}</td>
                                    <td className='p-1 border' style={{ fontSize: 12 }}>{v.qty}</td>
                                    <td className='p-1 border' style={{ fontSize: 12, textAlign: 'right' }}>{(v.monthRentPrice + (v.rentDays*v.dayRentPrice))?.toLocaleString()}</td>
                                </tr>
                            )
                        })}
                        {emptyList.length > 0 &&
                            emptyList.map((v, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='p-1 border'>{i + 1 + (10 - emptyList.length)}</td>
                                        <td className='p-1 border'></td>
                                        <td className='p-1 border'></td>
                                        <td className='p-1 border'></td>
                                        <td className='p-1 border'></td>
                                        <td className='p-1 border'></td>
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <td className='p-1 border' align={'right'} colSpan={5}>
                                <span style={{marginRight: 10, fontWeight: 'bold', fontSize: 12}}>소계 (VAT별도)</span>
                            </td>
                            <td className='p-1 border' valign={'middle'} align={'right'} style={{fontSize: 12}}>{(store.contractProductList.reduce((total, v) => total + v.monthRentPrice + (v.rentDays*v.dayRentPrice), 0))?.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'right'} colSpan={5}>
                                <span style={{marginRight: 10, fontWeight: 'bold', fontSize: 12}}>합계 (VAT포함)</span>
                            </td>
                            <td className='p-1 border' valign={'middle'} align={'right'} style={{fontWeight: 'bold', fontSize: 12}}>{((store.contractProductList.reduce((total, v) => total + v.monthRentPrice + (v.rentDays*v.dayRentPrice), 0)) * 1.1)?.toLocaleString()}원</td>
                        </tr>
                    </tbody>
                </table>
                <h6>계약정보</h6>
                <table className="table" style={{ borderWidth: 1 }}>
                    <tbody>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>계약개시일</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '23%', fontSize: 12  }}>{`${dateKoreaFormat(store.data.contractStartDate)}`}</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>청구방법</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '23%', fontSize: 12  }}>{findCommKrnm(store.data.paymentType, '17')}</td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>결제일</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '23%', fontSize: 12  }}>{`${findCommKrnm(store.data.dueDateType, '22')} ${store.data.dueDateDay === 0 ? '말일' : store.data.dueDateDay} 결제`}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>설치장소</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12 }} colSpan={5}>{store.data.installPlace || ''}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>반납장소</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12 }} colSpan={5}>{store.data.companyReturnAddress}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12  }}>운송비</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '25%', fontSize: 12  }} colSpan={5}>
                                <span style={{fontSize: 12}}>{store.data.deliveryPrice?.toLocaleString()||0}원</span>
                                <div className="form-check form-check-inline" style={{ marginLeft: 15 }}>
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" onChange={() => { }} checked={store.data.isDeliveryPrice && store.data.chargeDelivery === '1' ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1" style={{fontSize: 12}}>고객부담</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="2" onChange={() => { }} checked={store.data.isDeliveryPrice && store.data.deliveryKind === '1' ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2" style={{fontSize: 12}}>왕복</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="3" onChange={() => { }} checked={store.data.isDeliveryPrice && store.data.deliveryKind === '2' ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox3" style={{fontSize: 12}}>편도</label>
                                </div>,
                                <div className="form-check form-check-inline" style={{ marginLeft: 15 }}>
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="4" onChange={() => { }} checked={store.data.isDeliveryPrice && store.data.chargeDelivery === '2' ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1" style={{fontSize: 12}}>당사부담</label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div style={{ width: '48%', paddingRight: '10px', borderRight: '1px solid #cececf' }}>
                        <h6>특약사항</h6>
                        <textarea 
                            rows={rows} 
                            style={{ width: '100%', padding: 0, fontSize: 11, borderWidth: 0, resize: 'none',  }} 
                            readOnly={true} 
                            value={store.data.specialRemark||'별도 특약사항 없음'}
                        />
                    </div>
                    <div style={{ width: '48%' }}>
                        <h6>확인사항</h6>
                        <table style={{ width: '100%', fontSize: 9 }}>
                            <caption style={{ padding: 0, fontSize: 9 }}>*렌탈 물건의 소유권은 {store.data.companyName}에 있습니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*현장 및 사용자 요청에 따른 옵션장착으로 인한 사고는 고객이 책임집니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*렌탈 물건 인도 및 인수 시 상/하차 업무 및 그에따른 부대비용은 고객이 부담합니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*렌탈료 연체 또는 렌탈 물건의 임의처분 및 전대 등 소유권 제한, 침해가 있으면 계약은 강제 해지되고 물건은 반환될 수 있습니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*렌탈료 연체 또는 물건 반환 지연에 따른 지연손해금 등에따른 불이익을 받으실 수 있습니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*계약기간 중 물건의 현장이동이 있을 경우 고객은 회사에 고지 의무를 다하며 회사와 협의가 필요합니다.</caption>
                        </table>
                    </div>
                </div>
                <h6 style={{ marginTop: 10 }}>계약대리인</h6>
                <table className="table" style={{ borderWidth: 1 }}>
                    <tbody>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>계약 담당자</td>
                            <td className='p-1 border placeholder-color' align={'left'} valign={'middle'} style={{ width: '22%' }}><input className="essential-input" style={{ width: '100%', fontSize: 12  }} value={store.data.contractManager || ''} onChange={(v) => store.data.contractManager = v.target.value} placeholder="이름을 입력해주세요.(필수)" disabled={store.data.customerSignatureUrl}/></td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>소속</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '22%' }}><input style={{ width: '100%', fontSize: 12 }} value={store.data.contractManagerBelong || ''} onChange={(v) => store.data.contractManagerBelong = v.target.value} placeholder="소속을 입력해주세요." disabled={store.data.customerSignatureUrl}/></td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{ width: '10%', backgroundColor: '#D8D8D8', fontSize: 12 }}>연락처</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{ width: '22%' }}><input className="essential-input" style={{ width: '100%', fontSize: 12 }} value={decrypt(store.data.contractManagerPhone) || ''} onChange={(v) => store.data.contractManagerPhone = encrypt(v.target.value)} placeholder="연락처를 입력해주세요.(필수)" disabled={store.data.customerSignatureUrl}/></td>
                        </tr>
                        <tr>
                            <td className='p-1' align={'center'} valign={'middle'} colSpan={3} style={{ height: 100, border: '1px solid transparent' }}></td>
                            <td className='p-1' align={'center'} valign={'middle'} style={{ fontWeight: 'bold', border: '1px solid transparent', fontSize: 12 }}>담당자대리인</td>
                            <td className='p-1' align={'left'} valign={'middle'} colSpan={2} style={{ border: '1px solid transparent' }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>{store.data.contractManager}</span>
                                    <span>
                                        {store.data.customerSignatureUrl ? (
                                            <div style={{ position: 'relative', marginLeft: 'auto' }}>
                                                <PreviewImage height={'50px'} imageUrl={store.data.customerSignatureUrl} />
                                                <span style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: 0, padding: '2px 4px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>(서명)</span>
                                            </div>
                                        ) : (
                                            <div style={{ position: 'relative', marginLeft: 'auto' }}>
                                                <Button className="signatureBtn" style={{ marginLeft: 'auto' }} onClick={handleSignatureClick}>서명하기</Button>
                                                <span style={{ marginLeft: 'auto' }}>(서명)</span>
                                            </div>
                                        )}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <div>
                    <div style={{ borderWidth: 1, width: '100%', height: 40, paddingTop: 9, textAlign: 'center', backgroundColor: '#D8D8D8' }}>
                        <h5>렌탈계약규정</h5>
                    </div>
                    <br />
                    <div className={'report-contract-agree d-flex justify-content-between'}>
                        <div style={{ width: '49%' }}>
                            <label>제 1조(목적)</label>
                            <p>본 규정은 앞면 기재의 렌탈물건(이하 '물건'이라 함)을 임대한 자(이하 '회사'라 함)와 물건을 임차한 자(이하 '고객'이라 함) 사이에 체결된 임대차 계약상의 권리,의무에 관한 사항에 관하여 규정함을 목적으로 한다.</p>
                            <label>제 2조(렌탈기간)</label>
                            <p>1.렌탈 기간은 회사가 고객에게 물건을 인도한 날로부터 계산을 시작하고, 물건을 반환한 날을 렌탈 기간 종료일로 한다.</p>
                            <p>2.렌탈 기간 만료 후 고객으로부터 회사에 서면 또는 유선으로 반납통보가 없으면 렌탈 기간이 자동으로 연장되어 물건을 사용하는 것으로 간주하며 본 계약 사항을 같이 적용한다.</p>
                            <label>제 3조(렌탈료)</label>
                            <p>1.앞 기재의 계약렌탈료는 1개월 단위 또는 1단위의 렌탈료로서 고객은 앞면 기재의 계약렌탈료를 매월 지급하기로 하고 이의 지급기일 및 지급방법은 앞면에 기재된 바에 의한다.</p>
                            <p>2.고객이 본 조 제1항 또는 개별 계약으로 정한 렌탈료에 대하여 지급기한을 도뢰한 경우 고객이 지급기한을 도뢰한 렌탈료를 회사에게 지급전까지 회사는 고객의 발주 및 출고 요청에 대하여 거절할 수 있다.</p>
                            <label>제 4조(물건의 인도)</label>
                            <p>1.회사는 고객이 지정하는 장소에서 물건을 인도하기로 하며 고객은 물건을 인수하면 바로 현장에서 물건을 검사하고 앞면 기재의 현장 담당자 또는 인수담당자가 서명날인 함으로써 정상적인 상태에서 그 물건을 인수한 것임을 확인한다.</p>
                            <p>2.물건의 인도 및 인수에 필요한 운반비 및 현장에서 필요한 물건의 상/하차 업무 및 그에따른 기타 부대비용은 고객이 부담한다.</p>
                            <label>제 5조(물건의 담보책임)</label>
                            <p>1.회사는 고객에게 물건 인도 시 물건이 정상적인 성능을 갖추고 있는 것을 담보 하고 물건의 사용 목적의 적합성에 대하여는 책임지지 않는다.</p>
                            <p>2.렌탈 기간 동안 물건과 관련된 인명사고, 재산상의 피해 등 발생되는 모든 사고는 고객의 책임으로 한다.</p>
                            <p>3.물건의 인도 후 24시간 이내에 고객은 회사에 물건의 성능결함에 대해 서면 및 유선으로 통지하지 않는 경우 물건이 정상적인 성능을 갖추고 인도된 것으로 간주한다.</p>
                            <p>4.전한과 같은 인도 후 회사는 고객 또는 제3자에게 발생한 손해를 책임지지 않는다.</p>
                            <label>제 6조(물건의 유지보수)</label>
                            <p>1.회사는 렌탈 기간 중 고객의 요청이 있으면 물건이 정상적인 상태로 사용할 수 있도록 일반적인 사용으로 인한 마모와 고객의 사용, 설치, 보관 등으로 인하여 발생한 하자에 대한 유지보수를 제공하며 원활한 유지보수를 위해 대행업체를 선정할 수 있다.</p>
                            <p>2.고객은 전항의 유지보수 제공에 문제가 있는 경우 유선 또는 서면으로 회사에 통보해야 하고 통보가 없는 경우에는 정상적인 유지보수 업무가 제공되고 서비스를 받은것으로 간주한다. 또한 물건의 유지보수 완료 후 24시간 이내에 그 유지보수상의 문제를 통보하지 않는 경우 그 유지보수는 정상적으로 완료된 것으로 간주한다.</p>
                            <label>제 7조(물건의 사용보관)</label>
                            <p>1.고객은 물건의 사용, 보관함에 있어 주의의무를 다하여야 하며, 사용 및 보관에 필요한 비용을 부담하여야 한다.</p>
                            <p>2.고객은 물건을 양도,전대,개조하지 못함은 물론 물건을 앞면 기재의 설치장소 외 곳으로 이동시키지 못하며, 물건에 부착된 회사의 소유권표시, 교정표식 등을 제거,훼손해서는 안된다.</p>
                            <p>3.고객의 필요에 의하여 물건의 이동 및 개조를 할 경우 사전에 회사에게 서면으로 통보한 후 회사의 승낙을 득해야 하며 개조 시 물건에 부착된 부속품 등의 종속물은 모두 회사의 소유로 한다.</p>
                            <label>제 8조(물건의 멸실,훼손 및 미반환)</label>
                            <p>1.물건이 멸실(도난,수리불능,소유권의 침해를 포함), 훼손(화재,파손,소유권의 제한,부속품의 부족을 포함), 미반환의 경우 고객은 회사에게 대체물건의 구입비용 또는 물건의 수리비용을 손해배상금으로 지급해야 한다.</p>
                            <p>2.고객은 물건의 훼손에 의한 수리기간동안 물건의 사용여부에 관계없이 렌탈 기간 중에는 렌탈료를 지급해야한다. 렌탈 기간 만료 이후 물건을 수리할 경우에는 그 수리에 피요한 기간 및 물건을 재구매할 경우에는 관련 제반 업무 등에 소요되는 기간 동안의 영업손해를 청구할 수 있다.</p>
                            <p>3.고객은 물건반환 시 물건의 오염도에 따라 별도의 세차비용을 부담하여야 한다.</p>
                        </div>
                        <div style={{ width: '49%' }}>
                            <label>제 9조(계약의 중도해지)</label>
                            <p>1.고객은 렌탈 기간 중 이라도 고객의 서면신청으로 회사가 지정하는 장소에 정상적인 상태의 물건을 반환하고 본 계약을 해지할 수 있다.</p>
                            <p>2.전항의 경우 앞면 기재의 계약렌탈료를 일할하여 실사용 기간으로 계산한다.</p>
                            <p>3.회사 또는 고객은 물건성능의 결함으로 수리에 과대한 비용 또는 시간이 필요 할 때에는 그 내용을 상대방에게 통지하여 본 계약을 해지할 수 있다.</p>
                            <label>제 10조(계약의 강제해지)</label>
                            <p>1.고객에게 다음 각 호의 사유가 발생하는 경우 회사는 계약을 해지할 수 있다.</p>
                            <p>1)렌탈료의 납부를 2회 이상 연체할 경우</p>
                            <p>2)어음 또는 수표가 부도 되어 은행의 거래 정지 처분이 있거나 조세공과금의 체납으로 독촉 또는 체납처분을 받을 경우</p>
                            <p>3)사업이 휴/폐업되거나 회사가 해산한 때 또는 파산, 회생절차 개시 등을 신청하거나 기타 신용을 상실한 경우</p>
                            <p>4)물건을 회사의 동의 없이 제 3자에게 양도,담보제공,처분,임대 또는 점유를 이전한 경우</p>
                            <p>5)회사에 제공한 담보재산 및 기타 재산에 대하여 제3자로부터 압류, 경매 또는 기타 강제집행 등을 당한 경우</p>
                            <p>6)고객이 개인 혹은 개인 사업자일 때 사망한 경우 또는 피성년후견,피한정후견의 선고를 받은 경우</p>
                            <p>7)허위자료의 제출 등 정당하지 않은 방법으로 계약을 한 사실이 발견된 경우</p>
                            <p>8)기타 고객이 본 계약의 채무를 이행할 수없을 것으로 인정되는 상당한 사유가 있는 경우</p>
                            <p>2.고객은 회사가 파산또는 청산될 때에 계약을 해지할 수 있다.</p>
                            <label>제 11조(기한 이익 상실)</label>
                            <p>1.제9조,제10조에 따른 계약 해지의 경우 고객에 대한 기한의 이익은 상실되고 계약 해지로 인하여 발생하는 금원 전부를 고객은 회사에게 즉시 지급하여야 한다.</p>
                            <label>제 12조(물건의 반환과 지연 손해금)</label>
                            <p>1.고객은 렌탈 기간 만료일 18시까지 물건의 성능이 정상적인 상태로 회사가 지정하는 장소에 고객의 비용으로 회사에 반환한다. 단 본 계약이 해제 또는 해지되면 고객은 해제 또는 해지 당일 18시까지 물건을 전기의 방법으로 반환하여야 한다.</p>
                            <p>2.고객은 물건의 반환을 지연한 때에는 반환기한의 다음날부터 반환완료일까지의 추가 렌탈료를 지급해야 한다. 이 경우 앞면에 기재된 렌탈료를 일할 계산하여 적용한다.</p>
                            <p>3.렌탈 물건의 반환이 지체되고 있는 도중 렌탈 장비와 관련하여 발생하는 손해애 관하여는 고객에게 책임이 있다.</p>
                            <label>제 13조(지연배상금)</label>
                            <p>고객이 본 계약에 의한 금전 채무의 이행을 지연할 경우 회사는 연이율 20%의 지연배상금을 고객에게 청구 할 수 있다.</p>
                            <label>제 14조(고객의 통지의무)</label>
                            <p>고객은 거래에 필요한 명칭,상호,대표자,주소 등의 신고사항을 변경하거나 사업장의 이전,물건설치장소의 변동 등 기타 본 계약의 이행에 영향을 미치는 사유가 발생하였을때는 사유 발생 즉시 회사에 통지하여야 하며 고객의 통지 불이행으로 인하여 발생하는 손실에 대하여 고객이 전적으로 책임을 지기호 한다.</p>
                            <label>제 15조(신용정보의 제공)</label>
                            <p>회사는 본 계약과 관련하여 고객 및 연대보증인의 객관적 거래 사실에 대한 신용 정보를 신용정보기관이나 계약 관련 기관에 제공할 수 있다.</p>
                            <label>제 16조(관할법원)</label>
                            <p>본 계약에 관한 분쟁이 발생하여 소송할 필요가 있을 때는 소를 제기하는 자의 주소지를 관할하는 법원을 관할법원으로 한다.</p>
                            <label>제 17조(특약사항)</label>
                            <p>본 계약에 정하지 않은 사항이나 기타 본 계약의 규정과 다른 사항을 특약사항으로 약정 할 수 있으며 특약사항에 기재되지 않은 사항에 대하여는 회사와 고객이 따로 서면 합의함으로써 그 효력이 발생한다.</p>
                            <label>제 18조(보험)</label>
                            <p>보험가입은 고객의 선택적 사항이며 보험가입 요청 시 보험료는 추가로 고객이 부담한다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <Modal title={'서명'} size={isMobileDevice ? 'xl' : 'lg'}>
                <ContractSignaturePopup signatureRef={signatureRef} contentRef={contentRef} emptyList={emptyList} />
            </Modal>
            <ConfirmMailModal callbackFn={handleSave} />
        </>
    );
}

export default observer(ContractLinkPage);

import { observer } from "mobx-react-lite";
import { CIconBtn, CInputGroup, Subject } from "../../components/CustomContainer";
import { useState, useRef, useEffect } from "react";
import { CContainer, CCol, CalendarGroup, CSaveBtn, CCancelBtn, CInputDoubleGroup, CSelectGroup } from "../../components/CustomContainer";
import { Row, Col, InputGroup, Form, Button, Badge, Stack } from "react-bootstrap";
import { AsStore } from "../../store/asset/AsStore";
import { AppStore } from "../../store/AppStore";
import axios from "axios";
import { showToast, decrypt, encrypt } from "../../common/utils";
import UserSearch from "../../components/searchModal/UserSearch";
import AgGridContainer from "../../components/AgGridContainer";
import { ICON_ADD, ICON_SAVE, USER_INFO } from "../../common/constants";
import AssetSearch from "../../components/searchModal/AssetSearch";
import { SiteSearchStore } from "../../store/search/SiteSearchStore";
import Modal from "../../components/Modal";
import { callConfirm, getLocalStorage } from "../../utils";
import { ConsumableStore } from "../../store/search/ConsumableStore";
import { getCommcode } from "../../utils/commcode";
import { getUserName } from "../../utils/userUtils";
import { autoHyphen } from "../../common/utils";
import Address from "../../components/Address";
import CustomerSearch from "../../components/searchModal/CustomerSearch";

const AsDetailPopup = ({getAsList}) => {
    const gridRef = useRef();
    const maintenanceGridRef = useRef();
    const userInfo = getLocalStorage(USER_INFO);

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [assetNo, setAssetNo] = useState('');
    const [label, setLabel] = useState('list');
    const [userColumn, setUserColumn] = useState('');
    const [btnStatus, setBtnStatus] = useState('add');
    const [managerList, setManagerList] = useState([]);
    const [acceptDetail, setAcceptDetail] = useState('안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n고객명: \n고객주소: \n담당자: \n연락처: \n자산번호: \n접수내용: ');
    const [assetNoList, setAssetNoList] = useState([]);
    const [acceptContent, setAcceptContent] = useState('');
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [inputCustomerName, setInputCustomerName] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [inputAssetNo, setInputAssetNo] = useState('');
    const [assetNoData, setAssetNoData] = useState([]);

    useEffect(()=>{
        setIsReadOnly(AsStore.data.asSeq ? true : false);
        setAcceptDetail(AsStore.data.acceptDetail ? AsStore.data.acceptDetail : acceptDetail);
        
        if (AsStore.data.asSeq) {
            // AS 담당자 배정 값 넣어주기
            AsStore.data.asManagerList.map(v => managerList.push({userSeq: v.userSeq, name: v.name, phoneNumber: v.phoneNumber}))
            AsStore.data.asManagerName = managerList.map(user => user.name).join(' / ');

            // 접수 내용
            const index = AsStore.data.acceptDetail.indexOf('접수내용:');
            const content = AsStore.data.acceptDetail.substring(index + 6);

            // 주소 셋팅
            setPostcode(AsStore.data.sitePostcode);
            setAddress(AsStore.data.siteAddress);
            setAddressDetail(AsStore.data.siteAddressDetail);

            setAcceptContent(content);
        }

    }, [AsStore.data.asStatus, AsStore.data.asSeq]);

    useEffect(()=> {
        makeAcceptDetails();
    }, [AsStore.data.customerName, AsStore.data.siteManagerName, AsStore.siteManagerPhone, postcode, address, addressDetail, acceptContent]);

    /* 업체명 엔터키 이벤트 */
    const customerNameEnterKeyFn = async () => {
        const result = await axios.get('/customer/list', {params: {customerName: AsStore.data.customerName}});

        if (result.length === 1) {
            AsStore.data.customerName = result[0].customerName;
            AsStore.data.customerNo = result[0].customerNo;
        } else {
            setInputCustomerName(AsStore.data.customerName);
            setCustomerData(result);
            AsStore.data.customerNo = '';
            AppStore.toggleCustomerSearchModal();
        }
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {

        if (!userInfo || !column) {
            return false;
        }

        if (userInfo.constructor === Array) {
            userInfo.map(v => managerList.push({userSeq: v.userSeq, name: v.name, phoneNumber: v.phoneNumber}));
        } else {
            managerList.push({userSeq: userInfo.userSeq, name: userInfo.name, phoneNumber: userInfo.phoneNumber});
        }

        // 중복값 제거
        const uniqueArray = [...new Map(managerList.map((m) => [m.userSeq, m])).values()];
        setManagerList(uniqueArray);

        // 이름 나열 출력
        const nameString = uniqueArray.map(user => user.name).join(' / ');
        AsStore.data.asManagerName = nameString;

        setUserColumn('');
    }


    /* AS 접수 내용 만들기 */
    const makeAcceptDetails = () => {

        // 접수할 때 아니면 실행되지 않게 리턴
        if (AsStore.data.asSeq) {
            return false;
        }

        const addressContent = address !== '' ? address + ", " + addressDetail: '';

        // 자산번호 나열
        const assetNoArr = AsStore.data.managementNoList.map(v => {return v.assetNo});

        let content = '안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n고객명: ' + AsStore.data.customerName + '\n주소: ' + addressContent + '\n담당자: ' + AsStore.data.siteManagerName + '\n연락처: ' + (autoHyphen(AsStore.data.siteManagerPhone) || '') + '\n자산번호: ' + assetNoArr.join(',') + '\n접수내용: ' + acceptContent;
        setAcceptDetail(content);
    }

    /* AS 접수 생성 */
    const saveAsAccept = async () => {
        
        AsStore.data.acceptDetail = acceptDetail;
        
        let requiredParams = ['customerName', 'siteManagerName', 'siteManagerPhone', 'acceptDetail'];
        if (!requiredParams.every(param => AsStore.data[param]) || managerList.length === 0) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }
        
        let asUrl = window.location.protocol + "//" + window.location.host + "/as/accept/detail/";
        let params = {...AsStore.data
                        , siteManagerPhone: encrypt(AsStore.data.siteManagerPhone)
                        , asManagerList: managerList
                        , asUrl: asUrl
                        , sitePostcode: postcode
                        , siteAddress: address
                        , siteAddressDetail: addressDetail
                        , managementNoList: AsStore.data.managementNoList
                    };

        if (!await callConfirm('AS를 접수하시겠습니까?')) {
            return false;
        };
        const result = await axios.post('/as', params);
        if(result.result === 'option_failed') {
            alert(result.message);
        }else {
            showToast('AS 접수가 완료되었습니다.');
            
            if (result.fail > 0) {
                alert('알림톡 전송 성공: ' + result.success + '건 \n전송 실패: ' + result.fail + '건');
            }
            
            AppStore.toggleAsDetail();
            getAsList();
        }
    }

    /* AS 접수 상태 변경 */
    const changeStatus = async (status) => {

        let isSendSms = 0;
        let msg = '';
        let resultMsg = '';

        switch (status) {
            case '2' : msg = 'A/S를 접수하시겠습니까?'; resultMsg = 'A/S를 접수하였습니다.' ; break;
            case '3' : msg = '출발 처리 하시겠습니까?'; resultMsg = '출발 처리하였습니다.' ; break;
            case '4' : msg = '도착 처리 하시겠습니까?'; resultMsg = '도착 처리하였습니다.' ; break;
            case '8' : msg = '미결 처리 하시겠습니까?'; resultMsg = '미결 처리하였습니다.' ; break;
            case '9' : msg = 'A/S를 취소하시겠습니까?'; resultMsg = 'A/S를 취소하였습니다.' ; break;
        }

        if(!await callConfirm(msg)) {
            return false;
        }
        
        if(status === '2' && AsStore.data.siteManagerPhone !== null && AsStore.data.siteManagerPhone !== '') {
            isSendSms = await callConfirm('담당자에게 알림톡을 전송하시겠습니까?') ? 1 : 0;
        }

        // 추가된 자산번호만 새로 저장해주기
        const createdList = AsStore.data.managementNoList.filter(v => v.isCreated);

        const params = {asSeq: AsStore.data.asSeq, asStatus: status, acceptDetail: acceptDetail, managementNoList: createdList, isSendSms: isSendSms, shortUrl: AsStore.data.shortUrl};

        await axios.put('/as', params);
        showToast(resultMsg);
        AppStore.toggleAsDetail();
        getAsList();
    }

    /* AS 처리 완료 */
    const completeAs = async () => {
        AsStore.data.managementNoList = AsStore.data.managementNoList ? AsStore.data.managementNoList : [];
        AsStore.data.processDetail = AsStore.data.processDetail ? AsStore.data.processDetail : '';

        let isSendSms = 0;

        // AS 상태 변경, 정비 테이블에 추가
        if (AsStore.data.managementNoList.length === 0 || AsStore.data.processDetail === '') {
            showToast('처리 내역 및 자산번호 목록을 모두 작성해주세요.');
            return false;
        }

        if (!AsStore.data.managementNoList.every(param => param.content)) {
            showToast('정비내역을 모두 입력해주세요.');
            return false;
        }

        if (!await callConfirm('AS를 완료 처리 하시겠습니까?')) {
            return false;
        }

        if(AsStore.data.siteManagerPhone !== null && AsStore.data.siteManagerPhone !== '') {
            isSendSms = await callConfirm('담당자에게 알림톡을 전송하시겠습니까?') ? 1 : 0;
        }

        AsStore.data.acceptDetail = acceptDetail;
        const asStatus = await axios.put('/as/complete', {...AsStore.data, isSendSms});
        AsStore.data.asStatus = asStatus;

        showToast('AS가 완료되었습니다.');
        AppStore.toggleAsDetail();
        getAsList();
    }

    /* 소모품 저장 버튼 커스텀*/
    const modConsumItemBtn = {
        isUsed: AsStore.data.asSeq ? true : false,
        callbackFn:() => modConsum(),
        icon: ICON_SAVE,
        title: '저장',
        width: 110,
    }

    /* 자산 추가 버튼 */
    const customAddBtnInfo = {
        isUsed: ['1','2','3','4'].includes(AsStore.data.asStatus),
        callbackFn: () =>  AppStore.toggleAssetSearchModal(),
        title: '자산번호 추가',
        icon: ICON_ADD,
        width: 130,
    };

    /* 자산번호 엔터키 이벤트 */
    const openAssetSearchPopup = async (e, type) =>{
        setLabel(type);

        if (type === 'input') {
            const params = {assetNo: AsStore.data.assetNo, assetStatus: '302'};
            const result = await axios.get('/asset/list', { params });
            ConsumableStore.search.assetNo = AsStore.data.assetNo;

            if(result.length === 1) {
                AsStore.data.assetNo = result[0].assetNo;
                AsStore.data.customerName = result[0].customerName;
                AsStore.data.customerNo = result[0].customerNo;
                AsStore.data.siteSeq = result[0].siteSeq;
                AsStore.data.siteName = result[0].siteName !== null ? result[0].siteName : '';
                AsStore.data.siteManagerName = result[0].managerName !== null ? result[0].managerName : '';
                AsStore.data.siteManagerPhone = decrypt(result[0].managerPhoneNumber);
                setPostcode(result[0].sitePostcode);
                setAddress(result[0].siteAddress !== null ? result[0].siteAddress : '');
                setAddressDetail(result[0].siteAddressDetail !== null ? result[0].siteAddressDetail: '');

                let uniqueArray = AsStore.data.managementNoList;
                let seq = AsStore.data.managementNoList.length + 1;
                uniqueArray.push({addRowId: seq++, assetNo: result[0].assetNo, model: result[0].model, content: '', isSelected: true, isCreated: true});
                uniqueArray = [...new Map(uniqueArray.map((m) => [m.assetNo, m])).values()];
                AsStore.data.managementNoList = uniqueArray;

                makeAcceptDetails();
                AsStore.data.assetNo = '';
            } else {
                setInputAssetNo(AsStore.data.assetNo);
                setAssetNoData(result);
                AppStore.toggleAssetSearchModal();
                AsStore.data.assetNo = '';
            }
        } else if (type === 'list') {
            const params = {assetNo: assetNo};
            const result = await axios.get('/asset/list', { params });

            ConsumableStore.search.assetNo = assetNo;
            AsStore.data.managementNoList = AsStore.data.managementNoList ? AsStore.data.managementNoList : [];
    
            if(result.length === 1) {
                let seq = AsStore.data.managementNoList.length + 1;
                
                if(!AsStore.data.managementNoList.some(v => v.assetNo === result[0].assetNo)){
                    const additionalRowInfo = {
                        addRowId: seq++
                        , assetNo: result[0].assetNo
                        , content: ''
                        // , productName: result[0].productName
                        , model: result[0].model
                        // , productCode: result[0].productCode
                        // , assetNo: result[0].assetNo
                        // , serialNo: result[0].serialNo
                        // , spec: result[0].spec
                        , isSelected: true
                        , isCreated: true
                    }
                    AsStore.data.managementNoList.push({...additionalRowInfo});
                    AsStore.data.customerName = result[0].customerName;
                    AsStore.data.customerNo = result[0].customerNo;
                    AsStore.data.siteSeq = result[0].siteSeq;
                    AsStore.data.siteName = result[0].siteName !== null ? result[0].siteName : '';
                    AsStore.data.siteManagerName = result[0].managerName !== null ? result[0].managerName : '';
                    AsStore.data.siteManagerPhone = decrypt(result[0].managerPhoneNumber);
                    setPostcode(result[0].sitePostcode);
                    setAddress(result[0].siteAddress !== null ? result[0].siteAddress : '');
                    setAddressDetail(result[0].siteAddressDetail !== null ? result[0].siteAddressDetail: '');
                }
            } else {
                AppStore.toggleAssetSearchModal();
            }

            setAssetNo('');
        }

        setLabel('list');
    }

    /* 자산 조회 콜백 함수 */
    const callbackAssetInfo = (data) => {
        if (label === 'list') {
            AsStore.data.managementNoList.push({assetNo: data.assetNo
                                                    , content: ''
                                                    // , productName: data.productName
                                                    , model: data.model
                                                    // , productCode: data.productCode
                                                    // , assetNo: data.assetNo
                                                    // , serialNo: data.serialNo
                                                    // , spec: data.spec
                                                    , isSelected: true
                                                    , isCreated: true
                                                })
            let uniqueArray = AsStore.data.managementNoList;
            let seq = AsStore.data.managementNoList.length + 1;
            uniqueArray.push({addRowId: seq++, assetNo: data.assetNo, model: data.model, content: '', isSelected: true, isCreated: true});
            uniqueArray = [...new Map(uniqueArray.map((m) => [m.assetNo, m])).values()];
            AsStore.data.managementNoList = uniqueArray;

            if (!AsStore.data.asSeq) {
                makeAcceptDetails();
            }
        } else if (label === 'input') {
            AsStore.data.assetNo = data.assetNo;
            AsStore.data.customerName = data.customerName;
            AsStore.data.customerNo = data.customerNo;
            AsStore.data.siteManagerName = data.managerName;
            AsStore.data.siteManagerPhone = decrypt(data.managerPhoneNumber);
            
            // 중복제거
            const uniqueArray = [...new Set([...assetNoList, data.assetNo])];
            setAssetNoList(uniqueArray);
        }

        AsStore.data.customerName = data.customerName;
        AsStore.data.customerNo = data.customerNo;
        AsStore.data.siteSeq = data.siteSeq;
        AsStore.data.siteName = data.siteName !== null ? data.siteName : '';
        AsStore.data.siteManagerName = data.managerName !== null ? data.managerName : '';
        AsStore.data.siteManagerPhone = decrypt(data.managerPhoneNumber);
        setPostcode(data.sitePostcode);
        setAddress(data.siteAddress !== null ? data.siteAddress : '');
        setAddressDetail(data.siteAddressDetail !== null ? data.siteAddressDetail: '');
    }

    /* AS 모달 닫기 */
    const closeModal = () => {
        AsStore.init();
        setAcceptDetail('안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n고객명: \n주소: \n담당자: \n연락처: \n자산번호: \n접수내용: ');
        setAssetNoList([]);
        setManagerList([]);
        setAssetNo('');
        setAcceptContent('');
        setPostcode('');
        setAddress('');
        setAddressDetail('');
    };

    /* AS 담당자 비우기 버튼 */
    const onEmptyBtn = () => {
        AsStore.data.asManagerName = '';
        setManagerList([]);
    }

    /* 자산번호 뱃지 삭제 버튼 이벤트 */
    const cancelNo = (v) => {
        let resultList = [...assetNoList];

        for (let i = 0; i < assetNoList.length; i++) {
            if (assetNoList[i] === v) {
                resultList.splice(i, 1);
            }
        }
        setAssetNoList(resultList);
    }

    /* 체크박스 클릭 이벤트 */
    const getCheckboxClickEvent = () => {
        const selectedList = AsStore.data.managementNoList.filter(v => v.isSelected);
        makeAcceptDetails();
    }

    /* 접수내용 수정 */
    const changeAcceptContent = (e) => {
        setAcceptContent(e.target.value); 
        makeAcceptDetails();
    }

    /* 자산번호 그리드 삭제 */
    const removeFn = async (e) => {
        const { asSeq, assetNo } = e.data;
        if (!await callConfirm(`자산번호[${assetNo}] 삭제 하시겠습니까?`)) {
            return false;
        }

        await axios.delete('/as/managementNo', {params: {asSeq: asSeq, assetNo: assetNo}});
        showToast('삭제 되었습니다.');

        getAsassetNoList();
    }

    /* 자산번호 불러오기 */
    const getAsassetNoList = async() => {
        const result = await axios.get("/as/managementNo", {params: {asSeq: AsStore.data.asSeq}});
        AsStore.data.managementNoList = result;
    }

    const appendTitle = () => {
        return (
            <div className="text-danger">정비내역은 AS완료 시에만 저장됩니다.</div>
        )
    }

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    const getCustomerInfo =async(data) => {
        AsStore.data.customerName = data.customerName;
        AsStore.data.customerNo = data.customerNo;
        makeAcceptDetails();
    }

    return(
        <>
            <Modal
                title={'AS 접수'}
                show={AppStore.isOpenAsDetail}
                onHide={() => AppStore.toggleAsDetail()}
                toggleValue={AppStore.isOpenAsDetail}
                size={'lg'}
                onExit={closeModal}
            >
                <CContainer search>
                    {AsStore.data.asSeq && 
                    <>
                        <Row>
                            <CCol lg={4}>
                                <CInputGroup label={'AS번호'}
                                        labelId={'asSeq'}
                                        value={AsStore.data.asSeq || ''}
                                        disabled={true}             
                                />
                            </CCol>
                            <CCol lg={4}>
                                <CSelectGroup label={'AS상태'} labelId={'asStatus'}
                                            placeholder={'AS상태'}
                                            options={[{name: 'AS상태', value: ''}].concat(getCommcode('55'))}
                                            value={AsStore.data.asStatus || ''}
                                            disabled={true}
                                />
                            </CCol>
                            <CCol lg={4}>
                                <CInputGroup label={'AS처리자'}
                                        labelId={'asManagerSeq'}
                                        value={getUserName(AsStore.data.asManagerSeq) || ''}
                                        disabled={true}             
                                />
                            </CCol>
                        </Row>
                        <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#e8e6e6', marginTop: -5, marginBottom: 10, marginLeft: 10, marginRight: 10}}/>                    
                    </>
                    }
                        <Row>
                        <CCol lg={5}>
                            <CInputGroup
                                label={'고객명'}
                                labelId={'customerName'}
                                value={AsStore.data.customerName || ''}
                                onChange={e => AsStore.data.customerName = e.target.value}
                                enterKeyHint={customerNameEnterKeyFn}
                                onCallbackBtn={openSearchCustomerPopup}
                                disabled={isReadOnly}
                                btnDisabled={isReadOnly}
                                placeholder={'고객명 입력'}
                            />
                        </CCol>
                        <CCol lg={2}><div style={{textAlign: 'center'}}>또는</div></CCol>
                        <CCol lg={5}>
                            <CInputGroup
                                label={'자산번호'}
                                labelId={'assetNo'}
                                value={AsStore.data.assetNo || ''}
                                onChange={e => {
                                    AsStore.data.assetNo = e.target.value; 
                                    makeAcceptDetails();
                                }}
                                enterKeyHint={e => openAssetSearchPopup(e, 'input')}
                                onCallbackBtn={() =>  AppStore.toggleAssetSearchModal()}
                                disabled={isReadOnly}
                                btnDisabled={isReadOnly}
                                placeholder={'자산번호 입력'}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Address label='주소'
                                    setPostcode={setPostcode}
                                    setAddress={setAddress}
                                    setAddressDetail={setAddressDetail}
                                    postcode={postcode}
                                    address={address}
                                    addressDetail={addressDetail}
                                    disabled={isReadOnly}
                                    addressDisabled={isReadOnly}
                                    style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}
                                    btnStyle={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}}
                        />
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'siteManagerName'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>담당자</InputGroup.Text>
                                <Form.Control 
                                    style={{flex: 1}} aria-describedby={'siteManagerName'} 
                                    value={AsStore.data.siteManagerName || ''} 
                                    onChange={v => {
                                        AsStore.data.siteManagerName = v.target.value;
                                        makeAcceptDetails();
                                    }}
                                    // readOnly={true} disabled={isReadOnly} 
                                    disabled={isReadOnly} 
                                    placeholder={'담당자'}
                                />
                                <Form.Control 
                                    style={{flex: 2}} aria-describedby={'siteManagerPhone'} 
                                    value={autoHyphen(AsStore.data.siteManagerPhone) || ''} 
                                    onChange={v => {
                                        AsStore.data.siteManagerPhone = v.target.value;
                                        makeAcceptDetails();
                                    }} 
                                    disabled={isReadOnly} placeholder={'담당자 전화번호'}
                                />
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'asManager'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>AS 담당자 배정</InputGroup.Text>
                                <Form.Control style={{flex: 1}} aria-describedby={'asManagerName'} value={AsStore.data.asManagerName || ''} onChange={v => AsStore.data.asManagerName = v.target.value} readOnly={true} disabled={isReadOnly}/>
                                <Button className="btn btn-outline-secondary" style={{borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onEmptyBtn} disabled={isReadOnly}>
                                    <i className="fi fi-rr-cross-small" />
                                </Button>
                                <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={() => openSearchUserPopup('asManager')} disabled={isReadOnly}>
                                    <i className="me-1 fi fi-br-search" />
                                </Button>
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'acceptContent'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>접수내용</InputGroup.Text>
                                <textarea className="form-control" rows="2"
                                                value={acceptContent || ''}
                                                onChange={e => changeAcceptContent(e)}
                                                disabled={isReadOnly}
                                                placeholder={'접수내용을 입력해주세요.'}
                                    />
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            {!isReadOnly && ( <div> <span style={{color: 'blue'}}>고객명 / 자산번호</span> 입력 후 Enter키(엔터키)를 눌러주세요. </div> )}
                        </Col>
                    </Row>
                </CContainer>
                <CContainer>
                    <Row>
                        <CCol lg={12}>
                            <div className="mb-3">
                                <Subject><h5>접수 내역</h5></Subject>
                                <textarea className="form-control" rows="10"
                                            value={acceptDetail || ''}
                                            onChange={v => setAcceptDetail(v.target.value)}
                                            disabled={true}
                                />
                            </div>
                        </CCol>
                    </Row>
                    {(AsStore.data.asSeq && ['2','3','4','5'].includes(AsStore.data.asStatus)) && (
                        <Row>
                        <CCol lg={12}>
                            <div className="mb-3">
                                <Subject><h5>처리 내역</h5></Subject>
                                <textarea className="form-control" rows="3"
                                            placeholder="처리 내역을 작성해주세요."
                                            value={AsStore.data.processDetail || ''}
                                            onChange={v => AsStore.data.processDetail = v.target.value}
                                            disabled={!['1','2','3','4'].includes(AsStore.data.asStatus)}
                                />
                            </div>
                        </CCol>
                    </Row>
                    )}
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            { ( AsStore.data.asSeq && AsStore.data.asStatus !== '5') && (
                                <>
                                {/* { (['2','3','4'].includes(AsStore.data.asStatus) && (!AsStore.data.asManagerSeq || AsStore.data.asManagerSeq === userInfo.userSeq)) && */}
                                    <CIconBtn style={{width:110}}
                                            title={'AS 완료'}
                                            onClick={completeAs}
                                            icon={'fi-rr-hammer-crash'}
                                            variant={'primary'}
                                            disabled={!(['2','3','4'].includes(AsStore.data.asStatus) && (!AsStore.data.asManagerSeq || AsStore.data.asManagerSeq === userInfo.userSeq))}
                                    />
                                {/* } */}
                                {/* {(!['4','5'].includes(AsStore.data.asStatus) && (!AsStore.data.asManagerSeq || AsStore.data.asManagerSeq === userInfo.userSeq)) && */}
                                {/* {(!['4','5'].includes(AsStore.data.asStatus)) && */}
                                    <CIconBtn style={{width: 110}}
                                            title={'도착'}
                                            onClick={() => changeStatus('4')}
                                            icon={'fi-rr-marker-time'}
                                            variant={'success'}
                                            disabled={['4','5'].includes(AsStore.data.asStatus) || AsStore.data.asStatus === '1'}
                                    />
                                {/* } */}
                                {/* {(!['3','4','5'].includes(AsStore.data.asStatus)) &&  */}
                                    <CIconBtn style={{width: 110}}
                                            title={'출발'}
                                            onClick={() => changeStatus('3')}
                                            icon={'fi-rr-marker-time'}
                                            variant={'success'}
                                            disabled={['3','4','5'].includes(AsStore.data.asStatus) || AsStore.data.asStatus === '1'}
                                        />
                                {/* } */}
                                {/* {(!['2','3','4','5'].includes(AsStore.data.asStatus)) && */}
                                    <CIconBtn style={{width: 110}}
                                            title={'AS접수'}
                                            onClick={() => changeStatus('2')}
                                            icon={'fi-rr-checkbox'}
                                            variant={'success'}
                                            disabled={['2','3','4','5'].includes(AsStore.data.asStatus)}
                                    />
                                {/* } */}
                                {/* {(!['5'].includes(AsStore.data.asStatus)) && */}
                                    <CIconBtn style={{width: 110}}
                                            title={'미결'}
                                            onClick={() => changeStatus('8')}
                                            icon={'fi-rr-circle-xmark'}
                                            variant={'secondary'}
                                            disabled={['5'].includes(AsStore.data.asStatus)}
                                    />
                                    <CIconBtn style={{width: 110}}
                                            title={'AS 접수 취소'}
                                            onClick={() => changeStatus('9')}
                                            icon={'fi-rr-circle-xmark'}
                                            variant={'danger'}
                                            disabled={['5'].includes(AsStore.data.asStatus)}
                                    />
                                {/* } */}
                                </>
                            )}
                            {!AsStore.data.asSeq && (
                                <CSaveBtn style={{width: 110}}
                                        title={'접수'}
                                        onClick={saveAsAccept}
                                />
                            )}
                        </Col>
                    </Row>
                </CContainer>
                {(['1','2','3','4'].includes(AsStore.data.asStatus) || !AsStore.data.asSeq) && 
                <CContainer>
                    { AsStore.data.asSeq &&
                    <CCol lg={4}>
                    <CInputGroup
                        label={'자산번호'}
                        value={assetNo}
                        onChange={v => setAssetNo(v.target.value)}
                        enterKeyHint={e => openAssetSearchPopup(e, 'list')}
                        />
                    </CCol>
                    }
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'자산 목록'}
                        appendTitle={appendTitle()}
                        height={20}
                        rowData={AsStore.data.managementNoList}
                        columnDefs={[
                            {field: 'seq', headerName: '순번', valueGetter: 'node.rowIndex + 1', width: 80},
                            {field: 'managementNo', headerName: '관리번호', width: 110, hide: true},
                            {field: 'assetNo', headerName: '자산번호', width: 110},
                            {field: 'model', headerName: '모델명', width: 160},
                            // {field: 'serialNo', headerName: '시리얼번호', width: 155},
                            {field: 'content', headerName: '정비내역', headerClass: 'grid-column-required', editable: true, flex: 1},
                        ]}
                        isCheckBox={true}
                        getCheckboxClickEvent={getCheckboxClickEvent}
                        seqColumn={'agId'}
                        customBtnInfo={[customAddBtnInfo]}
                        useRemoveRow={true}
                        // useUpdated={true}
                        // callBackGridData={saveFn}
                        removeCallback={removeFn}
                    />
                </CContainer>
                }
                {(AsStore.data.asSeq && AsStore.data.asStatus === '5') && (
                    <>
                        <CContainer>
                            <AgGridContainer
                                gridRef={maintenanceGridRef}
                                gridTitle={'정비 목록'}
                                height={20}
                                rowData={AsStore.data.maintenanceList}
                                columnDefs={[
                                    {field: 'seq', headerName: '순번', valueGetter: 'node.rowIndex + 1', width: 90},
                                    {field: 'maintenanceSeq', headerName: '정비번호', width: 100},
                                    {field: 'managementNo', headerName: '관리번호', width: 120, hide: true},
                                    {field: 'assetNo', headerName: '자산번호', width: 120},
                                    {field: 'content', headerName: '정비일지', flex: 1},
                                ]}
                                seqColumn={'agId'}
                                // useRowSearch={true}
                            />
                        </CContainer>
                    </>
                )}
                <CustomerSearch callbackFn={getCustomerInfo} gridData={customerData} inputCustomerName={AsStore.data.customerName}/>
                <UserSearch callbackFn={getUserInfo} column={userColumn} btnStatus={btnStatus}/>
                <AssetSearch callbackFn={callbackAssetInfo} isAsManagement={true} inputAssetNo={inputAssetNo} gridData={assetNoData}/>
            </Modal>
        </>
    )

}

export default observer(AsDetailPopup);


import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { CContainer } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import {findCommKrnm} from "../../utils/commcode";
import { showToast } from "../../common/utils";
import NonBillSearch from "./NonBillSearch";
import {NonBillStore} from "../../store/billing/NonBillStore";

const NonBill =()=> {
    const gridRef = useRef();

    const getNonBillList = async() => {
        NonBillStore.setSearch('searchDate', NonBillStore.calendar.startDate);

        const result = await axios.get('/nonBill/list', {params: NonBillStore.search});
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.')
        }
        NonBillStore.setNonBillList(result);
    }

    const pinnedTopRowData = [{
        billingOrgChargeAmount: NonBillStore.nonBillList.reduce((total, v) => total + v.billingOrgChargeAmount, 0),
        billingChargeAmount: NonBillStore.nonBillList.reduce((total, v) => total + v.billingChargeAmount, 0),
        orgChargeAmount: NonBillStore.nonBillList.reduce((total, v) => total + v.orgChargeAmount, 0),
        realChargeAmount: NonBillStore.nonBillList.reduce((total, v) => total + v.realChargeAmount, 0),
        nonBillAmount: NonBillStore.nonBillList.reduce((total, v) => total + v.nonBillAmount, 0),
        delCount: NonBillStore.nonBillList.reduce((total, v) => total + v.delCount, 0)
    }];

    return (
        <>
            <NonBillSearch getNonBillList={getNonBillList}/>
            <CContainer>
                <AgGridContainer
                    gridTitle={'미청구 목록'}
                    gridRef={gridRef}
                    height={66}
                    rowData={NonBillStore.nonBillList}
                    columnDefs={[
                        //{field: "customerNo", headerName: "고객번호", hide: true},
                        {field: "customerName", headerName: "고객명", width: 160},
                        {field: "saleUserName", headerName: "영업담당자", width: 120},
                        {field: "contractNo", headerName: "계약번호", width: 160},
                        {field: "contractType", headerName: "계약구분", width: 110, valueFormatter: v => findCommKrnm(v.value, '1')},
                        //{field: "billingAddressSeq", headerName: "청구지번호", hide: true},
                        {field: "billingMinChargeDate", headerName: "청구기간", width: 120},
                        {field: "billingMaxChargeDate", headerName: "청구기간", width: 120},
                        {
                            field: "orgChargeAmount",
                            headerName: "계산서 발행 대상 금액",
                            width: 180,
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString()
                        },
                        {
                            field: "realChargeAmount",
                            headerName: "계산서 발행 금액",
                            width: 150,
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString()
                        },
                        {
                            field: "nonBillAmount",
                            headerName: "미청구 금액",
                            width: 130,
                            cellClass: 'ag-grid-money-align ag-grid-column-cancel',
                            valueFormatter: v => v.value?.toLocaleString()
                        },
                        {
                            field: "billingOrgChargeAmount",
                            headerName: "청구 스케쥴의 원청구 금액",
                            width: 210,
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString()
                        },
                        {
                            field: "billingChargeAmount",
                            headerName: "청구 스케쥴의 청구 금액",
                            width: 200,
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString()
                        },
                        {
                            field: "billingNonChargeAmount",
                            headerName: "청구 스케쥴의 미청구 금액",
                            width: 210,
                            cellClass: v => v.value > 0 ?  'ag-grid-column-cancel ag-grid-money-align' : 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString()
                        },
                        {
                            field: "delCount",
                            headerName: "삭제된 청구 건수",
                            width: 160,
                            cellClass: v => v.value > 0 ?  'ag-grid-column-cancel' : '',
                            valueFormatter: v => v.value?.toLocaleString()
                        },
                    ]}
                    useCsvDownload={true}
                    pinnedTopRowData={pinnedTopRowData}
                />
            </CContainer>
        </>
    );
}

export default observer(NonBill);

import { lazy, Suspense } from 'react';
import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../components/layout/MainLayout';
//import Dashboard from '../views/Dashboard';
const Dashboard = lazy(() => import('../views/Dashboard'));
const CommCodeList = lazy(() => import('../views/commCode/CommCodeList'));
const Authority = lazy(() => import('../views/human-resources/Authority'));
const AssetList = lazy(() => import('../views/asset/AssetList'));
const AssetHistory = lazy(() => import('../views/asset/AssetHistory'));
const ProductGroupList = lazy(() => import('../views/product/ProductGroupList'));
const ProductList = lazy(() => import('../views/product/ProductList'));
const OrderList = lazy(() => import('../views/product/OrderList'));
const ContractDetail = lazy(() => import('../views/contract/ContractDetail'));
const ContractCreation = lazy(() => import('../views/contract/ContractCreation'));
const ContractList = lazy(() => import('../views/contract/ContractList'));
const Estimate = lazy(() => import('../views/contract/Estimate'));
const CustomerList = lazy(() => import('../views/customer/CustomerList'));
const ReleaseAskList = lazy(() => import('../views/release/ReleaseAskList'));
const ReleaseAskDetail = lazy(() => import('../views/release/ReleaseAskDetail'));
const ReleaseList = lazy(() => import('../views/release/ReleaseList'));
const StorageList = lazy(() => import('../views/storage/StorageList'));
const StorageReturn = lazy(() => import('../views/storage/StorageReturn'));
const StorageAcquisition = lazy(() => import('../views/storage/StorageAssetAcquisition'));
const StorageGoods = lazy(() => import('../views/storage/StorageGoods'));
const StockList = lazy(() => import('../views/asset/StockList'));
const BillingList = lazy(() => import('../views/billing/BillingList'));
const BillManager = lazy(() => import('../views/billing/BillManager'));
const ReceiveManager = lazy(() => import('../views/billing/ReceiveManager'));
const NonPaymentManager = lazy(() => import('../views/billing/NonPaymentManager'));
const AccountList = lazy(() => import('../views/billing/AccountList'));
const AssetReservationList = lazy(() => import('../views/asset/AssetReservationList'));
const AssetExpirationList = lazy(() => import('../views/asset/AssetExpirationList'));
const ReturnAskList = lazy(() => import('../views/return/ReturnAskList'));
const ShipList = lazy(() => import('../views/ship/ShipList'));
const UserList = lazy(() => import('../views/human-resources/UserList'));
const AccountManagementList = lazy(() => import('../views/accounting/AccountManagementList'));
const GeneralStatementList = lazy(() => import('../views/accounting/GeneralStatementList'));
const StatementList = lazy(() => import('../views/accounting/StatementList'));
const CardManagementList = lazy(() => import('../views/accounting/CardManagementList'));
const CardList = lazy(() => import('../views/accounting/CardList'));
const CertificateList = lazy(() => import('../views/accounting/CertificateList'));
const CardStatementList = lazy(() => import('../views/accounting/CardStatementList'));
const RentalList = lazy(() => import('../views/contract/RentalList'));
const SalesList = lazy(() => import('../views/customer/SalesList'));
const WorkLog = lazy(() => import('../views/customer/WorkLog'));
const PaybackList = lazy(() => import('../views/accounting/PaybackList'));
const NonBill = lazy(() => import('../views/billing/NonBill'));
const CustomerLedgerList = lazy(() => import('../views/accounting/CustomerLedgerList'));
// const CertificateList = lazy(() => import('../views/commCode/CertificateList'));
const PurchaseSalesStatementList = lazy(() => import('../views/accounting/PurchaseSalesStatementList'));
const AccountLedgerList = lazy(() => import('../views/accounting/AccountLedgerList'));
const AccountCloseList = lazy(() => import('../views/accounting/AccountCloseList'));
const AsList = lazy(() => import('../views/asset/AsList'));
const AttendanceList = lazy(() => import('../views/human-resources/AttendanceList'));
const SalaryManager = lazy(() => import('../views/human-resources/SalaryManager'));
const SalaryStatusList = lazy(() => import('../views/human-resources/SalaryStatusList'));
const CompanyInfo = lazy(() => import('../views/commCode/CompanyInfo'));
const InsurancePolicyList = lazy(() => import('../views/product/InsurancePolicyList'));
const TaxBillList = lazy(() => import('../views/billing/TaxBillList'));
const ShipSchedule = lazy(() => import('../views/ship/ShipSchedule'));
const MyPage = lazy(() => import('../views/MyPage'));
const StorageConsumOption = lazy(() => import('../views/storage/StorageConsumOption'));
const BrandCodeList = lazy(() => import('../views/product/BrandCodeList'));
const HireAssetManagerList = lazy(() => import('../views/asset/HireAssetManagerList'));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        { 
            index: true, 
            path: '/dashboard', 
            element: (
                <Suspense fallback={<p>loading...</p>}>
                    <Dashboard /> 
                </Suspense>
            )
        },
        { path: '/contract/estimate', element: <Suspense fallback={<p>loading...</p>}><Estimate /></Suspense> },
        { path: '/contract/list', element: <Suspense fallback={<p>loading...</p>}><ContractList /></Suspense> },
        { path: '/contract/detail', element: <Suspense fallback={<p>loading...</p>}><ContractDetail /></Suspense> },
        { path: '/contract/creation', element: <Suspense fallback={<p>loading...</p>}><ContractCreation /></Suspense> },
        { path: '/contract/rentalList', element: <Suspense fallback={<p>loading...</p>}><RentalList /></Suspense> },
        { path: '/asset/list', element: <Suspense fallback={<p>loading...</p>}><AssetList /></Suspense> },
        { path: '/asset/histories', element: <Suspense fallback={<p>loading...</p>}><AssetHistory /></Suspense> },
        { path: '/hr/authority', element: <Suspense fallback={<p>loading...</p>}><Authority /></Suspense> },
        { path: '/customer/list', element: <Suspense fallback={<p>loading...</p>}><CustomerList /></Suspense> },
        { path: '/workLog', element: <Suspense fallback={<p>loading...</p>}><WorkLog /></Suspense> },
        { path: '/sales', element: <Suspense fallback={<p>loading...</p>}><SalesList /></Suspense> },
        { path: '/commcode/list', element: <Suspense fallback={<p>loading...</p>}><CommCodeList /></Suspense> },
        { path: '/product/productGroup', element: <Suspense fallback={<p>loading...</p>}><ProductGroupList /></Suspense> },
        { path: '/product/list', element: <Suspense fallback={<p>loading...</p>}><ProductList /></Suspense> },
        { path: '/order/list', element: <Suspense fallback={<p>loading...</p>}><OrderList /></Suspense> },
        { path: '/release/ask/list', element: <Suspense fallback={<p>loading...</p>}><ReleaseAskList /></Suspense> },
        { path: '/release/ask/detail', element: <Suspense fallback={<p>loading...</p>}><ReleaseAskDetail /></Suspense> },
        { path: '/release/list', element: <Suspense fallback={<p>loading...</p>}><ReleaseList /></Suspense> },
        { path: '/storage/list', element: <Suspense fallback={<p>loading...</p>}><StorageList /></Suspense> },
        { path: '/storage/return', element: <Suspense fallback={<p>loading...</p>}><StorageReturn /></Suspense> },
        { path: '/storage/acquisition', element: <Suspense fallback={<p>loading...</p>}><StorageAcquisition/></Suspense> },
        { path: '/storage/goods', element: <Suspense fallback={<p>loading...</p>}><StorageGoods /></Suspense> },
        { path: '/product/stock', element: <Suspense fallback={<p>loading...</p>}><StockList /></Suspense> },
        { path: '/asset/reservation/list', element: <Suspense fallback={<p>loading...</p>}><AssetReservationList /></Suspense> },
        { path: '/billing/list', element: <Suspense fallback={<p>loading...</p>}><BillingList /></Suspense> },
        { path: '/billing/manager', element: <Suspense fallback={<p>loading...</p>}><BillManager /></Suspense> },
        { path: '/billing/receive', element: <Suspense fallback={<p>loading...</p>}><ReceiveManager /></Suspense> },
        { path: '/billing/account', element: <Suspense fallback={<p>loading...</p>}><AccountList /></Suspense> },
        { path: '/billing/nonPayment', element: <Suspense fallback={<p>loading...</p>}><NonPaymentManager /></Suspense> },
        { path: '/billing/nonBill', element: <Suspense fallback={<p>loading...</p>}><NonBill /></Suspense> },
        { path: '/asset/expiration/list', element: <Suspense fallback={<p>loading...</p>}><AssetExpirationList /></Suspense> },
        { path: '/return/ask/list', element: <Suspense fallback={<p>loading...</p>}><ReturnAskList /></Suspense> },
        { path: '/ship/list', element: <Suspense fallback={<p>loading...</p>}><ShipList /></Suspense> },
        { path: '/hr/list', element: <Suspense fallback={<p>loading...</p>}><UserList /></Suspense>},
        { path: '/accounting/account', element: <Suspense fallback={<p>loading...</p>}><AccountManagementList /></Suspense> },
        { path: '/accounting/generalStetement/list', element: <Suspense fallback={<p>loading...</p>}><GeneralStatementList /></Suspense> },
        { path: '/accounting/statement/list', element: <Suspense fallback={<p>loading...</p>}><StatementList /></Suspense> },
        { path: '/accounting/card/management', element: <Suspense fallback={<p>loading...</p>}><CardManagementList /></Suspense> },
        { path: '/accounting/card/list', element: <Suspense fallback={<p>loading...</p>}><CardList /></Suspense> },
        { path: '/accounting/certificate', element: <Suspense fallback={<p>loading...</p>}><CertificateList /></Suspense> },
        { path: '/accounting/card/statement', element: <Suspense fallback={<p>loading...</p>}><CardStatementList /></Suspense> },
        { path: '/accounting/payback', element: <Suspense fallback={<p>loading...</p>}><PaybackList /></Suspense> },
        { path: '/accounting/customer/ledger', element: <Suspense fallback={<p>loading...</p>}><CustomerLedgerList /></Suspense> },
        { path: '/commcode/certificate', element: <Suspense fallback={<p>loading...</p>}><CertificateList /></Suspense> },
        { path: '/accounting/purchaseSales', element: <Suspense fallback={<p>loading...</p>}><PurchaseSalesStatementList /></Suspense> },
        { path: '/accounting/account/ledger', element: <Suspense fallback={<p>loading...</p>}><AccountLedgerList /></Suspense> },
        { path: '/accounting/yearClose', element: <Suspense fallback={<p>loading...</p>}><AccountCloseList /></Suspense> },
        { path: '/asset/as/list', element: <Suspense fallback={<p>loading...</p>}><AsList /></Suspense> },
        { path: '/hr/attendance', element: <Suspense fallback={<p>loading...</p>}><AttendanceList /> </Suspense>},
        { path: '/hr/salary', element: <Suspense fallback={<p>loading...</p>}><SalaryManager /></Suspense> },
        { path: '/hr/salary/status', element: <Suspense fallback={<p>loading...</p>}><SalaryStatusList /></Suspense> },
        { path: '/company/management', element: <Suspense fallback={<p>loading...</p>}><CompanyInfo /></Suspense> },
        { path: '/product/insurancePolicy', element: <Suspense fallback={<p>loading...</p>}><InsurancePolicyList /></Suspense> },
        { path: '/billing/taxbill', element: <Suspense fallback={<p>loading...</p>}><TaxBillList /></Suspense> },
        { path: '/ship/schedule', element: <Suspense fallback={<p>loading...</p>}><ShipSchedule /></Suspense> },
        { path: '/mypage', element: <Suspense fallback={<p>loading...</p>}><MyPage /></Suspense> },
        { path: '/storage/consum/option', element: <Suspense fallback={<p>loading...</p>}><StorageConsumOption /></Suspense> },
        { path: '/product/brandCode', element: <Suspense fallback={<p>loading...</p>}><BrandCodeList /></Suspense> },
        { path: '/asset/hire/list', element: <Suspense fallback={<p>loading...</p>}><HireAssetManagerList /></Suspense> },
    ]
};

export default MainRoutes;

import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

const goodsColumn = { storageType: '',  orderSeq: '', orderType: '', orderTypeName: '', shelfId: '', remark: '', exchangeRateApplyDate: '', exchangeRate: '', currencyUnit: '', deliveryCost: '', etcCost: '' };

export const StorageRegisterStore = observable({
    /* register storage */
    storageType: 'sto_return',
    goodsInfo: goodsColumn,
    search: {
        inputNo: '', 
        isRfid: false,
        isReader: false,
        selAssetNo: '',
    },

    //입고 리더기 사용시 사용
    checkCountSec: 0,

    setStorageType(v) {
        this.storageType = v;
    },
    setGoodsInfo(v, k) {
        this.goodsInfo[k] = v;
    },
    setCheckCountSec(v){
        this.checkCountSec = v;
    },

    /* unmount */
    initGoodsInfo() {
        this.goodsInfo = goodsColumn;
    },
});

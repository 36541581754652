import { forwardRef, useCallback, useEffect, useMemo, useImperativeHandle, useState, useRef } from 'react'
import { Row, Col, OverlayTrigger, Button } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react-lite';
import LUtils from '../utils/lodashUtils'
import { callConfirm } from "../utils";
import {CAddBtn, CSaveBtn, CCancelBtn, CIconBtn, IconBtn, CExcelBtn, CCol} from './CustomContainer';
import { today, getTime } from '../utils/dateUtils';
import { ICON_ADD, ICON_TRASH } from '../common/constants';
import {  IS_SELECTED, IS_CREATED, IS_UPDATED } from '../common/constants';
import AgreeModal from "./AgreeModal";
import {AppStore} from "../store/AppStore";
import {showToast} from "../common/utils";

/**
 * Ag grid component
 * @param {Number} height : grid 높이값 (viewport에 % 값[vh])
 * @param {Object} formStyle : grid 컴포넌트를 감싸고 있는 form에 style
 * @param {Object} rowStyle : grid 컴포넌트를 감싸고 있는 form 안에 있는 row style
 * @param {Array} rowData : grid에 나타낼 값 목록
 * @param {Array} columnDefs : grid header
 * @param {Object} defaultColDefs : grid 컬럼 공통 옵션
 * @param {Object} gridRef : react에 useRef값. 한 화면에 여러개의 grid를 사용할 경우 각각 선언한 값을 입력한다.
 * @param {boolean} isCheckBox : [선택, 수정, 추가 기능 사용시 필수] checkBox 사용할지 여부
 * @param {String} seqColumn : [선택, 수정, 추가 기능 사용시 필수] 사용하는 grid 데이터에 sequence가 되는 값
 * @param {Array} originList : [수정, 추가 기능 사용시 필수] 초기화 할때 사용할 처음 조회 한 값
 * @param {Object} addRowInfo : [추가 기능 사용시 필수] 추가할때 컬럼 초기값
 * @param {Function} addRowCheckFunction : [추가 기능 사용시 필수] 추가할때 검사 할것이 있는 경우 사용될 함수
 * @param {boolean} useUpdated : [수정기능 사용시 필수] 저장 버튼 사용하기 위한 값
 * @param {boolean} useCreated : [수정 기능 사용시 필수] 추가, 저장 버튼 사용하기 위한 값
 * @param {Object} selectBtnInfo : 선택버튼을 사용하기 위한 값
 * @param {Function} callBackGridData : [선택, 수정, 추가 기능 사용시 필수] 선택된 row list들이 담긴 object 반환. 반환object{selectedList:[], updatedList:[], createdList:[]}
 * @param {boolean} useCsvDownload : scv 다운 버튼 표시 여부
 * @param {String} gridTitle : grid 이름 (엑셀다운할때 파일명으로 사용)
 * @param {boolean} useRowSearch: row에 상세 조회 버튼 사용 유무
 * @param {Function} rowSearchCallback: 상세 조회 버튼에서 사용할 함수
 * @param {Function} rowDoubleClickCallback: row 더블 클릭시 실행될 함수 (isCheckBox값이 false인 경우만 실행)
 * @param {boolean} useRemoveRow: 삭제 컬럼 사용여부
 * @param {Function} removeCallback: 삭제 아이콘에서 사용할 콜백 함수
 * @param {Array} customBtnInfo: 커스텀하게 사용할 수 있는 추가 버튼에 정보 { isUse, callbackFn, title, width }
 * @param {Object} btnTitles: 그리드 버튼의 text 변경. 기본값 { add: '추가', save: '저장', cancel: '초기화', excel: '데이터 내리기' }
 * @param {Function} getCellChange: 컬럼에 데이터 변경 후 콜백
 * @param {Function} getCheckboxClickEvent: 체크박스 클릭 이벤트 콜백, (isSelected: 선택여부, e: 클릭이벤트)
 * @param {Object} ref: 그리드 컴포넌트의 함수를 부모에서 사용하기 위한 useRef값
 * @param {Object} getRowStyle: 그리드 RowStyle 변경
 * @param {boolean} isRowVirtual: row 모두 가져와서 보여줄지 여부
 * @param {Integer} rowBuffer: 보여주는 갯수
 * @param {boolean} noIconBtn: 아이콘 없는 버튼
 * @param {Function} onSelectionChanged: 선택한 체크박스 정보 가져오기
 * @param {Object} addRowStyle : grid 위의 row style
 * @param {onRowClicked} onRowClicked: row 클릭시 실행될 함수
 * @param {appendTitle} appendTitle: gridTitle 옆에 붙일 html
 * @param {Integer} gridMarginTop: grid margin 설정
 * @param {boolean} isDeleteExcelHide: 엑셀 다운로드시 hide 컬럼 숨길지 말지
 *  * @param {boolean} removeColumnFront: 삭제(쓰레기통 아이콘) 컬럼 맨앞에 둘지 맨뒤에 둘지

*/
const AgGridContainer = ({
    height,
    formStyle,
    rowStyle,
    rowData=[],
    columnDefs,
    defaultColDefs,
    gridRef,
    isCheckBox=false,
    seqColumn='agId',
    originList=[],
    addRowInfo={},
    addRowCheckFunction=null,
    useUpdated=false,
    useCreated=false,
    selectBtnInfo={ isUsed: false, callbackFn: null },
    callBackGridData=null,
    useIntoTheTab=false,
    useCsvDownload=false,
    gridTitle='',
    useRowSearch=false,
    rowSearchCallback=null,
    rowDoubleClickCallback=null,
    useRemoveRow=false,
    removeCallback=null,
    customBtnInfo=[],
    btnTitles={},
    getCellChange=false,
    getCheckboxClickEvent=false,
    getRowStyle,
    pinnedTopRowData,
    pinnedBottomRowData,
    isRowVirtual=false,
    noIconBtn=false,
    onSelectionChanged,
    addRowStyle,
    subArea,
    onRowClicked,
    appendTitle='',
    isCellCustom=false,
    cellCustomChangeValue,
    gridMarginTop= 22,
    isVisibleTotalCount=true,
    isDeleteExcelHide=false,
    inputBox=false,
    inputBoxContent,
    removeColumnFront=false,
    useBtnTooltip=false,
    tooltipInfo
}, ref) => {

    const btnTitleInfo = LUtils.assign({ add: '', save: '', cancel: '초기화', excel: '데이터 내리기' }, btnTitles);
    const refData = useRef();

    useEffect(() => {
        LUtils.forEach(originList, (v, i) => LUtils.assign(v, {agId: i}))
    }, [])

    // TODO : 일단 주석처리하고 영향성 파악해 봐야함 ( 그리드 체크 후 state변화시 체크박스 해제 오류로 인해 해당 내용 주석처리한 후 정상적으로 작동)
    const rowDataHandler = () => LUtils.map(rowData || [], (k, i) => LUtils.assign(k, {agId: i})) || [];
    const gridDataList = rowDataHandler();

    /* grid 최초 render된 이후 실행되는 함수 - 화면에 다시 돌아왔을때 변경되었던 이력에 선택표시 */
    const onFirstDataRendered = useCallback(() =>  {
        if(gridRef){
            gridRef.current.api.forEachNode((node) => {
                const { isUpdated, isCreated, isSelected, ...row } = node.data;
                node.setSelected(LUtils.some([isUpdated, isCreated, isSelected]));
            })
        }
    }, []);

    /* row에 변경 상태 조회 */
    const getRowStatus = (v) => {
/*        const rowStatusTxt = {
            isUpdated: '수정',
            isCreated: '추가',
            isSelected: '선택',
        }
*/
        const isUpdated = LUtils.get(v.data, IS_UPDATED, false);
        const isCreated = LUtils.get(v.data, IS_CREATED, false);
        const isSelected = LUtils.get(v.data, IS_SELECTED, false);
        const rowStatus = {isUpdated, isCreated, isSelected};


        if(isUpdated || isCreated || isSelected){
            //console.log('v.data.agId > ', v.data.agId);
            checkBoxChecker(v.data.agId);
        }
/*        return rowStatusTxt[LUtils.findKey(rowStatus, v => v)] || '';*/
    };

    /* checkBox 사용시 header 추가 - checkBox, row 변경 상태 */
    const checkBoxObj = [
        {
            field: 'checkBox',
            headerName: '' ,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: false,
            width: 40,
            valueGetter: getRowStatus,
            cellClass:'ag-grid-icon-slim-padding',
            pinned: 'left'
        },
        //{field: 'rowStatus', headerName: '' , width: 30, valueGetter: getRowStatus, cellClass:'ag-grid-column-slim-padding'}
    ];

    /* search 버튼 사용시 */
/*    const searchBtnColumn = () => [
        {
            field: 'searchIcon',
            headerName: '' ,
            width: 30,
            cellClass:'ag-grid-icon-slim-padding',
            cellRenderer: (e) => {
                // const isCreated = LUtils.get(e.data, IS_CREATED); TODO: 추가 할때 검색 버튼 안나오도록 했었는데 일단 모두 나오도록 수정
                const searchUiIcon = <IconBtn onClick={() => rowSearchCallback(e)} />;
                // return (!isCreated && searchUiIcon)
                return searchUiIcon
            }
        },
    ];*/
    const searchBtnColumn = useMemo(() => [
        {
            field: 'searchIcon',
            headerName: '' ,
            width: 30,
            cellClass:'ag-grid-icon-slim-padding',
            cellRenderer: (e) => {
                // const isCreated = LUtils.get(e.data, IS_CREATED); TODO: 추가 할때 검색 버튼 안나오도록 했었는데 일단 모두 나오도록 수정
                const searchUiIcon = <IconBtn style={{paddingTop: 6}} onClick={() => rowSearchCallback(e)} />;
                // return (!isCreated && searchUiIcon)
                return searchUiIcon
            }
        },
    ], []);

    /* remove 버튼 사용시 */
    const removeColumn = useMemo(() => [
        {field: 'removeIcon', headerName: '' , width: 30, cellRenderer: (e) => {
            const isCreated = LUtils.get(e.data, IS_CREATED);
            const removeUiIcon = <IconBtn iconClass={ICON_TRASH} onClick={() => !!removeCallback && removeCallback(e)} style={{position: 'fixed', paddingTop: 2, width: 30}} />;
            return (!isCreated && removeUiIcon)
        } },
    ], []);

    /* checkBox selected - 1. 선택 했을때 isSelected값 부여, 2. 선택 해재되었을때 원래데이터로 복구 */
    const onRowSelected = (e) => {
        const { isCreated, isUpdated, ...row } = e.node.data;
        const isSelected = e.node.isSelected();

        /* checkbox 사용할 때만 적용 */
        if (!isCheckBox) {
            return false;
        }

        /* 데이터가 없으면 진입불가 */
        if (LUtils.isEmpty(rowData)) {
            return false;
        }

        /* 선택 */
        if (!LUtils.some([isCreated, isUpdated])) {
            rowData.forEach(v => {
                v[seqColumn] === row[seqColumn] && LUtils.assign(v, { isSelected });
            })
        }

        /* 수정 - 해제 되었을때 데이터 초기화*/
        if (isUpdated && !isSelected) {
            rowData.forEach(v => {
                if (v[seqColumn] === row[seqColumn]) {
                    updateColumn(v, row);
                };
            });
        }

        /* 추가 - 해제 되었을때 추가된 row 삭제 */
        if (isCreated  && !isSelected) {
            LUtils.remove(rowData, v => v.addRowId === row.addRowId);
        }

        /* click event 전달 함수 */
        getCheckboxClickEvent && getCheckboxClickEvent(isSelected, e);

        //TODO : row 체크시 느려져 해당부분 주석 처리함. 영향성 테스트 해야함.
        //gridRef.current.api.redrawRows();
    }

    /* 컬럼 데이터 수정 - updated */
    const cellChangeValue = (e) => {
        const newValue = e.newValue;
        const { c, isSelected, isCreated, isUpdated, ...row } = e.data;
        const oldValue = c?.y;
        const isChanged = oldValue !== newValue;

        // 변경 사항이 없으면 돌려보낸다
        if (!isChanged) {
            return isChanged;
        }

        /* 추가 */
        if (isCreated) {
            LUtils.isEmpty(e.data.c) && (e.data.c = {})
            LUtils.assign(e.data.c, {y: newValue});
            LUtils.assign(row, { isCreated: true });

            rowData.forEach(v => v.addRowId === row.addRowId && LUtils.assign(v, row));
        }

        /* 수정 */
        if (!isCreated) {
            LUtils.isEmpty(e.data.c) && (e.data.c = {})
            LUtils.assign(e.data.c, {y: newValue});
            LUtils.assign(row, { isUpdated: true });

            rowData.forEach(v => v[seqColumn] === row[seqColumn] && LUtils.assign(v, row));
            checkBoxChecker(row[seqColumn]);
        }

        /* 화면에서 데이터 변경 후 콜백 이벤트를 원하는 경우 */
        getCellChange && getCellChange(e);
    }

    /* row 추가 버튼클릭시 - created */
    const onAddRow = async () => {
        if (LUtils.isEmpty(addRowInfo)) {
            showToast('추가하려는 데이터 초기화 값이 없습니다.');
            return false;
        }

        if (!!addRowCheckFunction && !addRowCheckFunction()) {
            return false;
        }

        addRow(addRowInfo);
    }

    /* 그리드에 row 추가 */
    const addRow = (data, orderBy='unshift') => {
        const rowInfo = LUtils.isEmpty(data) ? addRowInfo : data;
        const addIndex = rowData.filter(v => v.isCreated).length || 0;
        const addRow = {...rowInfo, addRowId: addIndex, isCreated: true };

        rowData[orderBy](addRow);
        gridRef.current.api.redrawRows();
        checkBoxChecker('addRowId');
    }

    /* 추가되는 row의 index 가져오기 */
    const getAddRowIndex = () => {
        const checkList = gridRef.current.api.getSelectedRows();
        if (checkList.length === 0) {
            return 0;
        }

        const createRowList = checkList.filter(v => LUtils.get(v, IS_CREATED))
        return createRowList.length || 0;
    }

    /* checkBox 선택/해제 */
    const checkBoxChecker =(seqCol) => {
        gridRef.current.api.forEachNode((node) => {
            if(seqCol === node.data.agId){
                const { isUpdated, isCreated, isSelected, ...row } = node.data;
                node.setSelected(LUtils.some([isUpdated, isCreated, isSelected]));
                return;
            }
        });
    }

    /* 그리드 데이터 전체 checkBox 선택/해제 */
    const allRowCheckBoxChecker = () => {
        LUtils.forEach(rowData, v => checkBoxChecker(v[seqColumn]));
    }

    /* 내부에서만 사용되는 컬럼 */
    const omitColumns = [IS_SELECTED, IS_CREATED, IS_UPDATED, 'addRowId', 'c', 'agId']

    /* 저장버튼 - 선택된 list를 반환 */
    const saveBtn = async () => {
        const checkList = gridRef.current.api.getSelectedRows();
        if (LUtils.isEmpty(checkList)) {
            showToast('저장할 데이터를 선택해주세요.');
            return false;
        }

        if (!callBackGridData) {
            showToast('콜백함수가 없습니다.');
            return false;
        }

        const param = {
            selectedList: checkList.filter(v => LUtils.get(v, IS_SELECTED)).map(v => LUtils.omit(v, omitColumns)),
            updatedList: checkList.filter(v => LUtils.get(v, IS_UPDATED)).map(v => LUtils.omit(v, omitColumns)),
            createdList: checkList.filter(v => LUtils.get(v, IS_CREATED)).map(v => LUtils.omit(v, omitColumns)),
        }
        await callBackGridData(param);
    };

    /* 선택 버튼 */
    const getSelectedRows = async () => {
        const checkList = gridRef.current.api.getSelectedRows();
        if (LUtils.isEmpty(checkList)) {
            showToast('선택된 데이터가 없습니다.');
            return false;
        }

        if (!callBackGridData) {
            showToast('콜백함수가 없습니다.');
            return false;
        }

        const param = {
            selectedList: checkList.filter(v => LUtils.get(v, IS_SELECTED)).map(v => LUtils.omit(v, omitColumns)),
        }

        selectBtnInfo.callbackFn ? await selectBtnInfo.callbackFn(param) : await callBackGridData(param);
    }

    /* 초기화 */
    const resetBtn = async () => {
        if (!await callConfirm('초기화 하시겠습니까?')) {
            return false;
        }

        rowData.forEach(async v => {
            const { isSelected, isCreated, isUpdated, ...row } = v;
            // 추가
            if (isCreated) {
                LUtils.remove(rowData, v => v[IS_CREATED]);
            }

            // 선택
            if (isSelected) {
                v[seqColumn] === row[seqColumn] && (v.isSelected = false);
            }

            // 수정
            if (isUpdated) {
                if (v[seqColumn] === row[seqColumn]) {
                    updateColumn(v, row);
                };
            }

            checkBoxChecker(row[seqColumn]);
        });
    };

    /* 수정된 컬럼 초기화 */
    const updateColumn = (v, row) => {
        const targetIndex = LUtils.findIndex(LUtils.sortBy(rowData, seqColumn), v => v[seqColumn] === row[seqColumn])
        if (targetIndex !== 0 && !targetIndex) {
            return;
        }

        const originObj = LUtils.sortBy(originList, seqColumn)[targetIndex];
        v.c = {};
        LUtils.assign(v, LUtils.assign(originObj, { isUpdated: false }));
    }

    /* csv 다운 */
    const downCsv = () => {
        if(rowData.length === 0){
            showToast('조회된 데이터가 없습니다.');
            return;
        }
        AppStore.gridData = gridRef.current;
        AppStore.gridTitle = gridTitle;

        AppStore.toggleAgreePopup();
    }

    const excelDownLoadFn =()=> {
        const fileName = `${AppStore.gridTitle || 'csv'}_[${today()}_${getTime().replaceAll(':','-')}]`;
        refData.current = AppStore.gridData;

        if(isDeleteExcelHide) {
            const allColumns = refData.current.columnApi.getAllColumns();
            const columnIds = allColumns.filter(column => column.getColDef().hide).map(column => column.getColId());
            refData.current.columnApi.setColumnsVisible(columnIds, true);   // 열 숨김 제거
            refData.current.api.exportDataAsCsv({ fileName });
            refData.current.columnApi.setColumnsVisible(columnIds, false);  // 열 다시 숨김
        }else {
            refData.current.api.exportDataAsCsv({ fileName });
        }
       
        //팝업닫기
        AppStore.toggleAgreePopup();
    }

    /* 컬럼목록 합치기 */
    const setColumnDefs = () => {
        if (removeColumnFront) {
            return LUtils.concat(LUtils.concat(LUtils.concat(isCheckBox ? checkBoxObj : [], useRowSearch ? searchBtnColumn : []), useRemoveRow ? removeColumn : []), columnDefs).map(column => ({ ...column, filter: 'agTextColumnFilter' }))
        } else {
            return LUtils.concat(LUtils.concat(LUtils.concat(isCheckBox ? checkBoxObj : [], useRowSearch ? searchBtnColumn : []), columnDefs), useRemoveRow ? removeColumn : []).map(column => ({ ...column, filter: 'agTextColumnFilter' }))
        }
    }

/*
    const isRowSelectable = (params) => {
        //console.log(params)
        return !!params.data && (params.data.isCancel === 0 || params.data.isDel === 0 || params.data.isDeleted === 0 || params.data.assetStatus !== '3');
    };
*/

    /* 부모에서 사용 할 함수
     * ex) agGridContainerRef.current.addRow(data);
     *
     * addRow: grid에 row 추가, 추가할 데이터 object를 파라미터로 받는다.
     * getAddRowIndex: 추가 상태인 row 들의 가장큰 인덱스 반환
     * allRowCheckBoxChecker: grid에 모든 row 선택 여부 다시 계산 - grid 선택시 백그라운드 색 변경
     */
    useImperativeHandle(ref, () => ({
        addRow,
        getAddRowIndex,
        allRowCheckBoxChecker,
        getSelectedRows,
    }));

    const customBtnUsedCheck =()=> {
        let isUse = false;
        if(customBtnInfo.length > 0){
            for(let btn of customBtnInfo){
                if(btn.isUsed){
                    isUse = true;
                    break;
                }
            }
        }
        //console.log('isUse => ' + isUse);
        return isUse;
    }


    const MainUi = () => {
        return(
            <div style={{justifyContent: 'center'}}>
                <Row>
                    {gridTitle ?
                        <Col>
                            <div style={{left: 15, display: 'inline-flex', opacity: 0.8}}>
                                <i className="fi fi-rs-comment-alt" style={{paddingTop: 5, marginRight: 8}}/>
                                <h5>{gridTitle}</h5>
                                {isVisibleTotalCount &&
                                    <div style={{marginLeft: 20, marginTop: 1, minWidth: 110}}>
                                    <span>총 <span
                                        style={{color: 'red'}}>{gridDataList.length > 0 ? gridDataList.length.toLocaleString() : 0}</span>건 조회됨
                                    </span>
                                    </div>
                                }
                                {appendTitle ? <>{appendTitle}</> : ''}
                            </div>
                        </Col>
                        :
                        <>
                            {isVisibleTotalCount &&
                                <Col lg={2}>
                                        <div style={{left: 15, marginTop: 7, opacity: 0.8, minWidth: 110}}>
                                            <span>총 <span
                                                style={{color: 'red'}}>{gridDataList.length > 0 ? gridDataList.length.toLocaleString() : 0}</span>건 조회됨</span>
                                        </div>
                                </Col>
                            }
                            {appendTitle &&
                                <Col>
                                    {appendTitle}
                                </Col>
                            }
                        </>
                    }
                </Row>
                {(useCsvDownload || useUpdated || useCreated || selectBtnInfo.isUsed || useBtnTooltip || customBtnInfo.length > 0 || (inputBox && inputBoxContent)) &&
                    <Row>
                        {/* button group */}
                        <Col className='d-flex flex-row-reverse position-relative row' style={{paddingRight: 0, paddingTop: -2}}>
                            {useCsvDownload &&
                                <CExcelBtn onClick={downCsv} />
                            }
                            {LUtils.some([useUpdated, useCreated]) &&
                                <CSaveBtn title={btnTitleInfo.save} onClick={saveBtn} noIcon={noIconBtn}/>
                            }
                            {useCreated &&
                                <CAddBtn title={btnTitleInfo.add} onClick={onAddRow} noIcon={noIconBtn}/>
                            }
                            {selectBtnInfo.isUsed &&
                                <CIconBtn
                                    title={selectBtnInfo.title || '선택'}
                                    onClick={getSelectedRows}
                                    icon={selectBtnInfo.icon || ICON_ADD}
                                    variant={selectBtnInfo.variant || ''}
                                    color={selectBtnInfo.color || 'red'}
                                    style={{width: selectBtnInfo.width || 110}}
                                />
                            }
                            {useBtnTooltip && (
                                <div className="d-flex align-items-center" style={{ width: 110 }}>
                                    <OverlayTrigger
                                        style={{ width: 'auto' }}
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip" style={{ width: 500 }}>
                                                {tooltipInfo}
                                            </Tooltip>
                                        }
                                    >
                                        <Button variant="secondary" style={{ width: 110 }}>
                                            견적 정보
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            )}
                            {customBtnInfo.length > 0 &&
                                customBtnInfo.map((v,i) => {
                                    if(v.isUsed){
                                        return (
                                            <CIconBtn
                                                key={i}
                                                title={v.title || '추가'}
                                                onClick={v.callbackFn}
                                                style={{width: v.width || 110, ...v.style}}
                                                icon={v.icon || ''}
                                                variant={v.variant || ''}
                                                color={v.color}
                                            />
                                        )
                                    }
                                })
                            }
                        </Col>
                    </Row>
                }
                <Row className='ag-theme-alpine' style={LUtils.assign({height: height+'vh',
                    marginTop: (!useUpdated && !useCreated && !useCsvDownload && !selectBtnInfo.isUsed && !customBtnUsedCheck()) ? gridMarginTop : 0}, rowStyle)}>
                    <AgGridReact
                        ref={gridRef}
                        onCellValueChanged={(params) => {
                            if (isCellCustom) cellCustomChangeValue(params);
                            cellChangeValue(params);
                        }}
                        rowHeight={40}
                        headerHeight={30}
                        rowData={gridDataList || []}
                        columnDefs={setColumnDefs()}
                        colResizeDefault={'shift'}
                        defaultColDef={LUtils.assign({ resizable: true, sortable: true,  width: 100}, defaultColDefs)}
                        singleClickEdit={false}
                        onRowDoubleClicked={rowDoubleClickCallback}
                        rowMultiSelectWithClick={true}
                        rowSelection={isCheckBox ? 'multiple' : 'single'}
                        onRowSelected={onRowSelected}
                        suppressRowClickSelection={isCheckBox}
                        animateRows={false}
                        suppressColumnMoveAnimation={false}
                        onFirstDataRendered={onFirstDataRendered}
                        alwaysShowHorizontalScroll={false}
                        enableCellTextSelection={true}
                        getRowStyle={getRowStyle}
                        pinnedTopRowData={pinnedTopRowData}
                        pinnedBottomRowData={pinnedBottomRowData}
                        rowBuffer={50}
                        suppressRowVirtualisation={isRowVirtual}
                        onSelectionChanged={onSelectionChanged}
                        debounceVerticalScrollbar={true}
                        //isRowSelectable={isRowSelectable}
                        pagination={false}
                        tooltipShowDelay={200}
                        onRowClicked={onRowClicked}
                        suppressScrollOnNewData={true}  //더블클릭시 스크롤 멈춤
                        localeText={{noRowsToShow: '조회 결과가 없습니다.', filterOoo: '검색어', contains: '포함', notContains: '비포함',
                                    equals: '동일', notEqual: '비동일', startsWith: '시작하는', endsWith: '끝나는', blank: '공백', notBlank: '비공백', empty: '비어있음',}}
                    />
                </Row>
            </div>
        )
    }

    return (
        <>
            { MainUi() }
{/*            {useIntoTheTab ?
                MainUi()
                :
                <Container fluid>
                    <Form className='rounded m-2 p-3' style={fStyle}>
                        { MainUi() }
                    </Form>
                </Container>
            }*/}
            <AgreeModal callbackFn={excelDownLoadFn} gridTitle={gridTitle}/>
        </>
    );
};

export default observer(forwardRef(AgGridContainer));

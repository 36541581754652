import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const ReleaseAskDetailStore = observable({
    releaseAskDetail: {
        releaseAskSeq: null,    // 출고의뢰번호
        releaseAskStatus: '',   // 출고의뢰상태
        releaseAskType: '',     // 출고의뢰구분
        releaseAskDate: '',     // 출고의뢰일
        creator: null,       // 의뢰자시퀀스
        creatorName: '',        // 의뢰자명
        contractNo: '',         // 계약번호
        contractType: '',       // 계약유형
        saleUserName: '',       // 영업담당자
        supportUserName: '',    // 지원담당자
        acceptUserName: '',     // 출고접수자
        acceptDate: '',         // 접수일
        releaseUserName: '',    // 출고완료자
        releaseDate: '',        // 완료일
        releaseAskRemark: '',   // 출고의뢰비고
        returnAskRemark: '',    // 회수의뢰비고
        customerName: '',       // 고객명
        telephone: '',          // 전화번호
        phoneNumber: '',        // 휴대폰
        receiveUserDept: '',    // 부서명
        receiveUserPosition: '',// 직급
        receiveUserName: '',    // 받는분
        shipHopeDate: '',       // 배송희망일
        shipHopeTime: '1',      // 시간
        shipType: '',           // 배송방법
        postCode: '',           // 우편번호
        address: '',            // 주소
        addressDetail: '',      // 상세주소
        shipRemark: '',         // 배송비고
        isVirtualRelease: 0,    // 가상출고여부
        assetStatus: '',        // 자산상태

        isReturnAskInfo: true,          // 회수의뢰정보
        returnTelephone: '',            // 전화번호
        returnPhoneNumber: '',          // 휴대폰
        returnReceiveUserDept: '',      // 부서명
        returnReceiveUserPosition: '',  // 직급
        returnReceiveUserName: '',      // 받는분
        returnShipHopeDate: '',         // 회수희망일
        returnShipHopeTime: '1',        // 시간
        returnShipType: '',             // 배송방법
        returnPostCode: '',             // 우편번호
        returnAddress: '',              // 주소
        returnAddressDetail: '',        // 상세주소
        returnShipRemark: '',           // 배송비고

        releaseAskItemList: [], // 기타 출고일 때 출고의뢰아이템 같이 보낼 용도
        contractStartDate: '',
        contractEndDate: '',
        rentMonth: 0,
        rentDays: 0,
    },
    releaseAskItemList: [],
    checkCountSec: 0,
    releaseAskDetailParams: {
        releaseAskSeq: null,
        seq: null,
    },
    isClicked: false,
    dataList: [],
    isPrintAll: true,

    setReleaseAskDetail(v) {
        LUtils.assign(this.releaseAskDetail, v);
    },
    setReleaseAskItemList(v) {
        this.releaseAskItemList = v;
    },
    setCheckCountSec(v){
        this.checkCountSec = v;
    },

    init() {
        this.releaseAskDetail ={
            releaseAskSeq: null, releaseAskStatus: '', releaseAskDate: '', creatorSeq: null, creatorName: '', contractNo: '', contractType: '', saleUserName: '', supportUserName: '',
            acceptUserName: '', acceptDate: '', releaseUserName: '', releaseDate: '', releaseAskRemark: '', customerName: '', telephone: '', phoneNumber: '', receiveUserDept: '',
            receiveUserPosition: '', receiveUserName: '', shipHopeDate: '', shipHopeTime: '1', shipType: '', postCode: '', address: '', addressDetail: '', shipRemark: ''
        };
        this.releaseAskItemList = [];
        this.releaseAskDetailParams.releaseAskSeq = null;
        this.releaseAskDetailParams.seq = null;
        this.isClicked = false;
    },
    returnShipInit() {
        this.releaseAskDetail.returnAskRemark = '';            
        this.releaseAskDetail.returnTelephone = '';            
        this.releaseAskDetail.returnPhoneNumber = '';     
        this.releaseAskDetail.returnReceiveUserDept = '';   
        this.releaseAskDetail.returnReceiveUserPosition = ''; 
        this.releaseAskDetail.returnReceiveUserName = '';  
        this.releaseAskDetail.returnShipHopeDate = '';       
        this.releaseAskDetail.returnShipHopeTime = ';',      
        this.releaseAskDetail.returnShipType = '';           
        this.releaseAskDetail.returnPostCode = '';           
        this.releaseAskDetail.returnAddress = '';           
        this.releaseAskDetail.returnAddressDetail = '';       
        this.releaseAskDetail.returnShipRemark = '';      
    },
    returnSetting() {
        this.releaseAskDetail.returnTelephone = this.releaseAskDetail.telephone;            
        this.releaseAskDetail.returnPhoneNumber = this.releaseAskDetail.phoneNumber;     
        this.releaseAskDetail.returnReceiveUserDept = this.releaseAskDetail.receiveUserDept;   
        this.releaseAskDetail.returnReceiveUserPosition = this.releaseAskDetail.receiveUserPosition; 
        this.releaseAskDetail.returnReceiveUserName = this.releaseAskDetail.receiveUserName;  
        this.releaseAskDetail.returnShipHopeTime = this.releaseAskDetail.shipHopeTime;      
        this.releaseAskDetail.returnShipType = this.releaseAskDetail.shipType;           
        this.releaseAskDetail.returnAddressDetail = this.releaseAskDetail.addressDetail;       
        this.releaseAskDetail.returnShipRemark = this.releaseAskDetail.shipRemark;      
    }
})

import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";
import {contractColumns} from "../contract/ContractDetailStore";
import { today } from "../../utils/dateUtils";

export const StorageRegisterListStore = observable({
    returnStorageList: [],

    setReturnStorageList(v) {
        this.returnStorageList = v;
    },
    pushReturnStorageList(v){
        this.returnStorageList.push(v);
    },

    newEndDate: {startDate: today(), endDate: today()},
    returnInfo: {
        storageType: 'sto_return',
        managementNo: '',
        assetNo: '',
        serialNo: '',
        productName: '',
        model: '',
        spec: '',
        assetType: '',
        contractNo: '',
        contractType: '',
        customerName: '',
        contractDate: '',
        contractStartDate: '',
        contractEndDate: '',
        rentMonth: '',
        rentDays: '',
        monthRentPrice: 0,
        dayRentPrice: 0,
        installPlace: '',
        shortContractEndDate: '',
        newRentMonth: '',
        newRentDays: '',
        shortRentPrice: 0,
        exteriorStatus: '',
        tireStatus: '',
        remark: '',
        newEndDate: '',
    },

    hireEndDate: {},
    //assetMakeDate: {},
    assetCertificateCheckDate: {},
    assetAcquDate: {startDate: today(), endDate: today()},
    acquAssetInfo: {
        storageType: 'sto_acqu',
        lgCate: '',
        productSeq: '',
        managementNo: '',
        assetNo: '',
        serialNo: '',
        productName: '',
        model: '',
        spec: '',
        assetType: '1',
        buyCustomerNo: '',
        buyCustomerName: '',
        hireEndDate: '',
        manufacturing: '',
        acquDate: today(),
        acquAmount: '',
        newOrUsed: '1',
        shelfId: '',
        remark: '',
        qty: 1,
    },

    //입고 리더기 사용시 사용
    isReader: false,
    checkCountSec: 0,

    returnAssetOptionList: [],
    assetAcquList:[],
    goodsList: [],

    setNewEndDate(v){
        this.newEndDate = {startDate: v, endDate: v};
    },

    setAssetAcquList(v) {
        this.assetAcquList = v;
    },

    pushAssetAcquList(v) {
        this.assetAcquList.push(v);
    },

    setReturnAssetOptionList(v){
        this.returnAssetOptionList = v;
    },

    setReturnInfo(target, v){
        this.returnInfo[target] = v;
    },

    setAcquAssetInfo(target, v){
        this.acquAssetInfo[target] = v;
    },

    setHireEndDate(v){
        this.hireEndDate = {startDate: v, endDate: v};
    },

/*    setAssetMakeDate(v){
        this.assetMakeDate = {startDate: v, endDate: v};
    },*/

    setAssetCertificateCheckDate(v){
        this.assetCertificateCheckDate = {startDate: v, endDate: v};
    },

    setAssetAcquDate(v){
        this.assetAcquDate = {startDate: v, endDate: v};
    },

    setGoodsList(v){
        this.goodsList = v;
    },

    setIsReader(v){
        this.isReader = v;
    },
    
    setCheckCountSec(v){
        this.checkCountSec = v;
    },


    init() {
        this.returnStorageList = [];
        this.returnAssetOptionList = [];
        this.goodsList = [];
    },

    returnInfoInit(){
        //this.returnAssetOptionList = [];
        this.newEndDate = {startDate: null, endDate: null};
        this.returnInfo.storageType = 'sto_return';
        this.returnInfo.managementNo = '';
        this.returnInfo.assetNo = '';
        this.returnInfo.serialNo = '';
        this.returnInfo.productName = '';
        this.returnInfo.model = '';
        this.returnInfo.spec = '';
        this.returnInfo.assetType = '';
        this.returnInfo.contractNo = '';
        this.returnInfo.contractType = '';
        this.returnInfo.customerName = '';
        this.returnInfo.contractDate = '';
        this.returnInfo.contractStartDate = '';
        this.returnInfo.contractEndDate = '';
        this.returnInfo.rentMonth = '';
        this.returnInfo.rentDays = '';
        this.returnInfo.monthRentPrice = 0;
        this.returnInfo.dayRentPrice = 0;
        this.returnInfo.installPlace = '';
        this.returnInfo.shortContractEndDate = '';
        this.returnInfo.newRentMonth = '';
        this.returnInfo.newRentDays = '';
        this.returnInfo.shortRentPrice = 0;
        this.exteriorStatus = '';
        this.tireStatus = '';
        this.returnInfo.remark = '';
    },

    assetAcquInfoInit(){
        this.hireEndDate = {startDate: null, endDate: null};
        //this.assetMakeDate = {};
        this.assetSafetyCheckDate = {startDate: null, endDate: null};
        this.assetAcquDate = {startDate: today(), endDate: today()};

        this.acquAssetInfo.storageType = 'sto_acqu';
        this.acquAssetInfo.lgCate = '';
        this.acquAssetInfo.productSeq = '';
        this.acquAssetInfo.managementNo = '';
        this.acquAssetInfo.assetNo = '';
        this.acquAssetInfo.serialNo = '';
        this.acquAssetInfo.productName = '';
        this.acquAssetInfo.model = '';
        this.acquAssetInfo.spec = '';
        this.acquAssetInfo.assetType = '1';
        this.acquAssetInfo.buyCustomerNo = '';
        this.acquAssetInfo.buyCustomerName = '';
        this.acquAssetInfo.powerTransmission = '';
        this.acquAssetInfo.drivingSpeed = '';
        this.acquAssetInfo.workingMaximumHeight = '';
        this.acquAssetInfo.loadCapacity = '';
        this.acquAssetInfo.drivingMethod = '';
        this.acquAssetInfo.hireEndDate = '';
        this.acquAssetInfo.manufacturing = '';
        this.acquAssetInfo.safetyCheckDate = '';
        this.acquAssetInfo.safetyCheckFileUrl = '';
        this.acquAssetInfo.safetyCheckFileName = '';
        this.acquAssetInfo.acquDate = today();
        this.acquAssetInfo.acquAmount = '';
        this.acquAssetInfo.newOrUsed = '1';
        this.acquAssetInfo.remark = '';
        this.acquAssetInfo.assetCertificateUrl = '';
        this.acquAssetInfo.assetCertificateFileName = '';
        this.acquAssetInfo.insurancePolicyUrl = '';
        this.acquAssetInfo.insurancePolicyFileName = '';
        this.acquAssetInfo.qty = 1;
    }
});

import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useReactToPrint } from "react-to-print";
import { Col, Row } from "react-bootstrap";

import { businessNumberFormat } from "../../../common/utils";
import { CContainer, CIconBtn } from "../../../components/CustomContainer";
import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import "./tax-bill-styles.css"; 
import axios from "axios";

const TaxBillDetailPopup =(props, ref)=> {
    const divRef = useRef(null);
    const [info, setInfo] = useState([]);
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        setInfo(props.taxBillData);
        if(props.taxBillData.taxBillSeq) {
            getBillItem();
        }
    }, [props.taxBillData]);

    const getBillItem = async () => {
        const result = await axios.get(`/taxbill/detail/${props.taxBillData.taxBillSeq}`);
        setItemList(result);
    }

    const printBtn = () => {
        alert('페이지 레이아웃을 가로방향으로 설정해주세요.');
        print();
    }

    const print = useReactToPrint({
        content: () => divRef.current,
    });

    const refresh = () => {

    }

    return (
        <Modal title={'세금계산서'}
            onHide={() => AppStore.toggleTaxBillDetailPopup()}
            toggleValue={AppStore.isOpenTaxBillDetailPopup}
            onExit={refresh}
            size={'lg'}
        >
            <div className="trading-statement tax-bill" ref={divRef}>
                <h3 style={{textAlign: 'center', marginBottom: 20}}>세금계산서</h3>
                <table className="table trading-statement-table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px'}}>
                    <thead style={{borderBottom: 'solid 2px'}}>
                        <tr>
                            <th className='border' style={{width: '50%', textAlign: 'center'}} colSpan={4}>공급자</th>
                            <th className='border' style={{width: '50%', textAlign: 'center'}} colSpan={4}>공급받는자</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{textAlign: 'center', verticalAlign: 'middle'}}>
                            <td className='p-1 border' >등록번호</td>
                            <td className='p-1 border data-text' colSpan={3}>{businessNumberFormat(info.supplyBusinessNumber) || ''}</td>
                            <td className='p-1 border'>등록번호</td>
                            <td className='p-1 border data-text' colSpan={3}>{businessNumberFormat(info.customerBusinessNumber) || ''}</td>
                        </tr>
                        <tr style={{verticalAlign: 'middle'}}>
                            <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>상 호</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{info.supplyCompanyName || ''}</td>
                            <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>대 표</td>
                                <td className='p-2 border data-text' style={{width: '18%'}}>
                                    {info.supplyOwner || ''}                                    
                                </td>
                            <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>상 호</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{info.customerCompanyName || ''}</td>
                            <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>대 표</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{info.customerOwner || ''}</td>
                        </tr>
                        <tr style={{verticalAlign: 'middle'}}>
                            <td className='p-1 border' style={{textAlign: 'center'}}>주 소</td>
                            <td className='p-2 border data-text' colSpan={3}>{info.supplyCompanyAddress || ''}</td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>주 소</td>
                            <td className='p-2 border data-text' colSpan={3}>{info.customerCompanyAddress || ''}</td>
                        </tr>
                        <tr style={{verticalAlign: 'middle'}}>
                            <td className='p-1 border' style={{textAlign: 'center'}}>업 태</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{info.supplyCategory || ''}</td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>종 목</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{info.supplyItem || ''}</td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>업 태</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{info.customerCategory || ''}</td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>종 목</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{info.customerItem || ''}</td>
                        </tr> 
                    </tbody>
                </table>
                <br/>

                <table className="table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px', tableLayout: 'fixed'}}>
                    <thead style={{borderBottom: 'solid 2px'}}>
                        <tr>
                            <th className='border' style={{textAlign: 'center', width: '3%'}}>월</th>
                            <th className='border' style={{textAlign: 'center', width: '3%'}}>일</th>
                            <th className='border' style={{textAlign: 'center', width: '35%'}}>품목</th>
                            <th className='border' style={{textAlign: 'center', width: '5%'}}>규격</th>
                            <th className='border' style={{textAlign: 'center', width: '5%'}}>수량</th>
                            <th className='border' style={{textAlign: 'center', width: '9%'}}>단가</th>
                            <th className='border' style={{textAlign: 'center', width: '9%'}}>공급가액</th>
                            <th className='border' style={{textAlign: 'center', width: '9%'}}>세액</th>
                            <th className='border' style={{textAlign: 'center'}}>비고</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-class">
                        {itemList?.map((data, index) => {
                            return( 
                                <tr key={index}>
                                    <td className='p-1 border data-text' style={{width: '3%'}}>{data.issueDate?.substring(5, 7) || ''}</td>
                                    <td className='p-1 border data-text' style={{width: '3%'}}>{data.issueDate?.substring(8, 10) || ''}</td>
                                    <td className='p-1 border data-text' style={{width: '8%'}}>{data.itemName || ''}</td>
                                    <td className='p-1 border data-text' style={{width: '8%'}}>{data.standard || ''}</td>
                                    <td className='p-1 border data-text' style={{width: '5%'}}>{data.qty}</td>
                                    <td className='p-1 border data-text ag-grid-money-align' style={{width: '10%'}}>{data.unitAmount?.toLocaleString()}</td>
                                    <td className='p-1 border data-text ag-grid-money-align' style={{width: '10%'}}>{data.supplyAmount?.toLocaleString()}</td>
                                    <td className='p-1 border data-text ag-grid-money-align' style={{width: '8%'}}>{data.vatAmount?.toLocaleString()}</td>
                                    <td className='p-1 border data-text' style={{width: '28%'}}>{data.remark}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <table className="table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px', height: 30}}>
                    <tbody>
                        <tr style={{textAlign: 'center', verticalAlign: 'middle'}}>
                            <td className='p-1 border'>합계</td>
                            <td className='p-1 border data-text ag-grid-money-align' style={{width: '80%'}}>₩ {info.totalAmount?.toLocaleString()} </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn title={'프린트'} style={{width: 120}} variant={'primary'} icon={'fi-rr-print'} onClick={printBtn} />
                    </Col>
                </Row>
            </CContainer>
        </Modal> 
    );
}

export default observer(TaxBillDetailPopup);

import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {CalendarGroup,CCol,CContainer, CIconBtn, CInputDoubleGroup, CInputGroup,CSearchBtn, CSelectGroup} from "../../components/CustomContainer";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {AppStore} from "../../store/AppStore";
import {BillSearchStore} from "../../store/billing/BillSearchStore";
import axios from "axios";
import {callConfirm} from "../../utils";
import {showToast} from "../../common/utils";
import {getCommcode} from "../../utils/commcode";
import BillPublishPopup from "./popup/BillPublishPopup";
import { getSessionUserCompanyCode } from "../../utils/userUtils";

const BillManagerSearch =(props)=> {
    const {getBillPublishedList, getBillingTargetList, selView, setSelView, selSearchUserType, setSelSearchUserType, billingSearchDate, setBillingSearchDate, billPublishDate, setBillPublishDate, selectAmount, selectVat, chargeTotalAmount, setChargeTotalAmount} = props;
    const [companyList, setCompanyList] = useState([]);
    const [companyCode, setCompanyCode] = useState('');
    const userCompanyCode = getSessionUserCompanyCode();

    useEffect(() => {
        getCompanyList();
    }, []);

    const getCompanyList = async () => {
        const resultList = await axios.get("/bill/company");
        const resultCompanyList = resultList.map((company, index, resultList) => {return {name: company.companyName, value: company.companyCode}});
        setCompanyList(resultCompanyList);
    }

    const publishBillCheck =async()=> {
        const billingPublishList = BillSearchStore.billingList.filter(v => v.isSelected || v.isUpdated);
        if(billingPublishList.length === 0){
            showToast('발행할 계약번호를 선택해 주세요.');
            return;
        }

        //정발행/역발행 체크
        const isNotReverseBill = billingPublishList.filter(v => v.isReverseBill === 0);
        const isReverseBill = billingPublishList.filter(v => v.isReverseBill === 1);
        if(isNotReverseBill.length > 0 && isReverseBill.length > 0){
            showToast('정발행/역발행 계산서는 같이 발행할 수 없습니다.');
            return;
        }

        //역발행 계산서만 존재할 경우 역발행으로 체크
        if(isNotReverseBill.length === 0 && isReverseBill.length > 0 && BillSearchStore.condition.publishSendType === '1'){
            BillSearchStore.setCondition('2', 'publishSendType');
        }

        //통합발행이면 품목과 수량을 입력받도록 한다.
        if(BillSearchStore.condition.billPublishType === '2'){
            let customerNo = '';
            let paymentType = '';
            for(const data of billingPublishList){
                if(!customerNo){
                    customerNo = data.customerNo;
                }else{
                    if(customerNo !== data.customerNo){
                        showToast('같은 고객만 통합이 가능 합니다.');
                        return;
                    }
                }
                if(!paymentType){
                    paymentType = data.paymentType;
                }else{
                    if(paymentType !== data.paymentType){
                        showToast('결제 방식이 다르면 통합이 불가능 합니다.');
                        return;
                    }
                }
            }
            let selectedList = BillSearchStore.billingList.filter(v => v.isSelected);

            for (const i in selectedList) {
                selectedList[i].billItemList.forEach(v => BillSearchStore.pushTargetBillItemList({...v, seq: Number(i)}));
            }

            setChargeTotalAmount(Number(selectAmount) + Number(selectVat));
            AppStore.toggleOpenBillItemPopup();
            // AppStore.toggleBillPublishPopup();
        }else{

            for(const data of billingPublishList){
                if(!data.billItem || !data.billItemQty){
                    showToast('품목 또는 수량의 입력이 누락된 청구건이 있습니다.');
                    return;
                }
            }
            await publishBill();
        }
    }

    const publishBill =async(item, qty, note)=> {
        const billingPublishList = BillSearchStore.billingList.filter(v => v.isSelected || v.isUpdated);
        let sumChangePublishAmount = 0;
        let sumChangePublishVat = 0;
        let diffVat = false;

        if (BillSearchStore.condition.billPublishType === '1') {
            //총 청구액
            billingPublishList.forEach(v => {
                sumChangePublishAmount += Number(v.changeChargeAmount ? v.changeChargeAmount : v.chargeAmount);
            });
            //총 부가세
            billingPublishList.forEach(v => {
                sumChangePublishVat += Number(v.changeChargeVat ? v.changeChargeVat : v.chargeVat);
            })

            //부가세 체크
            if((sumChangePublishAmount * 0.1) !== Number(sumChangePublishVat)){
                diffVat = true;
            }
        } else {
            //총 청구액
            data.selectedList.forEach(v => {
                sumChangePublishAmount += v.changeChargeAmount ? Number(v.changeChargeAmount) : Number(v.chargeAmount);
            })
            //총 부가세
            data.selectedList.forEach(v => {
                sumChangePublishVat += v.changeChargeVat ? Number(v.changeChargeVat) : Number(v.chargeVat);
            })
            //부가세 체크
            if((sumChangePublishAmount * 0.1) !== Number(sumChangePublishVat)){
                diffVat = true;
            }
        }

        if(!await callConfirm(`총 발행 금액 ${sumChangePublishAmount.toLocaleString()}원, ${diffVat ? '부가세 계산이 10%가 아닙니다. 그래도 ' : ''}계산서를 발행 하시겠습니까? (${BillSearchStore.condition.publishSendType === '1' ? '정발행' : '역발행'})`)){
            return;
        }

        const param = {
            billPublishCompany: companyCode,
            billPublishDate: billPublishDate.startDate,
            billPublishType: BillSearchStore.condition.billPublishType,
            taxType: BillSearchStore.condition.taxType,
            // billItem: item,
            // billItemQty: qty,
            // billNote: note,
            isAutoSendBill: BillSearchStore.condition.isAutoSendBill,
            publishSendType: BillSearchStore.condition.publishSendType,
            billingPublishList
        }

        if (BillSearchStore.condition.billPublishType === '2') {
            LUtils.assign(param, {billNote: data.billNote, billItemList: data.selectedList, chargeAmount: sumChangePublishAmount, chargeVat: sumChangePublishVat});
        } 

        const result = await axios.post('/bill', param);
        showToast('발행되었습니다.');
        //재조회
        await getBillingTargetList();
    }

    const publishMinusBill =async()=> {
        const billPublishedList = BillSearchStore.billPublishedList.filter(v => v.isSelected);

        if(billPublishedList.length === 0){
            showToast('대상 계산서를 선택해주세요.');
            return;
        }

        const validList = billPublishedList.every(v => v.isSendBill !== 0 && v.taxNo);
        if (!validList) {
            showToast('미발송 계산서는 (-) 계산서 발행이 불가합니다.');
            return;
        }

        if(!await callConfirm(`총 ${billPublishedList.length}건의 (-) 계산서를 발행 하시겠습니까?`)){
            return;
        }

        const param = {
            billPublishDate: billPublishDate.startDate,
            billNote: BillSearchStore.condition.billNote,
            billPublishedList
        }

        const result = await axios.post('/bill/minus', param);
        showToast('발행되었습니다.');
        //재조회
        await getBillPublishedList();
        BillSearchStore.setCondition('', 'billNote');
    }

    const sendBill =async()=> {
        const checkBillList = BillSearchStore.billPublishedList.filter(v => v.isSelected);

        if(checkBillList.length === 0){
            showToast('선택된 계산서가 없습니다.');
            return;
        }

        if(checkBillList.some(v => v.isSendBill === 1)){
            showToast('이미 발송된 계산서가 있습니다.');
            return;
        }

        if(!await callConfirm(`${checkBillList.length}건을 전송 하시겠습니까?`)) return;

        const result = await axios.post('/bill/send', checkBillList);
        if(result.result === 'option_failed') {
            alert(result.message);
        }else if(result.result === 'success'){
            showToast('전송되었습니다.');
        }else{
            showToast('실패하였습니다.(결과 메세지 확인)');
        }

        //재조회
        await getBillPublishedList();
    }

    /* 카드 결제 */
    const billingBtn = async()=> {
        const checkBillList = BillSearchStore.billPublishedList.filter(v => v.isSelected);
        BillSearchStore.sendTradingStatementList = BillSearchStore.billPublishedList.filter(v => v.isSelected);
        BillSearchStore.selectBillList = BillSearchStore.billPublishedList.filter(v => v.isSelected).map(v => ({ ...v, isSelected: false }));

        if(checkBillList.length === 0){
            showToast('선택된 계산서가 없습니다.');
            return;
        }

        if(checkBillList.some(v => v.isSendBill === 1)){
            showToast('이미 발송된 계산서가 있습니다.');
            return;
        }

        const result = await axios.post('/company/card/billing', checkBillList);
        await getBillPublishedList();
        showToast(result);
    }

    /* 카드 결제 취소 */
    const billingCancelBtn = async()=> {
        const checkBillList = BillSearchStore.billPublishedList.filter(v => v.isSelected);
        BillSearchStore.sendTradingStatementList = BillSearchStore.billPublishedList.filter(v => v.isSelected);
        BillSearchStore.selectBillList = BillSearchStore.billPublishedList.filter(v => v.isSelected).map(v => ({ ...v, isSelected: false }));

        if(checkBillList.length === 0){
            showToast('선택된 계산서가 없습니다.');
            return;
        }

        if(checkBillList.some(v => v.isSendBill === 0)){
            showToast('발송된 계산서를 선택해주세요.');
            return;
        }

        const result = await axios.put('/company/card/billing', checkBillList);
        await getBillPublishedList();
        showToast(result);
    }

    return(
        <>
            <CContainer>
                <Row style={{marginTop: -30, height: 56}}>
                    <CCol lg={12}>
                        <ul className="nav nav-pills pt-3 pb-3" style={{borderTopWidth: 0}}>
                            <li className="nav-item" style={{borderRadius: 4, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px'}}>
                                <a className={selView === 'billing' ? "nav-link active" : "nav-link"}
                                   style={{cursor: "pointer"}}
                                   onClick={()=> setSelView('billing')}>계산서 발행</a>
                            </li>
                            <li className="nav-item" style={{marginLeft: 10, borderRadius: 4, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px'}}>
                                <a className={selView === 'search' ? "nav-link active" : "nav-link"}
                                   style={{cursor: "pointer"}}
                                   onClick={()=> setSelView('search')}>계산서 조회</a>
                            </li>
                        </ul>
                    </CCol>
                </Row>
            </CContainer>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                           placeholder={'고객코드'}
                                           placeholder2={'고객명'}
                                           value={BillSearchStore.condition.customerNo}
                                           value2={BillSearchStore.condition.customerName}
                                           onChange={(v)=> BillSearchStore.setCondition(v.target.value, 'customerNo')}
                                           onChange2={(v)=> BillSearchStore.setCondition(v.target.value, 'customerName')}
                                           disabled={true}
                                           onCallbackBtn={()=> AppStore.toggleCustomerSearchModal()}
                                           onEmptyAction={()=> {
                                               BillSearchStore.setCondition('', 'customerNo')
                                               BillSearchStore.setCondition('', 'customerName')
                                           }}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'청구지'} labelId={'billingSeq'} labelId2={'billingTitle'}
                                           placeholder={'번호'}
                                           placeholder2={'청구지명'}
                                           value={BillSearchStore.condition.billingAddressSeq}
                                           value2={BillSearchStore.condition.billingAddressName}
                                           onChange={(v)=> BillSearchStore.setCondition(v.target.value, 'billingAddressSeq')}
                                           disabled2={true}
                                           onCallbackBtn={()=> AppStore.toggleCustomerBillingAddressSearchModal()}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'계약유형'} labelId={'contractType'}
                                      placeholder={'계약유형'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('1'))}
                                      value={BillSearchStore.condition.contractType}
                                      onChange={(v)=> BillSearchStore.setCondition(v.target.value, 'contractType')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'청구구분'} labelId={'billingType'}
                                      placeholder={'청구구분'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('23'))}
                                      value={BillSearchStore.condition.billingType}
                                      onChange={(v)=> BillSearchStore.setCondition(v.target.value, 'billingType')}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        {/* 당월 1일 ~ 말일까지 기본 설정 */}
                        <CalendarGroup
                            isValid={true}
                            label={selView === 'billing' ? '청구일' : '계산서발행일'}
                            value={billingSearchDate}
                            onChange={(v)=> {
                                setBillingSearchDate(v)
                                BillSearchStore.setCondition(v.startDate, 'searchStartDate');
                                BillSearchStore.setCondition(v.endDate, 'searchEndDate');
                            }}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            selectOptions={
                                [
                                    {name: '영업담당자1', value: 0},
                                    {name: '영업담당자2', value: 1},
                                ]
                            }
                            onSelect={(e)=> setSelSearchUserType(e.target.value)}
                            selectValue={selSearchUserType}
                            value={BillSearchStore.condition.userName}
                            placeholder={'사원명'}
                            disabled={true}
                            onCallbackBtn={()=> AppStore.toggleUserSearchModal()}
                            onEmptyBtn={()=> {
                                BillSearchStore.setCondition('', 'userName');
                                BillSearchStore.setCondition('', 'saleUserSeq');
                                BillSearchStore.setCondition('', 'supportUserSeq');
                            }}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            value={BillSearchStore.condition.contractNo}
                            onChange={(e)=> BillSearchStore.setCondition(e.target.value, 'contractNo')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'청구번호'}
                            labelId={'billingNo'}
                            value={BillSearchStore.condition.billingNo}
                            onChange={(e)=> BillSearchStore.setCondition(e.target.value, 'billingNo')}
                        />
                    </CCol>
                </Row>
                <Row>
                    {selView === 'search' &&
                        <>
                            <CCol lg={2}>
                                <CInputGroup
                                    label={'계산서번호'}
                                    labelId={'billNo'}
                                    value={BillSearchStore.condition.billNo}
                                    onChange={(e)=> BillSearchStore.setCondition(e.target.value, 'billNo')}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <Form.Check
                                    type="switch"
                                    id="switch"
                                    label="미발송"
                                    style={{paddingTop:3}}
                                    checked={BillSearchStore.condition.isNonSendBill === 1}
                                    onChange={(v)=> BillSearchStore.setCondition(v.target.checked ? 1 : 0, 'isNonSendBill')}
                                />
                            </CCol>
                        </>
                    }
                    <Row className="align-items-center">
                        {selView === 'search' &&
                            <>
                                <CCol lg={4} >
                                    <Form.Check
                                        inline
                                        label="전체"
                                        name="all"
                                        type={'radio'}
                                        id='all'
                                        value={''}
                                        checked={BillSearchStore.condition.isStatus === ''}
                                        onChange={(e)=> BillSearchStore.condition.isStatus = e.target.value}
                                    />
                                    <Form.Check
                                        inline
                                        label="정상"
                                        name="isNormal"
                                        type={'radio'}
                                        id='isNormal'
                                        value={'0'}
                                        checked={BillSearchStore.condition.isStatus === '0'}
                                        onChange={(e)=> BillSearchStore.condition.isStatus = e.target.value}
                                    />
                                    <Form.Check
                                        inline
                                        label="취소"
                                        name="isCancel"
                                        type={'radio'}
                                        id='isCancel'
                                        value={'1'}
                                        checked={BillSearchStore.condition.isStatus === '1'}
                                        onChange={(e)=> BillSearchStore.condition.isStatus = e.target.value}
                                    />
                                </CCol>
                            </>
                        }               
                        <Col className='d-flex flex-row-reverse'>
                            <CSearchBtn onClick={selView === 'billing' ? getBillingTargetList : getBillPublishedList} />
                        </Col>
                    </Row>
                </Row>
            </CContainer>
            {selView === 'billing' &&
                <CContainer search>
                    <Row>
{/*                        <CCol lg={3}>
                            <CInputGroup
                                label={'계산서 비고'}
                                labelId={'billNote'}
                                value={BillSearchStore.condition.billNote}
                                onChange={(e)=> BillSearchStore.setCondition(e.target.value, 'billNote')}
                            />
                        </CCol>*/}
                        <CCol lg={2} style={{marginTop: 4}}>
                            <Form.Check
                                inline
                                label="정발행"
                                id='regularPublish'
                                name="publishStatus"
                                type={'radio'}
                                value={'1'}
                                onChange={(e) => BillSearchStore.setCondition(e.target.value, 'publishSendType')}
                                checked={BillSearchStore.condition.publishSendType === '1'}
                            />
                            <Form.Check
                                inline
                                label="역발행"
                                id='reversePublish'
                                name="publishStatus"
                                type={'radio'}
                                value={'2'}
                                onChange={(e) => BillSearchStore.setCondition(e.target.value, 'publishSendType')}
                                checked={BillSearchStore.condition.publishSendType === '2'}
                            />
                        </CCol>
                        <CCol lg={6} style={{marginTop: 4}}>
                            <Form.Check
                                inline
                                label="개별발행"
                                id='publishOne'
                                name="billPublishType"
                                type={'radio'}
                                value={'1'}
                                onChange={(e) => {BillSearchStore.setTargetBillItemList([]); BillSearchStore.setCondition(e.target.value, 'billPublishType')}}
                                checked={BillSearchStore.condition.billPublishType === '1'}
                            />
                            <Form.Check
                                inline
                                label="통합발행"
                                id='publishSum'
                                name="billPublishType"
                                type={'radio'}
                                value={'2'}
                                onChange={(e) => {BillSearchStore.setTargetBillItemList([]); BillSearchStore.setCondition(e.target.value, 'billPublishType')}}
                                checked={BillSearchStore.condition.billPublishType === '2'}
                            />
                            <span>| <b>개별발행</b> : 계약번호 단위로 계산서 발행, <b>통합발행</b> : 모든 계약건을 1장으로 발행</span>
                        </CCol>
                        {/*
                        <CCol lg={1} style={{marginTop: 4}}>
                            <Form.Check
                                inline
                                label="과세"
                                id='tax'
                                name="taxType"
                                type={'radio'}
                                value={'A'}
                                onChange={(e) => BillSearchStore.setCondition(e.target.value, 'taxType')}
                                checked={BillSearchStore.condition.taxType === 'tax'}
                            />
                            <Form.Check
                                inline
                                label="영세"
                                id='small'
                                name="taxType"
                                type={'radio'}
                                value={'B'}
                                onChange={(e) => BillSearchStore.setCondition(e.target.value, 'taxType')}
                                checked={BillSearchStore.condition.taxType === 'small'}
                            />
                            <Form.Check
                                inline
                                id={'dutyfree'}
                                label="면세"
                                name="taxType"
                                type={'radio'}
                                value={'C'}
                                onChange={(e) => BillSearchStore.setCondition(e.target.value, 'taxType')}
                                checked={BillSearchStore.condition.taxType === 'dutyfree'}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <Form.Check
                                type="switch"
                                id="switch"
                                label="국세청 자동 발송"
                                style={{paddingTop:3}}
                                checked={BillSearchStore.condition.isAutoSendBill === 1}
                                onChange={(v)=> BillSearchStore.setCondition(v.target.checked ? 1 : 0, 'isAutoSendBill')}
                            />
                        </CCol>
                        */}
                    </Row>
                    <Row>
                        <CCol lg={3}>
                            <CSelectGroup label={'공급사'} labelId={'company'}
                                options={companyList}
                                value={companyCode}
                                onChange={v => setCompanyCode(v.target.value)}
                                disabled={companyList.length === 1}
                            />
                        </CCol>
                        <CCol lg={3}>
                            {/* 오늘 날짜를 기본 설정 */}
                            <CalendarGroup asSingle={true} label={'계산서발행일'}
                                           value={billPublishDate}
                                           onChange={(v) => setBillPublishDate(v)}
                            />
                        </CCol>
                        <CCol lg={4}>
                            <InputGroup size={'sm'}>
                                <InputGroup.Text>선택된 금액</InputGroup.Text>
                                <Form.Control style={{flex: 1, textAlign: 'right'}}
                                              value={selectAmount.toLocaleString()}
                                              disabled={true}
                                />
                                <InputGroup.Text>선택된 부가세</InputGroup.Text>
                                <Form.Control style={{flex: 1, textAlign: 'right'}}
                                              value={selectVat.toLocaleString()}
                                              disabled={true}
                                />
                            </InputGroup>
                        </CCol>
                        <Col className='d-flex flex-row-reverse'>
                            <CIconBtn style={{width: 120, height: 30}}
                                      title={'계산서 발행'}
                                      icon={'fi-rr-box-check'}
                                      onClick={publishBillCheck}
                            />
                        </Col>
                    </Row>
                </CContainer>
            }
            {selView === 'search' &&
                <CContainer search>
                    <Row>
                        <CCol lg={3} className={'mb-0 '}>
                            {/* 오늘 날짜를 기본 설정 */}
                            <CalendarGroup asSingle={true} label={'(-)계산서 발행일'}
                                           value={billPublishDate}
                                           onChange={(v) => setBillPublishDate(v)}
                                           
                            />
                        </CCol>
                        <CCol lg={4} className={'mb-0 '}>
                            <CInputGroup
                                label={'(-)계산서 비고'}
                                labelId={'billNote'}
                                placeholder={'마이너스 계산서의 품목(비고사항)에 입력됩니다.'}
                                value={BillSearchStore.condition.billNote}
                                onChange={(e)=> BillSearchStore.setCondition(e.target.value, 'billNote')}
                            />
                        </CCol>
                        <Col className='d-flex flex-row-reverse'>
                            <CIconBtn style={{width: 120, height: 30}}
                                      title={'(-)계산서 발행'}
                                      onClick={publishMinusBill}
                            />
                            <CIconBtn style={{width: 120, height: 30, backgroundColor: 'green'}}
                                      title={'국세청 발송'}
                                      icon={'fi-rs-money-check-edit'}
                                      onClick={()=> sendBill()}
                            />
                            {userCompanyCode === '2301001' &&
                                <>
                                    <CIconBtn style={{width: 130, backgroundColor: '#0d6efd', border: 'none'}}
                                            title={'카드 결제'}
                                            icon={'fi-rr-credit-card'}
                                            onClick={()=> billingBtn()}
                                    />
                                    <CIconBtn style={{width: 130, backgroundColor: '#ff0000', border: 'none'}}
                                            title={'카드 취소'}
                                            icon={'fi-sr-undo'}
                                            onClick={()=> billingCancelBtn()}
                                    />
                                </>
                            }
                        </Col>
                    </Row>
                </CContainer>
            }
            <BillPublishPopup callbackFn={publishBill} />
        </>
    );
}

export default observer(BillManagerSearch);

import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import {CCol, CContainer, CInputGroup, CSearchBtn} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import {callConfirm, getLocalStorage} from "../../utils";
import axios from "axios";
import {showToast} from "../../common/utils";
import {AuthorityStore} from "../../store/human-resources/AuthorityStore";
import AuthorityGroup from "./AuthorityGroup";
import AuthorityManager from "./AuthorityManager";
import {USER_LIST} from "../../common/constants";
import {toJS} from "mobx";

const Authority =()=> {
    const groupGridRef = useRef();
    const groupUserGridRef = useRef();

    //const [mdCategories, setMdCategories] = useState([]);
    const [smCategories, setSmCategories] = useState([]);
    const [detailAuthList, setDetailAuthList] = useState([]);
    const [authUserList, setAuthUserList] = useState([]);

    useEffect(()=> {
        getAuthList();
        getAuthGroupList();
    }, [])

    const getAuthList =async()=> {
        refresh();
        // 권한
        const authorityInfo = await axios.get('/authority');
        AuthorityStore.setAuthorityList(authorityInfo);
        AuthorityStore.setOrgMdAuthorityList(authorityInfo.filter(v => v.depth === 1));
        AuthorityStore.setOrgSmAuthorityList(authorityInfo.filter(v => v.depth === 2));
        AuthorityStore.setOrgDetailAuthorityList(authorityInfo.filter(v => v.depth === 3));
    }

    const getAuthGroupList =async()=> {
        // 권한그룹목록
        const groupInfo = await axios.get('/authority/group');
        AuthorityStore.setAuthorityGroupList(groupInfo);
        const groupList = groupInfo.map(v => {
            v.name = v.groupName;
            v.value = v.authorityGroupSeq;
            return v;
        })
        AuthorityStore.setGroupList(groupList);
    }
/*
   const searchAuthMdCategories = (row) => {
       const mdList = row.node.data.mdCategories.split(',').map((v, i) => {
           const auth = AuthorityStore.authorityList.find(t => t.authorityCode === v);
           return (
               <Button
                   key={i}
                   onClick={() => searchAuthSmallCategories(auth.authoritySeq)}
                   size="sm"
                   variant={'light'}
                   className={'border border-primary me-2 mb-2'}
               >
                   {auth.authorityName}
               </Button>
           )
       });
       setMdCategories(mdList);
   }

   const searchAuthSmallCategories =(seq)=> {
       const smFilterList = AuthorityStore.orgSmAuthorityList.filter(v => v.parentSeq === seq);
       const smList = smFilterList.map((v,i) => {
           return (
               <Button
                   key={i}
                   onClick={() => searchAuthDetailList(v.authoritySeq)}
                   size="sm"
                   variant="light"
                   className='border border-danger me-2 mb-2'
               >
                   {v.authorityName}
               </Button>
           )
       });

       const mdDetailList = [];
       for(let detail of AuthorityStore.orgDetailAuthorityList){
           for(let smallCate of smFilterList){
               if(detail.parentSeq === smallCate.authoritySeq){
                   for(let mdCate of AuthorityStore.orgMdAuthorityList){
                       if(smallCate.parentSeq === mdCate.authoritySeq){
                           detail.category = mdCate.authorityName;
                           detail.menuName = smallCate.authorityName;
                           mdDetailList.push(detail);
                       }
                   }
               }
           }
       }

       setSmCategories(smList);
       setDetailAuthList(mdDetailList);
   }
*/

    const searchAuthDetailList =async(row)=> {
        let groupAuthorities= [];
        const groupDetailList = [];
        const seq = row.node.data.authorityGroupSeq;

        //해당 그룹에 등록된 기능 코드들을 가져온다.
        for (let group of AuthorityStore.groupList) {
            if (group.authorityGroupSeq == seq) {
                groupAuthorities = group.authorities.split(',');
            }
        }

        for (let detail of AuthorityStore.orgDetailAuthorityList) {
            if(groupAuthorities.some(v => v === detail.authorityCode)){
                const smCate = AuthorityStore.orgSmAuthorityList.find(v => v.authoritySeq === detail.parentSeq);
                if(smCate){
                    const mdCate = AuthorityStore.orgMdAuthorityList.find(v => v.authoritySeq === smCate.parentSeq);
                    if(mdCate){
                        const detailAuth = {};
                        detailAuth.category = mdCate.authorityName;
                        detailAuth.menuName = smCate.authorityName;
                        detailAuth.authorityCode = detail.authorityCode;
                        detailAuth.authorityName = detail.authorityName;
                        detailAuth.crud = detail.crud;
                        groupDetailList.push(detailAuth);
                    }
                }
            }
        }

        //권한별 사용자 목록 조회
        const userList = getLocalStorage(USER_LIST).filter(v => v.authorityGroupSeq === seq);
        //권한별 메뉴 목록
        const smList = [];
        const smallCategories = [];
        groupDetailList.forEach((e,i) => {
            if(!smList.includes(e.menuName)){
                smList.push(e.menuName);
                console.log(e)
                smallCategories.push(
                    <Button
                        key={i}
                        size="sm"
                        variant="light"
                        className='border border-danger me-2 mb-2'
                    >
                        {e.menuName}
                    </Button>
                );
            }
        });

        setAuthUserList(userList);
        setSmCategories(smallCategories);
        setDetailAuthList(groupDetailList);
    }

/*    const searchMenuAuthDetailList =(seq)=> {
        const detailList = AuthorityStore.orgDetailAuthorityList.filter(v => v.parentSeq === seq);
        setDetailAuthList(detailList);
    }*/

    const authGroupCopy =async()=> {
        const authGroupList = toJS(AuthorityStore.authorityGroupList);
        const selectedList = authGroupList.filter(v => v.isSelected);

        if(selectedList.length > 1){
            showToast('1개의 권한만 선택가능합니다.');
            return;
        }

        const authNewGroup = authGroupList.map(v => {
            v.isSelected = false;
            return v;
        });

        const addGroup = {
            addRowId: authGroupList.length,
            groupName: selectedList[0].groupName,
            isUse: 1,
            authorities: selectedList[0].authorities,
            mdCategories: selectedList[0].mdCategories,
            smCategories: selectedList[0].smCategories,
            isCreated: true,
            isSelected: true
        }
        AuthorityStore.setAuthorityGroupList(authNewGroup.concat(addGroup));
    }

    const callbackAuthGroupSave =async(callback)=> {
        const updatedList = callback.updatedList;
        const createdList = callback.createdList;

        if(!await callConfirm(`권한을 저장 하시겠습니까?`)) return;

        let result;
        if(updatedList.length > 0 && createdList.length > 0){
            result = Promise.all([await axios.put('/authority/group', updatedList), await axios.post('/authority/group', createdList)]) ;
        }else{
            result = updatedList.length > 0 && await axios.put('/authority/group', updatedList);
            result = createdList.length > 0 && await axios.post('/authority/group', createdList);
        }

        showToast(`저장되었습니다`);
        await getAuthGroupList();
    }

    const refresh =()=> {
        //setMdCategories([]);
        setSmCategories([]);
        setDetailAuthList([]);
        setAuthUserList([]);

        AuthorityStore.setSmAuthorityList([]);
        AuthorityStore.setDetailAuthorityList([]);
    }

    return(
        <Tabs defaultActiveKey="auth" className="mb-3"
              mountOnEnter={true}
              unmountOnExit={true}
              activeKey={AuthorityStore.mode}
              onSelect={k => {
                  AuthorityStore.setMode(k);
                  refresh();
              }}
        >
            <Tab eventKey="auth" title="권한 그룹">
                {/*<AuthGroupSearch searchGroupList={getAuthGroupList}/>*/}
                <CContainer>
                    <Row>
                        <CCol lg={4}>
                            <Row>
                                <AgGridContainer
                                    gridRef={groupGridRef}
                                    gridTitle={'권한 목록'}
                                    height={40}
                                    rowData={AuthorityStore.authorityGroupList}
                                    columnDefs={[
                                        {field: "authorityGroupSeq", headerName: "순번", width: 80, hide: true},
                                        {
                                            field: "groupName",
                                            headerName: "그룹명",
                                            width: 180,
                                            editable: true,
                                            headerClass: 'grid-column-editable grid-column-required',
                                        },
                                        {
                                            field: 'isUse',
                                            headerName: '사용',
                                            width: 70,
                                            flex: 1,
                                            editable: true,
                                            cellEditor: 'agSelectCellEditor',
                                            cellEditorParams: { values: [0, 1] },
                                            valueFormatter: params => params.value === 1 ? '사용' : '미사용',
                                            headerClass: 'grid-column-editable grid-column-required',
                                        },
                                    ]}
                                    isCheckBox={true}
                                    rowSearchCallback={v => searchAuthDetailList(v)}
                                    useRowSearch={true}
                                    selectBtnInfo={{
                                        variant: 'secondary',
                                        isUsed: true,
                                        title: '권한 복사',
                                        callbackFn: async()=> await authGroupCopy()
                                    }}
                                    useUpdated={true}
                                    seqColumn={'agId'}
                                    callBackGridData={callbackAuthGroupSave}
                                    getRowStyle={(params) => {
                                        if(params.data.isUse === 0){
                                            return { color: '#A4A4A4' };
                                        }
                                        return null;
                                    }}
                                />
                            </Row>
                            <br/>
                            <Row>
                                <AgGridContainer
                                    gridRef={groupUserGridRef}
                                    gridTitle={'그룹 사용자 목록'}
                                    height={30}
                                    rowData={authUserList}
                                    columnDefs={[
                                        {field: "id", headerName: "아이디", width: 150},
                                        {field: "name", headerName: "이름", width: 200, flex: 1},
                                    ]}
                                    seqColumn={'userSeq'}
                                />
                            </Row>
                        </CCol>
                        <CCol lg={2} style={{paddingTop: 13}}>
                            <h5>메뉴 목록</h5>
                            <Row className='border p-2 ms-1 me-1 mb-3 rounded' style={{height: 740, overflow: 'hidden'}}>
                                <Col sm={12} md={12} lg={12} className='ps-0 pe-0'>
                                    {smCategories.length > 0 ? smCategories : '등록된 메뉴가 없습니다.'}
                                </Col>
                            </Row>
                        </CCol>
                        <CCol lg={6} style={{paddingTop: 5}}>
                            <AgGridContainer
                                gridTitle={'권한별 기능 목록'}
                                height={77}
                                rowData={detailAuthList}
                                columnDefs={[
                                    {field: "category", headerName: "카테고리", width: 120},
                                    {field: "menuName", headerName: "메뉴명", width: 140},
                                    {field: "authorityCode", headerName: "코드", width: 120},
                                    {field: "authorityName", headerName: "기능명", width: 180},
                                    {field: "crud", headerName: "기능코드", width: 90, hide: true},
                                    {
                                        field: "crud",
                                        headerName: "기능구분",
                                        width: 120,
                                        flex: 1,
                                        valueFormatter: params => params.value === 'C' ? '입력' : params.value === 'R' ? '조회' : params.value === 'U' ? '수정' : '삭제',
                                    },
                                ]}
                                seqColumn={'authorityCode'}
                            />
                        </CCol>
                    </Row>
                </CContainer>
            </Tab>
            <Tab eventKey="group" title="그룹별 권한 관리">
                <AuthorityGroup getAuthGroupList={getAuthGroupList}/>
            </Tab>
            <Tab eventKey="manager" title="권한 관리">
                <AuthorityManager getAuthList={getAuthList}/>
            </Tab>
        </Tabs>
    );
}

const AuthGroupSearch =observer(({searchGroupList})=> {
    return(
        <CContainer search>
            <Row>
                <CCol lg={3}>
                    <CInputGroup
                        label={'권한명'}
                        labelId={'name'}
                        placeholder={'권한명'}
                        value={AuthorityStore.searchGroupName}
                        onChange={v => AuthorityStore.setSearchGroupName(v.target.value)}
                    />
                </CCol>
                <Col className='d-flex flex-row-reverse'>
                    <CSearchBtn onClick={searchGroupList} />
                </Col>
            </Row>
        </CContainer>
    );
});

export default observer(Authority);

import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const StorageSearchStore = observable({
    searchCondition: {
        storageType: '', storageStartDate: '', storageEndDate:'', assetNo: '', contractNo: '', storageSeq: '', productCode: '',
        customerNo: '', customerName: '', remark: '', creator: '', creatorName: '', saleUserSeq:'',
        saleUserName: '', model: '', orderSeq: '', serialNo: '', storageDelayDays: '', managementNo: '', shelfId: ''
    },

    setSearchCondition(v, k) {
        this.searchCondition[k] = v === 0 ? 0 : v || '';
    },
});

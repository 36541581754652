import { createBrowserRouter } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import ContractDocumentRoutes from './ContractDocumentRoutes';
import ShipConfirmRoutes from './ShipConfirmRoutes';
import AsMobileRoutes from './AsMobileRoutes';

export default function IndexRoutes() {
    return createBrowserRouter([
        LoginRoutes, 
        MainRoutes, 
        AsMobileRoutes, 
        ContractDocumentRoutes, 
        ShipConfirmRoutes
    ]);
}

import { observer } from 'mobx-react-lite';
import axios from 'axios';
import { Row, Col } from "react-bootstrap";
import { CContainer, CInputGroup, CCol, CSearchBtn } from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import { AssetStore } from '../../store/asset/AssetStore';
import { AssetSearchStore } from '../../store/asset/AssetSearchStore';
import { dateTimeFormat } from '../../utils/dateUtils';
import { callAlert } from '../../utils';
import {findCommKrnm} from "../../utils/commcode";
import {getUserName} from "../../utils/userUtils";

const AssetHistory = () => {
    const columnDefs = [
        {field: "assetHistorySeq", headerName: "순번", hide:true},
        {field: "historyOrder", headerName: "순번", width: 80},
        {field: "assetNo", headerName: "자산번호", width: 120},
        {field: "assetType", headerName: "자산구분", width: 110, valueFormatter: v => v.value === '1' ? '당사자산' : '임차자산'},
        {field: "conditionStatus", headerName: "자산상태", width: 110, hide: true},
        {field: "workStatus", headerName: "처리상태", width: 110, hide: true},
        {field: "assetStatus", headerName: "자산상태코드", width: 130, valueFormatter: v => findCommKrnm(v.value, '8')},
        {field: "teamCode", headerName: "팀코드", hide: true},
        {field: "customerName", headerName: "고객명", width: 140},
        {field: "contractNo", headerName: "계약번호", width: 160},
        {field: "contractType", headerName: "계약구분", width: 110, valueFormatter: v => findCommKrnm(v.value, '1')},
        {field: "serialNo", headerName: "시리얼번호", width: 140},
/*        {field: "acquAmount", headerName: "취득가", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
        {field: "acquDate", headerName: "취득일", valueGetter: v => dateTimeFormat(v.data.acquDate)},
        {field: "ifrsCode", headerName: "상각코드"},*/
        {field: "sellAmt", headerName: "매각금액", width: 120, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "sellDate", headerName: "매각일자", width: 120},
        {field: "sellType", headerName: "매각구분", width: 120, valueFormatter: v => findCommKrnm(v.value, '9')},
/*        {field: "shelfId", headerName: "선반번호"},*/
        {field: "creator", headerName: "수정자", valueFormatter: v => getUserName(v.value)},
        {field: "createdDate", headerName: "수정일", minWidth: 200, flex:2, valueGetter: v => dateTimeFormat(v.data.createdDate)},
    ];

    const getAssetHistory = async () => {
        const { assetNo, managementNo, serialNo } = AssetSearchStore.assetHistorySearchCondition;
        if (!assetNo && !managementNo && !serialNo) {
            callAlert('검색조건을 입력해주세요.');
            return false;
        }

        const resultList = await axios.get('/asset/histories', { params: AssetSearchStore.assetHistorySearchCondition });
        AssetStore.setAssetHistoryList(resultList);
    };

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol>
                        <CInputGroup
                            label={'자산번호'}
                            labelId={'assetNo'}
                            placeholder={'자산번호'}
                            value={AssetSearchStore.assetHistorySearchCondition.assetNo}
                            onChange={v => AssetSearchStore.assetHistorySearchCondition.assetNo = v.target.value}
                            enterKeyHint={getAssetHistory}
                        />
                    </CCol>
                    <CCol>
                        <CInputGroup
                            label={'시리얼번호'}
                            labelId={'serialNo'}
                            placeholder={'시리얼번호'}
                            value={AssetSearchStore.assetHistorySearchCondition.serialNo}
                            onChange={v => AssetSearchStore.assetHistorySearchCondition.serialNo = v.target.value}
                            enterKeyHint={getAssetHistory}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getAssetHistory} />
                    </Col>
                </Row>
            </CContainer>

            {/* data grid */}
            <CContainer>
                <AgGridContainer
                    height={60}
                    gridTitle={'이력현황'}
                    isCheckBox={false}
                    rowData={AssetStore.assetHistoryList}
                    columnDefs={columnDefs}
                    useCsvDownload={true}
                />
            </CContainer>
        </>
    );
};

export default observer(AssetHistory);

import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const BillSearchStore = observable({
    condition: {
        contractType: '',
        searchStartDate: '',
        searchEndDate: '',
        customerNo: '',
        customerName: '',
        billingAddressSeq: '',
        billingAddressName: '',
        billingType: '',
        saleUserSeq: '',
        supportUserSeq: '',
        userName: '',
        billPublishDate: '',
        contractNo: '',
        billingNo: '',
        companyCode: '',
        billNo: '',
        isStatus: '0',
        isNonSendBill: 1,
        publishSendType: '1',
        taxType: 'A',
        billPublishType: '1',
        billNote: '',
        isAutoSendBill: 0,
        companyList: [],
        billItemList: [],
    },

    targetBillItemList: [],
    billingList: [],
    billPublishedList: [],
    tradingStatementList: [],
    sendTradingStatementList: [],
    emailList: {},
    monthList: [],
    dayList: [],
    amount: 0,
    vat: 0,
    totalAmount: 0,
    startDate: '',
    endDate: '',
    code: '',
    emptyInputList: [],
    isSave: false,
    subject: '',
    content: '',
    billNo: '',
    emailBillNo: '',

    setTargetBillItemList(v) {
        this.targetBillItemList = v;
    },
    pushTargetBillItemList(v) {
        this.targetBillItemList.push(v);
    },
    setCondition(v, t) {
        this.condition[t] = v;
    },
    setBillingList(v){
        this.billingList = v;
    },
    setBillPublishedList(v){
        this.billPublishedList = v;
    },
    setBillNote(v){
        this.billNote = v;
    },
    sendEmailInit() {
        this.subject = '';
        this.content = '';
        this.emailList = {};
        this.addEmailList = {};
        this.selectBillList = [];
        this.sendTradingStatementList = [];
    }
});

import React, { forwardRef } from "react";

import "./reportTemplate.css";
import { findCommKrnm } from "../../utils/commcode";
import { BillSearchStore } from "../../store/billing/BillSearchStore";

const ReportTemplate =(props, ref)=> {
    const store = BillSearchStore;

    return (
        <div className="trading-statement report-template" ref={ref}>
            <h2 style={{textAlign: 'center', marginBottom: 20}}>거래명세표</h2>
            <table className="table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px'}}>
                <thead style={{borderBottom: 'solid 2px'}}>
                    <tr>
                        <th className='border' style={{width: '50%', textAlign: 'center'}} colSpan={4}>공급자</th>
                        <th className='border' style={{width: '50%', textAlign: 'center'}} colSpan={4}>공급받는자</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{height: 50, textAlign: 'center', verticalAlign: 'middle'}}>
                        <td className='p-1 border'>등록번호</td>
                        <td className='p-1 border data-text' colSpan={3}>{store.tradingStatementList[0]?.companyBusinessNumber || ''}</td>
                        <td className='p-1 border'>등록번호</td>
                        <td className='p-1 border data-text' colSpan={3}>{store.tradingStatementList[0]?.customerBusinessNumber || ''}</td>
                    </tr>
                    <tr style={{height: 50, verticalAlign: 'middle'}}>
                        <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>상 호</td>
                        <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.companyName || ''}</td>
                        <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>대 표</td>
                        {store.tradingStatementList[0]?.signatureImageUrl ? (
                            <td className='p-2 border data-text' style={{width: '18%', verticalAlign: 'bottom'}}>
                                {store.tradingStatementList[0]?.companyOwner || ''}
                                <img src={store.tradingStatementList[0]?.signatureImageUrl} style={{ float: 'right', height: '30px' }} alt="signature image" />
                                
                            </td>
                        ) : (
                            <td className='p-2 border data-text' style={{width: '18%'}}>
                                {store.tradingStatementList[0]?.companyOwner || ''}                                    
                            </td>
                        )}
                        <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>상 호</td>
                        <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.customerName || ''}</td>
                        <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>대 표</td>
                        <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.customerOwner || ''}</td>
                    </tr>
                    <tr style={{height: 50, verticalAlign: 'middle'}}>
                        <td className='p-1 border' style={{textAlign: 'center'}}>주 소</td>
                        <td className='p-2 border data-text' colSpan={3}>{store.tradingStatementList[0]?.companyAddress || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>주 소</td>
                        <td className='p-2 border data-text' colSpan={3}>{store.tradingStatementList[0]?.customerAddress || ''}</td>
                    </tr>
                    <tr style={{height: 50, verticalAlign: 'middle'}}>
                        <td className='p-1 border' style={{textAlign: 'center'}}>업 태</td>
                        <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.companyBusinessConditions || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>종 목</td>
                        <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.companyIndustryType || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>업 태</td>
                        <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.customerBusinessConditions || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>종 목</td>
                        <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.customerIndustryType || ''}</td>
                    </tr>
                </tbody>
            </table>
            <br/>

            <table className="table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px'}}>
                <thead style={{borderBottom: 'solid 2px'}}>
                    <tr>
                        <th className='border' style={{textAlign: 'center'}}>월</th>
                        <th className='border' style={{textAlign: 'center'}}>일</th>
                        <th className='border' style={{textAlign: 'center'}} colSpan={4}>품목</th>
                        <th className='border' style={{textAlign: 'center'}}>규격</th>
                        <th className='border' style={{textAlign: 'center'}}>수량</th>
                        <th className='border' style={{textAlign: 'center'}}>단가(월)</th>
                        <th className='border' style={{textAlign: 'center'}}>공급가액</th>
                        <th className='border' style={{textAlign: 'center'}}>세액</th>
                        <th className='border' style={{textAlign: 'center'}}>비고</th>
                    </tr>
                </thead>
                <tbody>
                {store.tradingStatementList?.map((data, index) => {
                        return(
                            <tr key={index}>
                                <td className='p-1 border data-text' style={{width: '3%'}}>
                                    {
                                        <input value={data.month || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.month = v.target.value} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '3%'}}>
                                    {
                                        <input value={data.day || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.day = v.target.value} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '14%'}}>
                                    {
                                        <input className="pl-2" value={data.model} style={{width: '100%'}} onChange={(v)=> data.model = v.target.value} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '12%'}}>
                                    {
                                        <input value={data.startDate || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.startDate = v.target.value} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '3%'}}>
                                    {
                                        <input defaultValue={data.from || ''} style={{width: '100%', textAlign: 'center'}} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '12%'}}>
                                    {
                                        <input value={data.endDate || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.endDate = v.target.value} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '7%'}}>
                                    {
                                        <input value={data.standard || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.standard = v.target.value} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '6%'}}>
                                    {
                                        <input value={data.qty} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.qty = v.target.value} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '11%'}}>
                                    {
                                        <input className="ag-grid-money-align" value={data.chargeAmount?.toLocaleString()} style={{width: '100%'}} onChange={(v)=> data.chargeAmount = v.target.value} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '11%'}}>
                                    {
                                        <input className="ag-grid-money-align" value={data.chargeTotalAmount?.toLocaleString()} style={{width: '100%'}} onChange={(v)=> data.chargeTotalAmount = v.target.value?.toLocaleString()} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '8%'}}>
                                    {
                                        <input className="ag-grid-money-align" value={data.chargeVat?.toLocaleString()} style={{width: '100%'}} onChange={(v)=> data.chargeVat = v.target.value?.toLocaleString()} />
                                    }
                                </td>
                                <td className='p-1 border data-text' style={{width: '10%'}}>
                                    {
                                        <input value={data.remark || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.remark = v.target.value} />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                    {store.emptyInputList.map(data => {
                        return (
                            <tr key={data.seq}>
                                <td className='p-1 border data-text' style={{width: '3%'}}>
                                    {<input value={data.month || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.month = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '3%'}}>
                                    {<input value={data.day || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.day = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '14%'}}>
                                    {<input className="pl-2" value={data.model} style={{width: '100%'}} onChange={(v)=> data.model = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '12%'}}>
                                    {<input value={data.startDate || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.startDate = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '3%'}}>
                                    {<input defaultValue={data.from} style={{width: '100%', textAlign: 'center'}} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '12%'}}>
                                    {<input value={data.endDate || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.endDate = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '7%'}}>
                                    {<input value={data.standard} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.standard = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '6%'}}>
                                    {<input value={data.qty} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.qty = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '11%'}}>
                                    {<input className="ag-grid-money-align" value={data.chargeAmount} style={{width: '100%'}} onChange={(v)=> data.chargeAmount = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '11%'}}>
                                    {<input className="ag-grid-money-align" value={data.chargeTotalAmount} style={{width: '100%'}} onChange={(v)=> data.chargeTotalAmount = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '8%'}}>
                                    {<input className="ag-grid-money-align" value={data.chargeVat} style={{width: '100%'}} onChange={(v)=> data.chargeVat = v.target.value} />}
                                </td>
                                <td className='p-1 border data-text' style={{width: '10%'}}>
                                    {<input value={data.remark || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.remark = v.target.value} />}
                                </td>
                            </tr>
                        )
                    })}     
                </tbody>
            </table>

            <table className="table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px', height: 50}}>
                <tbody>
                    <tr style={{textAlign: 'center', verticalAlign: 'middle'}}>
                        <td className='p-1 border'>계</td>
                        <td className='p-1 border data-text ag-grid-money-align' style={{width: '20%'}}>₩ {store.amount?.toLocaleString()}</td>
                        <td className='p-1 border'>세액</td>
                        <td className='p-1 border data-text ag-grid-money-align' style={{width: '20%'}}>₩ {store.vat?.toLocaleString()}</td>
                        <td className='p-1 border'>합계</td>
                        <td className='p-1 border data-text ag-grid-money-align' style={{width: '20%'}}>₩ {store.totalAmount?.toLocaleString()} </td>
                        <td className='p-2 border data-text' style={{width: '20%', textAlign: 'left'}}>
                            {'인수자'}
                            <span style={{float: 'right', paddingRight: 10}}>(인)</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <div className='data-text' style={{fontSize: 16, textAlign: 'right', marginRight: 20}}>{'입금계좌'} {findCommKrnm(store.tradingStatementList[0]?.bankCode || '', '24')} {store.tradingStatementList[0]?.accountNumber || ''} {store.tradingStatementList[0]?.companyName || ''}</div> */}
            <br/>
        </div>
    );
};

export default forwardRef(ReportTemplate);

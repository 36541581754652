import React, {useRef, useState} from "react";
import {CContainer} from "../../components/CustomContainer";
import {findCommKrnm,} from "../../utils/commcode";
import AgGridContainer from "../../components/AgGridContainer";
import {StockStore} from "../../store/asset/StockStore";
import StockListSearch from "./StockListSearch";
import {observer} from "mobx-react-lite";
import StcokDetailPopup from "./popup/StcokDetailPopup";
import { AppStore } from "../../store/AppStore";
import axios from "axios";
import { showToast } from "../../common/utils";

const StockList =()=> {
    const gridRef = useRef();
    const [selSearchItem, setSelSearchItem] = useState('asset');
    const [title, setTitle] = useState('');
    const [stockDetailList, setStockDetailList] = useState([]);
    const [originStockDetailList, setOriginStockDetailList] = useState([]);


    const openStockDetailPopup = async (v) => {
        setTitle(v.colDef.headerName);
        let result;
        if (selSearchItem === 'asset') {
            result = await axios.get(`stock/detail/asset/${v.colDef.field}`, {params: {productSeq: v.data.productSeq}});
        } else if (selSearchItem === 'goods') {
            result = await axios.get(`stock/detail/goods/${v.colDef.field}`, {params: {productSeq: v.data.productSeq}});
        } else {
            result = await axios.get(`stock/detail/consumable/${v.colDef.field}`, {params: {productSeq: v.data.productSeq}});
        }
        setStockDetailList(result);
        setOriginStockDetailList(result);
        if (result.length === 0) {
            showToast('조회된 데이터가 없습니다.');
            return;
        }
        AppStore.toggleStockDetailPopup();
    }

    return (
        <>
            <StockListSearch selSearchItem={selSearchItem} setSelSearchItem={setSelSearchItem} />
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={60}
                    rowData={StockStore.stockList}
                    columnDefs={
                        [
                            {field: "stockType", headerName: "구분", width: 110},
                            {field: "assetType", headerName: "자산구분", width: 110, hide: selSearchItem !== 'asset', valueFormatter: v => findCommKrnm(v.value, '7')},
                            {field: "lgCate", headerName: "대분류", width: 130},
                            //{field: "mdCate", headerName: "중분류", width: 100},
                            {field: "smCate", headerName: "소분류", width: 140},
                            {field: "productSeq", headerName: "제품시퀀스", width: 110, hide: true},
                            {field: "productCode", headerName: "제품코드", width: 110, hide: true},
                            {field: "productName", headerName: "제품명", width: 200},
                            {field: "model", headerName: "모델명", width: 160},
                            {field: "spec", headerName: "스펙", width: 200},
                            {field: "brandName", headerName: "제조사", hide: true},
                            {field: "operatingRate", headerName: "평균가동율", width: 120, hide: selSearchItem !== 'asset', valueFormatter: v => v.value ? v.value + '%' : ''},
                            /* 상품 / 자산 / 소모품에 따라 컬럼이 달라진다. */
                            /* 상품 */
                            {field: "amount", headerName: "평균 단가", width: 120, hide: selSearchItem === 'asset', valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                            {field: "stockQty", headerName: "재고수량", width: 110, hide: selSearchItem === 'asset', cellClass: 'ag-grid-money-align', onCellDoubleClicked: (v) => selSearchItem === 'goods' ? openStockDetailPopup(v) : ''},
                            {field: "storageQty", headerName: "입고수량", width: 110, hide: selSearchItem === 'asset', cellClass: 'ag-grid-money-align', onCellDoubleClicked: (v) => selSearchItem === 'goods' ? openStockDetailPopup(v) : ''},
                            {field: "releaseQty", headerName: "출고수량", width: 110, hide: selSearchItem === 'asset', cellClass: 'ag-grid-money-align', onCellDoubleClicked: (v) => selSearchItem === 'goods' ? openStockDetailPopup(v) : ''},
                            {field: "storageAmount", headerName: "총 입고금액", width: 140, hide: selSearchItem === 'asset', valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                            {field: "releaseAmount", headerName: "총 출고금액", width: 140, hide: selSearchItem === 'asset', valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                            {field: "totalAmount", headerName: "총 금액", width: 140, hide: selSearchItem === 'asset', valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},

                            /* 자산, 소모품 */
                            {field: "availableCount", headerName: "대여 가능 수량", width: 140, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "releaseAskWaitCount", headerName: "출고 대기 수량", width: 140, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "reservationCnt", headerName: "예약된 수량", width: 130, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "assetCount", headerName: "총 자산 보유 수량", width: 160, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "storageWaitCnt", headerName: "입고대기", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "storageCheckCnt", headerName: "입고검수중", width: 120, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "shelfWaitCnt", headerName: "선반대기", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "repairWaitCnt", headerName: "수리대기", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "rentAvailableCnt", headerName: "임대가능", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "releaseCheckCnt", headerName: "출고검수중", width: 120, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "releaseCompleteCnt", headerName: "출고완료", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "shipWaitCnt", headerName: "배송대기", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "shippingCnt", headerName: "배송중", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "rentCnt", headerName: "임대중", width: 100, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "returnWaitCnt", headerName: "회수대기", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "returnCnt", headerName: "회수중", width: 100, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "repairCnt", headerName: "수리중", width: 100, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "useCompanyCnt", headerName: "사내사용중", width: 120, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "nonRentCnt", headerName: "임대불가", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "saleWaitCnt", headerName: "매각대기", width: 110, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                            {field: "saleCnt", headerName: "매각", width: 90, hide: selSearchItem !== 'asset', onCellDoubleClicked: (v) => openStockDetailPopup(v)},
                        ]
                    }
                    useCsvDownload={true}
                    seqColumn={'seq'}
                    originList={[]}
                />
            </CContainer>
            <StcokDetailPopup stockDetailList={stockDetailList} setStockDetailList={setStockDetailList} selSearchItem={selSearchItem} title={title} originStockDetailList={originStockDetailList}/>
        </>
    )
}

export default observer(StockList);

import { observer } from "mobx-react-lite";
import { CContainer, CCol, CalendarGroup, CInputGroup, CInputDoubleGroup, CSelectGroup, CSearchBtn, Subject } from "../../components/CustomContainer";
import {Row, Col, Form} from "react-bootstrap";
import { getCommcode } from "../../utils/commcode";
import { ReceiveStore } from "../../store/billing/ReceiveStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { AppStore } from "../../store/AppStore";
import CustomerBillingAddressSearch from '../../components/searchModal/CustomerBillingAddressSearch';

const ReceiveManagerSearch = ({selView, setSelView, getResultList, isBillingSearch, setIsBillingSearch, getBillingList}) => {

    /* 고객 Search Popup */
    const searchCustomerInfo = (data) => {
        if(isBillingSearch){
            ReceiveStore.billingSearch.customerNo = data.customerNo;
            ReceiveStore.billingSearch.customerName = data.customerName;
        }else if(selView === 'receive') {
            ReceiveStore.accountSearch.customerNo = data.customerNo;
            ReceiveStore.accountSearch.customerName = data.customerName;
        }else if(selView === 'search'){
            ReceiveStore.search.customerNo = data.customerNo;
            ReceiveStore.search.customerName = data.customerName;
        }
        setIsBillingSearch(false);
    }

    const getBillingAddressInfo =(info)=> {
        ReceiveStore.billingSearch.billingAddressSeq = info.billingAddressSeq;
        ReceiveStore.billingSearch.billingAddressName = info.billingAddressName;
    }

    return (
        <>
            <CContainer>
                <Row style={{marginTop: -30, height: 56, borderBottomWidth: 1}}>
                    <CCol lg={12}>
                        <ul className="nav nav-pills pt-3 pb-3" style={{borderTopWidth: 0}}>
                            <li className="nav-item" style={{borderRadius: 4, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px'}}>
                                <a className={selView === 'receive' ? "nav-link active" : "nav-link"}
                                   style={{cursor: "pointer"}}
                                   onClick={()=> setSelView('receive')}>수납처리</a>
                            </li>
                            <li className="nav-item" style={{marginLeft: 10, borderRadius: 4, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px'}}>
                                <a className={selView === 'search' ? "nav-link active" : "nav-link"}
                                   style={{cursor: "pointer"}}
                                   onClick={()=> setSelView('search')}>수납조회</a>
                            </li>
                        </ul>
                    </CCol>
                </Row>
            </CContainer>
        
            {selView === 'receive' ? (
                <Row>
                <CCol lg={6} style={{borderRightWidth: 1}}> 
                    <Subject style={{paddingLeft: 20}} isBorder={false}>입금내역</Subject>
                    <CContainer search>  
                        <Row>
                            <CCol lg={6}>
                                <CalendarGroup label={'입금일자'}
                                            value={{
                                                    startDate: ReceiveStore.accountSearch.startDateString,
                                                    endDate: ReceiveStore.accountSearch.endDateString
                                            }}
                                            onChange={v => {ReceiveStore.accountSearch.startDateString = v.startDate, ReceiveStore.accountSearch.endDateString = v.endDate}}
                                />
                            </CCol>
                            <CCol lg={6}>
                                <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                                placeholder={'고객코드'} placeholder2={'고객명'}
                                                value={ReceiveStore.accountSearch.customerNo} value2={ReceiveStore.accountSearch.customerName}
                                                onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                                onChange={v => {ReceiveStore.accountSearch.customerNo = v.target.value, ReceiveStore.accountSearch.customerName = ''}}
                                                onChange2={v => {ReceiveStore.accountSearch.customerName = v.target.value, ReceiveStore.accountSearch.customerNo = ''}}
                                                enterKeyHint1={()=> getResultList()}
                                                enterKeyHint2={()=> getResultList()}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={3}>
                                <CInputGroup label={'입금번호'} labelId={'accountSeq'}
                                            value={ReceiveStore.accountSearch.accountSeq}
                                            onChange={v => ReceiveStore.accountSearch.accountSeq = v.target.value}
                                            enterKeyHint={()=> getResultList()}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CSelectGroup label={'은행'} labelId={'bankCode'}
                                            options={[{name: '전체', value: ''}].concat(getCommcode('24'))}
                                            value={ReceiveStore.accountSearch.bankCode}
                                            onChange={v => ReceiveStore.accountSearch.bankCode = v.target.value}
                                />
                            </CCol>
                            <CCol lg={6}>
                                <CInputGroup label={'계좌번호'} labelId={'accountNo'}
                                            value={ReceiveStore.accountSearch.accountNumber}
                                            onChange={v => ReceiveStore.accountSearch.accountNumber = v.target.value}
                                            enterKeyHint={()=> getResultList()}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <Col className='d-flex flex-row-reverse'>
                                <CSearchBtn title={'입금내역조회'}  width={140} onClick={getResultList} />
                            </Col>
                        </Row>                        
                    </CContainer>
                </CCol>
                <CCol lg={6}>
                    <Subject style={{paddingLeft: 20}} isBorder={false}>미수납 청구 내역</Subject>
                    <CContainer search>
                        <Row>
                            <CCol lg={6} style={{display: 'flex'}}>
                                <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                                   placeholder={'고객코드'} placeholder2={'고객명'}
                                                   value={ReceiveStore.billingSearch.customerNo} value2={ReceiveStore.billingSearch.customerName}
                                                   onCallbackBtn={() => {setIsBillingSearch(true), AppStore.toggleCustomerSearchModal()}}
                                                   onChange={v => {ReceiveStore.billingSearch.customerNo = v.target.value, ReceiveStore.billingSearch.customerName = '', setIsBillingSearch(true)}}
                                                   onChange2={v => {ReceiveStore.billingSearch.customerName = v.target.value, ReceiveStore.billingSearch.customerNo = '', setIsBillingSearch(true)}}
                                                   enterKeyHint1={getBillingList}
                                                   enterKeyHint2={getBillingList}
                                />

                            </CCol>
                            <CCol lg={6}>
                                <CInputDoubleGroup label={'청구지'} labelId={'billingAddressSeq'} labelId2={'billingAddressName'}
                                                   placeholder={'청구지순번'} placeholder2={'청구지명'}
                                                   value={ReceiveStore.billingSearch.billingAddressSeq} 
                                                   value2={ReceiveStore.billingSearch.billingAddressName}
                                                   onCallbackBtn={() => AppStore.toggleCustomerBillingAddressSearchModal()}
                                                   onChange={v => {ReceiveStore.billingSearch.billingAddressSeq = v.target.value, ReceiveStore.billingSearch.billingAddressName = ''}}
                                                   onChange2={v => {ReceiveStore.billingSearch.billingAddressName = v.target.value, ReceiveStore.billingSearch.billingAddressSeq = ''}}
                                                   enterKeyHint1={getBillingList}
                                                   enterKeyHint2={getBillingList}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={6} style={{height: 43}}>
                            </CCol>
                        </Row>
                        <Row>
                            <Col className='d-flex flex-row-reverse'>
                                <CSearchBtn title={'청구조회'}
                                            style={{width: 110, marginBottom: 0}}
                                            onClick={() => getBillingList(ReceiveStore.billingSearch.customerNo, ReceiveStore.billingSearch.customerName, accountSeq)}
                                />
                            </Col>
                        </Row>
                    </CContainer>
                </CCol>
            </Row>
            ) : (
                <CContainer search>
                    <Row>
                        <CCol lg={3}>
                            <CalendarGroup label={'수납일자'}
                                            value={{
                                                startDate: ReceiveStore.search.startDate,
                                                endDate: ReceiveStore.search.endDate
                                            }}
                                            onChange={v => {ReceiveStore.search.startDate = v.startDate, ReceiveStore.search.endDate = v.endDate}}
                            />
                        </CCol>
                        <CCol lg={3}>
                            <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                                placeholder={'고객코드'} placeholder2={'고객명'}
                                                value={ReceiveStore.search.customerNo} value2={ReceiveStore.search.customerName}
                                                onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                                onChange={v => {ReceiveStore.search.customerNo = v.target.value, ReceiveStore.search.customerName = ''}}
                                                onChange2={v => {ReceiveStore.search.customerName = v.target.value, ReceiveStore.search.customerNo = ''}}
                                                enterKeyHint1={()=> searchCustomerInfo()}
                                                enterKeyHint2={()=> searchCustomerInfo()}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup label={'수납번호'} labelId={'receiveNo'}
                                            value={ReceiveStore.search.receiveNo}
                                            onChange={v => ReceiveStore.search.receiveNo = v.target.value}
                                            enterKeyHint={()=> getResultList()}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                            value={ReceiveStore.search.contractNo}
                                            onChange={v => ReceiveStore.search.contractNo = v.target.value}
                                            enterKeyHint={()=> getResultList()}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <Form.Check
                                type="switch"
                                id="exceptCancel"
                                label="삭제건 제외"
                                style={{marginTop: 3}}
                                checked={ReceiveStore.search.exceptCancel}
                                onChange={v=> ReceiveStore.search.exceptCancel = v.target.checked}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            <CSearchBtn onClick={getResultList} />
                        </Col>
                    </Row>
                </CContainer>
            )}
            <CustomerSearch callbackFn={searchCustomerInfo}/>
            <CustomerBillingAddressSearch callbackFn={getBillingAddressInfo} customerNo={ReceiveStore.billingSearch.customerNo} />
        </>
    );
}

export default observer(ReceiveManagerSearch);



import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Form, Button, Image, Container } from "react-bootstrap";
import axios from "axios";
import { refreshCommcode } from "../../utils/commcode";
import { refreshUserInfo } from "../../utils/userUtils";
import { callAlert } from "../../utils";
import { AppStore } from "../../store/AppStore";
import LUtils from "../../utils/lodashUtils";
import { encrypt } from "../../common/utils";
import { AsStore } from "../../store/asset/AsStore";

const AsDetailLinkLogin = () => {
    const params = useParams();
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect (()=>{
        AsStore.data.asSeq = params.asSeq;
    }, [])

    const processLogin = async () => {
        const userInfoParams = {id: userId, password: encrypt(password), mobile: true};
        const userInfo = await axios.post('/user/as/login', userInfoParams);
        AppStore.setLinkUser(userInfo);
        navigate(`/as/accept/detail/${params.asSeq}`, { replace: true });
    }

    return(

        <div className="login-body">
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" />
        {/* <div className="d-flex align-items-center min-vh-100 py-3 py-md-0"> */}
        <div className="d-flex justify-content-center align-items-center min-vh-100"
             style={{backgroundSize: 'cover', backgroundImage: `url(${process.env.PUBLIC_URL}/image/2.jpg)`}}>
            <div style={{width: '900px'}}>
                <div className="login-card" style={{boxShadow: 'rgb(28 33 47 / 60%) 0px 0px 10rem 0px'}}>
                    <div className="row no-gutters" style={{height:'100%'}}>
                        <div style={{height: 60, zIndex: 1000, backgroundColor: '#fff', width: '100%', paddingTop: 12, paddingLeft: 25, borderBottomWidth: 0}}>
                            <div className="brand-wrapper d-flex justify-content-lg-start align-items-center flex-row">
                                <Image src={`${process.env.PUBLIC_URL}/image/pelotonlab_ci.png`} alt="logo" className="logo"/>
                            </div>
                        </div>
                        <div className="col-md-5" style={{backgroundColor: '#fff'}}>
                        {/* style={{borderRight: '1px solid #D8D8D8'}} */}
                            <img src={`${process.env.PUBLIC_URL}/image/laptop.jpg`} alt="로그인 이미지" style={{paddingTop: 60}}/>
                        </div>
                        <div className="col-md-7">
                            {/* 로그인 폼 요소 중앙 정렬 */}
                            {/* style={{background: '#7cd7e9'}}*/}
                            <div className="card-body d-flex justify-content-center align-items-center flex-column" style={{paddingBottom: 40, paddingTop: 40}}>
                            {/* 로그인 폼 요소 왼쪽 정렬 */}
                            {/* <div className="card-body"> */}
                                <div>
                                    <h6 style={{color: '#6E6E6E'}}>렌탈 비즈니스 최고의 파트너!</h6>
                                </div>
                                <div>
                                    <label className="login-card-description" style={{color: '#0B2161'}}><b style={{fontSize: 25, color: '#084B8A'}}>IT Rent Plan.</b></label>
                                </div>
                                <div>
                                    <Form style={{width: '400px'}}>
                                        <Form.Group className='form-group' controlId="formBasicId">
                                            <Form.Label className='sr-only'>ID</Form.Label>
                                            <Form.Control type='text' value={userId} onChange={v => setUserId(v.target.value)} placeholder='아이디를 입력해 주세요.'/>
                                        </Form.Group>
                                        <Form.Group className='form-group' controlId="formBasicPassword">
                                            <Form.Label className='sr-only'>비밀번호</Form.Label>
                                            <Form.Control type='password'
                                                          value={password}
                                                          onKeyPress={(e)=> e.code === 'Enter' && processLogin()}
                                                          onChange={v => setPassword(v.target.value)}
                                                          autoComplete="on"
                                                          placeholder='비밀번호를 입력해 주세요.'
                                            />
                                        </Form.Group>
                                        <Button className='btn btn-block login-btn mb-4' type="button" variant={'primary'} onClick={processLogin}>
                                            로그인
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div style={{height: 120, zIndex: 1000, backgroundColor: '#fff', width: '100%', color: '#000', paddingTop: 16, paddingLeft: 20, paddingBottom: 15, display: 'flex', alignItems: 'center'}}>
                            <span>
                                <b style={{color: '#000'}}>(주)펠로톤랩</b>
                                <br/>
                                Copyright © 2023 Peloton Lab All rights reservered.
                            </span>
                            {/* <div>
                                대표자 : 김규완 | 사업자 등록번호: 395-88-02597 | 개인정보관리책임자: 김기준
                                주소 : 서울특별시 금천구 벚꽃로36길 30, 가산KS타워 11층
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );

}

export default observer(AsDetailLinkLogin);

import React, { useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';
import {Form, InputGroup, Row} from "react-bootstrap";
import axios from "axios";
import { findCommKrnm } from "../../../utils/commcode";
import AgGridContainer from "../../../components/AgGridContainer";
import { ContractTabsStore } from "../../../store/contract/ContractTabsStore";
import {CCol, CInputGroup} from "../../../components/CustomContainer";
import { addMonthDate, dayAgo } from "../../../utils/dateUtils";

const ContractBillingList =({ contractNo, contractStartDate, contractEndDate }) => {
    const gridRef = useRef();
    const [totalRentalPrice, setTotalRentalPrice] = useState(0);
    const [assetNo, setAssetNo] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [round, setRound] = useState('');
    const [resultSize, setResultSize] = useState('');

    useEffect(() => {
        getContractBillingSchedule(contractNo);
        getContractBillingList(contractNo);

        return () => {
            ContractTabsStore.setContractAssetBillingList([]);
            ContractTabsStore.setContractBillingSchedule([]);
        }
    }, [contractNo]);

    useEffect(() => {
        setStartDate(contractStartDate);
        setEndDate(contractEndDate);
    }, [contractStartDate])

    const getContractBillingSchedule = async (contractNo) => {
        if (!contractNo) {
            return false;
        }

        const result = await axios.get(`/billing/${contractNo}/schedule`);
        const groupedResults = Object.values(
            result.reduce((acc, curr) => {
                const month = curr.billingMonth;
                if (!acc[month]) {
                    acc[month] = { ...curr };
                } else {
                    acc[month].billingAmount += curr.billingAmount;
                    acc[month].chargeTotalAmount += curr.chargeTotalAmount;
                    acc[month].receiveAmount += curr.receiveAmount;
                    acc[month].notReceiveAmount += curr.notReceiveAmount;
                    // isReceive는 하나라도 "N"이 있으면 "N"으로 설정, 그렇지 않으면 "Y"
                    acc[month].isReceive = acc[month].isReceive === "N" || curr.isReceive === "N" ? "N" : "Y";
                }
                return acc;
            }, {})
        );

        ContractTabsStore.setContractBillingSchedule(groupedResults);
        setResultSize(groupedResults.length);
        setTotalRentalPrice(groupedResults.reduce((total, v) => total + v.billingAmount,0));
    }

    // Row 더블클릭 이벤트
    const getContractBillingList = async(contractNo, month, seq)=> {
        if (!contractNo) {
            return false;
        }

        if(resultSize === seq) {
            setStartDate(addMonthDate(contractStartDate, seq-1));
            setEndDate(contractEndDate);
        }else {
            setStartDate(addMonthDate(contractStartDate, seq-1));
            setEndDate(dayAgo(addMonthDate(contractStartDate, seq)));
        }

        const result = await axios.get(`/billing/list`, {params: {contractNo: contractNo, billingMonth: month, exceptDel: 1}});
        setRound(seq ? seq + '회차 : ' : '청구 기간 : ');
        ContractTabsStore.setContractAssetBillingList(result);
        ContractTabsStore.setOriginContractAssetBillingList(result);
    }

    return (
        <>
            <Row>
                <CCol lg={4}>
                    <Row>
                        <CCol lg={6}>
                            <InputGroup size={'sm'}>
                                <InputGroup.Text id={'totalRentalPrice'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>총 렌탈료</InputGroup.Text>
                                <Form.Control
                                    style={{flex: 1, textAlign: 'right'}}
                                    aria-describedby={'totalRentalPrice'}
                                    value={totalRentalPrice?.toLocaleString()}
                                    readOnly={true}
                                />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <AgGridContainer
                            gridRef={gridRef}
                            height={40}
                            rowData={ContractTabsStore.contractBillingSchedule}
                            columnDefs={[
                                {field: "contractNo", hide: true},
                                {field: "seq", headerName: "회차", width: 80},
                                {field: "billingMonth", headerName: "청구월"},
                                {
                                    field: "billingAmount",
                                    headerName: "청구금액",
                                    cellClass: 'ag-grid-money-align',
                                    width: 110,
                                    valueFormatter: v => v.value?.toLocaleString(),
                                },
                                {
                                    field: "chargeTotalAmount",
                                    headerName: "계산서발행 금액",
                                    cellClass: 'ag-grid-money-align',
                                    valueFormatter: v => v.value?.toLocaleString(),
                                    width: 150
                                },
                                {
                                    field: "receiveAmount",
                                    headerName: "수납금액",
                                    cellClass: 'ag-grid-money-align',
                                    valueFormatter: v => v.value?.toLocaleString(),
                                    width: 120
                                },
                                {field: "isReceive", headerName: "수납완료", width: 110},
                                {
                                    field: "notReceiveAmount",
                                    headerName: "미수납금액",
                                    width: 120,
                                    cellClass: 'ag-grid-money-align',
                                    valueFormatter: v => v.value?.toLocaleString(),
                                }
                            ]}
                            seqColumn={'billingMonth'}
                            useIntoTheTab={true}
                            useCsvDownload={true}
                            rowDoubleClickCallback={(e)=> getContractBillingList(e.data.contractNo, e.data.billingMonth, e.data.seq)}
                        />
                    </Row>
                </CCol>
                <CCol lg={8}>
                    <Row style={{}}>
                        <CCol lg={3}>
                            <CInputGroup
                                label={'자산번호'}
                                labelId={'assetNo'}
                                value={assetNo}
                                onChange={v => {
                                    let filterAssetNo = v.target.value;
                                    setAssetNo(filterAssetNo);

                                    if(filterAssetNo){
                                        const filterAssetList = ContractTabsStore.originContractAssetBillingList.filter(f => f.assetNo.indexOf(filterAssetNo) > -1);
                                        ContractTabsStore.setContractAssetBillingList(filterAssetList);
                                    }else{
                                        ContractTabsStore.setContractAssetBillingList(ContractTabsStore.originContractAssetBillingList);
                                    }
                                }}
                                placeholder={'필터'}
                            />

                        </CCol>
                        <CCol lg={5} className=" mt-1 ">{contractNo ? round + startDate + ' - ' + endDate : ''}</CCol>
                    </Row>
                    <AgGridContainer
                        gridRef={gridRef}
                        height={40}
                        rowData={ContractTabsStore.contractAssetBillingList}
                        columnDefs={[
                            {field: "billingNo", headerName: "청구번호", width: 120},
                            {field: "billingSeq", headerName: "청구순번", width: 110},
                            {field: "assetNo", headerName: "자산번호", width: 110},
                            {
                                field: "billingType",
                                headerName: "청구구분",
                                width: 110,
                                valueFormatter: v => findCommKrnm(String(v.value), '23')
                            },
                            {field: "orgChargeDate", headerName: "원청구일", width: 120},
                            {field: "chargeDate", headerName: "청구일", width: 120},
                            {field: "dueDate", headerName: "납기일", width: 120},
                            {
                                field: "chargeAmount",
                                headerName: "청구금액",
                                width: 120,
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString(),
                            },
/*                            {
                                field: "receiveAmount",
                                headerName: "수납금액",
                                width: 120,
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString(),
                            },
                            {field: "billNo", headerName: "계산서번호", width: 130},*/
                            {field: "updater", headerName: "변경자"},
                            {field: "updatedDate", headerName: "변경일자", width: 200}
                        ]}
                        seqColumn={'billingNo'}
                        originList={ContractTabsStore.originContractAssetBillingList}
                        useIntoTheTab={true}
                        useCsvDownload={true}
                    />
                </CCol>
            </Row>
        </>
    );
}

export default observer(ContractBillingList);

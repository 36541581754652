import { useState, useRef, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import AgGridContainer from "../../../components/AgGridContainer";
import { CCol, CContainer, CInputDoubleGroup, CInputGroup, CSaveBtn, CSearchBtn } from "../../../components/CustomContainer";
import { ReturnAskListStore } from "../../../store/return/ReturnAskListStore";
import CustomerSearch from "../../../components/searchModal/CustomerSearch";
import axios from "axios";
import { showToast } from "../../../common/utils";

const ReturnItemPopup =({ callbackFn=null, column='', detailContractNo, setDetailContractNo, getLastReleaseAskInfo, ...props})=> {
    const gridRef = useRef();
    const [customerNo, setCustomerNo] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [contractNo, setContractNo] = useState('');
    const [itemList, setItemList] = useState(props.contractAssetList);

    useEffect(() => {
        setItemList(props.contractAssetList);
    }, [props.contractAssetList]);

    useEffect(() => {
        setContractNo(detailContractNo);
    }, [detailContractNo]);

    const selectContractAsset = async () => {
        const allContractNosSame = itemList.filter(v => v.isSelected).every(
            item => item.contractNo === itemList.filter(v => v.isSelected)[0].contractNo
        );

        if(!allContractNosSame){
            return showToast('계약번호가 같은 자산만 선택할 수 있습니다.');
        }

        let returnAskItemList = ReturnAskListStore.returnAskItemList;

        itemList.forEach(item => {
            if(item.isSelected === true) {
                if (returnAskItemList.every(v => v.assetNo !== item.assetNo && v.serialNo !== item.serialNo)) {
                    returnAskItemList.push({...item, isCreated: true});
                }
            }
        })
        ReturnAskListStore.returnAskItemList = returnAskItemList;

        // 회수의뢰 상세 채우기
        const no = itemList.filter(v => v.isSelected)[0].contractNo;
        const result = await axios.get('/contract/list', { params: { contractNo: no } });
        setDetailContractNo(no);
        getLastReleaseAskInfo(no);
        setItemList([]);

        AppStore.toggleReturnItemPopup();
    }

    const selectedRow = async (e) => {
        if (ReturnAskListStore.returnAskItemList.length > 0 && ReturnAskListStore.returnAskItemList.findIndex(v => v.assetNo === e.data.assetNo) > -1) {
            showToast(`이미 추가된 제품 입니다. [${e.data.productName}]`);
            return;
        }

        let returnAskItemList = ReturnAskListStore.returnAskItemList;
        returnAskItemList.push({...e.data, isCreated: true});
        ReturnAskListStore.returnAskItemList = returnAskItemList;

        // 회수의뢰 상세 채우기
        const no = e.data.contractNo;
        const result = await axios.get('/contract/list', { params: { contractNo: e.data.contractNo } });
        setDetailContractNo(no);
        getLastReleaseAskInfo(no);
        setItemList([]);

        AppStore.toggleReturnItemPopup();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        if(customerInfo){
            setCustomerNo(customerInfo.customerNo);
            setCustomerName(customerInfo.customerName);
        }
    }

    const onSearch = async () => {
        const list = await axios.get('returnAskItem/targetList', { params: {customerNo, customerName, contractNo} });
        setItemList(list);
    }

    const refresh = () => {
        setCustomerNo('');
        setCustomerName('');
        setItemList([]);
    }

    return (
        <Modal 
            title={'계약자산조회'}
            show={AppStore.isOpenReturnItemPopup}
            onHide={() => AppStore.toggleReturnItemPopup()}
            toggleValue={AppStore.isOpenReturnItemPopup}
            className={'search-modal-6'}
            onExit={refresh}
        >

            {!ReturnAskListStore.data.returnAskSeq &&
                <CContainer search>
                    <Row>
                        <CCol lg={4}>
                            <CInputDoubleGroup
                                label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                placeholder={'고객코드'} placeholder2={'고객명'}
                                onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                value={customerNo || ''}
                                value2={customerName || ''}
                                onChange={(v) => setCustomerNo(v.target.value)}
                                onChange2={(v) => setCustomerName(v.target.value)}
                                enterKeyHint1={()=> onSearch()}
                                enterKeyHint2={()=> onSearch()}
                            />
                        </CCol>
                        <CCol lg={4}>
                            <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                            value={contractNo}
                                            onChange={(v)=> setContractNo(v.target.value)}
                                            enterKeyHint={()=> onSearch()}
                                            disabled={detailContractNo.length > 0}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            <CSearchBtn onClick={onSearch} />
                        </Col>
                    </Row>
                </CContainer>
            }
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={30}
                    rowData={itemList}
                    columnDefs={[
                        {field: "assetNo", headerName: "자산번호", width: 130, hide: true},
                        {field: "contractNo", headerName: "계약번호", width: 160, hide: ReturnAskListStore.data.returnAskSeq},
                        {field: "productName", headerName: "제품명", width: 230},
                        {field: "model", headerName: "모델명", width: 230},
                        {field: "qty", headerName: "수량", width: 80},
                        {field: "serialNo", headerName: "시리얼번호", width: 200},
                        {field: "spec", headerName: "스펙", flex: 1},
                        {field: "productSeq", headerName: "제품 시퀀스", hide: true},
                    ]}
                    isCheckBox={true}
                    enableCellTextSelection={true}
                    animateRows={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer> 
            <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn style={{width: 80}}
                                title={'선택'}
                                onClick={selectContractAsset}
                        />
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo}/>
        </Modal>
    )
}

export default observer(ReturnItemPopup);

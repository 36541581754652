import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"
import { findCommKrnm } from "../../../utils/commcode";
import { dateFormat } from "../../../utils/dateUtils";
import AgGridContainer from "../../../components/AgGridContainer";
import { NonpaymentStore } from "../../../store/billing/NonpaymentStore";
import axios from "axios";

const NonPaymentList = ({getCustomerNonpaymentList}) => {
    const gridRef = useRef();

    useEffect(() => {
        getCustomerNonpaymentList();
    }, [])

    return (
        <AgGridContainer
            gridRef={gridRef}
            rowData={NonpaymentStore.customerNonpaymentList}
            height={25}
            columnDefs={
                [
                    {field: "consultationSeq", headerName: "순번", valueGetter: 'node.rowIndex + 1', width: 80},
                    {field: "contractNo", headerName: "계약번호", width: 160},
                    {field: "billNo", headerName: "계산서번호", width: 130},
                    {field: "siteName", headerName: "현장명", width: 140},
                    {field: "chargeDate", headerName: "청구일자", valueGetter: v => dateFormat(v.data.chargeDate), width: 120},
                    {field: "dueDate", headerName: "납기일자", valueGetter: v => dateFormat(v.data.dueDate), width: 120},
                    {field: "dueNonpaymentDate", headerName: "납기미납일수", valueGetter: v => v.data.dueNonpaymentDate, cellClass: 'ag-grid-money-align', width: 120},
                    {field: "dueNonpaymentAmount", headerName: "납기미납액", valueFormatter: v => v.data.dueNonpaymentAmount?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130},
                    {field: "chargeTotalAmount", headerName: "총청구액", valueFormatter: v => v.data.chargeTotalAmount?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130},
                    {field: "chargeNonpaymentDate", headerName: "청구미납일수", valueGetter: v => v.data.chargeNonpaymentDate, cellClass: 'ag-grid-money-align', width: 120, hide: true},
                    {field: "latePayment", headerName: "연체료"},
                    {field: "billingAddressName", headerName: "청구지", width: 140},
                    {field: "billingAddressSeq", headerName: "청구지순번", width: 130},
                    {field: "recentReceiveDate", headerName: "최근수납일", valueGetter: v => dateFormat(v.data.recentReceiveDate), width: 120},
                    {field: "contractType", headerName: "계약구분", valueFormatter: v => findCommKrnm(String(v.data.contractType), '1'), width: 110},
                    {field: "saleUserName", headerName: "영업사원", width: 110},
                    {field: "bondType", headerName: "채권형태", valueFormatter: v => findCommKrnm(String(v.data.bondType), '37'), width: 135},
                    {field: "bondActivity", headerName: "마지막 채권활동", valueFormatter: v => findCommKrnm(String(v.data.bondActivity), '38'), width: 150},
                    {field: "consultationDate", headerName: "마지막 상담일자", valueGetter: v => dateFormat(v.data.consultationDate), width: 150},
                    {field: "paymentPromiseDate", headerName: "납부약속일", valueGetter: v => dateFormat(v.data.paymentPromiseDate), width: 120},
                    {field: "consultationContent", headerName: "상담내용", width: 200, suppressSizeToFit: true},
                ]
            }
        />
    )
}

export default NonPaymentList;
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import koLocale from '@fullcalendar/core/locales/ko';
import axios from 'axios';

import { CContainer } from '../../components/CustomContainer';
import { dateFormat, startDateForMonth, endDateForMonth } from '../../utils/dateUtils';
import ShipSchedulePopup from './popup/ShipSchedulePopup';
import Modal from '../../components/Modal';
import { AppStore } from '../../store/AppStore';

const ShipSchedule = () => {
    const [height, setHeight] = useState(window.innerHeight);
    const [eventsData, setEventsData] = useState([]);
    const [shipSeq, setShipSeq] = useState(null);
    const [division, setDivision] = useState('');
    const [shipHopeTime, setShipHopeTime] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [contractNo, setContractNo] = useState('');
    const [notReceiveAmount, setNotReceiveAmount] = useState('');
    const [shipType, setShipType] = useState('');
    const [outsourcingInfo, setOutsourcingInfo] = useState('');
    const [outsourcingAmount, setOutsourcingAmount] = useState('');

    const getShipSchedule = async (startDate, endDate) => {
        const result = await axios.get('/ship/schedule', { params: { startDate: startDate, endDate: endDate }});
        settingData(result);
    };

    const handleDatesSet = (dateInfo) => {
        const date = dateFormat(dateInfo.view.currentStart);
        const startDate = startDateForMonth(date);
        const endDate = endDateForMonth(date);

        getShipSchedule(startDate, endDate);
    };

    const handleEventClick = (info) => {
        setShipSeq(info.event.id);
        setDivision(info.event._def.extendedProps.divisionTitle);
        setShipHopeTime(info.event._def.extendedProps.shipHopeTime);
        setCustomerName(info.event._def.extendedProps.customerName);
        setContractNo(info.event._def.extendedProps.contractNo);
        setNotReceiveAmount(info.event._def.extendedProps.notReceiveAmount);
        setShipType(info.event._def.extendedProps.shipType);
        setOutsourcingInfo(info.event._def.extendedProps.outsourcingInfo);
        setOutsourcingAmount(info.event._def.extendedProps.outsourcingAmount);
        AppStore.toggleModal();
    }

    const settingData = (list) => {
        const newList = list.map(item => ({
            id: item.shipSeq,
            divisionTitle: item.divisionTitle,
            title: getTitle(item.divisionTitle, item.customerName, item.qty, item.shipHopeTime), 
            start: `${item.shipHopeDate}`, 
            borderColor: item.division !== '출고/회수' ? item.division === '완료' ? 'gray' : item.division === '회수' ? 'green' : 'blue' : 'orange', 
            backgroundColor: item.division !== '출고/회수' ? item.division === '완료' ? 'gray' : item.division === '회수' ? 'green' : 'blue' : 'orange', 
            division: item.division,
            shipHopeTime: item.shipHopeTime,
            customerName: item.customerName,
            contractNo: item.contractNo,
            notReceiveAmount: item.notReceiveAmount?.toLocaleString(),
            shipType: item.shipType,
            outsourcingInfo: item.outsourcingInfo,
            outsourcingAmount: item.outsourcingAmount,
        }));

        setEventsData(newList);
    };

    const getTitle = (divisionTitle, siteName, qty, time) => {
        if(!siteName) return '';
        const customTitle = divisionTitle ? '['+divisionTitle+']' : '';
        const customTime = time ? time.includes('시') ? '('+time+')' : '('+time+'시)' : '';
        return `${customTitle} ${customTime} ${siteName} ${qty}대`;
    }

    const closedModal = () => {
        setShipSeq(null);
    }

    const colorInfo = () => {
        return (
            <div className="fc-toolbar" style={{marginBottom: 20}}>
                <div className="fc-left">
                    <span className="circle blue"></span> 출고&nbsp;&nbsp;&nbsp;
                    <span className="circle green"></span> 회수&nbsp;&nbsp;&nbsp;
                    <span className="circle orange"></span> 교환&nbsp;&nbsp;&nbsp;
                    <span className="circle gray"></span> 완료
                </div>
            </div>
        );
    };

    return (
        <>
            <CContainer>
                <div style={{textDecoration: 'none', color: 'inherit'}}>
                    {colorInfo()}
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        headerToolbar={{
                            left: 'dayGridMonth,dayGridWeek,dayGridDay',
                            center: 'title',
                        }}
                        height={height}
                        dayMaxEvents={true}
                        events={eventsData}
                        locale={koLocale}
                        eventClick={handleEventClick}
                        datesSet={handleDatesSet}
                    />
                </div>
            </CContainer>

            <Modal title={'배송 스케쥴 상세 정보'} onExit={closedModal} size={'md'}>
                <ShipSchedulePopup shipSeq={shipSeq} division={division} shipHopeTime={shipHopeTime} customerName={customerName} contractNo={contractNo} 
                                    notReceiveAmount={notReceiveAmount} shipType={shipType} outsourcingInfo={outsourcingInfo} outsourcingAmount={outsourcingAmount} />
            </Modal>
        </>
      );

};

export default observer(ShipSchedule);

import {useEffect, useRef, useState} from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import { CommcodeSMOptions } from "../index";
import AgGridContainer from "../AgGridContainer";
import {ContractDetailStore} from "../../store/contract/ContractDetailStore";
import {showToast} from "../../common/utils";
import {findCommKrnm} from "../../utils/commcode";

const StockAssetSearch =({ callbackProduct=null, callbackOption=null, ...props })=> {
    const {searchType} = props;
    const gridRef = useRef();

    const [searchList, setSearchList] = useState([]);
    const [managementNo, setManagementNo] = useState('');
    const [assetNo, setAssetNo] = useState('');
    const [productName, setProductName] = useState('');
    const [model, setModel] = useState('');
    const [brandCode, setBrandCode] = useState('');

    useEffect(() => {
    }, [searchType]);

    /* 제조사 값 설정 */
    const onChangeBrandName = (v) => {
        setBrandCode(v.target.value);
    }

    /* 조회 */
    const onSearch = async () => {
        const params = {
            managementNo,
            productName,
            assetNo,
            model,
            brandCode,
            available: 1
            // isDeleted: 0,
            // searchType: searchType,
        };
        // const result = await axios.get(`/asset/awp/stock/list/${searchType}`, { params });
        const result = await axios.get(`/asset/stock/list`, { params });
        const selectedItems = searchList.filter(v => v.isSelected);
        const uniqueList = [...selectedItems];

        for (const item of result) {
            const isDuplicate = selectedItems.some(v =>
                v.serialNo === item.serialNo && v.managementNo === item.managementNo
            );

            if (!isDuplicate) {
                uniqueList.push(item);
            }
        }

        setSearchList(uniqueList);
    }

    const selectedRow = (e) => {
        if(searchType === 'A') {
            if (ContractDetailStore.contractProductList.length > 0 && ContractDetailStore.contractProductList.findIndex(v => v.assetNo === e.data.assetNo) > -1) {
                showToast(`이미 추가된 제품 입니다. [${e.data.productName}]`);
                return;
            }

            callbackProduct([e.data]);

        }else if(searchType === 'O'){
            if(ContractDetailStore.productOptionList.length > 0 && ContractDetailStore.productOptionList.findIndex(v => v.productSeq === e.data.productSeq) > -1) {
                showToast(`이미 추가된 옵션 입니다. [${e.data.productName}]`);
                return;
            }

            callbackOption([e.data]);
        }

        AppStore.toggleStockAssetSearchModal();
    }

    const callBackGridData = ({ selectedList }) => {
        // 동일한 제품코드가 있는지 체크
        if(ContractDetailStore.contractProductList.length > 0){
            for(let data of selectedList){
                for(let row of ContractDetailStore.contractProductList){
                    if(searchType === 'A' && data.assetNo === row.assetNo){
                        showToast(`이미 추가된 제품 입니다. [${data.managementNo}]`);
                        return false;
                    }
                    if(searchType === 'O' && data.productSeq === row.productSeq){
                        showToast(`이미 추가된 옵션 입니다. [${data.productName}]`);
                        return false;
                    }
                }
            }
        }

        if(searchType === 'A'){
            callbackProduct(selectedList);
        }else{
            callbackOption(selectedList);
        }
        AppStore.toggleStockAssetSearchModal();
    }

    /* 초기화 */
    const refresh = () => {
        setSearchList([]);
    }

    return (
        <Modal
            title={`재고${searchType === 'A' ? '장비' : '옵션'}조회`}
            show={AppStore.isStockAssetSearch}
            onHide={() => AppStore.toggleStockAssetSearchModal()}
            toggleValue={AppStore.isStockAssetSearch}
            className={'modal-w12'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    {searchType === 'A' &&
                        <CCol lg={3}>
                            <CInputGroup
                                label={'관리번호'}
                                labelId={'managementNo'}
                                value={managementNo}
                                onChange={(v)=> setManagementNo(v.target.value)}
                                enterKeyHint={onSearch}
                            />
                        </CCol>
                    }
                    <CCol lg={3}>
                        <CInputGroup
                            label={'자산번호'}
                            labelId={'assetNo'}
                            value={assetNo}
                            onChange={(v)=> setAssetNo(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    {searchType === 'O' &&
                        <CCol lg={3}>
                            <CInputGroup
                                label={'옵션명'}
                                labelId={'productName'}
                                value={productName}
                                onChange={(v)=> setProductName(v.target.value)}
                                enterKeyHint={onSearch}
                            />
                        </CCol>
                    }
                    <CCol lg={3}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            value={productName}
                            onChange={(v)=> setProductName(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={brandCode} onChange={onChangeBrandName} disabled={props.productCode}>
                                <option value={''}>- 제조사 -</option>
                                { <CommcodeSMOptions commKind={'18'} />}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3} style={{paddingTop: 3}}>
                        <Form.Check
                            inline
                            label="임대가능 장비만 보기"
                            name="availableStock"
                            type={'radio'}
                            id='stock'
                            value={'stock'}
                            defaultChecked={true}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={45}
                    rowData={searchList}
                    columnDefs={[
                        {field: "productGroupName", headerName: "분류", minWidth: 110},
                        {field: "assetType", headerName: "자산구분", minWidth: 110, valueFormatter: v => findCommKrnm(v.value, '7')},
                        {field: "assetNo", headerName: "자산번호", minWidth: 110},
                        {field: "managementNo", headerName: "관리번호", minWidth: 110, hide: true},
                        {field: "productCode", headerName: "제품코드", minWidth: 110, hide: true},
                        {field: "productName", headerName: "제품명", minWidth: 120},
                        {field: "model", headerName: "모델명", minWidth: 160},
                        {field: "serialNo", headerName: "시리얼 번호", minWidth: 160},
                        {field: "exteriorStatus", headerName: "외관상태", minWidth: 120},
                        {field: "tireStatus", headerName: "타이어상태", minWidth: 120},
                        {field: "isReserve", headerName: "예약여부", minWidth: 90, hide: true},
                        {field: "spec", headerName: "스펙", minWidth: 500},
                    ]}
                    callBackGridData={callBackGridData}
                    selectBtnInfo={{isUsed: true}}
                    btnTitles={{save: '선택'}}
                    isCheckBox={true}
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(StockAssetSearch);

import { observer } from "mobx-react-lite";
import { CContainer, CCol, CInputDoubleGroup, CInputGroup, CSelectGroup, CSearchBtn } from "../../components/CustomContainer";
import { Row, InputGroup, Form, Col } from "react-bootstrap";
import { NonpaymentStore } from "../../store/billing/NonpaymentStore";
import { getCommcode } from "../../utils/commcode";
import CustomerBillingAddressSearch from "../../components/searchModal/CustomerBillingAddressSearch";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import { AppStore } from "../../store/AppStore";

const NonPaymentManagerSearch = ({getNonpaymentList}) => {

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        NonpaymentStore.search.customerName = customerInfo.customerName;
        NonpaymentStore.search.customerNo = customerInfo.customerNo;
    }

    /* 청구지 조회 팝업 오픈 */
    const openSearchBillingAddressPopup = () => {
        AppStore.toggleCustomerBillingAddressSearchModal()
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        NonpaymentStore.search.billingAddressSeq = info.billingAddressSeq;
        NonpaymentStore.search.billingAddressName = info.billingAddressName;
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if (!userInfo) {
            return false;
        }

        NonpaymentStore.search.saleUserSeq = userInfo.userSeq;
        NonpaymentStore.search.saleUserName = userInfo.name;
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            disabled={true} disabled2={true}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={NonpaymentStore.search.customerNo}
                            value2={NonpaymentStore.search.customerName}
                            onEmptyAction={()=> {
                                NonpaymentStore.search.customerNo = '';
                                NonpaymentStore.search.customerName = '';
                            }}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'청구지'}
                            value={NonpaymentStore.search.billingAddressName}
                            onCallbackBtn={openSearchBillingAddressPopup}
                            disabled={true}
                            onEmptyBtn={()=> {
                                NonpaymentStore.search.billingAddressSeq = '';
                                NonpaymentStore.search.billingAddressName = '';
                            }}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            value={NonpaymentStore.search.contractNo}
                            onChange={v => NonpaymentStore.search.contractNo = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'영업담당자'}
                            value={NonpaymentStore.search.saleUserName}
                            onCallbackBtn={openSearchUserPopup}
                            disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'minimumAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>납기미납액(최소)</InputGroup.Text>
                            <Form.Control
                                type="Number"
                                style={{flex: 1, textAlign: 'right'}}
                                max={2}
                                aria-describedby={'minimumAmount'}
                                value={NonpaymentStore.search.minimumAmount}
                                onChange={v => NonpaymentStore.search.minimumAmount = v.target.value}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'maximumAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>납기미납액(최대)</InputGroup.Text>
                            <Form.Control
                                type="Number"
                                style={{flex: 1, textAlign: 'right'}}
                                max={2}
                                aria-describedby={'maximumAmount'}
                                value={NonpaymentStore.search.maximumAmount}
                                onChange={v => NonpaymentStore.search.maximumAmount = v.target.value}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'minimumAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>미납일</InputGroup.Text>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                max={2}
                                aria-describedby={'dueNonpaymentDate'}
                                value={NonpaymentStore.search.dueNonpaymentDate}
                                onChange={v => NonpaymentStore.search.dueNonpaymentDate = v.target.value}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>일</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'채권형태'} labelId={'bondType'}
                            placeholder={'채권형태'}
                            options={[{name: '선택', value: ''}].concat(getCommcode('37'))}
                            value={NonpaymentStore.search.bondType || ''}
                            onChange={v => NonpaymentStore.search.bondType = v.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getNonpaymentList} />
                        {/* <CIconBtn title={'생성'}
                                  icon={'fi-rr-download'}
                                  onClick={()=> createNonpaymentData}
                        /> */}
                    </Col>
                </Row>
            </CContainer>
            {/* 팝업 */}
            <CustomerBillingAddressSearch customerNo={NonpaymentStore.search.customerNo} callbackFn={getBillingAddressInfo} />
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo}/>
        </>
    );

}

export default observer(NonPaymentManagerSearch);

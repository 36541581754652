import {useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import {CContainer} from "../../components/CustomContainer";
import { findCommKrnm } from "../../utils/commcode";
import { StorageStore } from "../../store/storage/StorageStore";
import { getUserName } from "../../utils/userUtils";
import {dateFormat} from "../../utils/dateUtils";
import AgGridContainer from "../../components/AgGridContainer";
import {callConfirm} from "../../utils";
import {showToast} from "../../common/utils";
import {StorageSearchStore} from "../../store/storage/StorageSearchStore";
import StorageListSearch from "./StorageListSearch";

const StorageList =() => {
    const gridRef = useRef();

    const commonColumns = [
        {field: "storageType", headerName: "입고구분", width: 120, valueFormatter: v => findCommKrnm(v.value, '13')},
        {field: "storageNo", headerName: "입고번호", width: 120},
        {field: "storageSeq", headerName: "순번", width: 80},
        {field: "storageDate", headerName: "입고일자", width: 140, valueFormatter: v => dateFormat(v.value)},
        {field: "creator", headerName: "입고작업자", width: 120, valueFormatter: v => getUserName(v.value)},
    ];

    const storageGoods = [
        ...commonColumns,
        {field: "productName", headerName: "제품명", width: 170},
        {field: "model", headerName: "모델명", width: 200},
        {field: "serialNo", headerName: "시리얼번호", width: 170},
        {field: "qty", headerName: "입고수량", width: 110},
        {field: "amount", headerName: "입고금액", width: 110, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "releaseQty", headerName: "출고수량", width: 110},
        {field: "releaseAmount", headerName: "출고금액", width: 110, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "marginRate", headerName: "마진율", width: 100, valueFormatter: v => v.value && v.value + '%'},
        {field: "releaseAssetNo", headerName: "취득자산", width: 110},
        {field: "orderSeq", headerName: "발주번호", width: 120},
        {field: "orderer", headerName: "발주자", width: 100, valueFormatter: v => getUserName(v.value)},
        {field: "shelfId", headerName: "선반번호", width: 150},
        {field: "remark", headerName: "입고비고", width: 200},
    ];

    const storageReturn = [
        ...commonColumns,
        {field: "storageDelayDate", headerName: "입고지연일수", valueGetter: v => v.data.storageDelayDays ? v.data.storageDelayDays + "일" : '', width: 130},
        {field: "contractNo", headerName: "계약번호", width: 160},
        {field: "customerName", headerName: "고객명", width: 160},
        {field: "assetNo", headerName: "자산번호", width: 120},
        {field: "managementNo", headerName: "관리번호", width: 120},
        {field: "qty", headerName: "입고수량", width: 110},
        {field: "serialNo", headerName: "시리얼번호", width: 160},
        {field: "productName", headerName: "제품명", width: 200},
        {field: "model", headerName: "모델명", width: 200},
        {field: "releaseSeq", headerName: "출고번호", width: 120},
        {field: "saleUserName", headerName: "영업담당", width: 120},
        {field: "shelfId", headerName: "선반번호", width: 150},
        {field: "remark", headerName: "입고비고", width: 300},
    ];

    /* 조회 */
    const searchStorage = async () => {
        const result = await axios.get('/storage/list', { params: StorageSearchStore.searchCondition });
        StorageStore.setStorageList(result);
    }

    const assetAcquisition =async()=> {
        // 선택한 row
        const goodsList = StorageStore.storageList.filter(v => v.isSelected);

        if(goodsList.length === 0){
            showToast('상품을 선택해주세요.');
            return;
        }

        if(goodsList.some(v => v.releaseAssetNo)){
            showToast('이미 취득된 상품이 선택되었습니다.');
            return;
        }

        if(!await callConfirm(goodsList.length + '건을 자산으로 취득 하시겠습니까?')) return;

        const result = await axios.post('asset', {goodsList: goodsList});
        showToast('자산을 취득하였습니다.');
        searchStorage();
    }

    const deleteStorage =async(e) => {
        const { storageNo, storageSeq } = e.data;

        if(!await callConfirm(`입고번호[${storageNo}]를 삭제하시겠습니까?`)) return;

        const result = await axios.delete(`/storage/${storageNo}`, {params: {storageSeq: storageSeq}});
        showToast('삭제되었습니다.');
        searchStorage();
    }

    const customButtonInfo = [
        {
            isUsed: StorageSearchStore.searchCondition.storageType === 'sto_goods',
            callbackFn: () => assetAcquisition(),
            title: '자산취득',
            icon: 'fi-rr-box-check',
            width: 110,
        }
    ];

    const pinnedTopStorageData = [{
        qty: StorageStore.storageList.reduce((total, v) => total + v.qty, 0),
        amount: StorageStore.storageList.reduce((total, v) => total + v.amount, 0),
        releaseQty: StorageStore.storageList.reduce((total, v) => total + v.releaseQty, 0),
        releaseAmount: StorageStore.storageList.reduce((total, v) => total + v.releaseAmount, 0),
        marginRate: ''
    }];

    return (
        <>
            <StorageListSearch searchStorage={searchStorage}/>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={58}
                    rowData={StorageStore.storageList}
                    columnDefs={StorageSearchStore.searchCondition.storageType === 'sto_goods' || StorageSearchStore.searchCondition.storageType === 'sto_consum' ? storageGoods : storageReturn}
                    enableCellTextSelection={true}
                    useCsvDownload={true}
                    isCheckBox={true}
                    useRemoveRow={true}
                    removeCallback={deleteStorage}
                    customBtnInfo={customButtonInfo}
                    pinnedTopRowData={pinnedTopStorageData}
/*                    getRowStyle={(params) => {
                        if (StorageSearchStore.searchCondition.storageType === 'sto_goods' && params.data.qty === params.data.releaseQty) {
                            return { pointerEvents: 'none', color: '#848484' };
                        }
                        return null;
                    }}*/
                />
            </CContainer>
        </>
    )
}

export default observer(StorageList);

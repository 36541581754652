import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const SalesListStore = observable({
    mode: 'total',
    detailTab: 'year',
    monthSalesList: [],
    companySalesList: [],
    deptSalesList: [],
    userSalesList: [],
    customerSalesList: [],
    orgCustomerSalesList: [],

    chartMonthList: [],
    rentalAmountList: [],
    saleAmountList: [],
    assetSaleAmountList: [],
    acquAmountList: [],

    searchCondition: { customerNo: '', customerName: '', contractType: '', saleUserSeq: null, saleUserName:''},
    startDate: { startDate:'', endDate: '' },
    endDate: { startDate:'', endDate:'' },

    setMode(v){
        this.mode = v;
    },
    setDetailTab(v){
        this.detailTab = v;
    },
    setMonthSalesList(v){
        this.monthSalesList = v;
    },
    setCompanySalesList(v){
        this.companySalesList = v;
    },
    setDeptSalesList(v){
        this.deptSalesList = v;
    },
    setUserSalesList(v){
        this.userSalesList = v;
    },
    setCustomerSalesList(v){
        this.customerSalesList = v;
    },
    setOrgCustomerSalesList(v){
        this.orgCustomerSalesList = v;
    },
    setChartMonthList(v){
        this.chartMonthList = v;
    },
    setRentalAmountList(v){
        this.rentalAmountList = v;
    },
    setSaleAmountList(v){
        this.saleAmountList = v;
    },
    setAssetSaleAmountList(v){
        this.assetSaleAmountList = v;
    },
    setAcquAmountList(v){
        this.acquAmountList = v;
    },
    setSearchCondition(target, v) {
        this.searchCondition[target] = v;
    },
    setStartDate(v) {
        this.startDate = v;
    },
    setEndDate(v) {
        this.endDate = v;
    },

    chartInit(){
        this.chartMonthList = [];
        this.rentalAmountList = [];
        this.saleAmountList = [];
        this.assetSaleAmountList = [];
        this.acquAmountList = [];
    }
});

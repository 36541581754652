import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { Container, Row, Col } from 'react-bootstrap';

import { CContainer, Subject } from '../../components/CustomContainer';
import { ProductGroupStore } from '../../store/product/ProductGroupStore';
import AgGridContainer from '../../components/AgGridContainer';
import LUtils from '../../utils/lodashUtils';
import { callAlert, callConfirm } from '../../utils';
import { findCommKrnm, getCommcode } from '../../utils/commcode';
import { showToast } from '../../common/utils';

const ProductGroupList = () => {
    const lgGridRef = useRef();
    const mdGridRef = useRef();
    const smGridRef = useRef();
    const xsGridRef = useRef();
    const store = ProductGroupStore;

    useEffect(() => {
        getProductGroupList();
    }, []);

    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all');

        // 대분류: parentSeq === null
        const originLG = result.filter(v => v.parentSeq === null);
        ProductGroupStore.setSelectLGSeq('');
        ProductGroupStore.setLGList(originLG);
        ProductGroupStore.setOriginLGList(originLG);

        // 중분류: 대분류의 seq === parentSeq
        const totalMD = result.filter(v => originLG.map(lg => lg.productGroupSeq).includes(v.parentSeq));
        ProductGroupStore.setSelectMDSeq('');
        ProductGroupStore.setMDList([]);
        ProductGroupStore.setTotalList('totalMDList', totalMD);

        // 소분류: 중분류의 seq === parentSeq
        const totalSM = result.filter(v => totalMD.map(md => md.productGroupSeq).includes(v.parentSeq));
        ProductGroupStore.setSelectSMSeq('');
        ProductGroupStore.setSMList([]);
        ProductGroupStore.setTotalList('totalSMList', totalSM);

        // 세분류: 중분류의 seq === parentSeq
        const totalXS = result.filter(v => totalSM.map(sm => sm.productGroupSeq).includes(v.parentSeq));
        ProductGroupStore.setXSList([]);
        ProductGroupStore.setTotalList('totalXSList', totalXS);

        if(store.selectLgParams) clickSearchIcon(store.selectLgParams, 'lg');
        if(store.selectMdParams) clickSearchIcon(store.selectMdParams, 'md');
        if(store.selectSmParams) clickSearchIcon(store.selectSmParams, 'sm');
    }

    /* 다음 하위 분류 표시 */
    const clickSearchIcon = (params, type) => {
        const { productGroupSeq } = params.data;
        const originColumns = ['productGroupSeq', 'parentSeq', 'isUsed', 'productGroupCode', 'productGroupName'];
        if (type === "lg") {
            ProductGroupStore.setSelectLGSeq(productGroupSeq);
            const originMD = ProductGroupStore.totalMDList.filter(v => v.parentSeq === productGroupSeq)
            ProductGroupStore.setMDList(originMD);
            ProductGroupStore.setOriginMDList(originMD.map(v => LUtils.pick(v, originColumns)));
            ProductGroupStore.setSMList([]);
            ProductGroupStore.setXSList([]);
            store.selectLgParams = params;
        } else if (type === "md") {
            ProductGroupStore.setSelectMDSeq(productGroupSeq);
            const originSM = ProductGroupStore.totalSMList.filter(v => v.parentSeq === productGroupSeq);
            ProductGroupStore.setSMList(originSM);
            ProductGroupStore.setOriginSMList(originSM.map(v => LUtils.pick(v, originColumns)));
            ProductGroupStore.setXSList([]);
            store.selectMdParams = params;
        } else if (type === 'sm') {
            ProductGroupStore.setSelectSMSeq(productGroupSeq);
            const originXS = ProductGroupStore.totalXSList.filter(v => v.parentSeq === productGroupSeq);
            ProductGroupStore.setXSList(originXS);
            ProductGroupStore.setOriginXSList(originXS.map(v => LUtils.pick(v, originColumns)));
            store.selectSmParams = params;
        }
    }

    /* row 추가시 검사 */
    const addRowCheckFunctionForMD = () => {
        if (ProductGroupStore.selectLGSeq === '') {
            callAlert('대분류 선택되어야 합니다.');
            return false;
        } else {
            return true;
        }
    }
    const addRowCheckFunctionForSM = () => {
        if (ProductGroupStore.selectMDSeq === '') {
            callAlert('중분류 선택되어야 합니다.');
            return false;
        } else {
            return true;
        }
    }
    const addRowCheckFunctionForXS = () => {
        if (ProductGroupStore.selectSMSeq === '') {
            callAlert('소분류 선택되어야 합니다.');
            return false;
        } else {
            return true;
        }
    }

    /* 중분류 저장버튼 */
    const callBackGridDataMD = async (param) => {
        const { updatedList, createdList } = param;
        if (!LUtils.isEmpty(createdList)) {
            createdList.forEach(v => {
                v.parentSeq = ProductGroupStore.selectLGSeq
            });
        }
        await saveProcess(updatedList, createdList, 'md');
    }
    /* 소분류 저장버튼 */
    const callBackGridDataSM = async (param) => {
        const { updatedList, createdList } = param;
        if (!LUtils.isEmpty(createdList)) {
            createdList.forEach(v => {
                v.parentSeq = ProductGroupStore.selectMDSeq
            });
        }
        await saveProcess(updatedList, createdList, 'sm');
    }
    /* 세분류 저장버튼 */
    const callBackGridDataXS = async (param) => {
        const { updatedList, createdList } = param;
        if (!LUtils.isEmpty(createdList)) {
            createdList.forEach(v => {
                v.parentSeq = ProductGroupStore.selectSMSeq
            });
        }
        await saveProcess(updatedList, createdList, 'xs');
    }
    /* 저장함수 */
    const saveProcess = async (updatedList, createdList, level) => {
        const isUpdate = !LUtils.isEmpty(updatedList)
        const isCreate = !LUtils.isEmpty(createdList)

        if (!LUtils.some([isUpdate, isCreate]) ) {
            await callAlert('저장 할 데이터가 없습니다.');
            return false
        }

        level !== 'lg' && isUpdate && await axios.put('/product/group', { productGroupList: updatedList, productGroupLevel: level });
        isCreate && await axios.post('/product/group', { productGroupList: createdList, productGroupLevel: level });

        getProductGroupList();        
        callAlert('저장되었습니다.');
    }

    const lgColumnDefs = [
        {
            field: "productGroupName",
            headerName: "분류명",
            minWidth: 120,
            flex: 1,
            editable: v => v.data.productGroupSeq ? false : true,
            headerClass: 'grid-column-editable grid-column-required',
        },
        {
            field: 'isUsed',
            headerName: '사용',
            width: 100,
            editable: v => v.data.productGroupSeq ? false : true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: [0, 1] },
            valueFormatter: params => params.value === 1 ? '사용' : '미사용',
            headerClass: 'grid-column-editable grid-column-required',
        },
    ];

    const columnDefs = [
        {
            field: "productGroupName",
            headerName: "분류명",
            minWidth: 120,
            flex: 1,
            editable: true,
            headerClass: 'grid-column-editable grid-column-required',
        },
        {
            field: 'isUsed',
            headerName: '사용',
            width: 100,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: [0, 1] },
            valueFormatter: params => params.value === 1 ? '사용' : '미사용',
            headerClass: 'grid-column-editable grid-column-required',
        },
    ];

    const addRowInfo = { parentSeq: '', isUsed: 0, productGroupCode: '', productGroupName: '' };

    const deleteRow = async (e) => {
        if(!await callConfirm('삭제하시겠습니까?')) return;
        await axios.delete(`/product/group/${e.data.productGroupSeq}`);

        getProductGroupList();
        showToast('삭제했습니다.');
    }

    return (
        <Container fluid style={{marginTop: -30}}>
            <Row>
                <Col lg={3} className='p-0'>
                    <CContainer>
                        <Subject>대분류</Subject>
                        <AgGridContainer
                            gridRef={lgGridRef}
                            height={70}
                            rowData={ProductGroupStore.lgList}
                            originList={ProductGroupStore.originLgList}
                            columnDefs={lgColumnDefs}
                            useRowSearch={true}
                            rowSearchCallback={v => clickSearchIcon(v, 'lg')}
                            isCheckBox={true}
                            //useUpdated={true}
                            useCreated={true}
                            noIconBtn={true}
                            callBackGridData={({updatedList, createdList}) => saveProcess(updatedList, createdList, 'lg')}
                            addRowInfo={addRowInfo}
                            useRemoveRow={true}
                            removeCallback={deleteRow}
                        />
                    </CContainer>
                </Col>
                <Col lg={3} className='p-0'>
                    <CContainer>
                        <Subject>중분류</Subject>
                        <AgGridContainer
                            gridRef={mdGridRef}
                            height={70}
                            rowData={ProductGroupStore.mdList}
                            originList={ProductGroupStore.originMdList}
                            columnDefs={columnDefs}
                            useRowSearch={true}
                            rowSearchCallback={v => clickSearchIcon(v, 'md')}
                            isCheckBox={true}
                            useUpdated={true}
                            useCreated={true}
                            noIconBtn={true}
                            callBackGridData={callBackGridDataMD}
                            addRowInfo={addRowInfo}
                            addRowCheckFunction={addRowCheckFunctionForMD}
                            useRemoveRow={true}
                            removeCallback={deleteRow}
                        />
                    </CContainer>
                </Col>
                <Col lg={3} className='p-0'>
                    <CContainer>
                        <Subject>소분류</Subject>
                        <AgGridContainer
                            gridRef={smGridRef}
                            height={70}
                            rowData={ProductGroupStore.smList}
                            originList={ProductGroupStore.originSmList}
                            columnDefs={columnDefs}
                            useRowSearch={true}
                            rowSearchCallback={v => clickSearchIcon(v, 'sm')}
                            isCheckBox={true}
                            useUpdated={true}
                            useCreated={true}
                            noIconBtn={true}
                            callBackGridData={callBackGridDataSM}
                            addRowInfo={addRowInfo}
                            addRowCheckFunction={addRowCheckFunctionForSM}
                            useRemoveRow={true}
                            removeCallback={deleteRow}
                        />
                    </CContainer>
                </Col>
                <Col lg={3} className='p-0'>
                    <CContainer>
                        <Subject>세분류</Subject>
                        <AgGridContainer
                            gridRef={xsGridRef}
                            height={70}
                            rowData={store.xsList}
                            originList={store.originXsList}
                            columnDefs={columnDefs}
                            isCheckBox={true}
                            useUpdated={true}
                            useCreated={true}
                            noIconBtn={true}
                            callBackGridData={callBackGridDataXS}
                            addRowInfo={addRowInfo}
                            addRowCheckFunction={addRowCheckFunctionForXS}
                            useRemoveRow={true}
                            removeCallback={deleteRow}
                        />
                    </CContainer>
                </Col>
            </Row>
        </Container>
    );
};

export default observer(ProductGroupList);

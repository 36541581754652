import { observer } from 'mobx-react-lite';
import { CContainer, CCol, CInputGroup, CSelectGroup, CSearchBtn, CAddBtn, RadioButtonGroupTrueFalse } from '../../components/CustomContainer';
import { Row, Col } from 'react-bootstrap';
import { UserStore } from '../../store/human-resources/UserStore';
import { UserTabsStore } from '../../store/human-resources/UserTabsStore';

const UserListSearch = ( {getUserList, setViewMode, initUser, onClickIsActiveBtn} ) => {

    const initUserFn = () => {
        initUser();
        setViewMode(false);
        UserTabsStore.setUserTab('userInfo');
    }

    return (
        <>
            {/* 검색 조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'이름'}
                            labelId={'name'}
                            placeholder={'이름'}
                            value={UserStore.search.name}
                            onChange={v => UserStore.search.name = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'ID'}
                            placeholder={'ID'}
                            value={UserStore.search.id}
                            onChange={v => UserStore.search.id = v.target.value}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <RadioButtonGroupTrueFalse
                            name={'searchIsActive'}
                            label={'사용여부(퇴사)'}
                            btnText={['재직중', '퇴사']}
                            value={UserStore.search.isActive === 0 ? false : true}
                            onClick={e => onClickIsActiveBtn(e, 'searchIsActive')} />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getUserList} />
                        <CAddBtn title={'사원등록'} style={{ width: 90 }} onClick={initUserFn} />
                    </Col>
                </Row>
            </CContainer>
        </>
    )
}

export default observer(UserListSearch);

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { CContainer, CCol, CInputGroup, Subject, CIconBtn, CSelectGroup } from "../../components/CustomContainer";
import { Row, InputGroup, Form, Button, Col } from "react-bootstrap";
import { AsStore } from "../../store/asset/AsStore";
import { decrypt, encrypt } from "../../common/utils";
import { useParams } from "react-router-dom";
import axios from "axios";
import { callAlert, getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";
import { callConfirm } from "../../utils";
import { setAxiosAuthorization } from "../../utils/axiosSetup";
import { AppStore } from "../../store/AppStore";
import { getCommcode } from "../../utils/commcode";
import { autoHyphen } from "../../common/utils";
import Address from "../../components/Address";
import AsManagerUserSearch from "../../components/searchModal/AsManagerUserSearch";

const AsDetailLinkPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [disableMode, setDisableMode] = useState(false);
    const [managementNoList, setManagementNoList] = useState([{managementNo: '', content: '', isCreated: false}]);
    const [acceptContent, setAcceptContent] = useState('');
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [index, setIndex] = useState('');
    const [encUserSeq, setEncUserSeq] = useState('');
    const [userColumn, setUserColumn] = useState('');
    const [btnStatus, setBtnStatus] = useState('add');
    const [managerList, setManagerList] = useState([]);
    const [searchUser, setSearchUser] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const [asUserName, setAsUserName] = useState('');
    const location = useLocation();

    useEffect(()=> {
        // const jwt = localStorage.getItem("JWT_LINK_ITS_RENTAL");
        // AppStore.jwt = jwt;
        // setAxiosAuthorization();
        // AsStore.data.asSeq = params.asSeq;

        // if (!jwt) {
        //     alert('로그인 후 접근 가능합니다.');
        //     navigate(`/as/accept/detail/${params.asSeq}/login`, { replace: true });
        // }else {
            const fetchData = async () => {
                const searchParams = new URLSearchParams(location.search);
                const encUserSeqParam = searchParams.get("id");
                setEncUserSeq(encUserSeqParam);
                
                encUserSeqParam != null && await setAsUserName(getUserName(decrypt(encUserSeqParam.replace(' ', '+'))));
                await getAsAcceptDetail(params.asSeq);
            }

            fetchData();
        // }
    }, [])

    /* AS 접수 상세 내역 */
    const getAsAcceptDetail = async (asSeq) => {
        const result = await axios.get(`/as/accept/detail/${asSeq}`);
        
        AsStore.data = result;

        // 접수 내용
        const index = AsStore.data.acceptDetail.indexOf('접수내용:');
        const content = AsStore.data.acceptDetail.substring(index + 6);
        setAcceptContent(content);
        
        // AS 담당자 배정 값 넣어주기
        // AsStore.data.asManagerName = AsStore.data.asManagerList.map(v => {return v.name}).join(' / ');
        AsStore.data.asManagerName = AsStore.data.asStatus === '5' ? AsStore.data.asManagerName : "";

        // 관리번호 넣어주기
        setManagementNoList(AsStore.data.managementNoList.map(v => {return {...v, isCreated: false}}));

        setCompanyCode(AsStore.data.companyCode);

        if (AsStore.data.asStatus === '5' || AsStore.data.asStatus === '8' || AsStore.data.asStatus === '9') {
            setDisableMode(true);
            setManagementNoList(result.maintenanceList);
            // alert('이미 완료된 접수입니다.');
        }
    }

    /* AS 처리 완료 */
    const completeAs = async () => {
        let isSendSms = 0;

        if ((encUserSeq === '' || encUserSeq === null) && managerList.length === 0) {
            alert('A/S 담당자를 지정해주세요.');
            return;
        }

        if(Number(AsStore.data.asStatus) > 1 && (encUserSeq !== null && encUserSeq !== '')) {
            if ((AsStore.data.asManagerSeq !== Number(decrypt(encUserSeq.replace(' ', '+'))))) {
                alert('A/S 담당자가 일치하지 않습니다. A/S 담당자를 확인해주세요.');
                return;
            }
        } else if (Number(AsStore.data.asStatus) > 1 && (encUserSeq === '' || encUserSeq === null)) {
            if (AsStore.data.asManagerSeq !== Number(managerList[0].userSeq)) {
                alert('A/S 담당자가 일치하지 않습니다. A/S 담당자를 확인해주세요.');
                return;
            }
        }

        if (managementNoList.length === 0 || (AsStore.data.processDetail === null || AsStore.data.processDetail === '')) {
            alert('처리 내역 및 관리번호 목록을 모두 작성해주세요.');
            return false;
        }

        if (!managementNoList.every(param => param.content)) {
            alert('정비내역을 모두 입력해주세요.');
            return false;
        }

        if(!confirm('AS를 완료 처리하시겠습니까?')) {
            return false;
        }

        if(AsStore.data.siteManagerPhone !== null && AsStore.data.siteManagerPhone !== '') {
            isSendSms = confirm('담당자에게 알림톡을 전송하시겠습니까?') ? 1 : 0;
        }

        const params = {...AsStore.data, managementNoList: managementNoList, isSendSms: isSendSms, encUserSeq: (encUserSeq !== null && encUserSeq !== '') ? encUserSeq : encrypt(String(managerList[0].userSeq))}
        const paramsStr = JSON.stringify(params);
        const statusResult = await axios.put('/as/accept/detail/complete', paramsStr);
        AsStore.data.asStatus = statusResult;
        
        alert('AS가 완료되었습니다.');
        getAsAcceptDetail(AsStore.data.asSeq);
        setDisableMode(true);
    }

    /* 관리번호 목록 추가 */
    const addRow = () => {
        let countArr = [...managementNoList];
        countArr.push({assetNo: '', managementNo: '', content: '', isCreated: true});
        setManagementNoList(countArr);
    }

    /* 관리번호 목록 삭제 */
    const removeRow = async (i, seq, assetNo) => {

        if(!confirm('삭제하시겠습니까?')) {
            return false;
        }
        
        if (assetNo.length === 1 && i === 0) {
            return false;
        }
        
        let countArr = [...managementNoList];
        countArr.splice(i, 1);

        if (!managementNoList[i].isCreated === true) {   
            // 관리번호 삭제
            await axios.delete('/as/accept/detail', {params: {asSeq: AsStore.data.asSeq, seq: seq, assetNo: assetNo}});
        }

        setManagementNoList(countArr);
    }

    /* input 체인지 이벤트 */
    const changeFn = (index, attr, v) => {
        let arr = [...managementNoList];
        arr[index][attr] = v.target.value;

        setManagementNoList(arr);
    }

    /* AS 접수 상태 변경 */
    const changeStatus = async (status) => {

        if((encUserSeq === '' || encUserSeq === null) && managerList.length === 0) {
            alert('A/S 담당자를 지정해주세요.');
            return;
        }

        if(Number(AsStore.data.asStatus) > 1 && encUserSeq !== null && encUserSeq !== '') {
            if ((AsStore.data.asManagerSeq !== Number(decrypt(encUserSeq.replace(' ', '+'))))) {
                alert('A/S 담당자가 일치하지 않습니다. A/S 담당자를 확인해주세요.');
                return;
            }
        } else if (Number(AsStore.data.asStatus) > 1 && (encUserSeq === '' || encUserSeq === null)) {
            if (AsStore.data.asManagerSeq !== Number(managerList[0].userSeq)) {
                alert('A/S 담당자가 일치하지 않습니다. A/S 담당자를 확인해주세요.');
                return;
            }
        }
            
        let isSendSms = 0;
        let msg = '';
        let resultMsg = '';
        switch (status) {
            case '2' : msg = 'A/S를 접수하시겠습니까?'; resultMsg = 'A/S를 접수하였습니다.' ; break;
            case '3' : msg = '출발 처리 하시겠습니까?'; resultMsg = '출발 처리하였습니다.' ; break;
            case '4' : msg = '도착 처리 하시겠습니까?'; resultMsg = '도착 처리하였습니다.' ; break;
            case '8' : msg = '미결 처리 하시겠습니까?'; resultMsg = '미결 처리하였습니다.' ; break;
            case '9' : msg = 'A/S를 취소하시겠습니까?'; resultMsg = 'A/S를 취소하였습니다.' ; break;

        }

        if(!confirm(msg)) {
            return false;
        }

        if(status === '2' && AsStore.data.siteManagerPhone !== null && AsStore.data.siteManagerPhone !== '') {
            isSendSms = confirm('담당자에게 알림톡을 전송하시겠습니까?') ? 1 : 0;
        }

        // 관리번호 추가
        const createdList = managementNoList.filter(v => v.isCreated);
        const params = {...AsStore.data, asStatus: status, managementNoList: createdList, isSendSms: isSendSms, encUserSeq: (encUserSeq !== null && encUserSeq !== '') ? encUserSeq : encrypt(String(managerList[0].userSeq))};
        const paramsStr = JSON.stringify(params);
        await axios.put('/as/accept/detail', paramsStr);
        alert(resultMsg);
        getAsAcceptDetail(AsStore.data.asSeq);
    }

    /* 관리번호 목록 조회 */
    const getAsManagementNoList = async() => {
        const result = await axios.get("/as/accept/detail/managementNo", {params: {asSeq: AsStore.data.asSeq}});
        AsStore.data.managementNoList = result;
        setManagementNoList(result);
    }

    /* 유저 조회 팝업 호출 */
    const openSearchAsManagerUserPopup = (column) => {
        setUserColumn(column);
        if (column === 'asManager') {
            setSearchUser('as');
        } else {
            setSearchUser('');
        }
        AppStore.toggleOpenAsManagerUserSearch();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {

        if (!userInfo || !column) {
            return false;
        }

        let uniqueArray = [];

        if (userInfo.constructor === Array) {
            if (userInfo.length === 1) {
                userInfo.map(v => uniqueArray.push({userSeq: v.userSeq, name: v.name, phoneNumber: v.phoneNumber}));
            } else {
                alert('한 명만 선택해주세요.');
                AppStore.toggleOpenAsManagerUserSearch();
                return;
            }
            // userInfo.map(v => managerList.push({userSeq: v.userSeq, name: v.name, phoneNumber: v.phoneNumber}));
        } else {
            // managerList.push({userSeq: userInfo.userSeq, name: userInfo.name, phoneNumber: userInfo.phoneNumber});
        }

        // 중복값 제거
        // const uniqueArray = [...new Map(managerList.map((m) => [m.userSeq, m])).values()];
        setManagerList(uniqueArray);

        // 이름 나열 출력
        const nameString = uniqueArray.map(user => user.name).join(' / ');
        AsStore.data.asManagerName = nameString;

        setUserColumn('');
    }


    /* AS 담당자 비우기 버튼 */
    const onEmptyBtn = () => {
        AsStore.data.asManagerName = '';
        setManagerList([]);
    }


    const getUserName = async (userSeq) => {
        const result = await axios.get(`/as/accept/detail/user/${userSeq}`);
        setAsUserName(result);
    }

    return(
        <>
            <Row style={{textAlign: 'center'}}>
                <Col>
                    <h2 style={{marginTop: 14, color: '#5f6975'}} className="text-second">AS 처리 내역</h2>
                    <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#efefef', marginTop: 10, marginBottom: 10, marginLeft: 0, marginRight: 0}}/>
                </Col>
            </Row>
            <CContainer search>
            {AsStore.data.asSeq && 
            <>
                <Row>
                    <CCol lg={4}>
                        <CInputGroup label={'AS번호'}
                                    labelId={'asSeq'}
                                    value={AsStore.data.asSeq || ''}
                                    disabled={true}             
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CSelectGroup label={'AS상태'} labelId={'asStatus'}
                                    placeholder={'AS상태'}
                                    options={[{name: 'AS상태', value: ''}].concat(getCommcode('55'))}
                                    value={AsStore.data.asStatus || ''}
                                    disabled={true}
                        />
                    </CCol>
                    {((encUserSeq !== null && encUserSeq !== '') || AsStore.data.asStatus === '5') && 
                        <CCol lg={4}>
                            <CInputGroup label={'AS처리자'}
                                        labelId={'asManagerSeq'}
                                        value={AsStore.data.asManagerName !== '' ? AsStore.data.asManagerName : (encUserSeq != null && encUserSeq != '') ? asUserName : ''}
                                        disabled={true}             
                            />
                        </CCol>
                    }
                    {(!disableMode && (encUserSeq === null || encUserSeq === '')) && 
                        <>
                            <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#efefef', marginTop: 5, marginBottom: 15, marginLeft: 0, marginRight: 0}}/>
                            <CCol lg={12}>
                                <InputGroup size="sm">
                                    <InputGroup.Text id={'asManager'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>AS 담당자</InputGroup.Text>
                                    <Form.Control style={{flex: 1}} aria-describedby={'asManagerName'} value={AsStore.data.asManagerName || ''} onChange={v => AsStore.data.asManagerName = v.target.value} disabled={true}/>
                                    {/* <Form.Control style={{flex: 2}} aria-describedby={'asManagerPhone'} value={decrypt(AsStore.data.asManagerPhone) || ''} onChange={v => AsStore.data.asManagerPhone = v.target.value} disabled={true}/> */}
                                    <Button className="btn btn-outline-secondary" style={{borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onEmptyBtn} disabled={false}>
                                        <i className="fi fi-rr-cross-small" />
                                    </Button>
                                    <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={() => openSearchAsManagerUserPopup('asManager')} disabled={false}>
                                        <i className="me-1 fi fi-br-search" />
                                    </Button>
                                </InputGroup>
                            </CCol>
                        </>
                    }
                </Row>                 
            </>
            }
            </CContainer>
            <CContainer>
                <Row>
                    <CCol lg={12}>
                        <div className="mb-3">
                            <h5 style={{marginTop: 14, color: '#5f6975'}} className="text-second">접수 내역</h5>
                            <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#efefef', marginTop: 10, marginBottom: 10, marginLeft: 0, marginRight: 0}}/>
                            <textarea className="form-control" rows="7"
                                        value={AsStore.data.acceptDetail || ''}
                                        onChange={v => AsStore.data.acceptDetail = v.target.value}
                                        disabled={true}
                            />
                        </div>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12}>
                        <div className="mb-3">
                            <h5 style={{marginTop: 14, color: '#5f6975'}} className="text-second">처리 내역</h5>
                            <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#efefef', marginTop: 10, marginBottom: 10, marginLeft: 0, marginRight: 0}}/>
                            <textarea className="form-control" rows="3"
                                        value={AsStore.data.processDetail || ''}
                                        onChange={v => AsStore.data.processDetail = v.target.value}
                                        placeholder="처리 내역을 자유롭게 작성해주세요."
                                        disabled={disableMode}
                            />
                        </div>
                    </CCol>
                </Row>
                {/* <Row className="mb-3">
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn style={{width:110}}
                                title={'관리번호 추가'}
                                onClick={addRow}
                                icon={'fi-rr-add'}
                                variant={'primary'}
                                disabled={disableMode}
                        />
                    </Col>
                </Row> */}
                {/* <Row id="managementNoInputRow">
                    {managementNoList && managementNoList.map((item, i) => (
                        <CCol lg={12} key={i}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'asManager'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>관리번호</InputGroup.Text>
                                <Form.Control style={{flex: 1}} aria-describedby={'assetNo'} value={managementNoList[i].assetNo || ''} onChange={v => changeFn(i, 'assetNo', v)} disabled={disableMode}/>
                                <InputGroup.Text id={'asManager'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>정비내역</InputGroup.Text>
                                <Form.Control style={{flex: 2}} aria-describedby={'content'} value={managementNoList[i].content || ''} onChange={v => changeFn(i, 'content', v)} disabled={disableMode}/>

                                <Button variant="danger" onClick={v => removeRow(i, managementNoList[i].assetNo)} disabled={disableMode}>
                                    <i className="fi fi-rr-minus-circle" />
                                </Button>
                            </InputGroup>
                        </CCol>
                        ))
                    }
                </Row> */}

                <Row className="mb-3">
                    <CCol lg={12}>
                        <div>
                            <h5 style={{marginTop: 14, color: '#5f6975'}} className="text-second">관리번호 목록</h5>
                            <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#efefef', marginTop: 10, marginLeft: 0, marginRight: 0}}/>
                        </div>
                    </CCol>
                    {managementNoList && managementNoList.map((item, i) => (
                        <CCol lg={12} key={i}>
                            <div style={{
                                         borderWidth: 1, 
                                         borderRadius: '0.375rem', 
                                         borderBottomLeftRadius: (!disableMode && managementNoList.length-1 === i) ? 0 : '0.375rem', 
                                         borderBottomRightRadius: (!disableMode && managementNoList.length-1 === i) ? 0 : '0.375rem', 
                                         paddingTop: 15, 
                                         paddingLeft: 15, 
                                         paddingRight: 15, 
                                         paddingBottom: 15
                                        }}>
                                <InputGroup size="md" className="mb-2 mt-2">
                                    <InputGroup.Text style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>자산번호</InputGroup.Text>
                                    <Form.Control aria-describedby={'managementNo'} value={managementNoList[i].assetNo || ''} onChange={v => changeFn(i, 'assetNo', v)} disabled={disableMode || !managementNoList[i].isCreated}/>
                                </InputGroup>
                                <InputGroup size="md" className="mb-2">
                                    <InputGroup.Text style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>정비내역</InputGroup.Text>
                                    <Form.Control as='textarea' rows='3' aria-describedby={'content'} value={managementNoList[i].content || ''} onChange={v => changeFn(i, 'content', v)} disabled={disableMode}/>
                                </InputGroup>
                                {!disableMode && 
                                    <Button className="mb-2"
                                            variant="outline-danger" 
                                            onClick={v => removeRow(i, managementNoList[i].seq, managementNoList[i].assetNo)} 
                                            disabled={disableMode}
                                            style={{width: '100%'}}
                                    >
                                        <i className="fi fi-rr-minus-circle" /> 삭제
                                    </Button>
                                }
                            </div>
                            {(!disableMode && managementNoList.length-1 === i) && 
                                <div>
                                    <Button variant="primary" 
                                            onClick={addRow} 
                                            style={{width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0}}
                                    >
                                        <i className="fi fi-rr-add" /> 관리번호 추가
                                    </Button>
                                </div>
                            }
                        </CCol>
                        ))
                    }
                    {(!disableMode && managementNoList.length === 0) && 
                        <div>
                            <Button variant="primary" 
                                    onClick={addRow} 
                                    style={{width: '100%'}}
                            >
                                <i className="fi fi-rr-add" /> 관리번호 추가
                            </Button>
                        </div>
                    }
                </Row>
                {!disableMode &&
                <>
                    <div style={{height: 1, borderBottomWidth: 1, borderBottomColor: '#efefef', marginLeft: -18, marginRight: -18, marginTop: 30, marginBottom: 25}}/>
                    <div style={{ display: 'flex', width: '100%' }}>
                    {(!['2','3','4','5','8','9'].includes(AsStore.data.asStatus)) && (
                        <CIconBtn
                            className={'mb-2'}
                            style={{ flex: 1 }} 
                            title={'AS접수'}
                            onClick={() => changeStatus('2')}
                            icon={'fi-rr-checkbox'}
                            variant={'success'}
                            disabled={['2','3','4','5'].includes(AsStore.data.asStatus)}
                        />
                    )}
                    {(!['3','4','5','8','9'].includes(AsStore.data.asStatus) && AsStore.data.asStatus !== '1') && (
                        <CIconBtn
                            className={'mb-2'}
                            style={{ flex: 1}} 
                            title={'출발'}
                            onClick={() => changeStatus('3')}
                            icon={'fi-rr-marker-time'}
                            variant={'success'}
                            disabled={['3','4','5'].includes(AsStore.data.asStatus) ||  AsStore.data.asStatus === '1'}
                        />
                    )}
                    {(!['4','5','8','9'].includes(AsStore.data.asStatus) && AsStore.data.asStatus !== '1') && (
                        <CIconBtn
                            className={AsStore.data.asStatus === '2' ? 'mb-2 ml-1' : 'mb-2'}
                            style={{ flex: 1 }} 
                            title={'도착'}
                            onClick={() => changeStatus('4')}
                            icon={'fi-rr-marker-time'}
                            variant={'success'}
                            disabled={['4','5'].includes(AsStore.data.asStatus) ||  AsStore.data.asStatus === '1'}
                        />
                    )}
                    {(['2','3','4'].includes(AsStore.data.asStatus) ) && (
                        <CIconBtn
                            className={Number(AsStore.data.asStatus) < 4 ? 'mb-2 ml-1' : 'mb-2'}
                            style={{ flex: 1 }} 
                            title={'AS 완료'}
                            onClick={completeAs}
                            icon={'fi-rr-hammer-crash'}
                            variant={'primary'}
                            disabled={disableMode && !['2','3','4'].includes(AsStore.data.asStatus)}
                        />
                    )}
                    </div>
                    { ( AsStore.data.asSeq && (!['5', '8', '9'].includes(AsStore.data.asStatus))) && (
                        <div style={{ display: 'flex', width: '100%' }}>
                            <CIconBtn
                                className={'mb-1'}
                                style={{ flex: 1 }} 
                                title={'미결'}
                                onClick={() => changeStatus('8')}
                                icon={'fi-rr-circle-xmark'}
                                variant={'secondary'}
                                disabled={['5'].includes(AsStore.data.asStatus)}
                            />
                            <CIconBtn
                                className={'mb-1 ml-1'}
                                style={{ flex: 1 }} 
                                title={'AS 접수 취소'}
                                onClick={() => changeStatus('9')}
                                icon={'fi-rr-circle-xmark'}
                                variant={'danger'}
                                disabled={disableMode && ['5'].includes(AsStore.data.asStatus)}
                            />
                        </div>
                    )}
                </>
                }
            </CContainer>
            <AsManagerUserSearch callbackFn={getUserInfo} column={userColumn} btnStatus={btnStatus} search={searchUser} companyCode={companyCode}/>
        </>
    );

}

export default observer(AsDetailLinkPage);

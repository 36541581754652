import { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import { CContainer } from "../../../components/CustomContainer";
import AgGridContainer from "../../../components/AgGridContainer";
import { Row } from 'react-bootstrap';
import { showToast } from '../../../common/utils';
import LUtils from '../../../utils/lodashUtils';
import axios from 'axios';
import { callConfirm } from '../../../utils';

const RepairAssetPopup = ({checkedAsset, callBackFn}) => {

    const [originList, setOriginList] = useState([]);
    const gridRef = useRef();

    useEffect(() => {
        setOriginList(checkedAsset);
    }, [checkedAsset]);

    const columnDefs = [
        {field: "assetNo", headerName: "자산번호", minWidth: 130},
        {field: "repairDate", headerName: "수리일(YYYY-MM-DD)", headerClass: 'grid-column-required', editable:true, minWidth: 190},
        {field: "repairPart", headerName: "고장내역",headerClass: 'grid-column-required', editable:true, minWidth: 400},
        {field: "expectAmount", headerName: "예상금액", width:120, headerClass: 'grid-column-editable', editable:true, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "repairShop", headerName: "수리처", headerClass: 'grid-column-required', editable:true, minWidth: 190},
    ]

    const callBackGridData = async (data) => {

        const isUpdate = !LUtils.isEmpty(data.updatedList);
        const regex = /\d{4}-\d{2}-\d{2}/;

        const faultData = data.updatedList.filter(v => !regex.test(String(v.repairDate)));

        if(faultData.length > 0){
            showToast('날짜를 형식에 맞춰 입력해주세요.');
            return false;
        }

        if(!isUpdate){
            showToast('저장할 내용이 없습니다.');
            return false;
        }

        // 저장 확인
        if(!await callConfirm('수리 처리 하시겠습니까?')) return;

        await axios.post('/asset/repair', {repairList: data.updatedList});
        showToast('자산의 상태가 수리중으로 변경되었습니다.');

        callBackFn && callBackFn();
    }

    return (
       <Modal
            title={'자산조회'}
            show={AppStore.isOpenRepairAssetPopup}
            onHide={() => AppStore.toggleRepairAssetPopup()}
            toggleValue={AppStore.isOpenRepairAssetPopup}
            className={'search-modal-6'}
       >

        <CContainer>
            <Row>
                <AgGridContainer
                        gridTitle={'수리 자산 목록'}
                        gridRef={gridRef}
                        height={30}
                        rowData={originList}
                        columnDefs={columnDefs}
                        originList={originList}
                        isCheckBox={true}
                        useUpdated={true}
                        callBackGridData={callBackGridData}
                        />
            </Row>
        </CContainer>

       </Modal>

    )

}

export default observer(RepairAssetPopup);

import React from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Col, Row, Form } from "react-bootstrap";

import { CContainer, CInputGroup, CCol, CSelectGroup, Subject, CSaveBtn, CRemoveBtn } from '../../components/CustomContainer';
import { getCommcode } from "../../utils/commcode";
import { AccountStore } from '../../store/commCode/AccountStore';
import { callConfirm } from "../../utils";
import { decrypt, encrypt, showToast } from "../../common/utils";
import { AppStore } from "../../store/AppStore";
import CertificateListPopup from "../../components/searchModal/CertificateListPopup";
import { Link } from "react-router-dom";

const AccountManagementInfo = ({getAccountList}) => {
    const store = AccountStore;

    /* 새로 등록 or 수정 후 저장 */
    const saveAccount = async () => {
        // 필수값 확인
        let requiredParams = [];
        if(store.data.accountManagementType === '1') { // 간편 계좌
            requiredParams = ['accountNumber', 'accountPassword', 'bankCode'];
        }else if(store.data.accountManagementType === '2') { // 인증서 로그인
            requiredParams = ['accountNumber', 'accountPassword', 'bankCode', 'certName'];
        }else { // 아이디/패스워드
            requiredParams = ['accountNumber', 'accountPassword', 'bankCode', 'loginId', 'loginPassword'];
        }
        if (!requiredParams.every(param => store.data[param])) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        if (!store.data.accountManagementSeq) {
            if (!await callConfirm('등록하시겠습니까?')) return;
            const result = await axios.post('account/management', store.data);
            if(result.result === 'option_failed') {
                alert(result.message);
            }else {
                store.data = result;
                showToast("등록이 완료되었습니다.");
            }
        } else {
            if(!await callConfirm('수정하시겠습니까?')) return;
            const result = await axios.put('account/management', store.data);
            store.data = result;

            showToast("수정이 완료되었습니다.");
        }
        getAccountList();
    }

    /* 인증서 팝업 */
    const openCertificatePopup = () => {
        AppStore.toggleCertificateListPopup();
    }

    const getCertificateInfo = info => {
        store.data.certificateSeq = info.certificateSeq;
        store.data.certName = info.certName;
    }

    const accountRepresetativeCheck = async(v) => {
        // 해당 company code로 isRepresentative true인 row가 있는지 확인
        const result = await axios.get('/account/management/representative');
        if(!v.target.checked && result) {
            if(!await callConfirm('대표계좌가 이미 있습니다. 대표계좌로 설정하시겠습니까?')) {
                return;
            }
        }
        store.data.isRepresentative = v.target.checked ? 0 : 1;
    }

    /* 삭제 */
    const removeAccount = async () => {
        if(!await callConfirm('삭제하시겠습니까?')) return;
        await axios.delete(`account/management/${store.data.accountManagementSeq}`);
        
        showToast("삭제되었습니다.");
        store.dataInit();
        getAccountList();
    }

    return (
        <Col>
            <CContainer>
                <Subject>계좌정보</Subject>
                <Row>
                    
                    <CCol lg={6}>
                        <CSelectGroup label={'은행'} labelId={'bankCode'}
                                    options={[{ name: '전체', value: '' }].concat(getCommcode('24'))}
                                    value={store.data.bankCode || ''}
                                    onChange={v => store.data.bankCode = v.target.value}
                                    labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={3}>
                        <Form.Check
                            type="switch"
                            id="isRepresentative"
                            label="대표계좌"
                            style={{marginBottom: 8}}
                            checked={!!store.data.isRepresentative}
                            onChange={(v) => accountRepresetativeCheck(v)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'계좌번호'} labelId={'accountNumber'}
                                        value={store.data.accountNumber}
                                        onChange={v => store.data.accountNumber = v.target.value}
                                        labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'계좌비밀번호'}
                                        labelId={'accountPassword'}
                                        value={decrypt(store.data.accountPassword)}
                                        onChange={v => store.data.accountPassword = encrypt(v.target.value)}
                                        type={'password'}
                                        labelClassName='input-required'
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12} >
                        <Form.Check
                            inline
                            label={"빠른 계좌"}
                            name="accountManagementType"
                            type={'radio'}
                            value={'1'}
                            checked={store.data.accountManagementType === '1'}
                            onChange={(v)=> {
                                store.data.accountManagementType = v.target.value;
                            }}
                        />
                        <Form.Check
                            inline
                            label={"인증서"}
                            name="checkMonth"
                            type={'radio'}
                            value={'2'}
                            checked={store.data.accountManagementType === '2'}
                            onChange={(v)=> {
                                store.data.accountManagementType = v.target.value;
                            }}
                        />
                        <Form.Check
                            inline
                            label={"아이디/패스워드"}
                            name="checkMonth"
                            type={'radio'}
                            value={'3'}
                            checked={store.data.accountManagementType === '3'}
                            onChange={(v)=> {
                                store.data.accountManagementType = v.target.value;
                            }}
                        />
                    </CCol>
                </Row>
                {store.data.accountManagementType === '1' && (
                    <Row>
                        <CCol lg={6} style={{ marginBottom: 40 }}>
                        <div style={{ display: 'inline-flex', alignItems: 'center', padding: '5px', borderRadius: '10px', border: '1px solid #ccc' }}>
                            <i className="fi fi-sr-bullseye-pointer" style={{ marginRight: 5, marginLeft: 5, color: '#686868' }}></i>
                            <Link to={'https://kwichub.com/BNK_INF_011000'} target="_blank" style={{ marginRight: 5, color: '#747474' }}>
                                은행별 빠른 계좌 조회 등록 방법 안내
                            </Link>
                        </div>

                        </CCol>
                    </Row>
                )}
                {store.data.accountManagementType === '3' && (
                    <Row>
                        <CCol lg={6}>
                            <CInputGroup label={'로그인아이디'} labelId={'loginId'}
                                        value={store.data.loginId}
                                        onChange={v => store.data.loginId = v.target.value}
                                        labelClassName='input-required'
                            />
                        </CCol>
                        <CCol lg={6}>
                            <CInputGroup label={'로그인패스워드'} labelId={'loginPassword'}
                                        value={decrypt(store.data.loginPassword)}
                                        onChange={v => store.data.loginPassword = encrypt(v.target.value)}
                                        type={'password'}
                                        labelClassName='input-required'
                            />
                        </CCol>
                    </Row>
                )}
                {store.data.accountManagementType === '2' &&
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup label={'인증서이름'} labelId={'certName'}
                                         value={store.data.certName}
                                         onChange={v => store.data.certName = v.target.value}
                                         onCallbackBtn={openCertificatePopup}
                                         disabled={true}
                                         labelClassName='input-required'
                            />
                        </CCol>
                    </Row>
                }
                <Row>
                    <CCol lg={12}>
                        <CInputGroup label={'비고'} labelId={'remark'}
                                    placeholder={'비고를 입력해 주세요.'}
                                    value={store.data.remark}
                                    onChange={v => store.data.remark = v.target.value}
                        />
                    </CCol>
                </Row>
            </CContainer>
            <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn style={{ width: 80 }}
                            title={'저장'}
                            onClick={saveAccount}
                        />
                        {store.data.accountManagementSeq && 
                            <CRemoveBtn style={{ width: 80 }}
                                title={'삭제'}
                                onClick={removeAccount}
                            />
                        }
                    </Col>
                </Row>
            </CContainer>
            <CertificateListPopup callbackFn={getCertificateInfo} />
        </Col>
    );
};

export default observer(AccountManagementInfo);

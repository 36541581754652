import React, {useRef, useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import {Row, Col, Tab, Tabs} from "react-bootstrap";
import {CContainer, CInputGroup, CCol, Subject, CSaveBtn, CSelectGroup, CalendarGroup, CIconBtn} from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import { AssetStore } from '../../store/asset/AssetStore';
import {getUserAuthGroup, getUserName} from '../../utils/userUtils';
import {findCommKrnm, getCommcode} from "../../utils/commcode";
import {dateTimeFormat} from "../../utils/dateUtils";
import {showToast} from "../../common/utils";
import {callConfirm} from "../../utils";
import { ICON_ADD } from '../../common/constants';
import { AppStore } from '../../store/AppStore';
import AddConsumablePopup from '../../components/searchModal/AddConsumablePopup';

const AssetDetail = ({assetNo}) => {
    const gridRef = useRef();
    const consumableGridRef = useRef();
    const imgRef = useRef();

    const [selectTab, setSelectTab] = useState('consum');

    /* 기본값 세팅 */
    useEffect(() => {
        Promise.all([getAsset(), getAssetConsumableList(), getAssetHistory(), getAssetRepairHistory()]);
    }, []);

    /* 상세 조회 */
    const getAsset = async () => {
        const result = await axios.get('/asset', {params: {assetNo: assetNo}});

        AssetStore.assetDetail = result;
        AssetStore.setAssetStatus(result.assetStatus);
        AssetStore.setManagementNo(result.managementNo);
    }

    /* asset consumable list 조회 */
    const getAssetConsumableList = async () => {
        const result = await axios.get('asset/consum/list', {params: {assetNo: assetNo}});
        AssetStore.assetConsumableList = result;
    }

    /* 자산 이력 조회 */
    const getAssetHistory =async()=> {
        const resultList = await axios.get('/asset/histories', { params: {assetNo: assetNo}});
        AssetStore.setAssetHistoryList(resultList);
    }

    /* 수리이력조회 */
    const getAssetRepairHistory =async()=> {
        const result = await axios.get(`/asset/repair/${assetNo}`);
        AssetStore.setAssetRepairHistoryList(result);
    }

    const columnDefs = [
        {field: "assetNo", headerName: "자산번호", minWidth: 120, hide: true},
        {field: "seq", headerName: "순번", minWidth: 60},
        {field: "productName", headerName: "제품명", minWidth: 180},
        {field: "model", headerName: "모델명", minWidth: 150},
        {field: "isUse", headerName: "사용유무", width: 110, valueFormatter: v => v.value === 0 ? 'N' : 'Y'},
        {field: "creator", headerName: "등록자", valueFormatter: v => getUserName(v.value)},
        {field: "createdDate", headerName: "등록일자", minWidth: 180},
        {field: "updater", headerName: "수정자", valueFormatter: v => getUserName(v.value)},
        {field: "updatedDate", headerName: "수정일자", minWidth: 180},
    ];

    /* 바코드 출력 */
    const printBarcord = async () => {
        if(!await callConfirm('자산 바코드를 출력하시겠습니까?')){
            return;
        }

        //let data = `^XA^BY2,2.0^FS^SEE:UHANGUL.DAT^FS^CW1,E:KFONT3.FNT^CI26^FS^FO20,55^A1,30,30^FDAsset:${AssetStore.assetDetail.assetNo}^FS^FO20,100^A1N,30,30^FDProduct:${AssetStore.assetDetail.productCode}^FS^FO20,145^A0N,30,30^FDS/N:${AssetStore.assetDetail.serialNo}^FS^FO20,190^A0N,30,30^FDM/N:${AssetStore.assetDetail.model}^FS^FO340,60^BCN,50,N^FD${AssetStore.assetDetail.assetNo}^FS^XZ`;
        let data = `^XA^BY2,2.0^FS^SEE:UHANGUL.DAT^FS^CW1,E:KFONT3.FNT^CI26^FS^FO20,55^A1,30,30^FDAsset:${AssetStore.assetDetail.assetNo}^FS^FO20,145^A0N,30,30^FDS/N:${AssetStore.assetDetail.serialNo}^FS^FO20,190^A0N,30,30^FDM/N:${AssetStore.assetDetail.model}^FS^FO340,60^BCN,50,N^FD${AssetStore.assetDetail.assetNo}^FS^XZ`;

        fetch(`http://192.168.0.14:6101?=${data}`, {
            method: 'GET',
            mode: "cors",
            credentials: 'include',
            //body: data,
            headers:{
              'Content-Type': 'application/json'
            }
        });

        showToast('출력을 전송하였습니다.');
    }
    
    const localeMoneyString =(val)=> {
        return val ? Number(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
    }

    const isChangeStatusCheck =(code)=> {
        //관리자, 기술팀만 수정 가능
        if(code && (getUserAuthGroup() === 1 || getUserAuthGroup() === 2 || getUserAuthGroup() === 9 || getUserAuthGroup() === 10)){
            if(code === '200' || code === '202'|| code === '306'){
                return true;
            }else{
                return false;
            }
        }
    }

    const assetChangeSave =async()=> {
        const oldStatus = findCommKrnm(AssetStore.assetDetail.assetStatus, '8');
        const newStatus = findCommKrnm(AssetStore.assetStatus, '8');
        const params = {
                        assetStatus: AssetStore.assetDetail.assetStatus !== AssetStore.assetStatus ? AssetStore.assetStatus : null,
                        remark: AssetStore.assetDetail.remark,
                        serialNo: AssetStore.assetDetail.serialNo,
                        managementNo: AssetStore.managementNo,
                        shelfId: AssetStore.assetDetail.shelfId,
                        usefulLifeMonth: AssetStore.assetDetail.usefulLifeMonth,
                    }

        if (AssetStore.assetDetail.managementNo !== AssetStore.managementNo) {
            const result = await axios.get(`asset/check/${managementNo}`);
            if(result > 0) {
                showToast('중복된 관리번호 입니다. ');
                return;
            }
        }

        if(AssetStore.assetDetail.assetStatus !== AssetStore.assetStatus){
            if(AssetStore.assetDetail.assetStatus !== AssetStore.assetStatus && AssetStore.assetStatus !== '101' && AssetStore.assetStatus !== '200' && AssetStore.assetStatus !== '202' && AssetStore.assetStatus !== '305' && AssetStore.assetStatus !== '306' && AssetStore.assetStatus !== '401'){
                showToast('[임대가능],[출고완료],[사내사용중],[입고검수중],[수리중],[매각대기] 상태만 변경이 가능합니다.');
                return;
            }

            if(!await callConfirm(`[${oldStatus}] > [${newStatus}]로 자산 상태 변경 및 자산 내용을 수정하시겠습니까?`)) return;
            await axios.put(`asset/status/${AssetStore.assetDetail.assetNo}`, {assetStatus: AssetStore.assetStatus});
        } else {
            if(!await callConfirm(`자산 내용을 수정하시겠습니까?`)) return;
        }

        await axios.put(`/asset/${AssetStore.assetDetail.assetNo}`, params);

        showToast('변경되었습니다.');

        await getAssetHistory();
    }

    const callBackRepairSave = async (gridData) => {
        // 저장 확인
        if (!await callConfirm('저장 하시겠습니까?')) return;

        const paramList = gridData.updatedList.map(v => {
            const object = {};
            object.assetRepairNo = v.assetRepairNo;
            object.repairPart = v.repairPart;
            object.fixAmount = v.fixAmount;
            object.fixRepairShop = v.fixRepairShop;
            object.remark = v.remark;
            return object;
        });

        await axios.put('/asset/repair', { repairList: paramList });
        showToast('저장되었습니다.');
        await getAssetRepairHistory();
    }    

    const addConsumableBtn = {
        isUsed: true,
        title: '소모품 결합',
        callbackFn: () => AppStore.toggleAddConsumablePopup(),
        icon: ICON_ADD,
        variant: 'primary'
    };

    const deleteConsumableBtn = {
        isUsed: true,
        title: '소모품 해제',
        callbackFn: () => deleteConsumable(),
        icon: 'fi fi-br-ban',
        variant: 'danger'
    };

    const deleteConsumable = async () => {
        if(!confirm('소모품 결합을 해제하시겠습니까?')) {
            return;
        }

        const selectedList = AssetStore.assetConsumableList.filter(v => v.isSelected);
        await axios.put('asset/consumable/list', {assetConsumableList: selectedList});

        getAssetConsumableList();
        showToast('소모품 결합을 해제했습니다.');
    }

    return (
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Subject>자산정보</Subject>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'자산번호'} labelId={'assetNo'}
                                     value={AssetStore.assetDetail.assetNo}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'관리번호'} labelId={'managementNo'}
                                     value={AssetStore.managementNo}
                                     onChange={e => AssetStore.managementNo = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'자산구분'} labelId={'assetType'}
                                     value={findCommKrnm(String(AssetStore.assetDetail.assetType), '7')}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup label={'자산상태'} labelId={'assetStatus'}
                                     options={getCommcode('8')}
                                     value={AssetStore.assetStatus}
                                     disabled={!isChangeStatusCheck(AssetStore.assetStatus)}
                                     onChange={(e)=> AssetStore.setAssetStatus(e.target.value)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'대분류'} labelId={'productGroupName'}
                                     value={AssetStore.assetDetail.productGroupName}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup label={'모델명'} labelId={'model'}
                                     value={AssetStore.assetDetail.productName}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup label={'시리얼번호'} labelId={'serialNo'}
                                     value={AssetStore.assetDetail.serialNo}
                                     onChange={e => AssetStore.assetDetail.serialNo = e.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    {/*
                    <CCol lg={2}>
                        <CInputGroup label={'상각개월'} labelId={'usefulLifeMonth'}
                                     value={AssetStore.assetDetail.usefulLifeMonth}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'장부가'} labelId={'booksPrice'}
                                     value={localeMoneyString(AssetStore.assetDetail.booksPrice)}
                                     disabled={true}
                        />
                    </CCol>
                    */}
                    <CCol lg={2}>
                        <CInputGroup label={'매출총액'} labelId={'totalRentAmount'}
                                     mo
                                     value={localeMoneyString(AssetStore.assetDetail.totalRentAmount)}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'회수율'} labelId={'returnRate'}
                                     value={AssetStore.assetDetail.returnRate ? AssetStore.assetDetail.returnRate + '%' : ''}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'총 보유일수'} labelId={'totalRetentionDay'}
                                     value={AssetStore.assetDetail.totalRetentionDay ? AssetStore.assetDetail.totalRetentionDay+'일' : ''}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'재고 보유일수'} labelId={'stockRetentionDay'}
                                     value={AssetStore.assetDetail.stockRetentionDay ? AssetStore.assetDetail.stockRetentionDay + '일' : ''}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'선반번호'} labelId={'shelfId'}
                                     value={AssetStore.assetDetail.shelfId}
                                     onChange={e => AssetStore.assetDetail.shelfId = e.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'외관상태'} labelId={'exteriorStatus'}
                                     options={[{value: 'A', name: 'A'}, {value: 'B', name: 'B'}, {value: 'C', name: 'C'}]}
                                     value={AssetStore.assetDetail.exteriorStatus}
                                     onChange={(e)=> AssetStore.assetDetail.exteriorStatus = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'연식'} labelId={'manufacturing'}
                                     value={AssetStore.assetDetail.manufacturing}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'구입방법'} labelId={'howToBuy'}
                                     value={AssetStore.assetDetail.howToBuy}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup label={'구입처'} labelId={'whereToBuy'}
                                     value={AssetStore.assetDetail.whereToBuy}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'신품/중고'} labelId={'newOrUsed'}
                                     value={AssetStore.assetDetail.newOrUsed === '2' ? '중고' : '신품'}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'취득가'} labelId={'acquAmount'}
                                     value={localeMoneyString(AssetStore.assetDetail.acquAmount)}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'취득일'} labelId={'acquDate'}
                                     value={AssetStore.assetDetail.acquDate}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Subject>최근 계약 정보</Subject>
                    <CCol lg={3}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={AssetStore.assetDetail.contractNo}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'고객명'} labelId={'customerName'}
                                     value={AssetStore.assetDetail.customerName}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'최근출고일'} labelId={'lastReleaseDate'}
                                     value={AssetStore.assetDetail.lastReleaseDate}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Subject>매각정보</Subject>
                    <CCol lg={3}>
                        <CInputGroup label={'매각구분'} labelId={'sellType'}
                                     value={AssetStore.assetDetail.sellType}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'매각금액'} labelId={'sellAmount'}
                                     value={AssetStore.assetDetail.sellAmount}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'매각일자'} labelId={'sellDate'}
                                     value={AssetStore.assetDetail.sellDate}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    {AssetStore.assetDetail.hireEndDate &&
                        <CCol lg={4}>
                            <CalendarGroup
                                asSingle={true}
                                label={'임차자산 반납예정일'}
                                labelId={'hireEndDate'}
                                value={AssetStore.assetDetail.hireEndDate}
                                disabled={true}
                            />
                        </CCol>
                    }
                    <CCol lg={6}>
                        <CInputGroup label={'비고'} labelId={'remark'}
                                     value={AssetStore.assetDetail.remark}
                                     onChange={(e)=> AssetStore.assetDetail.remark = e.target.value}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'바코드 출력'}
                                onClick={() => printBarcord()}
                        />
                        <CSaveBtn style={{width: 100, height: 30}}
                                  title={'저장'}
                                  onClick={() => assetChangeSave()}
                        />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <Tabs
                    defaultActiveKey="consum"
                    className="mb-3"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    activeKey={selectTab}
                    onSelect={k => setSelectTab(k)}
                >
                    <Tab eventKey="consum" title={'소모품 이력'}>
                        <AgGridContainer
                            gridRef={consumableGridRef}
                            height={30}
                            rowData={AssetStore.assetConsumableList}
                            columnDefs={columnDefs}
                            useCsvDownload={true}
                            getRowStyle={(v) => {
                                if (v.data.isUse !== 1) {
                                    return { color: '#bababa', pointerEvents: 'none', };
                                }
                                return null;
                            }}
                            customBtnInfo={[addConsumableBtn, deleteConsumableBtn]}
                            isCheckBox={true}
                        />
                    </Tab>
                    <Tab eventKey="history" title="자산 상태 이력">
                        <AgGridContainer
                            height={30}
                            isCheckBox={false}
                            rowData={AssetStore.assetHistoryList}
                            columnDefs={[
                                {field: "assetHistorySeq", headerName: "순번", hide:true},
                                {field: "historyOrder", headerName: "순번", width: 80},
                                {field: "assetNo", headerName: "자산번호", width: 120},
                                {field: "assetType", headerName: "자산구분", width: 110, valueFormatter: v => Number(v.value) === 1 ? '당사자산' : '임차자산'},
                                {field: "conditionStatus", headerName: "자산상태", width: 110, hide: true},
                                {field: "workStatus", headerName: "처리상태", width: 110, hide: true},
                                {field: "assetStatus", headerName: "자산상태", width: 110, valueFormatter: v => findCommKrnm(v.value, '8')},
                                {field: "teamCode", headerName: "팀코드", hide: true},
                                {field: "customerName", headerName: "고객명", width: 140},
                                {field: "contractNo", headerName: "계약번호", width: 150},
                                {field: "contractType", headerName: "계약구분", width: 110, valueFormatter: v => findCommKrnm(v.value, '1')},
                                {field: "serialNo", headerName: "시리얼번호", width: 150},
                                {field: "exteriorStatus", headerName: "외관상태", width: 110},
                                /*        {field: "acquAmount", headerName: "취득가", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                        {field: "acquDate", headerName: "취득일", valueGetter: v => dateTimeFormat(v.data.acquDate)},*/
                                {field: "sellAmt", headerName: "매각금액", width: 120, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                                {field: "sellDate", headerName: "매각일자", width: 120},
                                {field: "sellType", headerName: "매각구분", width: 120, valueFormatter: v => findCommKrnm(v.value, '9')},
                                {field: "hireEndDate", headerName: "임차자산 반납 예정일", width: 200, hide: !AssetStore.assetStatus.hireEndDate},
                                {field: "remark", headerName: "비고", width: 200},
                                {field: "creator", headerName: "수정자", valueFormatter: v => getUserName(v.value)},
                                {field: "createdDate", headerName: "수정일", minWidth: 200, flex:2, valueGetter: v => dateTimeFormat(v.data.createdDate)},
                            ]}
                            useCsvDownload={true}
                        />
                    </Tab>
                    <Tab eventKey="repair" title={'수리 이력'}>
                        <AgGridContainer
                            gridRef={gridRef}
                            height={30}
                            rowData={AssetStore.assetRepairHistoryList}
                            columnDefs={[
                                {field: "assetRepairNo", headerName: "자산수리번호", hide: true, width: 130},
                                {field: "assetNo", headerName: "자산번호", hide:true, width: 130},
                                {field: "repairDate", headerName: "수리일(YYYY-MM-DD)", editable:true, width: 200},
                                {field: "repairPart", headerName: "수리내역", editable:true, headerClass: 'grid-column-editable', width: 200},
                                {field: "expectAmount", headerName: "예상금액", valueFormatter: v => v.value?.toLocaleString(), width: 110},
                                {field: "fixAmount", headerName: "확정금액", editable:true, valueFormatter: v => v.value?.toLocaleString(), headerClass: 'grid-column-editable', width: 110},
                                {field: "repairShop", headerName: "수리처", width: 150},
                                {field: "fixRepairShop", headerName: "확정 수리처", editable:true, headerClass: 'grid-column-editable', width: 150},
                                {field: "remark", headerName: "비고", editable:true, headerClass: 'grid-column-editable', width: 200},
                                {field: "creator", headerName: "작성자", valueGetter: v => getUserName(v.data.creator), width: 100},
                                {field: "createdDate", headerName: "작성일자", width: 180},
                                {field: "updater", headerName: "수정자", valueGetter: v => getUserName(v.data.updater), width: 100},
                                {field: "updatedDate", headerName: "수정일자", width: 180},
                            ]}
                            useCsvDownload={true}
                            isCheckBox={true}
                            useUpdated={true}
                            callBackGridData={callBackRepairSave}
                        />
                    </Tab>
                </Tabs>
                <AddConsumablePopup assetNo={assetNo} selProductList={AssetStore.assetDetail} getAssetConsumableList={getAssetConsumableList} />
            </CContainer>
        </>
    );
};

export default observer(AssetDetail);

import { observer } from 'mobx-react-lite';
import { AsStore } from '../../store/asset/AsStore';
import { useRef, useState } from 'react';
import axios from 'axios';
import { CContainer } from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import AsDetailPopup from './AsDetail';
import AsListSearch from './AsListSearch';
import { getUserName } from '../../utils/userUtils';
import { AppStore } from '../../store/AppStore';
import { showToast } from '../../common/utils';
import { decrypt } from '../../common/utils';
import { findCommKrnm } from '../../utils/commcode';
import { autoHyphen } from '../../common/utils';

const AsList = () => {
    const gridRef = useRef();

    const columnDefs=[
        {field: "asSeq", headerName: "AS번호", width: 100},
        {
            field: "asStatus"
            , headerName: "AS처리상태"
            , valueFormatter: v =>  findCommKrnm(v.value, '55')
            , cellClass: v => v.data.asStatus === '9' ? 'ag-grid-column-cancel' : v.data.asStatus === '2' ? 'ag-grid-column-complete' : ''
            , width: 120
        },
        {field: "siteAddress", headerName: "현장명", width: 200, hide: true},
        {field: "customerName", headerName: "고객명", width: 200},
        {field: "siteManagerName", headerName: "담당자", width: 110},
        {field: "siteManagerPhone", headerName: "담당자 연락처", valueGetter: v => autoHyphen(decrypt(v.data.siteManagerPhone)), width: 160},
        {field: "asUrl", headerName: "AS 링크", cellRenderer: e => asUrl(e)},
        // {field: "asUrl", headerName: "AS 링크", width: 120},
        {field: "acceptUserName", headerName: "접수자", width: 90},
        {field: "acceptedDate", headerName: "접수일자", width: 170},
        {field: "asManagerName", headerName: "AS처리자", width: 110},
        {field: "asManagerPhone", headerName: "AS처리자 연락처", valueGetter: v => autoHyphen(decrypt(v.data.asManagerPhone)), width: 150},
        {field: "completeUserName", headerName: "완료자", width: 90},
        {field: "completedDate", headerName: "완료일자", width: 170},
        {field: "processDetail", headerName: "처리내역", width: 250},
        {field: "updatedDate", headerName: "취소일자", width: 170},
        {field: "updaterName", headerName: "취소자", width: 90},
    ]

    const asUrl = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <a href={e.data.asUrl} target="_blank">{e.data.asUrl}</a>
            </div>
        )
    }
    const getAsList = async () => {
        const result = await axios.get('/as', {params: AsStore.search});

        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.')
        }
        AsStore.asList = result;
    }

    const getAsDetail = async (v) => {
        const result = await axios.get('/as/detail', {params: {asSeq: v.data.asSeq}});
        AsStore.data = result;
        AsStore.data.siteManagerPhone = decrypt(result.siteManagerPhone);

        // 상세 모달 열기
        AppStore.toggleAsDetail();
    }

    return (
        <>
            <AsListSearch getAsList={getAsList}/>
            <CContainer>
                <AgGridContainer
                    gridTitle={'AS목록'}
                    gridRef={gridRef}
                    height={63}
                    rowData={AsStore.asList}
                    columnDefs={columnDefs}
                    rowDoubleClickCallback={(v) => getAsDetail(v)}
                    useCsvDownload={true}
                    />
            </CContainer>
            <AsDetailPopup getAsList={getAsList}/>
        </>
    );
}

export default observer(AsList);

import React, {useRef, useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import axios from "axios";

import {CContainer} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import Modal from "../../components/Modal";
import {AppStore} from "../../store/AppStore";
import {findCommKrnm} from "../../utils/commcode";
import ReleaseAskDetail from "./ReleaseAskDetail";
import {ReleaseAskListStore} from "../../store/release/ReleaseAskListStore";
import {ReleaseAskDetailStore} from "../../store/release/ReleaseAskDetailStore";
import {daysFromToday} from '../../utils/dateUtils';
import ReleaseAskListSearch from "./ReleaseAskListSearch";

const ReleaseAskList =()=> {
    const gridRef = useRef();
    const [releaseAskSeq, setReleaseAskSeq] = useState(null);
    const [contractNo, setContractNo] = useState(null);

    useEffect(() => {
        // 한 달 전 날짜 세팅
        const initSearchCondition = {
            startDate: daysFromToday(-7),
            endDate: daysFromToday(10),
        }
        ReleaseAskListStore.setReleaseAskSearchCondition(initSearchCondition);
    }, []);

    const getReleaseAskList = async()=> {
        const result = await axios.get('/releaseAsk/list', {params: ReleaseAskListStore.releaseAskSearchCondition});
        ReleaseAskListStore.setReleaseAskList(result);
    }

    /* 출고의뢰 상세 */
    const openReleaseAskDetail = (v) => {
        setReleaseAskSeq(v.data.releaseAskSeq);
        setContractNo(v.data.contractNo);
        AppStore.toggleModal();
    }

    return(
        <>
            {/* 검색조건 */}
            <ReleaseAskListSearch getReleaseAskList={getReleaseAskList}/>

            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={62}
                    rowData={ReleaseAskListStore.releaseAskList}
                    columnDefs={
                        [
                            {field: "releaseAskSeq", headerName: "출고의뢰번호", width: 130},
                            {
                                field: "releaseAskType",
                                headerName: "출고의뢰구분",
                                valueFormatter: function(params) {
                                    return findCommKrnm(String(params.value), '39');
                                }, width: 130
                            },
                            {field: "shipHopeDate", headerName: "배송 예정일", width: 130},
                            {
                                field: "releaseAskStatus",
                                headerName: "출고의뢰상태",
                                valueFormatter: function(params) {
                                    return findCommKrnm(params.value, '5');
                                },
                                cellClass: v => v.value === '9' ? 'ag-grid-column-cancel' : v.value === '6' ? 'ag-grid-column-complete' : '',
                                width: 130
                            },
                            {field: "contractNo", headerName: "계약번호", width: 160},
                            {field: "saleUserName", headerName: "영업담당", width: 110},
                            {field: "supportUserName", headerName: "지원담당", width: 110, hide: true},
                            {field: "customerName", headerName: "고객명", minWidth: 170},
                            {field: "productName", headerName: "품목", width: 200},
                            {field: "releaseAskRemark", headerName: "출고의뢰비고", width: 200},
                            {field: "releaseQty", headerName: "출고수량", width: 110},
                            {field: "creatorName", headerName: "의뢰자", width: 100},
                            {field: "releaseAskDate", headerName: "의뢰일자", width: 120},
                            {field: "acceptUserName", headerName: "접수자", width: 90, hide: true},
                            {field: "acceptDate", headerName: "접수일자", width: 180, hide: true},
                            {field: "releaseUserName", headerName: "출고완료자", width: 120},
                            {field: "releaseDate", headerName: "출고완료일자", width: 180},
                        ]
                    }
                    seqColumn={'releaseAskSeq'}
                    rowDoubleClickCallback={openReleaseAskDetail}
                />
            </CContainer>
            <Modal title={'출고의뢰'}
                   onExit={()=> {
                       ReleaseAskDetailStore.init();
                       setReleaseAskSeq('');
                       setContractNo('');
                   }}>
                <ReleaseAskDetail
                    releaseAskSeq={releaseAskSeq}
                    getReleaseAskList={getReleaseAskList}
                    callBackFn={()=> getReleaseAskList()}
                    contractNo={contractNo}
                    // customerNo={'2303031'}
                    // billingAddressSeq={14}
                />
            </Modal>
        </>
    );
}

export default observer(ReleaseAskList);

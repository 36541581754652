import { observable } from "mobx";
import { startDateForThisMonth, endDateForThisMonth } from '../../utils/dateUtils';

export const AssetExpirationStore = observable({
    expirationList: [],
    search : {
        customerNo: '',
        customerName: '',
        contractNo: '',
        assetNo: '',
        startDate: startDateForThisMonth(),
        endDate: endDateForThisMonth(),
        expirationDays: null,
        saleUserName: '',
    },

    setExpirationList(v){
        this.expirationList = v;
    }
})

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const USER_INFO = 'USER_INFO';
const LINK_USER_INFO = 'LINK_USER_INFO';
const MULTIPART_HEADER = { 'Content-Type': 'multipart/form-data' };
const AES_SALT = process.env.REACT_APP_AES_SALT;

/* localStorage */
const COMM_CODE_LIST = 'comm_code_list';
const USER_LIST = 'user_list';

/* icon class */
const ICON_TRASH = 'fi-rr-trash';
const ICON_ADD = 'fi-rr-add';
const ICON_EXTEND = 'fi-rr-calendar-clock';
const ICON_FINISH = 'fi-rr-time-delete';
const ICON_TRANSFER = 'fi-rs-book-arrow-right';
const ICON_SAVE = 'fi-rs-disk';

/* error code */
const NO_DATA = 'NO_DATA';
const NO_CONTRACT= 'NO_CONTRACT';

/* ag grid custom value */
const IS_SELECTED = 'isSelected';
const IS_CREATED = 'isCreated';
const IS_UPDATED = 'isUpdated';

const SUCCESS = 'success';
const FAIL = 'fail';

/* 차변/대변 default 코드 */
const DEBIT_CODE = '10800';
const CREDIT_CODE = '10300';

/* 관리자 회사코드 */
const PELOTONLAB = '2310001';
const MASTER_PELOTONLAB = '2301001';

const OPTION_USE = ['Y', ''];
const OPTION_QTY = ['1','2','3','4','5','6','7','8','9','10'];
const OPTION_LEVEL = ['A', 'B', 'C'];
const STATUS_LEVEL = [
    {name: '선택', value: ''},
    {name: 'A', value: 'A'},
    {name: 'B', value: 'B'},
    {name: 'C', value: 'C'},
]

const ORDER_TYPE = [
    {name: '상품', value: '1'},
    {name: '임차자산', value: '2'},
    {name: '소모품', value: '3'},
]

const MAINTENANCE_MONTH = [
    {name: '1개월', value: 1},
    {name: '2개월', value: 2},
    {name: '3개월', value: 3},
    {name: '4개월', value: 4},
    {name: '5개월', value: 5},
    {name: '6개월', value: 6},
    {name: '7개월', value: 7},
    {name: '8개월', value: 8},
    {name: '9개월', value: 9},
    {name: '10개월', value: 10},
    {name: '11개월', value: 11},
    {name: '12개월', value: 12},
];

/* 권한 */
const RELEASE_ASK_MODIFY_AUTH = "CS-RRM-3";

export {
    BASE_URL,
    USER_INFO,
    LINK_USER_INFO,
    AES_SALT,
    COMM_CODE_LIST, USER_LIST,
    ICON_TRASH, ICON_ADD, ICON_EXTEND, ICON_FINISH, ICON_TRANSFER, ICON_SAVE,
    MULTIPART_HEADER,
    NO_DATA, NO_CONTRACT,
    IS_SELECTED, IS_CREATED, IS_UPDATED,
    SUCCESS, FAIL,
    DEBIT_CODE, CREDIT_CODE,
    PELOTONLAB, MASTER_PELOTONLAB,
    OPTION_USE, OPTION_QTY, OPTION_LEVEL, STATUS_LEVEL,
    ORDER_TYPE, MAINTENANCE_MONTH,
    RELEASE_ASK_MODIFY_AUTH
}
import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const DashboardStore = observable({
    contract: {preCount: 0, nowCount: 0},
    release: {preCount: 0, nowCount: 0},
    site: {preCount: 0, nowCount: 0},
    monthOperatingRate: [],
    nowOperatingRate: 0,
    nonReceiveAmount: 0,
    assetStatus: {ownAsset: 0, hireAsset: 0, goodsCount: 0},
    ownAssetRate: '',
    notReceiveSiteList: [],
    asStatus: {acceptCount: 0, completeCount: 0, allCount: 0},
    repairStatus: {acceptCount: 0, completeCount: 0, allCount: 0},
    billCount: {nowCount: 0, totalCount: 0},
    smsCount: {nowCount: 0, totalCount: 0},
    accountCount: 0,
    cardCount: 0,
    deliveryCount: 0,
    assetDetailStatus: '',
    productStockList: [],
    consumStock: '',
    consumStockLabel: '',
    consumStockCount: '',
    assetAcquLabels: [],
    assetAcquList: [],
    billingChargeTargetCount: 0,
    assetExpireCount: 0,
    notReceiveCount: 0,

    setContract(v){
        this.contract.preCount = v.preCount;
        this.contract.nowCount = v.nowCount;
    },
    setRelease(v){
        this.release.preCount = v.preCount;
        this.release.nowCount = v.nowCount;
    },
    setSite(v){
        this.site.preCount = v.preCount;
        this.site.nowCount = v.nowCount;
    },
    setMonthOperatingRate(v){
        this.monthOperatingRate = v;
    },
    setNowOperatingRate(v){
        this.nowOperatingRate = v;
    },
    setNonReceiveAmount(v){
        this.nonReceiveAmount = v;
    },
    setAssetStatus(v){
        this.assetStatus.ownAsset = v.ownAsset;
        this.assetStatus.hireAsset = v.hireAsset;
    },
    setOwnAssetRate(v){
        this.ownAssetRate = v;
    },
    setNotReceiveSiteList(v){
        this.notReceiveSiteList = v;
    },
    setAsStatus(v){
        this.asStatus.acceptCount = v.acceptCount;
        this.asStatus.completeCount = v.completeCount;
        this.asStatus.allCount = v.allCount;
    },
    setRepairStatus(v){
        this.repairStatus.acceptCount = v.acceptCount;
        this.repairStatus.completeCount = v.completeCount;
        this.repairStatus.allCount = v.allCount;
    },
    setBillCount(v){
        this.billCount.nowCount = v.nowCount;
        this.billCount.totalCount = v.preCount;
    },
    setSmsCount(v){
        this.smsCount.nowCount = v.nowCount;
        this.smsCount.totalCount = v.preCount;
    },
    setAccountCount(v){
        this.accountCount = v;
    },
    setCardCount(v){
        this.cardCount = v;
    },
    setDeliveryCount(v){
        this.deliveryCount = v;
    },
    setAssetDetailStatus(v){
        this.assetDetailStatus = v;
    },
    setProductStockList(v){
        this.productStockList = v;
    },
    setConsumStock(v){
        this.consumStock = v;
    },
    setConsumStockLabel(v){
        this.consumStockLabel = v;
    },
    setConsumStockCount(v){
        this.consumStockCount = v;
    },
    setAssetAcquLabels(v){
        this.assetAcquLabels = v;
    },
    setAssetAcquList(v){
        this.assetAcquList = v;
    },
    setBillingChargeTargetCount(v){
        this.billingChargeTargetCount = v;
    },
    setAssetExpireCount(v){
        this.assetExpireCount = v;
    },
    setNotReceiveCount(v){
        this.notReceiveCount = v;
    }
});

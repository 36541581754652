import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import Modal from '../../../components/Modal'
import {Row, Form, InputGroup, Col} from "react-bootstrap";
import { CContainer, CInputGroup, CCol, CSaveBtn, CSelectGroup } from '../../../components/CustomContainer';
import LUtils from '../../../utils/lodashUtils';
import { getCommcode } from '../../../utils/commcode';
import { ProductStore } from '../../../store/product/ProductStore';
import { MULTIPART_HEADER } from '../../../common/constants';
import { AppStore } from '../../../store/AppStore';
import {showToast} from "../../../common/utils";
import {callConfirm} from "../../../utils";


const InsertProduct = ( {getProductLst, productSeq} ) => {
    const fileRef1 = useRef();
    const fileRef2 = useRef();
    const fileRef3 = useRef();
    // const [ccSM, setCcSM] = useState('');
    // const [deleted, setDeleted] = useState(0);
    const [lgCode, setLGCode] = useState([]);
    const [mdCode, setMDCode] = useState([]);
    const [smCode, setSMCode] = useState([]);
    const [xsCode, setXSCode] = useState([]);
    const [selectedLGCode, setSelectedLGCode] = useState('');
    const [selectedMDCode, setSelectedMDCode] = useState('');
    const [selectedSMCode, setSelectedSMCode] = useState('');
    const [selectedXSCode, setSelectedXSCode] = useState('');
    const [productGroupList, setProductGroupList] = useState([]);
    const [isConsum, setIsConsum] = useState(false);

    useEffect(()=> {
        getProductGroupList();
    }, [])

    useEffect(() => {

        if(productSeq) {
            const list = ProductStore.productList.find(v => v.productSeq === productSeq);
            setMDCode(productGroupList.filter(v => v.parentSeq === list.lgSeq));
            setSMCode(productGroupList.filter(v => v.parentSeq === list.mdSeq));
            setXSCode(productGroupList.filter(v => v.parentSeq === list.smSeq));
            const md = productGroupList.find(v => v.productGroupSeq == list.mdSeq)
            const sm = productGroupList.find(v => v.productGroupSeq == list.smSeq)
            const xs = productGroupList.find(v => v.productGroupSeq == list.xsSeq)
            setSelectedLGCode(String(list.lgSeq));
            setSelectedMDCode(md.productGroupSeq);
            if(sm){
                setSelectedSMCode(sm.productGroupSeq);
            }
            if(xs){
                setSelectedXSCode(xs.productGroupSeq);
            }

            ProductStore.setData({...list, ccSM: list.brandCode});
            setIsConsum(list.isConsumable === 1);
        }

    }, [productSeq]);

    /* product group 조회 -> 대분류 배열에 담아주기 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        setProductGroupList(result);
        const lgCode = result.filter(v => v.parentSeq === null);
        setLGCode(lgCode);
    }

    /* 대분류 클릭 시 중분류 담아주기 */
    const onChangeLGCode = (e) => {
        setSelectedLGCode(e.target.value);
        const mdCode = productGroupList.filter(v => v.parentSeq === Number(e.target.value));
        setMDCode(mdCode);
        setSMCode([]);
        setXSCode([]);

        //소모품인지 체크
        checkConsumable('l', e.target.value);
    }

    /* 중분류 클릭 시 소분류 담아주기 */
    const onChangeMDCode = (e) => {
        setSelectedMDCode(e.target.value);
        const smCode = productGroupList.filter(v => v.parentSeq === Number(e.target.value));
        setSMCode(smCode);

        //소모품인지 체크
        checkConsumable('m', e.target.value);
    }

    /* 소분류(제품명) 클릭 이벤트 */
    const onChangeSMCode = (e) => {
        setSelectedSMCode(e.target.value);
        const xsCode = productGroupList.filter(v => v.parentSeq === Number(e.target.value));
        setXSCode(xsCode);
    }

    /* 세분류 클릭 이벤트 */
    const onChangeXSCode = (e) => {
        setSelectedXSCode(e.target.value);
    }

    /* 공통코드 소분류 담아주기 */
    const onChangeCcSM = (v) => {
        ProductStore.data.ccSM = v.target.value;
    }

    // 사용여부 스위치 클릭 이벤트
    const handleSwitchChange = (e) => {
        const { checked } = e.target;
        ProductStore.data.isDeleted = checked ?  0 : 1;
    };

    /* 제품 등록 */
    const createProduct = async () => {
        // 필수값 확인
        if (!selectedLGCode || !ProductStore.data.productName || !ProductStore.data.model || !ProductStore.data.ccSM || !ProductStore.data.spec) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }

        if(selectedLGCode === '1' && (!selectedMDCode || !selectedSMCode)){
            showToast('대/중/소 분류를 모두 선택해 주세요.');
            return;
        }

        if(!await callConfirm('저장하시겠습니까?')) return;

        const xsProductGroupCodeList = xsCode.filter(v => v.productGroupSeq === Number(selectedXSCode)).map(v => v.productGroupCode);

        const productCode = lgCode
            .filter(v => v.productGroupSeq === Number(selectedLGCode))
            .map(v => v.productGroupCode)
            .concat(mdCode.filter(v => v.productGroupSeq === Number(selectedMDCode)).map(v => v.productGroupCode))
            .concat(smCode.filter(v => v.productGroupSeq === Number(selectedSMCode)).map(v => v.productGroupCode))
            .concat(LUtils.isEmpty(xsProductGroupCodeList) ? [0] : xsProductGroupCodeList)
            .join('');


        const params = {
            ...ProductStore.data,
            lgSeq: selectedLGCode,
            mdSeq: selectedMDCode,
            smSeq: selectedSMCode,
            xsSeq: selectedXSCode,
            brandCode: ProductStore.data.ccSM,
            productCode,
            isConsum: isConsum ? 1 : 0
        }

        if(productSeq) {
            await axios.put('/product', params);

        }else {
            await axios.post('/product', params);
        }

        showToast('저장되었습니다.');
        //초기화
        init();

        getProductLst && getProductLst();
        AppStore.toggleInsertProduct();
    }

    const init =()=> {
        ProductStore.data.productSeq = 0;
        // 모든 state 초기화
        /*setLGCode([]);
        setMDCode([]);
        setSMCode([]);
        setXSCode([]);*/
        setSelectedLGCode('');
        setSelectedMDCode('');
        setSelectedSMCode('');
        setSelectedXSCode('');
        setIsConsum(false);
    }

    const checkConsumable =(target, productGroupSeq)=> {
        if(target !== 'l' && target !== 'm') return;

        const targetCategory = target === 'l' ? lgCode : mdCode;
        const cate = targetCategory.find(v => v.productGroupSeq == productGroupSeq);

        if(cate.productGroupName.indexOf('소모품') > -1){
            setIsConsum(true);
        }else{
            target === 'l' && setIsConsum(false);
        }
    }

    return (
        <Modal
            title={'제품등록'}
            show={AppStore.isOpenInsertProduct}
            onHide={() => AppStore.toggleInsertProduct()}
            toggleValue={AppStore.isOpenInsertProduct}
            onExit={init}
        >
            {/* body */}
            <CContainer fluid>
                <Row>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'대분류'}
                            value={selectedLGCode}
                            onChange={onChangeLGCode}
                            options={[{value:0, name:'- 대분류 -'}].concat(lgCode.map(v => ({value: v.productGroupSeq, name: v.productGroupName})))}
                            labelClassName='input-required'
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'중분류'}
                            value={selectedMDCode}
                            onChange={onChangeMDCode}
                            options={[{value:0, name:'- 중분류 -'}].concat(mdCode.map(v => ({value: v.productGroupSeq, name: v.productGroupName})))}
                            labelClassName='input-required'
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'소분류'}
                            value={selectedSMCode}
                            onChange={onChangeSMCode}
                            options={[{value:0, name:'- 소분류 -'}].concat(smCode.map(v => ({value: v.productGroupSeq, name: v.productGroupName})))}
                            labelClassName={selectedLGCode === '1' && 'input-required'}
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'세분류'}
                            value={selectedXSCode}
                            onChange={onChangeXSCode}
                            options={[{value:0, name:'- 세분류 -'}].concat(xsCode.map(v => ({value: v.productGroupSeq, name: v.productGroupName})))}
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'제품명'} labelId={'productName'} placeholder={'제품명'} value={ProductStore.data.productName} onChange={(e) => ProductStore.data.productName = e.target.value} labelClassName='input-required' />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'모델명'} labelId={'model'} placeholder={'모델명'} value={ProductStore.data.model} onChange={(e) => ProductStore.data.model = e.target.value} labelClassName='input-required' />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'제조사'}
                            value={ProductStore.data.ccSM}
                            onChange={onChangeCcSM}
                            options={[{value:0, name:'- 제조사 -'}].concat(getCommcode('61'))}
                            labelClassName='input-required'
                            disabled={productSeq}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12}>
                        <CInputGroup label={'스펙'} labelId={'spec'} value={ProductStore.data.spec} onChange={(e) => ProductStore.data.spec = e.target.value} placeholder={'/ 로 구분하여 입력해주세요'} labelClassName='input-required'/>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6} style={{display: 'flex'}}>
                        <Form.Check
                            type="switch"
                            id="consum-switch"
                            label="소모품"
                            checked={isConsum}
                            onChange={()=> {
                                setIsConsum(!isConsum);
                            }}
                            disabled={productSeq}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'비고'} labelId={'remark'} value={ProductStore.data.remark} onChange={(e) => ProductStore.data.remark = e.target.value} />
                    </CCol>
                    <CCol lg={3}>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="사용여부"
                            checked={ProductStore.data.isDeleted===0?true:false}
                            onChange={handleSwitchChange}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn onClick={createProduct} />
                    </Col>
                </Row>
            </CContainer>
        </Modal>
    )
}

export default observer(InsertProduct);

import { observer } from "mobx-react-lite"
import { Row } from "react-bootstrap";
import { AppStore } from "../../../store/AppStore";
import { CCol, CContainer, CIconBtn, CInputGroup, Subject } from "../../../components/CustomContainer";
import { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import { MULTIPART_HEADER, USER_INFO } from "../../../common/constants";
import { dateKoreaFormat, oneMonthAfter, today } from "../../../utils/dateUtils";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { decrypt, encrypt, showToast } from "../../../common/utils";
import { NonpaymentStore } from "../../../store/billing/NonpaymentStore";
import { callConfirm, getLocalStorage } from "../../../utils";
import LUtils from "../../../utils/lodashUtils";
import AgGridContainer from "../../../components/AgGridContainer";
import { findCommKrnm } from "../../../utils/commcode";

const SendCertificationContentPopup = ({getCertificateonContentHistoryList}) => {
    const gridRef = useRef();
    const [emailSubject, setEmailSubject] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [billingAddressList, setBillingAddressList] = useState([]);
    const [receiverName, setReceiverName] = useState('');
    const [receiverEmail, setReceiverEmail] = useState('');
    const userInfo = getLocalStorage(USER_INFO);

    useEffect(() => {
        getBillingAddressList();
    }, []);

    const groupByContractList = LUtils.groupBy(NonpaymentStore.customerNonpaymentList, 'contractNo');
    const contractListRows = Object.keys(groupByContractList).map((v, i) => {
        return (
            `<tr key=${i}>
                <td align='center' class='border' style="font-size: 11px">${i+1}</td>
                <td align='center' class='border' style="font-size: 11px">${groupByContractList[v][0].contractDate}</td>
                <td align='left' class='border' style="font-size: 11px">${groupByContractList[v][0].contractNo}</td>
                <td align='left' class='border' style="font-size: 11px">${groupByContractList[v][0].productName}</td>
            </tr>`
        )
    })

    const getBillingAddressList = async() => {
        const result = await axios.get("/nonpayment/billingAddress/list", {params: {customerNo: NonpaymentStore.data.customerNo,
                                                                                isActive: 1}});
        setBillingAddressList(result.map(v => {return {...v, isCreated: false}}));
    }

    const makeCertificationContent = async () => {
        // 필수값 확인
        if(emailSubject == '' || emailContent == '') {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        if (!await callConfirm('내용증명을 발송하시겠습니까?')) {
            return;
        }

        const selectedList = billingAddressList.filter(v => v.isSelected);
        let formData = new FormData();
        let fileName = dateKoreaFormat(today()) + "_내용증명.pdf";
        let emailStatus = true;

        formData.append("subject", emailSubject);
        formData.append("content", emailContent);
        formData.append("customerNo", NonpaymentStore.data.customerNo);

        selectedList.map((v, i) => {
            formData.append("receiverList[" + i + "].email", v.email);
            formData.append("receiverList[" + i + "].name", v.managerName);
            if(v.email !== null && v.email !== '') {
                emailStatus = true;
            } else {
                emailStatus = false;
            }
        })

        formData.append("fileHtml", NonpaymentStore.htmlString);

        if (!emailStatus) {
            showToast('청구지의 이메일 주소가 존재하지 않습니다.');
            return;
        }

        let content = `<!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Document</title>
                                <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
                                <style>
                                    .print-template th, .print-template td {
                                        border-color: #a19f9f !important;
                                        padding: 10px !important;
                                    }
                                    .bg-gray {
                                        background-color: #e3e3e3 !important;
                                    }
                                    .contract-list th, .contract-list td{
                                        padding-top: 5px !important;
                                        padding-bottom: 5px !important;
                                    }
                                </style>
                            </head>
                            <body>
                                <div style="padding: 20px;">
                                    <div class='print-template'>
                                        <table class="table" style="border-width: 1px;" height="80px">
                                            <thead>
                                                <tr>
                                                    <th class='border' valign="middle" style="text-align: center; font-size: 22px; font-weight: bold">
                                                        내 용 증 명
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <table class="table" style="border-width: 1px;" height='80px'>
                                            <tbody>
                                                <tr >
                                                    <td class='p-1 border bg-gray' valign="middle" style="width: 16%; text-align: center; font-size: 13px">발신인</td>
                                                    <td class='p-1 border' valign="middle" style="padding: 10px; font-size: 13px">${userInfo.companyName}</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border bg-gray' valign="middle" style="width: 16%; text-align: center; font-size: 13px">수신인</td>
                                                    <td class='p-1 border' valign="middle" style="padding: 10px; font-size: 13px">${NonpaymentStore.data.customerName}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table" style="border-width: 1px" height="40px">
                                            <tbody>
                                                <tr>
                                                    <td class='p-1 border bg-gray' valign="middle" style="width: 16%; text-align: center; font-size: 13px">제목</td>
                                                    <td class='p-1 border' valign="middle" style="padding: 10px; height: 20px; text-align: left; font-size: 13px">장비 임대료 청구</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table" style="border-width: 1px">
                                            <tbody>
                                                <tr>
                                                    <td class='p-1 border bg-gray' style="width: 16%; text-align: center; font-size: 13px">내용</td>
                                                </tr>
                                                <tr height="600px">
                                                    <td class='p-1 border' style="font-size: 13px;">
                                                        1. 귀사(수신인, 이하 '귀사'라고 한다)의 무궁한 발전을 기원합니다.
                                                        <br /><br />
                                                        2. 귀사는 아래와 같이 본 본사와의 임대차계약에 따라 본 발신인에게 차임 지급 의무가 있습니다. 그러나 귀사는 본사의 수차례에 걸친 독촉에도 불구하고 여전히 차임 지급 의무를 불이행하고 있는 바, 이에 지급 이행을 촉구합니다.
                                                        <br />
                                                        &nbsp; 가. 본사는 귀사와 다음과 같은 계약에 관하여 임대차계약을 체결하였습니다.
                                                        <br />
                                                        <table class='table contract-list' align='center' style="width: 80%; margin-top: 10px" >
                                                            <tbody>
                                                                <tr>
                                                                    <td class='border bg-gray' style="width: 7%; text-align: center; font-size: 11px; padding: 0"></td>
                                                                    <td class='border bg-gray' style="width: 17%; text-align: center; font-size: 11px; padding: 0">계약일자</td>
                                                                    <td class='border bg-gray' style="width: 20%; text-align: center; font-size: 11px; padding: 0">계약번호</td>
                                                                    <td class='border bg-gray' style="width: 56%; text-align: center; font-size: 11px; padding: 0">장비</td>
                                                                </tr>
                                                                ${contractListRows.join('')}
                                                            </tbody>
                                                        </table>
                                                        <br />
                                                        &nbsp; 나. 귀사는 본 계약에 따라 임대차 장비를 사용하고 있음에도 불구하고 현재까지 합계 ${NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원의 차임을 지급하고 있지 않습니다.
                                                        <br />
                                                        &nbsp; 다. 따라서 귀사는 본사에게 밀린 차임 및 기타 미지급 내역의 합계 ${NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원을 지급해야 할 의무가 있는 바, ${dateKoreaFormat(oneMonthAfter(today()))}자 까지 본사의 계좌로 밀린 차임을 지급할 것을 촉구합니다.
                                                        <br /><br />
                                                        3. 귀사가 위와 같은 내용을 이행하지 않을 때는 본 발신인은 귀사의 재산에 대하여 민사집행법 제276조 이하 등에 따른 가압류 등 보전 처분 및 민사소송법 등에 따른 차임 지급 청구 소송 등의 법적 조치를 할 것을 엄중히 경고합니다. 
                                                        이 경우, 귀사는 차임 지급 의무에 따른 원금, 이자 및 지연 손해금은 물론, 소송 비용까지 귀사가 부담하게 될 것입니다.
                                                        게다가 본사가 소송을 제기하면 지연 손해금은 소송 촉진 등에 관한 특례법 제3조 제1항 본문의 법정이율에 관한 규정에 따라 연 12%가 될 것입니다.
                                                        <br/><br/>
                                                        <div style="text-align: center">
                                                            <span>
                                                                • 입금 계좌 : ${findCommKrnm(NonpaymentStore.bankCode, '24')} ${NonpaymentStore.accountNumber} ${NonpaymentStore.accountHolder}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table" align='right' style="border-width: 1px; width: 40%;" height='80px'>
                                            <tbody>
                                                <tr>
                                                    <td class='p-1 border bg-gray' valign="middle" style="text-align: center; font-size: 13px">작성일자</td>
                                                    <td class='p-1 border' valign="middle" style="font-size: 13px">${dateKoreaFormat(today())}</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border bg-gray' valign="middle" style="text-align: center; font-size: 13px">발신인</td>
                                                    <td class='p-1 border' valign="middle" style="font-size: 13px">${userInfo.name}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </body>
                        </html>`

        const options = {
            fileName: fileName,
            image: {type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }

        const body = document.querySelector('body');

            body.style.lineHeight = '0.5';

            const pdfBlob = await html2pdf().set(options).from(content).toPdf().output('blob').then(data => {
                return data
            });
            await deleteStyle(body);

            const blob = new Blob([pdfBlob], {type: 'application/pdf'});
            const file = new File([blob], fileName);
            formData.append('file', file);

        await axios.post('/nonpayment/send/certificationContent', formData, { headers: MULTIPART_HEADER })
        showToast('메일을 발송하였습니다.');
        AppStore.toggleSendCertificationContentPopup();
        getCertificateonContentHistoryList();
    }

    const deleteStyle = async (body) => {
        body.style.lineHeight = 'normal';
    }

    const refresh = () => {
        setEmailSubject('');
        setEmailContent('');
        setBillingAddressList(billingAddressList.filter(v => !v.isCreated).map(v => {return {...v, isSelected: false}}));
    }

    const addReceiver = () => {
        if (receiverName === '' || receiverEmail === '') {
            showToast('수신자명과 이메일 주소를 모두 작성해주세요.');
            return;
        }

        let resultList = billingAddressList;
        resultList.push({addRowId: billingAddressList.length + 1, billingAddressSeq: '추가', managerName: receiverName, email: encrypt(receiverEmail), isSelected: true, isCreated: true});
        setBillingAddressList(resultList);

        setReceiverName('');
        setReceiverEmail('');
    }

    return (
        <Modal toggleValue={AppStore.isOpenSendCertificationContentPopup}
                onHide={()=> AppStore.toggleSendCertificationContentPopup()}
                title={'내용 증명 전송'}
                className='modal-w9'
                onExit={refresh}>
            <CContainer search>
                <Row>
                    <AgGridContainer
                        gridRef={gridRef} 
                        gridTitle={'고객 청구지 목록'}
                        rowData={billingAddressList}
                        height={25}
                        columnDefs={[
                            {field: "billingAddressSeq", headerName: "순번", width: 75},
                            {field: "billingAddressName", headerName: "청구지명", width: 140},
                            {field: "managerName", headerName: "담당자명", width: 120},
                            {field: "email", headerName: "이메일", flex:1,  width: 140, valueFormatter: v => v.value ? decrypt(v.value) : ""},
                            {field: "phoneNumber", headerName: "핸드폰번호", width: 140, valueFormatter: v => v.value ? decrypt(v.value) : ""},
                        ]}
                        isCheckBox={true}
                    />
                </Row>
                <Subject>수신인 추가</Subject>
                <Row>
                    <CCol lg={5}>
                        <CInputGroup
                            label={'수신자명'}
                            labelId={'receiverName'}
                            value={receiverName}
                            onChange={v => setReceiverName(v.target.value)}
                            placeholder={'ex. 홍길동'}
                            enterKeyHint={addReceiver}
                            labelClassName={'input-required'}
                        />
                    </CCol>
                    <CCol lg={5}>
                        <CInputGroup
                            label={'이메일'}
                            labelId={'receiverEmail'}
                            value={receiverEmail}
                            onChange={v => setReceiverEmail(v.target.value)}
                            placeholder={'ex. hgd@email.com'}
                            enterKeyHint={addReceiver}
                            labelClassName={'input-required'}
                        />
                    </CCol>
                    <CCol lg={2} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                        <CIconBtn style={{width: 110, height: 30}}
                            title={'추가'}
                            onClick={addReceiver}
                            variant={'success'}
                        />
                    </CCol>
                </Row>
                <Row style={{marginTop: 30}}>

                    <span style={{fontSize: 17, fontWeight: '500', color: '#5f6975', marginBottom: 2}}>
                        <i className="fi fi-rr-envelope" style={{fontSize: 15, fontWeight: '500', color: '#5f6975'}} />&nbsp;&nbsp;메일 작성
                    </span>
                    <CCol lg={12}>
                        <CInputGroup label={'제목'}
                                    labelId={'subject'}
                                    placeholder={'제목을 작성해주세요.'}
                                    value={emailSubject}
                                    onChange={v => setEmailSubject(v.target.value)}
                        />
                    </CCol>
                    <CCol lg={12}>
                        <textarea className="form-control" rows="8"
                            value={emailContent}
                            placeholder={'내용을 자유롭게 작성해주세요.'}
                            readOnly={false}
                            onChange={v => setEmailContent(v.target.value)}
                        />
                    </CCol>
                    <CCol lg={12} className="d-flex flex-row-reverse">
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'전송'}
                                icon={'fi fi-rs-paper-plane'}
                                onClick={makeCertificationContent}
                                variant={'primary'}
                        />
                    </CCol>
                </Row>
            </CContainer>
        </Modal>
    )

}

export default observer(SendCertificationContentPopup);
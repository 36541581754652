import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppStore } from '../../store/AppStore';
import { AsStore } from '../../store/asset/AsStore';
import { callAlert } from '..';

const GuestGuard = ({ children }) => {
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        // if (AppStore.isLogin && !params.contractNo && !params.shipSeq) {
        //     if (AsStore.data.asSeq) {
        //         navigate(`/as/accept/detail/${AsStore.data.asSeq}`, { replace: true });
        //     } else {
        //         navigate('/dashboard', { replace: true });
        //     }
        // }else if(!params.contractNo && !params.shipSeq) {
        //     if (!AsStore.data.asSeq) {
        //         navigate('/login', { replace: true });
        //     }
        // }
    }, [navigate]);

    return children;
};

export default GuestGuard;

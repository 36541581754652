import { useRef } from "react";
import { observer } from "mobx-react-lite";
import { CContainer } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { RentalListStore } from "../../store/contract/RentalListStore";
import axios from "axios";
import { getUserName } from "../../utils/userUtils";
import RentalListSearch from "./RentalListSearch";
import {findCommKrnm} from "../../utils/commcode";
import {showToast} from "../../common/utils";

const RentalList = () => {
    const gridRef = useRef();

    /* 조회 */
    const getRentalList = async () => {
        const searchCondition = Object.values(RentalListStore.searchCondition);
        let inputCount = 0;
        for(const value of searchCondition){
            if(value !== '' && value !== null){
                inputCount++;
            }
        }

        if(inputCount === 0){
            showToast('검색 조건을 1가지 이상 입력해주세요.');
            return;
        }

        const result = await axios.get('/contractAsset/status/list', {params: RentalListStore.searchCondition });
        RentalListStore.setRentalList(result);
    }

    const pinnedTopRowData = [{
        monthRentPrice: RentalListStore.rentalList.reduce((total, v) => total + v.monthRentPrice, 0),
        dayRentPrice: RentalListStore.rentalList.reduce((total, v) => total + v.dayRentPrice, 0),
        totalRentPrice: RentalListStore.rentalList.reduce((total, v) => total + v.totalRentPrice, 0),
        chargeAmount: RentalListStore.rentalList.reduce((total, v) => total + v.chargeAmount, 0),
        chargeVat: RentalListStore.rentalList.reduce((total, v) => total + v.chargeVat, 0),
        chargeTotalAmount: RentalListStore.rentalList.reduce((total, v) => total + v.chargeTotalAmount, 0),
    }];

    return(
        <>
            {/* 검색조건 */}
            <RentalListSearch getRentalList={getRentalList}/>
            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'계약 자산 목록'}
                    gridRef={gridRef}
                    height={65}
                    rowData={RentalListStore.rentalList}
                    columnDefs={[
                        {field: "contractNo", headerName: "계약번호", width: 160},
                        {field: "contractType", headerName: "계약구분", width: 110, valueFormatter: v => findCommKrnm(v.value, '1')},
                        {field: "customerName", headerName: "고객명", width: 140},
                        {field: "billingName", headerName: "청구지", width: 120},
                        {field: "contractSeq", headerName: "계약회차", width: 110},
                        {field: "assetNo", headerName: "자산번호", width: 110, hide: true},
                        {field: "managementNo", headerName: "관리번호", width: 120},
                        {field: "model", headerName: "모델", width: 140},
                        {field: "contractStatus", headerName: "변경구분", width: 110, valueFormatter: v => findCommKrnm(v.value, '2')}, //1:신규, 2:연장, 3:단축, 4:교환, 5:단가변경
                        {
                            field: "assetStatus", 
                            headerName: "자산상태", 
                            width: 110,
                            cellClass: v =>  v.data.storageDate ? 'ag-grid-column-cancel' : 'ag-grid-column-complete'
                        },
                        {field: "monthRentPrice", headerName: "월렌탈료", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                        {field: "dayRentPrice", headerName: "일렌탈료", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                        {field: "totalRentPrice", headerName: "총렌탈료", width: 140, hide:true, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                        {field: "rentMonth", headerName: "대여월", width: 100},
                        {field: "rentDays", headerName: "대여일", width: 100},
                        {field: "orgContractStartDate", headerName: "원개시일자", width: 130},
                        {field: "contractStartDate", headerName: "변경개시일", width: 130},
                        {field: "contractEndDate", headerName: "계약종료일", width: 130},
                        {field: "chargeAmount", headerName: "청구금액", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                        {field: "chargeVat", headerName: "청구부가세", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                        {field: "chargeTotalAmount", headerName: "청구총액", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                        {field: "storageDate", headerName: "입고일자", width: 130},
                        {field: "storageNo", headerName: "입고번호", width: 110},
                        {field: "managerName", headerName: "담당자", width: 110, hide: true},
                        {field: "saleUserSeq", headerName: "영업담당자", width: 120, valueGetter: (params) => getUserName(params.data.creator) },
                        {field: "creator", headerName: "변경자", valueGetter: (params) => getUserName(params.data.creator), hide:true },
                        {field: "createdDate", headerName: "변경일자", minWidth: 180, hide:true},
                    ]}
                    isCheckBox={true}
                    seqColumn={'assetNo'}
                    useCsvDownload={true}
                    pinnedTopRowData={pinnedTopRowData}
                />
            </CContainer>
        </>
    );
}

export default observer(RentalList);

import { observer } from "mobx-react-lite"
import { useRef, useState } from "react";
import axios from "axios";

import { CContainer, CCol, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import AgGridContainer from "../../../components/AgGridContainer";
import { BillSearchStore } from "../../../store/billing/BillSearchStore";
import { decrypt, showToast } from "../../../common/utils";


const SendTradingStatementPopup = ({sendTradingStatement}) => {
    const gridRef = useRef();
    const mailGridRef = useRef();
    const store = BillSearchStore;
    const [index, setIndex] = useState(null);
    // const [billNo, setBillNo] = useState('');   // store로 변경

    const refresh = () => {
        store.sendEmailInit();
    }

    // const fileRenderer = (e) => {
    //     return (
    //         <a target={'_blank'} style={{paddingLeft: 4}} href={e.data.contractDocUrl}>
    //             {e.data.contractDocFileName}
    //         </a>
    //     )
    // }

    const popupTradingStatement = (e) => {
        return (
            <>
                {!e.data.code ? (
                    <div className='d-flex justify-content-between'>
                        <CIconBtn onClick={() => handleEvent(e.data.billNo)} title={'거래명세서 저장필요'} className={'ms-1 me-1'} />
                    </div>
                ):(
                    <div className='text-center'>Y</div>
                )
                }
            </>
        )
    }

    const handleEvent = (billNo) => {
        store.billNo = billNo;
        
        const selectedList = BillSearchStore.sendTradingStatementList.filter(v => v.billNo === billNo);
        BillSearchStore.amount = selectedList[0].billAmount;
        BillSearchStore.vat = selectedList[0].billVat;
        BillSearchStore.totalAmount = selectedList[0].billTotalAmount;

        AppStore.toggleModal();
    }

    /* 메일 전송 버튼 */
    const sendEmailBtn = () => {
        store.sendTradingStatementList.some(item => !item.code)
        if(store.sendTradingStatementList.some(item => !item.code)) {
            showToast('거래명세서 저장이 필요합니다.');
            return;
        }
        let isEmailValid = true; // 이메일이 유효한지 여부를 나타내는 변수
        
        for (let i = 0; i < store.sendTradingStatementList.length; i++) {
            const v = store.sendTradingStatementList[i];
            if (!store.emailList[v.billNo] || store.emailList[v.billNo].length < 1) {
                showToast('계산서 별 수신 이메일 확인이 필요합니다.');
                isEmailValid = false;
                break; 
            }
        }

        if (isEmailValid) sendTradingStatement();
    }


    /* 청구지, 현장명으로 메일 주소 담기 */
    const getEmailList = async(billNo, customerNo, billingAddressSeq, v) => {
        setIndex(v.rowIndex);
        store.sendTradingStatementList[v.rowIndex].subject = `[${v.data.customerName}] ${v.data.billDate.slice(0, -3)} ${v.data.companyName} 거래 명세서`;
        store.sendTradingStatementList[v.rowIndex].content = `안녕하세요 ${v.data.customerName}님\n\n${v.data.billDate.slice(0, -3)} 거래 명세서를 전달 드리오니\n검토해 주시기 바랍니다.\n\n감사합니다.`;
        store.emailBillNo = billNo;
        if(!store.emailList[billNo] || store.emailList[billNo].length < 1) {
            const result = await axios.get(`/bill/email/${customerNo}/${billingAddressSeq}`);
            store.emailList[billNo] = result.filter(v => decrypt(v.email) !== '').map(v => {return {...v, isSelected: true}});
        }
    }

    return (
        <>
            <Modal title={'거래명세서 메일 전송'}
                onHide={() => AppStore.toggleSendTradingStatementPopup()}
                toggleValue={AppStore.isOpenSendTradingStatementPopup}
                onExit={refresh}
                size={'xl'}
            >
                <CContainer>
                    <p>거래 명세서 목록에서 명세서를 더블클릭하면 메일 수신 대상자를 확인할 수 있습니다.</p>
                    {store.sendTradingStatementList.length > 1 && 
                            <AgGridContainer
                                gridRef={gridRef}
                                gridTitle={'거래명세서목록'}
                                height={23}
                                rowData={store.sendTradingStatementList}
                                columnDefs={
                                    [
                                        {field: "billNo", headerName: '계산서번호', width: 140},
                                        {field: "billDate", headerName: "계산서발행일", width: 120},
                                        {field: "customerName", headerName: "고객명", width: 140},
                                        {field: "billingAddressName", headerName: "청구지명", width: 140},
                                        {field: "billTotalAmount", headerName: "총 발행 금액", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "code", headerName: "거래명세서 확인", cellRenderer: (e) => popupTradingStatement(e), width: 180},
                                        // {field: "receiverEmail", headerName: '이메일', valueFormatter: v => v.value ? decrypt(v.value) : '', width: 200},
                                    ]
                                }
                                rowDoubleClickCallback={v => getEmailList(v.data.billNo, v.data.customerNo, v.data.billingAddressSeq, v)}
                            />
                    }
                    <Row className="mt-3">
                        <CCol lg={6}>
                            <CCol lg={12}>
                                <CInputGroup label={'제목'}
                                            labelId={'subject'}
                                            placeholder={'제목을 작성해주세요.'}
                                            value={store.sendTradingStatementList.subject ? store.sendTradingStatementList.subject : store.sendTradingStatementList[index]?.subject}
                                            onChange={v => store.sendTradingStatementList.subject ? store.sendTradingStatementList.subject = v.target.value : store.sendTradingStatementList[index].subject = v.target.value}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <textarea className="form-control" rows="8"
                                    value={store.sendTradingStatementList.content ? store.sendTradingStatementList.content : store.sendTradingStatementList[index]?.content}
                                    placeholder={'내용을 자유롭게 작성해주세요.'}
                                    readOnly={false}
                                    onChange={v => store.sendTradingStatementList.content ? store.sendTradingStatementList.content = v.target.value : store.sendTradingStatementList[index].content = v.target.value}
                                />
                            </CCol>
                            {/* <CCol lg={12}>
                                <CInputGroup label={'제목'}
                                            labelId={'subject'}
                                            placeholder={'제목을 작성해주세요.'}
                                            value={store.subject}
                                            onChange={v => store.subject = v.target.value}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <textarea className="form-control" rows="8"
                                    value={store.content}
                                    placeholder={'내용을 자유롭게 작성해주세요.'}
                                    readOnly={false}
                                    onChange={v => store.content = v.target.value}
                                />
                            </CCol> */}
                            {/* <CCol lg={12}>
                                <CInputGroup label={'받는사람'}
                                            labelId={'receiverEmail'}
                                            placeholder={'sample@naver.com'}
                                            value={store.receiverEmail}
                                            onChange={v => store.receiverEmail =v.target.value}
                                />
                            </CCol> */}
                        </CCol>
                        <CCol lg={6}>
                            <AgGridContainer
                                gridRef={mailGridRef}
                                rowData={store.emailList[store.emailBillNo]}
                                gridTitle={'메일 전송 목록'}
                                height={19}
                                columnDefs={[
                                    {field: "title", headerName: "구분", width: 140},
                                    {field: "name", headerName: "담당자명"},
                                    {field: "email", headerName: "메일주소", valueFormatter: v => v.value ? decrypt(v.value) : '', flex: 1},
                                ]}
                                isCheckBox={true}
                            />
                        </CCol>
                    </Row>
                    {/* <Row style={{marginBottom: 20, color: 'red'}}>
                        <Col>* 받는 사람은 고객 청구지와 현장 담당자 청구지 이메일로 자동 전송됩니다. </Col>
                    </Row> */}
                    <Row>
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 100, height: 30}}
                                    title={'메일 전송'}
                                    icon={'fi-rr-file-invoice-dollar'}
                                    onClick={sendEmailBtn}
                                    variant={'dark'}
                            />
                        </Col>
                    </Row>
                </CContainer>
            </Modal>
        </>
    );
}

export default observer(SendTradingStatementPopup);
import {useState, useRef, useEffect} from "react";
import { observer } from "mobx-react-lite";
import { Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import {CCol, CInputGroup, CSaveBtn, CSelectGroup, Subject, CIconBtn} from "../../../components/CustomContainer";
import { ContractDetailStore } from "../../../store/contract/ContractDetailStore";
import {getCommcode} from "../../../utils/commcode";
import { callAlert, callConfirm } from "../../../utils";
import {getDays} from "../../../utils/dateUtils";
import AgGridContainer from "../../../components/AgGridContainer";
import LUtils from "../../../utils/lodashUtils";
import CustomerBillingAddressSearch from "../../../components/searchModal/CustomerBillingAddressSearch";
import { AppStore } from "../../../store/AppStore";
import { CommcodeSMOptions } from "../../../components";
import {
    ICON_TRASH,
    IS_CREATED,
    MAINTENANCE_MONTH,
    MULTIPART_HEADER,
    OPTION_LEVEL,
    OPTION_QTY,
    OPTION_USE
} from "../../../common/constants";
import contractUtils from "../contractUtils";
import Modal from "../../../components/Modal";
import ContractAssetSearch from "../../../components/searchModal/ContractAssetSearch";
import ReleaseAskDetail from "../../release/ReleaseAskDetail";
import {ReleaseAskDetailStore} from "../../../store/release/ReleaseAskDetailStore";
import { toJS } from 'mobx';
import { IconBtn } from "../../../components/CustomContainer";
import {showToast, insertComma, removeComma} from "../../../common/utils";
import CustomerManagerSearch from "../../../components/searchModal/CustomerManagerSearch";
import { getLocalStorage } from "../../../utils";
import { USER_INFO } from "../../../common/constants";
import StockProductSearch from "../../../components/searchModal/StockProductSearch";
import ConsumablesSearch from "../../../components/searchModal/ConsumablesSearch";
import { ContractTabsStore } from "../../../store/contract/ContractTabsStore";

const ContractInfo = ({ searchContract, searchContractProduct, searchProductConsum, contractEndDate, setTitle }) => {
    const gridRef = useRef();
    const contractProductRef = useRef();
    const consumGridRef = useRef();
    const consumRef = useRef();

    const [modifyFile, setModifyFile] = useState(false);
    const [checkRowProductList, setCheckRowProductList] = useState([]);
    const [returnExpectDate, setReturnExpectDate] = useState({startDate: ContractDetailStore.returnExpectDate ? ContractDetailStore.returnExpectDate : contractEndDate});
    const userInfo = getLocalStorage(USER_INFO);
    const [stockSearchType, setStockSearchType] = useState('');
    const [btnStatus, setBtnStatus] = useState('add');
    const [isFixed, setIsFixed] = useState(false);
    const [gridData, setGridData] = useState([]);

    useEffect(()=> {
        setIsFixed(ContractDetailStore.isFix === 1 ? true: false);
        setReturnExpectDate({startDate: ContractDetailStore.returnExpectDate ? ContractDetailStore.returnExpectDate : contractEndDate});
        ContractDetailStore.isReleaseAskBtn=true;
    }, [ContractDetailStore.contractProductList, contractEndDate])


    /* 계약저장 */
    const saveContract = async () => {
        const saveColumn = ['contractType','customerNo', 'saleUserSeq', 'supportUserSeq', 'rentMonth','rentDays',
                            'contractDate','contractStartDate','contractEndDate','billingAddressSeq','contractDocType','contractStartType','installPlace','isPenaltyFee','penaltyPercent',
                            'isExtendRate','extendFirstRate','extendSecondRate','isRepairCost', 'isInstallPrice', 'installPrice', 'specialRemark','billingType','billingDay','dueDateType','dueDateDay','paymentType',
                            'deposit','endDate','isReverseBill','isFreeAssignment','isVirtualRelease', 'returnExpectDate', 'contractDocUrl', 'contractDocFileName', 'isDeliveryPrice', 'deliveryPrice',
                            'chargeDelivery', 'deliveryKind', 'sellType','firstBillingType','truncationUnit'];
        const mustInputColumn = ['customerNo', 'saleUserSeq', 'contractDate','contractStartDate', 'billingAddressSeq'];

        //회수예정일세팅
        // ContractDetailStore.setContractDetail(returnExpectDate.startDate, 'returnExpectDate');

        // 데이터 null 값 제거
        ContractDetailStore.deposit = ContractDetailStore.deposit || 0;
        ContractDetailStore.rentMonth = ContractDetailStore.rentMonth || 0;
        ContractDetailStore.rentDays = ContractDetailStore.rentDays || 0;
        //금액 숫자로 변경
        ContractDetailStore.deliveryPrice = removeComma(ContractDetailStore.deliveryPrice);
        ContractDetailStore.installPrice = removeComma(ContractDetailStore.installPrice);
        ContractDetailStore.assignmentAmount = removeComma(ContractDetailStore.assignmentAmount);
        ContractDetailStore.deposit = removeComma(ContractDetailStore.deposit);

        const params = LUtils.pick(ContractDetailStore, saveColumn);

        // validate check
        if(params.contractType === '1' || params.contractType === '2'){
            if (!!params.isPenaltyFee && !params.penaltyPercent) {
                showToast('위약금을청구 하는 경우에 위약금율을 반드시 입력 해야 합니다.');
                return false;
            }

            if (!!params.isExtendRate && !LUtils.some([!!params.extendFirstRate, !!params.extendSecondRate])) {
                showToast('연장할인을 하는 경우 요율을 반드시 입력 해야 합니다.');
                return false;
            }
        }

        if (!params.rentMonth && !params.rentDays) {
            showToast('계약기간은 반드시 입럭되어야 합니다.');
            return false;
        }

        if (params.rentMonth === '0' && params.rentDays === '0') {
            showToast('계약기간이 1일 이상이어야 합니다.');
            return false;
        }

        if(ContractDetailStore.contractType === '4' && !ContractDetailStore.sellType){
            showToast('매각구분을 반드시 선택해야 합니다.');
            return false;
        }


        if (!LUtils.every(LUtils.values(LUtils.pick(params, mustInputColumn)))) {
            showToast('필수값은 모두 입력 해야 합니다.');
            return false;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        let result = null;
        if(ContractDetailStore.contractNo){
            result = await axios.put(`/contract/${ContractDetailStore.contractNo}`, params);
            const updatedList = ContractDetailStore.contractProductList.filter(v => v.isUpdated);

            if(updatedList.length > 0) {
                await axios.put(`/contract/product/${ContractDetailStore.contractNo}`, { contractProductList: updatedList });
            }
        }else{
            result = await axios.post('/contract', params);
        }

        LUtils.forOwn(result, (value, key) => {
            ContractDetailStore.setContractDetail(value === 0 ? 0 : value || '', [key]);
        });

        // 계약 조회 및 계약제품 목록 조회
        Promise.all([searchContract(ContractDetailStore.contractNo), searchContractProduct(ContractDetailStore.contractNo)]);
        showToast('저장되었습니다.');
    }

    const onCreateCancel = () => {
        ContractDetailStore.init();
        showToast('초기화 되었습니다.');
    }

    const onUpdateCancel = () => {
        // 초기화 하고 다시 조회
        const contractNo = ContractDetailStore.contractNo;
        ContractDetailStore.init();
        ContractDetailStore.setContractDetailMode('read')
        searchContract(contractNo);
    }

    /* 청구지 조회 팝업 오픈 */
    const openSearchBillingAddressPopup = () => {
        AppStore.toggleCustomerBillingAddressSearchModal()
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        ContractDetailStore.setContractDetail(info.billingAddressSeq, 'billingAddressSeq');
        ContractDetailStore.setContractDetail(info.billingAddressName, 'billingAddressName');
    }

    /* 커스텀 버튼 옵션 - 제품추가 */
    const customAddBtnInfo = [
        {
            isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
            callbackFn: () => AppStore.toggleStockProductSearchModal(),
            title: '제품추가',
            width: 100,
            icon: 'fi-rr-add'
        }
    ];

    /* 매각 자산 추가 */
    const saleAssetBtnInfo = [
        {
            isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
            callbackFn: () => AppStore.toggleContractAssetSearchModal(),
            title: '자산조회',
            icon: 'fi-rr-add'
        }
    ];

    /* 선택 버튼 옵션 - 삭제 */
    const selectBtnInfo = {
        isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
        title: '선택 삭제',
        callbackFn: e => removeSelectedRows(e),
        icon: ICON_TRASH
    };

    /* 선택 버튼 옵션 - 삭제 */
    const optionSelectBtnInfo = {
        isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
        title: '선택 삭제',
        callbackFn: e => removeOptionSelectedRows(e),
        icon: ICON_TRASH
    };

    const customOptionAddBtnInfo = [
        {
            isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
            callbackFn: () => {
                const productList = ContractDetailStore.contractProductList;
                const checkRows = productList.filter(v => v.isSelected);
                if(checkRows.length === 0){
                    showToast('상단 계약제품에서 옵션을 추가할 장비를 선택해 주세요.');
                    return;
                }
                setCheckRowProductList(checkRows);
                setStockSearchType('O');
                AppStore.toggleStockAssetSearchModal()
            },
            title: '옵션추가',
            icon: 'fi-rr-add',
            width: 100
            //color: '#FE2E64'
        }
    ];

    const addRowInfo = {contractNo: ContractDetailStore.contractNo, seq: '', productCode: '', productName: '', productSeq:'', model: '', qty: '',
                        rentMonth: ContractDetailStore.rentMonth, rentDays: ContractDetailStore.rentDays,
                        monthRentPrice: '', dayRentPrice: '', lastMonthPrice: '', totalRentPrice: '', productSalePrice: '', assetSalePrice: '', totalSalePrice: '',
                        remark: '', storageNo: '', ifrsCode: '', availableQty: ''};

    /* 제품 추가 콜백 함수 */
    const callbackStockProductSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        if(btnStatus === 'row') {
            const data = info[0];
            const result = ContractDetailStore.contractProductList.map(v => {
                        if (v.seq === gridData.data.seq) {
                            v.assetNo = data.assetNo;
                            v.storageNo = data.storageNo;
                            v.productSeq = data.productSeq
                            v.productCode = data.productCode
                            v.productName = data.productName
                            v.model = data.model
                            v.availableQty = data.rentAvailableCnt !== undefined ? data.rentAvailableCnt : data.stockQty !== undefined ? data.stockQty : 1;
                            LUtils.assign(v, {isUpdated: true });
                        }
                        return v;
                    });
            ContractDetailStore.setContractProductList(result);
        }else {
            // 동일한 제품코드가 있는지 체크 -> 이 로직은 제거한다. 2023.09.15
/*
            for(let data of info){
                for(let row of ContractDetailStore.contractProductList){
                    if(data.productCode === row.productCode){
                        showToast('같은 제품코드가 이미 추가되어 있습니다.');
                        return;
                    }
                }
            }
*/
            // 동일한 자산인 경우 제외하기
            for(let data of info){
                for(let row of ContractDetailStore.contractProductList){
                    console.log(row)
                    if(row.assetNo && data.assetNo === row.assetNo){
                        showToast('같은 자산이 이미 추가되어 있습니다.');
                        return;
                    }
                }
            }

            let seq = ContractDetailStore.contractProductList.length + 1;
            LUtils.forEach(info, k => {
                //const additionalRowInfo = {seq: null, ...LUtils.pick(k, ['storageNo', 'productSeq', 'productCode', 'productName', 'model', 'ifrsCode', 'remark', 'availableQty'])}
                //contractProductRef.current.addRow(LUtils.assign(addRowInfo, additionalRowInfo));
                const additionalRowInfo = {
                    seq: seq++
                    ,assetNo: k.assetNo
                    ,storageNo: k.storageNo
                    ,productSeq: k.productSeq
                    ,productCode: k.productCode
                    ,productName: k.productName
                    ,model: k.model
                    ,availableQty: k.rentAvailableCnt !== undefined ? k.rentAvailableCnt : k.stockQty !== undefined ? k.stockQty : 1
                    ,isSelected: true
                    ,isCreated: true /* 추가하려면 isCreated를 반드시 추가해 준다. */
                };
                contractProductRef.current.addRow(LUtils.assign(addRowInfo, additionalRowInfo));
            });
        }
        setBtnStatus('add');
    }

    /* 소모품 추가 콜백 함수 */
    const callbackConsumListSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        let seq = ContractDetailStore.productConsumList.length + 1;
        LUtils.forEach(checkRowProductList, p => {
            for(let consum of info){
                const additionalRowInfo = {
                    addRowId: seq++
                    ,seq: seq++
                    ,contractProductSeq: p.seq
                    ,productSeq: p.productSeq
                    ,productCode: p.productCode
                    ,productName: p.productName
                    ,model: p.model
                    ,consumProductSeq: consum.consumProductSeq, consumProductName: consum.consumProductName, consumModel: consum.consumModel
                    ,consumProductCode: consum.consumProductCode // consumProductCode에도 담아줌(같은 제품 또 넣을 경우 비교하기 위해)
                    //,assetNo: consum.assetNo
                    ,storageNo: consum.storageNo
                    ,qty: 1
                    ,maxQty: consum.qty
                    ,isSelected: true, isCreated: true /* 추가하려면 isCreated를 반드시 추가해 준다. */
                };
                ContractDetailStore.pushProductConsumList(additionalRowInfo);
            }
        });
    }

    /* 매각 자산 콜백 함수 */
    const callbackContractAssetListSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        if(info[0].assetStatus !== '200') {
            ContractDetailStore.setContractDetail(1, 'isVirtualRelease');
        }else {
            ContractDetailStore.setContractDetail(0, 'isVirtualRelease')
        }

        let isSame = false;
        const uniqueColumns = ['assetNo'];
        LUtils.forEach(ContractDetailStore.contractProductList, selectedItem => {
            const item = LUtils.pick(selectedItem, uniqueColumns);

            // 추가되어 있는 목록에서 선택된 목록중에 uniqueColumns 중복이 있으면 선택된 목록에서 제거한다.
            if (LUtils.some(info, v => LUtils.isMatch(LUtils.pick(v, uniqueColumns), item))) {
                LUtils.remove(info, v => LUtils.isMatch(LUtils.pick(v, uniqueColumns), item));
                isSame = true;
            }
        });

        isSame && callAlert('동일한 자산번호가 있습니다.');
        const addRow = [];
        LUtils.forEach(info, k => {
            const additionalRowInfo = {
                seq: null
                ,assetNo: k.assetNo
                ,productSeq: k.productSeq
                ,productCode: k.productCode
                ,productName: k.productName
                ,model: k.model
                ,qty: 1
                ,availableQty: 1
                ,isSelected: true
                ,isCreated: true /* 추가하려면 isCreated를 반드시 추가해 준다. */
            };
            contractProductRef.current.addRow(LUtils.assign(addRowInfo, additionalRowInfo));
        });
    }

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData) => {
        if (LUtils.isEmpty(fileData.target.files)) {
            ContractDetailStore.setContractDetail('', 'contractDocUrl');
            ContractDetailStore.setContractDetail('', 'contractDocFileName');
            return false;
        }

        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });

        ContractDetailStore.setContractDetail(result, 'contractDocUrl');
        ContractDetailStore.setContractDetail(file.name, 'contractDocFileName');
        setModifyFile(false);
    }

    /* 출고의뢰 팝업 - 고객 한도 검사 */
    const checkCustomerLimit = async () => {

        if(!ContractDetailStore.contractNo){
            showToast('계약이 저장되지 않았습니다.');
            return;
        }

        // validate
        const validateList = ContractDetailStore.originContractProductList;

        if (!ContractDetailStore.customerNo) {
            showToast('고객을 선택 해주세요');
            return false;
        }

        // 계약제품 등록여부 확인
        if (LUtils.isEmpty(validateList)) {
            showToast('계약 제품을 등록해 주세요.');
            return false;
        }

        if(ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2'){
            for(let data of validateList){
                if((data.totalRentPrice === 0 || !data.totalRentPrice) && !data.remark){
                    showToast('렌탈료가 입려되지 않았습니다. 사유를 비고란에 기입해주세요.');
                    return;
                }
            }
        }

        // 소모품 저장 알림
        if(ContractDetailStore.productConsumList.length > 0){
            const resultList = ContractDetailStore.productConsumList.filter(v => !v.consumRowNumber);

            if(resultList.length > 0) {
                showToast('소모품을 저장해주세요.');
                return false;
            }
        }

        // 총 렌탈료가 모두 있는지 확인
        const totalRentPriceList = LUtils.map(validateList, 'totalRentPrice');

        if(ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2'){
            // 총 렌탈료가 모두 있는지 확인
            if (!totalRentPriceList.every(v => v >= 0)) {
                showToast('수량과 렌탈료를 모두 입력해주세요.');
                return false;
            }
/*
            for(let product of ContractDetailStore.contractProductList){
                for(let c of ContractDetailStore.productConsumList){
                    const item = toJS(c);
                    if(product.seq === item.contractProductSeq){
                        if(item.qty > product.qty){
                            showToast('제품코드 '+product.productCode+' 수량보다 소모품 수량이 클수 없습니다.');
                            return false;
                        }
                    }
                }
            }
*/
        }else {
            const productSalePriceList = LUtils.map(validateList, 'totalSalePrice');

            if (!LUtils.every(productSalePriceList)) {
                showToast(`수량과 대당 ${ContractDetailStore.contractType === '3' ? '유통' : '매각'}가를 모두 입력해주세요.`);
                return false;
            }
        }

        const { isLimitPassed, totalLimitPrice, sumTotalRentalPrice } = await axios.get(`/customer/${ContractDetailStore.customerNo}/limit/check`);
        const  leftoverPrice = totalLimitPrice - sumTotalRentalPrice - totalRentPriceList.reduce((acc, current) => acc + current, 0);

        // if (isLimitPassed && leftoverPrice > 0) {

            await callAlert(`출고가 가능합니다.\n잔여한도: ${leftoverPrice > 0 ? leftoverPrice.toLocaleString() : 0}원`);

            //렌탈/유통 또는 매각
            if(ContractDetailStore.contractType !== '4'){
                AppStore.toggleModal();
            }else{
                //매각
                if (!await callConfirm(`매각 계약을 진행 하시겠습니까?`)) {
                    return false;
                }

                let success = [];
                if(validateList[0].assetStatus === '200'){
                    AppStore.toggleModal();
                }else {
                    await axios.post(`/releaseAsk/sale/${ContractDetailStore.contractNo}`);
                    showToast('처리되었습니다.');
                }
            }
        // } else {
        //     callAlert(`고객 한도가 부족합니다.\n잔여한도: ${leftoverPrice.toLocaleString()}원`);
        // }
    }

    /* 파일 다운 */
    const downFile = async () => {
        if (!ContractDetailStore.contractDocUrl) {
            showToast('저장된 파일이 없습니다.');
            return false;
        }

        // TODO: 안댐. cors에서 막혀서 더이상 진행 안됨. 일단 이대로 진행하고 나중에 수정하도록 함
        // const result = await s3({
        //     method: 'get',
        //     url: ContractDetailStore.contractDocUrl,
        //     responseType: 'blob',
        //     headers: {'Access-Control-Allow-Origin': '*'}
        // });
        // Blob은 배열 객체 안의 모든 데이터를 합쳐 blob으로 반환하기 때문에 []안에 담는다!
        // const blob = new Blob([result.data])

        // window 객체의 createObjuctURL을 이용해서 blob:http://~~~ 식의 url을 만들어 준다.
        // const fileUrl = window.URL.createObjectURL(blob);
        const fileUrl = ContractDetailStore.contractDocUrl;

        // link 안에 위에서 만든 url을 가지고 있는 a 태그를 만들고 보이지 않도록 해준다.
        const link = document.createElement('a');
        link.href = fileUrl;
        link.style.display = 'none';
        link.target = "_blank";
        link.download = ContractDetailStore.contractDocFileName;

        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(ContractDetailStore.contractDocUrl);
    }

    /* grid data 콜백 - 추가, 수정 */
    const callBackGridData = async ({ updatedList, createdList }) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);
        if (!isUpdate && !isCreate) {
            showToast('저장할 내용이 없습니다.');
            return false;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        if(isCreate) {
            if (createdList.some(v => v.qty === null || v.qty <= 0)) {
                alert('수량을 입력해주세요.');
                return;
            } 
            if (createdList.some(v => v.rentMonth === 0 && v.rentDays === 0)) {
                showToast('대여기간이 1일 이상이어야 합니다.');
                return;
            }
        }else {
            if (updatedList.some(v => v.qty === null || v.qty <= 0)) {
                alert('수량을 입력해주세요.');
                return;
            } 
            if (updatedList.some(v => v.rentMonth === 0 && v.rentDays === 0)) {
                showToast('대여기간이 1일 이상이어야 합니다.');
                return;
            }
        }

        isCreate && createdList.forEach((v, i) => (v.seq = i + 1))   // storageSeq 입력

        isUpdate && await axios.put(`/contract/product/${ContractDetailStore.contractNo}`, { contractProductList: updatedList });
        isCreate && await axios.post(`/contract/product/${ContractDetailStore.contractNo}`, { contractProductList: createdList });

        showToast('저장 되었습니다.');
        searchContractProduct(ContractDetailStore.contractNo);
    }

    /* 선택항목 삭제 */
    const removeSelectedRows = async ({selectedList}) => {
        if (LUtils.isEmpty(selectedList)) {
            callAlert('삭제 할 항목이 없습니다.');
            return false;
        }

        if (!await callConfirm('삭제 하시겠습니까?')) {
            return false;
        }

        const seqList = selectedList.map(v => v.seq);
        await axios.delete(`/contract/product/${ContractDetailStore.contractNo}`, { data: seqList });

        showToast('삭제 되었습니다.');
        searchContractProduct(ContractDetailStore.contractNo);
    }

    /* 소모품 선택항목 삭제 */
    const removeOptionSelectedRows = async ({selectedList}) => {
        if (LUtils.isEmpty(selectedList)) {
            showToast('삭제 할 항목이 없습니다.');
            return false;
        }

        if (!await callConfirm('삭제 하시겠습니까?')) {
            return false;
        }

        await axios.delete(`/contract/product/consum/${ContractDetailStore.contractNo}`, {data: selectedList});

        showToast('삭제 되었습니다.');
        searchContractProduct(ContractDetailStore.contractNo);
        searchProductConsum(ContractDetailStore.contractNo);
    }

    /* grid 입력값 콜백 이벤트 - 숫자만 입력해야 하는 컬럼 검사, 총 렌탈료 계산, 수량 검사 */
    const getCellChange = (e) => {
        const { field } = e.colDef;

        // 숫자만 입력 가능한 컬럼 검사
        const numberColumns = ['monthRentPrice', 'dayRentPrice', 'lastMonthPrice', 'qty', 'productSalePrice', 'assetSalePrice'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), v => isNaN(v))) {
            showToast('숫자만 입럭 가능합니다.');
            e.data[field] = 0;
        }

        // 총 렌탈료 계산
        if (LUtils.includes(['rentMonth', 'rentDays', 'monthRentPrice', 'dayRentPrice', 'lastMonthPrice', 'qty'], field)) {
            e.data.totalRentPrice = contractUtils.calculateTotalPrice(e.data);
        }

        // 총 판매(유통,매각)가 계산
        if(LUtils.includes(['productSalePrice', 'assetSalePrice', 'qty'], field)){
            e.data.totalSalePrice = contractUtils.calculateTotalSalePrice(e.data);
        }

        // 수량 검사
        if (field === 'qty') {
            const { qty, storageNo, productSeq, assetNo } = e.data;

            if(assetNo && Number(qty) > 1){
                showToast('자산 선택시 수량은 1개를 초과할 수 없습니다.');
                e.data.qty = 1;
            }

            const goodsInfo = LUtils.find(ContractDetailStore.contractProductGroupList, v => v.storageNo === storageNo && v.productSeq === productSeq);
            const availableQty = e.data.availableQty || goodsInfo?.availableQty;

            if (Number(qty) > Number(availableQty)) {
                let typeText = ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2' ? '렌탈' : '판매';
                showToast(`${typeText}가능 수량을 초과 했습니다. 가능 수량 [${availableQty}]`);
                e.data.qty = availableQty;
                e.data.totalRentPrice = contractUtils.calculateTotalPrice(e.data);
            }
        }

        // 그리드 전체 다시 그리기
        gridRef.current.api.redrawRows();
    }

    /* 선택 버튼 옵션 - 삭제 */
    const consumSelectBtnInfo = {
        isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
        title: '선택 삭제',
        callbackFn: e => removeConsumSelectedRows(e),
        icon: ICON_TRASH
    };

    const customConsumAddBtnInfo = [
        {
            isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
            callbackFn: () => {
                const productList = ContractDetailStore.contractProductList;
                const checkRows = productList.filter(v => v.isSelected);
                if(checkRows.length === 0){
                    showToast('상단 계약제품에서 소모품을 추가할 제품을 선택해 주세요.');
                    return;
                }
                setCheckRowProductList(checkRows);
                AppStore.toggleConsumablesSearchModal()
            },
            title: '소모품추가',
            icon: 'fi-rr-add',
            //color: '#FE2E64'
        }
    ];

    /* 자산 조회 팝업 UI */
    const popupAssetInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.assetNo}</div>
                    <IconBtn style={{paddingLeft: 5, paddingTop: 6, color:'darkblue', textAlign: 'right'}} key={e.rowIndex} onClick={() => {openAssetSearchPopup(e)}} />
            </div>
        )
    }

    /* 자산 조회 팝업 */
    const openAssetSearchPopup = (e) =>{
        AppStore.toggleStockProductSearchModal();
        setGridData(e);
        setBtnStatus('row');
    }

    /* 입고 조회 팝업 UI */
    const popupStorageInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.storageNo}</div>
                    <IconBtn style={{paddingLeft: 5, paddingTop: 6, color:'darkblue', textAlign: 'right'}} key={e.rowIndex} onClick={() => {openStorageSearchPopup(e)}} />
            </div>
        )
    }

    /* 입고 조회 팝업 */
    const openStorageSearchPopup = (e) =>{
        AppStore.toggleStockProductSearchModal();
        setGridData(e);
        setBtnStatus('row');
    }

     /* 고객 담당자 호출 */
     const openSearchCustomerManagerPopup = () => {
        AppStore.toggleCustomerManagerSearchPopup();
    }

    /* 고객 담당자 팝업 콜백 */
    const getCustomerManager = (info) => {
        ContractDetailStore.setContractDetail(info.customerManagerSeq, 'customerManagerSeq');
        ContractDetailStore.setContractDetail(info.managerName, 'managerName');
    }

    const releaseAskComplete =()=> {
        ContractDetailStore.setContractDetailMode('read');
        searchContract(ContractDetailStore.contractNo);
        ContractDetailStore.setContractDetail(1, 'contractStatus');
    }

    /* 계약제품목록 수량 체크 */
    const handleProductQtyEdit = async(e) => {
        if(e.data.availableQty < e.newValue) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        } else {
            e.data.qty = e.newValue;
        }
    }

    const handleConsumRemarkEdit =async(e)=> {
        e.data.remark = e.newValue;
        consumGridRef.current.api.redrawRows();
    }

    const handleConsumQtyEdit =async(e)=> {
        if(e.data.maxQty < e.newValue) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        } else {
            e.data.qty = e.newValue;
        }

        consumGridRef.current.api.redrawRows();
    }

    /* 소모품 그리드 저장,수정 */
    const callBackConsumSave = async ({ updatedList, createdList }) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);

        // 재고수량 확인
        let checkQty = 0;
        if (isUpdate) {
            updatedList.map(v => {
                if(v.maxQty < v.qty){
                    checkQty++;
                }
            });
        } else if (isCreate) {
            createdList.map(v => {
                if(v.maxQty < v.qty){
                    checkQty++;
                }
            });
        }
/*
        TODO: 기존 계약 입력이 완료되면 주석 풀어야함
        if (checkQty != 0) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            return false;
        }
*/

        if (!isUpdate && !isCreate) {
            showToast('저장할 내용이 없습니다.');
            return false;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        isCreate && await axios.post(`/contract/product/consum/${ContractDetailStore.contractNo}`, createdList);
        isUpdate && await axios.put(`/contract/product/consum/${ContractDetailStore.contractNo}`, updatedList);

        showToast('저장 되었습니다.');
        searchContractProduct(ContractDetailStore.contractNo);
        searchProductConsum(ContractDetailStore.contractNo);
    }

     /* 소모품 선택항목 삭제 */
     const removeConsumSelectedRows = async ({selectedList}) => {
        if (LUtils.isEmpty(selectedList)) {
            callAlert('삭제 할 항목이 없습니다.');
            return false;
        }

        if (!await callConfirm('삭제 하시겠습니까?')) {
            return false;
        }

        await axios.delete(`/contract/product/consum/${ContractDetailStore.contractNo}`, {data: selectedList});

        callAlert('삭제 되었습니다.');
        searchContractProduct(ContractDetailStore.contractNo);
        searchProductConsum(ContractDetailStore.contractNo);
    }

    const updateInstallPlace =async()=> {
        if (!await callConfirm('설치장소를 변경 하시겠습니까?')) {
            return false;
        }

        await axios.put(`/contract/${ContractDetailStore.contractNo}/installPlace`, {installPlace: ContractDetailStore.installPlace});
        showToast('변경되었습니다.');
    }

    return (
        <>
            <Row>
                <CCol lg={2}>
                    <CInputGroup
                        isValid={true}
                        isValidCheck={!!ContractDetailStore.billingAddressName}
                        label={'청구지'}
                        value={ContractDetailStore.billingAddressName || ''}
                        disabled={true}
                        onCallbackBtn={openSearchBillingAddressPopup}
                        btnDisabled={ContractDetailStore.detailMode === 'read'}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup label={'담당자명'} labelId={'managerName'} disabled={true}
                                 value={ContractDetailStore.managerName || ''}
                                 onCallbackBtn={openSearchCustomerManagerPopup}
                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup
                        label={'계약서형태'}
                        options={getCommcode('15')}
                        value={ContractDetailStore.contractDocType || ''}
                        onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'contractDocType')}
                        disabled={ContractDetailStore.detailMode === 'read'}
                    />
                </CCol>
                {ContractDetailStore.contractType == '4' &&
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'매각구분'}
                            isValid={true}
                            options={[{name: '선택', value: ''}].concat(getCommcode('9'))}
                            value={ContractDetailStore.sellType || ''}
                            onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'sellType')}
                            disabled={ContractDetailStore.detailMode === 'read'}
                        />
                    </CCol>
                }
            </Row>
            <Row>
                <Subject>특약사항</Subject>
                {(ContractDetailStore.contractType != '3' && ContractDetailStore.contractType != '4') &&
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isPenaltyFee"
                            label="위약금 청구"
                            style={{marginBottom: 8}}
                            checked={!!ContractDetailStore.isPenaltyFee}
                            onChange={
                                v => {
                                    !v.target.checked && ContractDetailStore.setContractDetail(0, 'penaltyPercent');
                                    v.target.checked && ContractDetailStore.setContractDetail(40, 'penaltyPercent');
                                    ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isPenaltyFee')
                                }}
                            disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                        />
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'penaltyPercent'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>잔여 렌탈료의</InputGroup.Text>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                max={2}
                                aria-describedby={'penaltyPercent'}
                                value={ContractDetailStore.penaltyPercent || ''}
                                onChange={(v) => ContractDetailStore.setContractDetail(v.target.value, 'penaltyPercent')}
                                disabled={(ContractDetailStore.detailMode === 'read' && isFixed) || !ContractDetailStore.isPenaltyFee}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>%</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                }
                {/*
                <CCol lg={2}>
                    <Form.Check
                        type="switch"
                        id="isExtendRate"
                        label="연장할인"
                        style={{marginBottom: 8}}
                        checked={!!ContractDetailStore.isExtendRate}
                        onChange={
                            v => {
                                if (!v.target.checked) {
                                    ContractDetailStore.setContractDetail(0, 'extendFirstRate');
                                    ContractDetailStore.setContractDetail(0, 'extendSecondRate');
                                }
                                ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isExtendRate')
                            }}
                        disabled={ContractDetailStore.detailMode === 'read'}
                    />
                    <InputGroup size={'sm'}>
                        <InputGroup.Text id={'extendFirstRate'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>1회차</InputGroup.Text>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            aria-describedby={'extendFirstRate'}
                            value={ContractDetailStore.extendFirstRate || 0}
                            onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'extendFirstRate')}
                            disabled={ContractDetailStore.detailMode === 'read' || !ContractDetailStore.isExtendRate}
                        />
                        <InputGroup.Text muted style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>%</InputGroup.Text>
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>2회차</InputGroup.Text>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            aria-describedby={'extendSecondRate'}
                            value={ContractDetailStore.extendSecondRate || 0}
                            onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'extendSecondRate')}
                            disabled={ContractDetailStore.detailMode === 'read' || !ContractDetailStore.isExtendRate}
                        />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>%</InputGroup.Text>
                    </InputGroup>
                </CCol>*/}
              <CCol lg={2}>
                    <Form.Check
                        type="switch"
                        id="isDeliveryPrice"
                        label="운반비 청구"
                        style={{marginBottom: 5}}
                        checked={!!ContractDetailStore.isDeliveryPrice}
                        onChange={(v)=> {
                            ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isDeliveryPrice');
                            if(v.target.checked) {ContractDetailStore.setContractDetail('0', 'deliveryPrice'); ContractDetailStore.setContractDetail('1', 'chargeDelivery'); ContractDetailStore.setContractDetail('1', 'deliveryKind')}
                        }}
                        disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                    />
                    <InputGroup size={'sm'}>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            max={2}
                            aria-describedby={'deliveryPrice'}
                            value={insertComma(ContractDetailStore.deliveryPrice) || 0}
                            onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'deliveryPrice')}
                            disabled={(ContractDetailStore.detailMode === 'read' && isFixed) || !ContractDetailStore.isDeliveryPrice}
                        />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                    </InputGroup>
                </CCol>
                {ContractDetailStore.isDeliveryPrice === 1 && 
                    <CCol lg={2}>
                        <CSelectGroup
                                label={'운반비 부담'}
                                style={{marginTop: 27}}
                                options={getCommcode('58')}
                                value={ContractDetailStore.chargeDelivery || ''}
                                onChange={(v)=> {ContractDetailStore.setContractDetail(v.target.value, 'chargeDelivery'), v.target.value === '1' ? ContractDetailStore.setContractDetail('1', 'deliveryKind') : ContractDetailStore.setContractDetail(null, 'deliveryKind')}}
                                disabled={!ContractDetailStore.isDeliveryPrice}
                            />
                    </CCol>
                }
                {Number(ContractDetailStore.isDeliveryPrice) === 1 && Number(ContractDetailStore.chargeDelivery) === 1 &&
                    <CCol lg={2}>
                        <CSelectGroup
                                label={'운반구분'}
                                style={{marginTop: 27}}
                                options={getCommcode('57')}
                                value={ContractDetailStore.deliveryKind || ''}
                                onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'deliveryKind')}
                                disabled={!ContractDetailStore.isDeliveryPrice}
                            />
                    </CCol>
                }
                <CCol lg={2}>
                    <Form.Check
                        type="switch"
                        id="isInstallPrice"
                        label="설치비 청구"
                        style={{marginBottom: 5}}
                        checked={!!ContractDetailStore.isInstallPrice}
                        onChange={(v)=> {
                            ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isInstallPrice');
                            if(!v.target.checked) ContractDetailStore.setContractDetail('0', 'installPrice');
                        }}
                        disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                    />
                    <InputGroup size={'sm'}>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            max={2}
                            aria-describedby={'installPrice'}
                            value={insertComma(ContractDetailStore.installPrice) || 0}
                            onChange={(v)=> ContractDetailStore.installPrice = v.target.value}
                            disabled={(ContractDetailStore.detailMode === 'read' && isFixed) || !ContractDetailStore.isInstallPrice}
                            />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                    </InputGroup>
                </CCol>
                {ContractDetailStore.contractType == '2' &&
                    <>
                        <CCol lg={2}>
                            <Form.Check
                                type="switch"
                                id="switch1"
                                label="무상양도"
                                style={{marginBottom: 5}}
                                checked={!!ContractDetailStore.isFreeAssignment}
                                onChange={(v)=> ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isFreeAssignment')}
                                disabled={ContractDetailStore.detailMode === 'read'}
                            />
                            <InputGroup size={'sm'}>
                                <Form.Control
                                    style={{flex: 1, textAlign: 'right'}}
                                    max={2}
                                    aria-describedby={'assignmentAmount'}
                                    value={insertComma(ContractDetailStore.assignmentAmount) || 0}
                                    onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'assignmentAmount')}
                                    disabled={ContractDetailStore.detailMode === 'read' || ContractDetailStore.isFreeAssignment}
                                />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                        <CCol lg={2}>
                            <Form.Check
                                type="switch"
                                id="isRepairCost"
                                label="유상 A/S"
                                style={{marginBottom: 5}}
                                checked={!!ContractDetailStore.isRepairCost}
                                onChange={(v)=> ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isRepairCost')}
                                disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                            />
                            <Form.Text id="passwordHelpBlock" muted>고객과실: 유상, 부품결함: 무상</Form.Text>
                        </CCol>
                    </>
                }
            </Row>
            <Row>
                <Subject>결제조건</Subject>
                {/*
                <Row>
                    <CCol lg={8}>
                    <Form.Label style={{color: '#DF013A', display: 'block'}}>첫 회차 청구 기준</Form.Label>
                        <div className="btn-group btn-group-toggle pt-1 pb-3">
                            <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                <Form.Check 
                                    type="radio" value="2" name="firstBillingType" 
                                    checked={ContractDetailStore.firstBillingType === '2' ? true : false} 
                                    onChange={v => ContractDetailStore.setContractDetail(v.target.value, 'firstBillingType')} 
                                    style={{verticalAlign: 'middle', marginRight: 8}}
                                    disabled={ContractDetailStore.detailMode === 'read' && isFixed}/>
                                    <span>말일 기준: 개시일 부터 당월 말일까지</span>
                            </label>
                            <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                <Form.Check 
                                    type="radio" value="3" name="firstBillingType" 
                                    checked={ContractDetailStore.firstBillingType === '3' ? true : false} 
                                    onChange={v => ContractDetailStore.setContractDetail(v.target.value, 'firstBillingType')} 
                                    style={{verticalAlign: 'middle', marginRight: 10}}
                                    disabled={ContractDetailStore.detailMode === 'read' && isFixed}/> 
                                    <span>마감일 기준: 개시일 부터 마감일까지</span>
                            </label>
                            <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                <Form.Check 
                                    type="radio" value="1" name="firstBillingType" 
                                    checked={ContractDetailStore.firstBillingType === '1' ? true : false} 
                                    onChange={v => ContractDetailStore.setContractDetail(v.target.value, 'firstBillingType')} 
                                    style={{verticalAlign: 'middle', marginRight: 10}}
                                    disabled={ContractDetailStore.detailMode === 'read' && isFixed}/> 
                                    <span>개시일 기준: 개시일 부터 (익월개시일-1일)까지</span>
                            </label>
                        </div>
                    </CCol>
                    <CCol lg={4}>
                        <Form.Label style={{color: '#DF013A'}}>일할 계산 시 절사 단위</Form.Label>
                        <Row>
                            <div className="btn-group btn-group-toggle pt-1 pb-3">
                                <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                    <Form.Check 
                                        type="radio" value={'10'} name="truncationUnit"
                                        checked={String(ContractDetailStore.truncationUnit) === '10' ? true : false} 
                                        onChange={v => ContractDetailStore.setContractDetail(v.target.value, 'truncationUnit')} 
                                        style={{verticalAlign: 'middle', marginRight: 10}}
                                        disabled={ContractDetailStore.detailMode === 'read' && isFixed}/> 
                                        10원 단위
                                </label>
                                <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                    <Form.Check 
                                        type="radio" value={'100'} name="truncationUnit" 
                                        checked={String(ContractDetailStore.truncationUnit) === '100' ? true : false} 
                                        onChange={v => ContractDetailStore.setContractDetail(v.target.value, 'truncationUnit')} 
                                        style={{verticalAlign: 'middle', marginRight: 8}}
                                        disabled={ContractDetailStore.detailMode === 'read' && isFixed}/> 
                                        100원 단위
                                </label>
                                <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                    <Form.Check 
                                        type="radio" value={'1000'} name="truncationUnit" 
                                        checked={String(ContractDetailStore.truncationUnit) === '1000' ? true : false} 
                                        onChange={v => ContractDetailStore.setContractDetail(v.target.value, 'truncationUnit')} 
                                        style={{verticalAlign: 'middle', marginRight: 8}}
                                        disabled={ContractDetailStore.detailMode === 'read' && isFixed}/> 
                                        1000원 단위
                                </label>
                            </div>
                        </Row>
                    </CCol>
                </Row>
                */}
                <CCol lg={2}>
                    <Form.Label style={{color: '#DF013A'}}>청구조건</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Select
                            aria-label="선택"
                            style={{width: '55%'}}
                            value={ContractDetailStore.billingType || ''}
                            onChange={(v) => ContractDetailStore.setContractDetail(v.target.value, 'billingType')}
                            disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                        >
                            <CommcodeSMOptions commKind={'21'} />
                        </Form.Select>
                        <Form.Select
                            aria-label="선택"
                            value={ContractDetailStore.billingDay || 0}
                            onChange={(v) => ContractDetailStore.setContractDetail(v.target.value, 'billingDay')}
                            disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                        >
                            {getDays().map((d,i) => {
                                return (<option key={i} value={d.value}>{d.text}</option>);
                            })}
                        </Form.Select>
                    </InputGroup>
                </CCol>
                <CCol lg={2}>
                    <Form.Label style={{color: '#DF013A'}}>납기조건</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Select
                            aria-label="선택"
                            style={{width: '55%'}}
                            value={ContractDetailStore.dueDateType || ''}
                            onChange={(v) => ContractDetailStore.setContractDetail(v.target.value, 'dueDateType')}
                            disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                        >
                            <CommcodeSMOptions commKind={'22'} />
                        </Form.Select>
                        <Form.Select
                            aria-label="선택"
                            value={ContractDetailStore.dueDateDay || ''}
                            onChange={(v) => ContractDetailStore.setContractDetail(v.target.value, 'dueDateDay')}
                            disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                        >
                            {getDays().map((d,i) => {
                                return (<option key={i} value={d.value}>{d.text}</option>);
                            })}
                        </Form.Select>
                    </InputGroup>
                </CCol>
                <CCol lg={2}>
                    <Form.Label style={{color: '#DF013A'}}>결제방식</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Select
                            aria-label="선택"
                            value={ContractDetailStore.paymentType || ''}
                            onChange={(v) => ContractDetailStore.setContractDetail(v.target.value, 'paymentType')}
                            disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                        >
                            <CommcodeSMOptions commKind={'17'} />
                        </Form.Select>
                    </InputGroup>
                </CCol>
                <CCol lg={2}>
                    <Form.Label>보증금</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            max={2}
                            aria-describedby={'deposit'}
                            value={insertComma(ContractDetailStore.deposit) || 0}
                            onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'deposit')}
                            disabled={ContractDetailStore.detailMode === 'read && isFixed'}
                        />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                    </InputGroup>
                </CCol>
                {(ContractDetailStore.contractType == '1' || ContractDetailStore.contractType == '2') &&
                    <CCol lg={1}>
                        <Form.Label>마감일자</Form.Label>
                        <InputGroup size={'sm'}>
                            <Form.Select
                                aria-label="선택"
                                value={ContractDetailStore.endDate || ''}
                                onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'endDate')}
                                disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                            >
                                {getDays().map((d,i) => {
                                    return (<option key={i} value={d.value}>{d.text}</option>);
                                })}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                }
            </Row>
            <Row>
                <CCol lg={2}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="계산서 역발행"
                        style={{paddingTop: 8}}
                        checked={!!ContractDetailStore.isReverseBill}
                        onChange={(v)=> ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isReverseBill')}
                        disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                    />
                </CCol>
{/*                <CCol lg={5}>
                    <Form.Check
                        type="switch"
                        id="switch2"
                        label="가상출고 (렌탈중인 자산을 매각하거나 입고되지 않은 상품을 출고할 경우 사용)"
                        style={{paddingTop: 8}}
                        checked={!!ContractDetailStore.isVirtualRelease}
                        onChange={(v)=> ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isVirtualRelease')}
                        disabled={true}
                    />
                </CCol>*/}
            </Row>
            <Row>
                <Subject>기타</Subject>
                {ContractDetailStore.contractType !== '4' &&
                    <Row style={{marginTop: 20, marginBottom: 20}}>
                        <CCol lg={1}>
                            <Form.Check
                                type="switch"
                                id="maintenance-switch"
                                label="정기유지보수"
                                style={{paddingTop: 8}}
                                checked={!!ContractDetailStore.isMaintenance}
                                onChange={(v)=> ContractDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isMaintenance')}
                                disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                            />
                        </CCol>
                        <CCol lg={2}>
                            {ContractDetailStore.isMaintenance ?
                                <CSelectGroup
                                    label={'유지보수 주기(월)'}
                                    options={MAINTENANCE_MONTH}
                                    value={ContractDetailStore.maintenanceCycleMonth}
                                    onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'maintenanceCycleMonth')}
                                />
                                : null
                            }
                        </CCol>
                    </Row>
                }
                <Row>
                    <CCol lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>특이사항</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                value={ContractDetailStore.specialRemark || ''}
                                onChange={(v) => ContractDetailStore.setContractDetail(v.target.value, 'specialRemark')}
                                disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                            />
                        </Form.Group>
                    </CCol>
                    <CCol lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>설치장소</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                value={ContractDetailStore.installPlace || ''}
                                onChange={(v)=> ContractDetailStore.setContractDetail(v.target.value, 'installPlace')}
                                disabled={ContractDetailStore.detailMode === 'read' && isFixed}
                            />
                        </Form.Group>
                    </CCol>
                </Row>
            </Row>
            {/* <Row>
                {ContractDetailStore.contractType == '1' &&
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true}
                                       label={'회수예정일'}
                                       value={returnExpectDate}
                                       minDate={today()}
                                       onChange={(e) => setReturnExpectDate(e)}
                                       disabled={ContractDetailStore.detailMode === 'read'}
                        />
                    </CCol>
                }
            </Row> */}
            <Row>
                <Subject>계약서</Subject>
                <CCol lg={4}>
                    {/* 1. 파일 수정상태이거나,  2. 등록 url로 접근했거나,  3. contractDocUrl값이 없을때 파일등록 ui 설정  */}
                    {(
                        modifyFile ||
                        ContractDetailStore.detailMode === 'create' ||
                        (ContractDetailStore.detailMode === 'update' && !ContractDetailStore.contractDocUrl)
                    )
                    ?
                        <InputGroup className="mb-3">
                            <Form.Control
                                type="file"
                                size="sm"
                                onChange={fileUploadForS3}
                            />
                        </InputGroup>
                    :
                        <CInputGroup
                            label={'계약서 파일'}
                            value={ContractDetailStore.contractDocFileName}
                            onCallbackBtn={downFile}
                            buttonLabel={'내려받기'}
                            disabled={ContractDetailStore.detailMode === 'read'}
                        />
                    }
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                {((ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractStatus === 0 && !ContractDetailStore.isVirtualRelease && ContractDetailStore.isReleaseAskBtn === true) || ContractDetailStore.releaseAskStatus === '9') &&
                        <CIconBtn
                            style={{width: 100}}
                            onClick={checkCustomerLimit}
                            title={'출고의뢰'}
                            icon={'fi-rr-money-check-edit-alt'}
                        />
                    }
                    {(ContractDetailStore.updater === userInfo.userSeq && ContractDetailStore.isFix !== 1) &&
                        <CSaveBtn onClick={saveContract} style={{width: 100}} title={'계약저장'}/>
                    }
                    {(ContractDetailStore.contractStatus === 0 && ContractDetailStore.isVirtualRelease === 1 &&
                            ContractDetailStore.contractType == '4' && !ContractDetailStore.sellDate && ContractDetailStore.updater === userInfo.userSeq) &&
                        <CIconBtn
                            style={{width: 80}}
                            onClick={checkCustomerLimit}
                            title={'매각'}
                            icon={'fi-rr-money-check-edit-alt'}
                        />
                    }
                </Col>
            </Row>

            {/* modal */}
            <CustomerBillingAddressSearch customerNo={ContractDetailStore.customerNo} callbackFn={getBillingAddressInfo} />
            <StockProductSearch callbackFn={callbackStockProductSearch} contractType={ContractDetailStore.contractType} btnStatus={btnStatus} setBtnStatus={setBtnStatus} />
            <ConsumablesSearch callbackFn={callbackConsumListSearch} selProductList={checkRowProductList} />
            <ContractAssetSearch callbackFn={callbackContractAssetListSearch} contractType={ContractDetailStore.contractType} />
            <CustomerManagerSearch callbackFn={getCustomerManager} customerNo={ContractDetailStore.customerNo} />
            <Modal title={'출고의뢰'} onExit={()=> ReleaseAskDetailStore.init()}>
                <ReleaseAskDetail
                    contractNo={ContractDetailStore.contractNo}
                    customerNo={ContractDetailStore.customerNo}
                    billingAddressSeq={ContractDetailStore.billingAddressSeq}
                    callBackFn={releaseAskComplete}
                />
            </Modal>

            {/* grid */}
            <Row>
                <Subject>계약제품목록</Subject>
                <AgGridContainer
                    ref={contractProductRef}
                    gridRef={gridRef}
                    height={40}
                    rowData={ContractDetailStore.contractProductList}
                    columnDefs={[
                        {
                            field: "contractProductSeq",
                            headerName: "순번",
                            width: 80,
                            valueGetter: e => {
                                const originRowCnt = ContractDetailStore.originContractProductList.length;
                                const createListCnt = ContractDetailStore.contractProductList.filter(v => v[IS_CREATED]).length;
                                const cnt = originRowCnt + (createListCnt - e.node.rowIndex);
                                return Number(e.data.seq) > 0 ? e.data.seq : cnt;
                            }
                        },
                        {field: "productCode", headerName: "제품코드", width: 120},
                        {field: "productName", headerName: "제품명", width: 200},
                        {field: "model", headerName: "모델명", width: 150},
                        {
                            field: "qty",
                            headerName: "수량",
                            editable: ContractDetailStore.detailMode !== 'read' || (ContractDetailStore.contractType !== '4' && ContractDetailStore.contractType !== '1'),
                            headerClass: 'grid-column-required',
                            onCellValueChanged : handleProductQtyEdit,
                            width: 80,
                        },
                        {field: "rentMonth", headerName: "대여월", width: 90, hide: ContractDetailStore.contractType == '3' || ContractDetailStore.contractType === '4'},
                        {field: "rentDays", headerName: "대여일", width: 90, hide: ContractDetailStore.contractType == '3' || ContractDetailStore.contractType === '4' },
                        {
                            field: "monthRentPrice",
                            headerName: "대당 월렌탈료",
                            width: 130,
                            editable: ContractDetailStore.detailMode !== 'read' || (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 0 && ContractDetailStore.contractType === '1'),
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: ContractDetailStore.contractType == '3' || ContractDetailStore.contractType == '4'
                        },
                        {
                            field: "dayRentPrice",
                            headerName: "대당 일렌탈료",
                            width: 130,
                            editable: ContractDetailStore.detailMode !== 'read' || (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 0 && ContractDetailStore.contractType === '1'),
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: true
                        },
                        {
                            field: "lastMonthPrice",
                            headerName: "대당 마지막달 요금",
                            width: 150,
                            editable: ContractDetailStore.detailMode !== 'read' || (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 0 && ContractDetailStore.contractType === '1'),
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: ContractDetailStore.departmentCode === '1' ||ContractDetailStore.contractType == '3' || ContractDetailStore.contractType == '4' || ContractDetailStore.contractType === '5'
                        },
                        {
                            field: "totalRentPrice",
                            headerName: "총 렌탈료",
                            width: 140,
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: ContractDetailStore.contractType == '3' || ContractDetailStore.contractType == '4'
                        },
                        {
                            field: "productSalePrice",
                            headerName: "대당 판매가",
                            width: 130,
                            editable: ContractDetailStore.detailMode !== 'read',
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: ContractDetailStore.contractType != '3'
                        },
                        {
                            field: "assetSalePrice",
                            headerName: "대당 매각가",
                            width: 130,
                            editable: ContractDetailStore.detailMode !== 'read',
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: ContractDetailStore.contractType != '4'
                        },
                        {
                            field: "totalSalePrice",
                            headerName: "총 금액",
                            width: 130,
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: ContractDetailStore.contractType != '3' && ContractDetailStore.contractType != '4'
                        },
                        {
                            field: "assetNo", 
                            headerName: "자산번호", 
                            width: 130, 
                            //cellRenderer: (e) => popupAssetInfo(e), 
                            //headerClass: 'grid-column-editable', 
                            hide: ContractDetailStore.contractType !== '4'
                        },
                        {
                            field: "storageNo", 
                            headerName: "입고번호", 
                            width: 130, 
                            cellRenderer: (e) => !e.data.isCreated ? popupStorageInfo(e) : <>{e.data.storageNo}</>, 
                            headerClass: 'grid-column-editable',
                            hide: ContractDetailStore.contractType === '4'
                        },
                        {
                            field: "usefulLifeMonth", 
                            headerName: "상각개월수", 
                            width: 130, 
                            headerClass: 'grid-column-editable',
                            hide: ContractDetailStore.contractType != '1' && ContractDetailStore.contractType != '2',
                            editable: ContractDetailStore.detailMode !== 'read',
                            hide: true
                        },
                        {
                            field: "residualValueRate", 
                            headerName: "잔존가치율(%)", 
                            width: 140, 
                            headerClass: 'grid-column-editable',
                            hide: ContractDetailStore.contractType != '1' && ContractDetailStore.contractType != '2',
                            editable: ContractDetailStore.detailMode !== 'read',
                            hide: true
                        },
                        {field: "remark", headerName: "비고", minWidth: 200, flex: 1, headerClass: 'grid-column-editable', editable: ContractDetailStore.detailMode !== 'read'},
                        {field: "contractNo", headerName: "계약번호", hide: true},
                        {field: "availableQty", headerName: "사용가능한수량", hide: true},
                        {field: "productSeq", headerName: "제품시퀀스", hide: true},
                        {field: "assetStatus", headerName: "자산상태", hide: true},
                    ]}
                    seqColumn={'agId'}
                    addRowInfo={addRowInfo}
                    isCheckBox={ContractDetailStore.detailMode !== 'read'}
                    originList={ContractDetailStore.originContractProductList}
                    useUpdated={ContractDetailStore.detailMode !== 'read' || (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 0 && ContractDetailStore.contractType === '1')}
                    customBtnInfo={ContractDetailStore.contractType == '4' ? saleAssetBtnInfo : customAddBtnInfo}
                    callBackGridData={callBackGridData}
                    getCellChange={getCellChange}
                    //rowSearchCallback={openPopupGoodsOriginList}
                    selectBtnInfo={selectBtnInfo}
                />
            </Row>
            {(ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2') &&
                 <Row>
                    <Subject>소모품목록</Subject>
                    <AgGridContainer
                        gridRef={consumGridRef}
                        ref={consumRef}
                        height={30}
                        rowData={ContractDetailStore.productConsumList}
                        columnDefs={[
                            {field: "contractProductSeq", headerName: "contract_product의 제품 순번", width: 70, hide: true},
                            {field: "productSeq", headerName: "제품시퀀스", hide: true},
                            {field: "productCode", headerName: "제품코드", width: 120},
                            {field: "productName", headerName: "제품명", width: 200},
                            {field: "model", headerName: "모델명", width: 150},
                            {field: "consumRowNumber", headerName: "소모품 순번", width: 120},
                            {field: "consumSeq", headerName: "소모품 실제 순번", width: 100, hide: true},
                            {field: "consumProductSeq", headerName: "추가 소모품 productSeq", width: 200, hide: true},
                            {field: "consumProductName", headerName: "추가 소모품명", width: 200},
                            {field: "consumModel", headerName: "소모품 모델명", width: 200, hide: true},
                            {
                                field: "qty",
                                headerName: "소모품 수량",
                                width: 120,
                                headerClass: 'grid-column-editable',
                                onCellValueChanged : handleConsumQtyEdit,
                                editable: true
                            },
                            {field: "maxQty", headerName: "최대수량", hide: true},
                            {
                                field: "monthRentPrice",
                                headerName: "대당 월렌탈료",
                                width: 130,
                                editable: ContractDetailStore.detailMode !== 'read',
                                headerClass: 'grid-column-editable',
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString(),
                                hide: ContractDetailStore.contractType === '3' || ContractDetailStore.contractType === '4'
                            },
                            {
                                field: "dayRentPrice",
                                headerName: "대당 일렌탈료",
                                width: 130,
                                editable: ContractDetailStore.detailMode !== 'read',
                                headerClass: 'grid-column-editable',
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString(),
                                hide: true
                            },
                            {
                                field: "lastMonthPrice",
                                headerName: "대당 마지막달 요금",
                                width: 150,
                                editable: ContractDetailStore.detailMode !== 'read',
                                headerClass: 'grid-column-editable',
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString(),
                                hide: ContractDetailStore.departmentCode === '1' || ContractDetailStore.contractType === '3' || ContractDetailStore.contractType === '4'
                            },
                            {field: "assetNo", headerName: "자산번호", width: 120, hide: true},
                            {field: "storageNo", headerName: "입고번호", width: 120},
                            {
                                field: "remark",
                                headerName: "비고",
                                minWidth: 200,
                                flex: 1,
                                onCellValueChanged: handleConsumRemarkEdit,
                                editable: true
                            },
                        ]}
                        seqColumn={'agId'}
                        isCheckBox={true}
                        originList={[]}
                        useUpdated={ContractDetailStore.detailMode !== 'read'}
                        callBackGridData={callBackConsumSave}
                        selectBtnInfo ={consumSelectBtnInfo}
                        customBtnInfo={customConsumAddBtnInfo}
                    />
                </Row>
            }
        </>
    );
}

export default observer(ContractInfo);

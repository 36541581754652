import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Row } from "react-bootstrap";

import { CCol, CContainer, CInputGroup, Subject } from "../../../components/CustomContainer";
import AgGridContainer from "../../../components/AgGridContainer";

const ShipSchedulePopup = ({shipSeq, division, shipHopeTime, customerName, contractNo, notReceiveAmount, shipType, outsourcingInfo, outsourcingAmount}) => {
    const [itemList, setItemList] = useState([]);
  
    useEffect(() => {
        if(shipSeq){
            getShipScheduleDetail();
        }
    }, [shipSeq]);

    const getShipScheduleDetail = async () => {
        const result = await axios.get(`/ship/schedule/${shipSeq}`);
        setItemList(result);
    }

    return (
        <>
            <CContainer>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'구분'} labelId={'division'}
                                     value={division}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'배송시간'} labelId={'shipHopeTime'}
                                     value={shipHopeTime}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'고객명'} labelId={'customerName'}
                                     value={customerName}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={contractNo}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                {division === '회수' &&
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup label={'미수납 금액'} labelId={'notReceiveAmount'}
                                        value={notReceiveAmount}
                                        backLabel={'원'}
                                        align={'right'}
                                        disabled={true}
                            />
                        </CCol>
                    </Row>
                }
                {shipType === 'outsourcing' &&
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup label={'외주 운송'} labelId={'outsourcingInfo'}
                                        value={outsourcingInfo}
                                        disabled={true}
                            />
                        </CCol>
                        <CCol lg={12}>
                            <CInputGroup label={'외주 배송비'} labelId={'outsourcingAmount'}
                                        value={outsourcingAmount?.toLocaleString()}
                                        backLabel={'원'}
                                        align={'right'}
                                        disabled={true}
                            />
                        </CCol>
                    </Row>
                }
            </CContainer>

            <CContainer>
                <Subject>제품 리스트</Subject>
                <AgGridContainer
                    height={30}
                    rowData={itemList}
                    columnDefs={[
                        {field: "model", headerName: "모델명", width: 170},
                        {field: "productName", headerName: "제품명", width: 170, hide:true},
                        {field: "assetNo", headerName: "자산번호", width: 150},
                        {field: "qty", headerName: "수량", flex: 1},

                    ]}
                />
            </CContainer>
        </>
    )
}

export default observer(ShipSchedulePopup);

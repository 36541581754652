import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import AgGridContainer from '../../../components/AgGridContainer';
import LUtils from '../../../utils/lodashUtils';
import { dateTimeFormat } from '../../../utils/dateUtils';
import { callAlert, callConfirm } from '../../../utils';
import { CustomerStore } from '../../../store/customer/CustomerStore';
import { IconBtn } from '../../../components/CustomContainer';
import { openAddressPopup } from '../../../utils';
import { getUserName } from '../../../utils/userUtils';
import { encrypt, decrypt } from '../../../common/utils';

const CustomerManager = ({ customerNo, ...props }) => {
    const gridRef = useRef();
    const columnDefs = [
        {field: "customerManagerSeq", hide: true},
        {field: "managerSeq", headerName: "순번", width: 80},
        {field: "managerName", headerName: "담당자명", editable: true, headerClass: 'grid-column-editable', width: 110},
        {field: "department", headerName: "부서", editable: true, headerClass: 'grid-column-editable', width: 140},
        {field: "jobPosition", headerName: "직위", editable: true, headerClass: 'grid-column-editable'},
        {field: "telephone", headerName: "전화번호", editable: true, headerClass: 'grid-column-editable', width: 140},
        {field: "extensionNumber", headerName: "내선번호", editable: true, headerClass: 'grid-column-editable', width: 110},
        {field: "fax", headerName: "팩스", editable: true, headerClass: 'grid-column-editable', width: 140},
        {field: "phoneNumber", headerName: "휴대전화", valueFormatter: v => v.data.phoneNumber ?? decrypt(v.data.phoneNumber), editable: true, headerClass: 'grid-column-editable', width: 140},
        {field: "email", headerName: "이메일", valueFormatter: v => v.data.email ?? decrypt(v.data.email), editable: true, headerClass: 'grid-column-editable', width: 180},
        {field: "role", headerName: "역할", editable: true, headerClass: 'grid-column-editable'},
        {
            field: "isLeave",
            headerName: "퇴사 여부",
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: [0, 1] },
            valueFormatter: params => !!params.value ? '퇴사' : '근속',
            headerClass: 'grid-column-editable grid-column-required',
            width: 110
        },
        {
            field: "isKeyMan",
            headerName: "키맨 여부",
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: [0, 1] },
            valueFormatter: params => !!params.value ? '키맨' : '일반',
            headerClass: 'grid-column-editable grid-column-required',
            width: 110
        },
        {
            field: "isSupervisor",
            headerName: "대표담당자 여부",
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: [0, 1] },
            valueFormatter: params => !!params.value ? '대표담당자' : '일반',
            headerClass: 'grid-column-editable grid-column-required',
            width: 150
        },
        {
            field: "postcode",
            headerName: "우편번호",
            width: 110,
            cellRenderer: (e) => popupAddress(e),
            headerClass: 'grid-column-editable grid-column-required',
        },
        {field: "address", headerName: "주소", width: 240, headerClass: 'grid-column-editable grid-column-required'},
        {field: "addressDetail", headerName: "상세주소", width: 200, editable: true, headerClass: 'grid-column-editable'},
        {field: "remark", headerName: "비고", editable: true, headerClass: 'grid-column-editable', width: 240},
        {field: "updater", headerName: "변경자", valueGetter: v => getUserName(v.data.updater)},
        {field: "updatedDate", headerName: "변경일", valueGetter: v => dateTimeFormat(v.data.updatedDate), width: 180},
    ]

    useEffect(() => {
        if(customerNo){
            getManagerList(customerNo);
            CustomerStore.customerNo = customerNo;
        }else{
            CustomerStore.customerNo = props.customerInfo.customerNo;
        }

    }, [customerNo]);

    const getManagerList = async (customerNo) => {
        if (!customerNo) {
            return false;
        }

        const result = await axios.get(`/customer/tabs/${customerNo}/manager`);
        result.map(v => {v.phoneNumber = decrypt(v.phoneNumber); v.email = decrypt(v.email); return v;});   // 핸드폰, 이메일 복호화
        CustomerStore.setManagerList(result);
    }

    /* 주소팝업 UI */
    const popupAddress = (e) => {
        return (
            <>
            <div className='d-flex justify-content-between'>
                <div>{e.data.postcode}</div>
                <IconBtn key={e.rowIndex} onClick={() => openAddressPopup(callbackAddress, e)}  />
            </div>
            </>
        )
    }

    /* 주소 callback 함수 */
    const callbackAddress = (data, e) => {
        const result = CustomerStore.managerList.map(v => {
            if (v.agId === e.data.agId) {
                v.postcode = data.postcode;
                v.address = data.address;
            }
            return v;
        });
        CustomerStore.setManagerList(result);
    }



    const saveFn = async ({ updatedList, createdList }) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);
        if (!isUpdate && !isCreate) {
            await callAlert('저장할 내용이 없습니다.');
            return false;
        }

        // TODO: 필수값 검사

        if(isUpdate) {
            const updatedParams = updatedList.map(v => {v.phoneNumber=encrypt(v.phoneNumber); v.email=encrypt(v.email); return v});   // 핸드폰, 이메일 암호화
            await axios.put(`/customer/tabs/${CustomerStore.customerNo}/manager`, { managerList: updatedParams });
        }
        if(isCreate) {
            const createdParams = createdList.map(v => {v.phoneNumber=encrypt(v.phoneNumber); v.email=encrypt(v.email); return v});   // 핸드폰, 이메일 암호화
            await axios.post(`/customer/tabs/${CustomerStore.customerNo}/manager`, { managerList: createdParams });
        }

        callAlert('저장 되었습니다.');
        await getManagerList(CustomerStore.customerNo);
    }

    const removeFn = async (e) => {
        const { customerManagerSeq, managerSeq } = e.data;
        if (!await callConfirm(`[${managerSeq}]번 순번 삭제 하시겠습니까?`)) {
            return false;
        }

        await axios.delete(`/customer/tabs/${CustomerStore.customerNo}/manager/${customerManagerSeq}`);
        callAlert('삭제 되었습니다.');
        await getManagerList(CustomerStore.customerNo);
    }

    const addRowInfo = { managerName: '', department: '', jobPosition: '', telephone: '', extensionNumber: '', fax: '', phoneNumber: '',
                        email: '', role: '', isLeave: 0, isKeyMan: 0, isSupervisor: 0, postcode: props.customerInfo.companyPostcode, address: props.customerInfo.companyAddress,
                        addressDetail: props.customerInfo.companyAddressDetail, remark: '' };

    return (
        <AgGridContainer
            gridRef={gridRef}
            height={35}
            rowData={CustomerStore.managerList}
            columnDefs={columnDefs}
            isCheckBox={!!CustomerStore.customerNo}
            seqColumn={'customerManagerSeq'}
            useCsvDownload={!!CustomerStore.customerNo}
            useIntoTheTab={true}
            useCreated={!!CustomerStore.customerNo}
            useUpdated={!!CustomerStore.customerNo}
            callBackGridData={saveFn}
            addRowInfo={addRowInfo}
            useRemoveRow={true}
            removeCallback={removeFn}
        />
    );
};

export default observer(CustomerManager);

import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import AgGridContainer from '../../../components/AgGridContainer';
import { dateTimeFormat, getDays } from '../../../utils/dateUtils';
import LUtils from '../../../utils/lodashUtils';
import { callAlert, callConfirm } from '../../../utils';
import {IconBtn} from '../../../components/CustomContainer';
import { CustomerStore } from '../../../store/customer/CustomerStore';
import { openAddressPopup } from '../../../utils';
import { getUserName } from '../../../utils/userUtils';
import {encrypt, decrypt, autoHyphen, showToast} from "../../../common/utils";
import {findCommKrnm, getCommcode} from "../../../utils/commcode";
import {AppStore} from "../../../store/AppStore";
import {ICON_ADD} from "../../../common/constants";
import CustomerManagerSearch from "../../../components/searchModal/CustomerManagerSearch";

const CustomerBillingAddress = ({ customerNo, ...props }) => {
    const gridRef = useRef();
    const columnDefs = [
        {
            headerName: '청구지 정보',
            children: [
                {field: "billingAddressSeq", headerName: "순번", width: 70},
                {field: "billingAddressName", headerName: "*청구지명", editable: true, headerClass: 'grid-column-editable', width: 150},
                {field: "managerName", headerName: "담당자명", editable: true, headerClass: 'grid-column-editable', width: 100},
                {field: "phoneNumber", headerName: "휴대전화", editable: true, headerClass: 'grid-column-editable', valueFormatter: v => v.data.phoneNumber ?? decrypt(v.data.phoneNumber), width: 140},
                {field: "email", headerName: "*이메일", editable: true, headerClass: 'grid-column-editable', valueFormatter: v => v.data.email ?? decrypt(v.data.email), width: 180},
            ]
        },
        {
            headerName: '청구지 결제 정보',
            children: [
                {
                    field: "paymentType",
                    headerName: "결제방법",
                    editable: true,
                    headerClass: 'grid-column-editable',
                    valueFormatter: v => findCommKrnm(v.value, '17'),
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: getCommcode('17').map(v => v.value),
                    },
                },
                {
                    field: "billingType",
                    headerName: "청구조건",
                    editable: true,
                    headerClass: 'grid-column-editable',
                    valueFormatter: v => findCommKrnm(v.value, '21'),
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: getCommcode('21').map(v => v.value),
                    },
                    width: 120
                },
                {
                    field: "billingDay",
                    headerName: "청구일자",
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: { values: getDays().map(v => v.value) },
                    valueFormatter: params => params.value === 0 ? '말일' : `${params.value}일`,
                    headerClass: 'grid-column-editable grid-column-required'
                },
                {
                    field: "dueDateType",
                    headerName: "납기조건",
                    editable: true,
                    headerClass: 'grid-column-editable',
                    valueFormatter: v => findCommKrnm(v.value, '22'),
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: getCommcode('22').map(v => v.value),
                    },
                    width: 120
                },
                {
                    field: "dueDateDay",
                    headerName: "납기일자",
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: { values: getDays().map(v => v.value) },
                    valueFormatter: params => params.value === 0 ? '말일' : `${params.value}일`,
                    headerClass: 'grid-column-editable grid-column-required'
                },
                {
                    field: "endDate",
                    headerName: "마감일자",
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: { values: getDays().map(v => v.value) },
                    valueFormatter: params => params.value === 0 ? '말일' : `${params.value}일`,
                    headerClass: 'grid-column-editable grid-column-required'
                },
                /*{field: "contractType", headerName: "계약형태", editable: true, headerClass: 'grid-column-editable'},*/
            ]
        },
        {
            headerName: '청구지 부가 정보',
            children: [
                {field: "customerSubNo", headerName: "종사업장번호", editable: true, headerClass: 'grid-column-editable', width: 120},
                {field: "department", headerName: "부서", editable: true, headerClass: 'grid-column-editable', width: 140},
                {field: "jobPosition", headerName: "직위", editable: true, headerClass: 'grid-column-editable', width: 90},
                {field: "telephone", headerName: "전화", editable: true, headerClass: 'grid-column-editable', width: 140},
                {field: "fax", headerName: "팩스", editable: true, headerClass: 'grid-column-editable', width: 140},
                {
                    field: "postcode",
                    headerName: "우편번호",
                    width: 100,
                    cellRenderer: (e) => popupAddress(e),
                    headerClass: 'grid-column-editable grid-column-required',
                },
                {field: "address", headerName: "주소", width: 240, headerClass: 'grid-column-editable grid-column-required'},
                {field: "addressDetail", headerName: "상세주소", width: 180, editable: true, headerClass: 'grid-column-editable'},
            ]
        },
        {
            children: [
                {field: "remark", headerName: "비고", editable: true, headerClass: 'grid-column-editable', width: 200},
                {
                    field: "isActive",
                    headerName: "사용여부",
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: { values: [0, 1] },
                    valueFormatter: params => !!params.value ? '사용' : '미사용',
                    headerClass: 'grid-column-editable grid-column-required'
                },
                {field: "updater", headerName: "변경자", valueGetter: v => getUserName(v.data.updater)},
                {field: "updatedDate", headerName: "변경일", valueGetter: v => dateTimeFormat(v.data.updatedDate), width: 180},
            ]
        }
    ];

    useEffect(() => {
        if(customerNo){
            getBillingAddressList(customerNo);
            CustomerStore.customerNo = customerNo;
        }else{
            CustomerStore.customerNo = props.customerInfo.customerNo;
        };
    }, [props.customerInfo.customerNo]);


    const getBillingAddressList = async (customerNo) => {
        if (!customerNo) {
            return false;
        }

        const result = await axios.get(`/customer/tabs/billing_address/list`, { params: { customerNo }});
        result.map(v => {v.phoneNumber = decrypt(v.phoneNumber); v.email = decrypt(v.email); return v;});   // 핸드폰, 이메일 복호화
        CustomerStore.setBillingAddressList(result);
    }

    /* 주소팝업 UI */
    const popupAddress = (e) => {
        return (
            <>
            <div className='d-flex justify-content-between'>
                <div>{e.data.postcode}</div>
                <IconBtn key={e.rowIndex} onClick={() => openAddressPopup(callbackAddress, e)}  />
            </div>
            </>
        )
    }

    /* 주소 callback 함수 */
    const callbackAddress = (data, e) => {
        const result = CustomerStore.billingAddressList.map(v => {
            if (v.id === e.data.id) {
                v.postcode = data.postcode;
                v.address = data.address;
            }
            return v;
        });
        CustomerStore.setBillingAddressList(result);
    }

    const saveFn = async ({ updatedList, createdList }) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);

        if (!isUpdate && !isCreate) {
            showToast('저장할 내용이 없습니다.');
            return false;
        }

        // TODO: 필수값 검사
        if(isUpdate) {
            if(updatedList.some(v => !v.email)){
                showToast('이메일은 필수 입니다.(계산서 발송시 사용)');
                return;
            }
            const updatedParams = updatedList.map(v => {v.phoneNumber=encrypt(v.phoneNumber); v.email=encrypt(v.email); return v});   // 핸드폰, 이메일 암호화
            await axios.put(`/customer/tabs/${CustomerStore.customerNo}/billing_address`, { billingAddressList: updatedParams });
        }
        if(isCreate) {
            if(createdList.some(v => !v.email)){
                showToast('이메일은 필수 입니다.(계산서 발송시 사용)');
                return;
            }
            const createdParams = createdList.map(v => {v.phoneNumber=encrypt(v.phoneNumber); v.email=encrypt(v.email); return v});   // 핸드폰, 이메일 암호화
            await axios.post(`/customer/tabs/${CustomerStore.customerNo}/billing_address`, { billingAddressList: createdParams });
        }

        showToast('저장 되었습니다.');
        await getBillingAddressList(CustomerStore.customerNo);
    }

    const removeFn = async (e) => {
        const { customerBillingAddressSeq, billingAddressSeq } = e.data;
        if (!await callConfirm(`[${billingAddressSeq}]번 순번 삭제 하시겠습니까?`)) {
            return false;
        }

        await axios.delete(`/customer/tabs/${CustomerStore.customerNo}/billing_address/${customerBillingAddressSeq}`);
        showToast('삭제 되었습니다.');
        await getBillingAddressList(CustomerStore.customerNo);
    }

    const customerManagerSearchBtn = {
        isUsed: true,
        callbackFn:() => AppStore.toggleCustomerManagerSearchPopup(),
        icon: ICON_ADD,
        title: '담당자정보 가져오기',
        width: 160,
    }

    const addRowInfo = { billingAddressSeq: '', billingAddressName: '', managerName: '', department: '', jobPosition: '', telephone: '',
                        fax: '', phoneNumber: '', email: '', postcode: '', address: '', addressDetail: '', isActive: 1,
                        paymentType: props.customerInfo.paymentType ? props.customerInfo.paymentType : '1', billingType: props.customerInfo.billingType ? props.customerInfo.billingType : '1', 
                        billingDay: props.customerInfo.billingDay ? props.customerInfo.billingDay : 0, dueDateType: props.customerInfo.dueDateType ? props.customerInfo.dueDateType : '1', 
                        dueDateDay: props.customerInfo.dueDateDay ? props.customerInfo.dueDateDay : 0, endDate: props.customerInfo.endDate ? props.customerInfo.endDate : 0, contractType: '', remark: ''};

    const callbackManager =async(e)=> {
        if(e){
            let addData = Object.assign(Object.assign(addRowInfo, e), {isCreated: true});

            if(CustomerStore.billingAddressList.length > 0){
                const otherRowData = CustomerStore.billingAddressList[CustomerStore.billingAddressList.length - 1];
                const billingData = {
                    paymentType: otherRowData.paymentType, billingType: otherRowData.billingType, billingDay: otherRowData.billingDay,
                    dueDateType: otherRowData.dueDateType, dueDateDay: otherRowData.dueDateDay, endDate: otherRowData.endDate
                }
                addData = Object.assign(addData, billingData);
                addData.addRowId = CustomerStore.billingAddressList.length;
            }

            CustomerStore.setBillingAddressList(CustomerStore.billingAddressList.concat(addData));
        }
    }

    return (
        <>
            <CustomerManagerSearch callbackFn={callbackManager} customerNo={customerNo} />
            <AgGridContainer
                gridRef={gridRef}
                height={35}
                rowData={CustomerStore.billingAddressList}
                columnDefs={columnDefs}
                isCheckBox={!!CustomerStore.customerNo}
                seqColumn={'agId'}
                useCsvDownload={!!CustomerStore.customerNo}
                useCreated={!!CustomerStore.customerNo}
                useUpdated={!!CustomerStore.customerNo}
                callBackGridData={saveFn}
                addRowInfo={addRowInfo}
                useRemoveRow={true}
                removeCallback={removeFn}
                customBtnInfo={[customerManagerSearchBtn]}
            />
        </>
    );
};

export default observer(CustomerBillingAddress);

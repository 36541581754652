import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Col, Row } from "react-bootstrap";

import { CContainer, CInputGroup, CCol, CSearchBtn, CSelectGroup, Subject, CSaveBtn, CAddBtn, CRemoveBtn } from '../../components/CustomContainer';
import { getCommcode } from "../../utils/commcode";
import { callConfirm, getLocalStorage } from "../../utils";
import { encrypt, showToast } from "../../common/utils";
import { AppStore } from "../../store/AppStore";
import { CertificateStore } from "../../store/commCode/CertificateStore";
import { PELOTONLAB, USER_INFO } from "../../common/constants";

const CertificateInfo = ({getCertificateList}) => {
    const store = CertificateStore;
    const [companyList, setCompanyList] = useState([]);
    const userInfo = getLocalStorage(USER_INFO);

    useEffect(() => {
        if (PELOTONLAB === userInfo.companyCode) {
            getCompanyList();
        } else {
            store.data.companyCode = userInfo.companyCode;
            store.data.companyName = userInfo.companyName;
        } 
    }, []);

    const getCompanyList = async () => {
        const result = await axios.get('/user/company');
        const resultList = result.map(v => {
            v.name = v.companyName;
            v.value = v.companyCode;
            return v;
        })

        setCompanyList(resultList);
    }

    /* 새로 등록 or 수정 후 저장 */
    const saveCertificate = async () => {
        // 필수값 확인
        const requiredParams = ['certName', 'certPassword', 'businessNumber', 'companyCode'];

        if (!requiredParams.every(param => store.data[param])) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        // 인증서 비밀번호 암호화
        store.data.certPassword = encrypt(store.data.certPassword);

        if (!store.data.certificateSeq) {
            if (!await callConfirm('등록하시겠습니까?')) return;
            const result = await axios.post('certificate', store.data);
            if(result === "success") showToast("등록이 완료되었습니다.");
            else alert(result);
        } else {
            if(!await callConfirm('수정하시겠습니까?')) return;
            const result = await axios.put('certificate', store.data);
            if(result === "success") showToast("수정이 완료되었습니다.");
            else alert(result);
        }
        getCertificateList();
    }

    /* 계좌 등록 버튼 클릭 시 작성칸 비우기  */
    const initCertificate = () => {
        store.dataInit();
    }

    /* 인증서 삭제 */
    const removeCertificate = async () => {
        if (!await callConfirm('삭제하시겠습니까?')) return;

        const result = await axios.delete(`certificate/${store.data.certificateSeq}`);
        if(result === "success") {
            showToast("삭제되었습니다.");
            store.dataInit();
        }else {
            alert(result);
        }
        getCertificateList();
    }

    return (
        <Col>
            <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CAddBtn title={'인증서등록'} style={{ width: 100 }} onClick={initCertificate} />
                        {store.data.certificateSeq && <CRemoveBtn title={'인증서삭제'} style={{ width: 100 }} onClick={removeCertificate} />}
                    </Col>
                </Row>
                <Subject>인증서정보</Subject>
                <Row>
                <CCol lg={6}>
                        <CSelectGroup label={'인증서 용도'} labelId={'certUsage'}
                                    placeholder={'회사구분'}
                                    options={getCommcode('46')}
                                    value={store.data.certUsage}
                                    onChange={v => store.data.certUsage = v.target.value}
                                    labelClassName='input-required'
                                    disabled={store.data.certificateSeq ? true : false}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        {/* <CSelectGroup label={'회사구분'} labelId={'companyCode'}
                                    placeholder={'회사구분'}
                                    options={[{name: '전체', value: ''}].concat(getCommcode('20'))}
                                    value={store.data.companyCode}
                                    onChange={v => store.data.companyCode = v.target.value}
                                    labelClassName='input-required'
                        /> */}
                        {PELOTONLAB === userInfo.companyCode &&
                            <CSelectGroup label={'회사'}
                                        labelId={'companyCode'}
                                        options={[{ name: '전체', value: '' }].concat(companyList)}
                                        value={store.data.companyCode || ''}
                                        onChange={v => store.data.companyCode = v.target.value}
                                        labelClassName='input-required' />
                        }
                        {PELOTONLAB !== userInfo.companyCode &&
                            <CInputGroup label={'회사'}
                                        labelId={'companyName'}
                                        value={store.data.companyName || ''}
                                        onChange={v => store.data.companyName = v.target.value} 
                                        disabled={true}/>
                        }
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'사업자번호'} labelId={'businessNumber'}
                                     value={store.data.businessNumber}
                                     onChange={v => store.data.businessNumber = v.target.value}
                                     labelClassName='input-required'
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'인증서이름'} labelId={'certName'}
                                    value={store.data.certName}
                                    onChange={v => store.data.certName = v.target.value}
                                    disabled={true}
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'인증서비밀번호'} labelId={'certPassword'}
                                    value={store.data.certPassword}
                                    onChange={v => store.data.certPassword = v.target.value}
                                    type={'password'}
                                    labelClassName='input-required'
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'만료일'} labelId={'expirationDate'}
                                    value={store.data.expirationDate}
                                    onChange={v => store.data.expirationDate = v.target.value}
                                    disabled={true}
                        />
                    </CCol>
                    {/* <CCol lg={6}>
                        <CInputGroup label={'인증서 ID'} labelId={'certId'}
                                    value={store.data.certId}
                                    onChange={v => store.data.certId = v.target.value}
                                    disabled={true}
                        />
                    </CCol> */}
                </Row>
                {!store.data.certificateSeq ? (
                    <Row>
                        <Col className='d-flex justify-center'>
                            <CSearchBtn style={{ width: 180, marginBottom: 10 }}
                                title={'인증서 가져오기'}
                                onClick={() => AppStore.toggleCertificatePopup()}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col className='d-flex justify-center'>
                            <CSearchBtn style={{ width: 180, marginBottom: 10 }}
                                title={'인증서 변경하기'}
                                onClick={() => AppStore.toggleCertificatePopup()}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <CCol lg={12}>
                        <CInputGroup label={'비고'} labelId={'remark'}
                                    placeholder={'비고를 입력해 주세요.'}
                                    value={store.data.remark}
                                    onChange={v => store.data.remark = v.target.value}
                        />
                    </CCol>
                </Row>
            </CContainer>
            <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn style={{ width: 80 }} title={'저장'} onClick={saveCertificate} />
                    </Col>
                </Row>
            </CContainer>
        </Col>
    );
};

export default observer(CertificateInfo);

import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";


export const ProductStore = observable({
    data : {
        productSeq: 0,
        ccSM: '',
        isDeleted: 0,
        productName: '',
        model: '',
        spec: '',
        remark: '',
    },
        
    productList: [],
    productGroupList: [],
    productSearchCondition: {productCode: '', lgSeq: '', model: '', productName: '', brandCode: '', isDeleted: 0},
    setData(v) {
        LUtils.assign(this.data, v);
    },
    setProductList(v) {
        this.productList = v;
    },
    setProductGroupList(v){
        this.productGroupList = v;
    },
    setProductSearchCondition(v) {
        LUtils.assign(this.productSearchCondition, v);
    },

    init() {
        this.data.productSeq = 0;
        this.data.ccSM = '';
        this.data.isDeleted = 0;
        this.data.productName = '';
        this.data.model = '';
        this.data.spec = '';
        this.data.remark = '';
    },
});

import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {CalendarGroup, CCol, CContainer, CIconBtn, CInputGroup, CSelectGroup, IconBtn, Subject} from "../../components/CustomContainer";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {StorageRegisterListStore} from "../../store/storage/StorageRegisterListStore";
import {findCommKrnm, getCommcode} from "../../utils/commcode";
import {AppStore} from "../../store/AppStore";
import LUtils from "../../utils/lodashUtils";
import axios from "axios";
import {MULTIPART_HEADER} from "../../common/constants";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import {today, getInputTime} from "../../utils/dateUtils";
import AgGridContainer from "../../components/AgGridContainer";
import {callConfirm} from "../../utils";
import {showToast} from "../../common/utils";

const StorageAssetAcquisition =()=> {
    const acquGridRef = useRef();
    const acquDataRef = useRef();

    const [inputSerialNo, setInputSerialNo] = useState('');
    const [inputManagementNo, setInputManagementNo] = useState('');

    useEffect(()=> {
        //취득일자는 기본으로 오늘날짜로 설정한다.
        StorageRegisterListStore.setAssetAcquDate(today());
        StorageRegisterListStore.setAcquAssetInfo('acquDate', today());

        return ()=> {
            StorageRegisterListStore.assetAcquInfoInit();
        }
    }, [])

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData, type) => {

        if (LUtils.isEmpty(fileData.target.files) && type === 'assetCertificate') {
            StorageRegisterListStore.setAcquAssetInfo('assetCertificateUrl', '');
            return false;
        }
        // else if (LUtils.isEmpty(fileData.target.files) && type === 'insurancePolicy') {
        //     StorageRegisterListStore.setAcquAssetInfo('insurancePolicyUrl', '');
        //     return false;
        // }

        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });

        if(type === 'assetCertificate') {
            StorageRegisterListStore.setAcquAssetInfo('assetCertificateUrl', result);
            StorageRegisterListStore.setAcquAssetInfo('assetCertificateFileName', file.name);
        }
        // else if(type === 'insurancePolicy') {
        //     StorageRegisterListStore.setAcquAssetInfo('insurancePolicyUrl', result);
        //     StorageRegisterListStore.setAcquAssetInfo('insurancePolicyFileName', file.name);
        // }      
    }

    /* 그리드 파일 업로드 하고 url 가져오기 */
    const gridFileUploadForS3 = async (fileData, gridData) => {
        if (LUtils.isEmpty(fileData.target.files)) {
            gridData.data.assetCertificateUrl = '';
            gridData.data.assetCertificateFileName = '';
            return false;
        }
      
        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });

        gridData.data.assetCertificateUrl = result;
        gridData.data.assetCertificateFileName = file.name;
    }

    /* 고객 조회 팝업 */
    const searchCustomerPopup = () => {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 조회 콜백 */
    const searchCustomerInfo =(data)=> {
        if(data){
            StorageRegisterListStore.setAcquAssetInfo('buyCustomerNo', data.customerNo);
            StorageRegisterListStore.setAcquAssetInfo('buyCustomerName', data.customerName);
        }
    }

    /* 제품 추가 callback */
    const callbackProductInfo = (info) => {
        StorageRegisterListStore.setAcquAssetInfo('lgCate', info.lg);
        StorageRegisterListStore.setAcquAssetInfo('productSeq', info.productSeq);
        StorageRegisterListStore.setAcquAssetInfo('productName', info.productName);
        StorageRegisterListStore.setAcquAssetInfo('model', info.model);
        StorageRegisterListStore.setAcquAssetInfo('spec', info.spec);
    }

    const addRowAsset =()=> {
        if(!StorageRegisterListStore.acquAssetInfo.productName){
            showToast('제품을 먼저 선택해주세요.');
            return;
        }

        if(!StorageRegisterListStore.acquAssetInfo.assetType){
            showToast('자산 구분을 선택해주세요.');
            return;
        }
        if(!StorageRegisterListStore.acquAssetInfo.acquDate){
            showToast('취득일자를 입력해주세요.');
            return;
        }
        if(!StorageRegisterListStore.acquAssetInfo.acquAmount){
            showToast('취득가를 입력해주세요.');
            return;
        }
        if(!StorageRegisterListStore.acquAssetInfo.newOrUsed){
            showToast('신품/중고 여부를 선택해주세요.');
            return;
        }
        // if(!StorageRegisterListStore.acquAssetInfo.makeDate){
        //     showToast('제조년월을 입력해주세요.');
        //     return;
        // }

        let addIndex = StorageRegisterListStore.assetAcquList.length;
        for (let i = 0; i < StorageRegisterListStore.acquAssetInfo.qty; i++) {
            StorageRegisterListStore.pushAssetAcquList(Object.assign({ isCreated: true, addRowId: addIndex++ }, StorageRegisterListStore.acquAssetInfo));
        }
    
        //초기화
        setTimeout(()=> {
            StorageRegisterListStore.assetAcquInfoInit();
            //imgRef.current.value = "";
        }, 200)
    }

    const localeMoneyString =(val)=> {
        return val ? Number(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
    }

    const hasDuplicate = (list, key) => {
        const values = list.map(item => item[key]);
        return new Set(values).size !== values.length;
    };

    /* 상품 저장 */
    const onSaveStorageAssetAcqu = async () => {
        // 필수값 확인
        if(!StorageRegisterListStore.assetAcquList.some(item => item.serialNo !== null && item.serialNo !== '')){
            showToast('시리얼번호(차대번호)를 입력해주세요.');
            return;
        }

        if (!await callConfirm('입고하시겠습니까?')) {
            return false;
        }

        // 중복 확인
        const isDuplicateManagementNo = hasDuplicate(StorageRegisterListStore.assetAcquList, 'managementNo');
        if(isDuplicateManagementNo) {
            showToast('관리번호에 중복이 있습니다.');
            return;
        }
        const isDuplicateSerialNo = hasDuplicate(StorageRegisterListStore.assetAcquList, 'serialNo');
        if(isDuplicateSerialNo) {
            showToast('시리얼번호에 중복이 있습니다.');
            return;
        }

        await axios.post('/asset/acqu', StorageRegisterListStore.assetAcquList);
        StorageRegisterListStore.setAssetAcquList([]);

        showToast('자산으로 취득입고 되었습니다.');
    }

    const resetInputField =()=> {
        setInputSerialNo('');
        setInputManagementNo('');
        StorageRegisterListStore.setCheckCountSec(0);
    }

    const pushInputNumber =(number, type)=> {
        const inputType = type === 'serial' ? 'serialNo' : 'managementNo';
        const inputTypeName = type === 'serial' ? '시리얼번호' : '관리번호';

        if(!number){
            return;
        }

        if(StorageRegisterListStore.isReader && number.length === 1){
            StorageRegisterListStore.setCheckCountSec(getInputTime());
            return;
        }

        if(StorageRegisterListStore.isReader && (getInputTime() - StorageRegisterListStore.checkCountSec) < 1){
            return;
        }

        if(StorageRegisterListStore.assetAcquList.some(v => v[inputType] === number)){
            showToast(`이미 입력된 ${inputTypeName} 입니다.`);
            resetInputField();
            return false;
        }

        const emptyIndex = StorageRegisterListStore.assetAcquList.findIndex(v => !v[inputType]);
        if(emptyIndex > -1){
            StorageRegisterListStore.assetAcquList[emptyIndex][inputType] = number;
            resetInputField();
            // 그리드 전체 다시 그리기
            acquGridRef.current.api.redrawRows();
        }else{
            showToast(`${inputTypeName}가 모두 입력되었습니다.`);
        }
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            value={StorageRegisterListStore.acquAssetInfo.productName}
                            onCallbackBtn={()=> AppStore.toggleProductListSearchModal()}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'모델'}
                            labelId={'model'}
                            value={StorageRegisterListStore.acquAssetInfo.model}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'스펙'}
                            labelId={'spec'}
                            value={StorageRegisterListStore.acquAssetInfo.spec}
                            disabled={true}
                        />
                    </CCol>
                </Row>
                <Subject>자산정보</Subject>
                <Row>
                    {/* <CCol lg={2}>
                        <CInputGroup
                            isValid={true}
                            label={'관리번호*'}
                            labelId={'managementNo'}
                            value={StorageRegisterListStore.acquAssetInfo.managementNo}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('managementNo', e.target.value)}
                        />
                    </CCol> */}
                    <CCol lg={2}>
                        <CSelectGroup
                            isValid={true}
                            label={'자산구분*'}
                            labelId={'assetType'}
                            options={[{name: '선택', value: ''}].concat(getCommcode('7'))}
                            value={StorageRegisterListStore.acquAssetInfo.assetType || ''}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('assetType', e.target.value)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        {/*                    <CInputDoubleGroup label={'구매처'} labelId={'buyCustomerNo'} labelId2={'buyCustomerName'}
                                       placeholder={'구매처코드'} placeholder2={'구매처'}
                                       disabled={true} disabled2={true}
                                       onCallbackBtn={() => searchCustomerPopup('buyCustomer')}
                                       value={StorageRegisterListStore.acquAssetInfo.buyCustomerNo}
                                       value2={StorageRegisterListStore.acquAssetInfo.buyCustomerName}
                                       labelClassName='input-required'
                    />*/}
                        <CInputGroup
                            label={'구매처'}
                            labelId={'buyCustomerName'}
                            value={StorageRegisterListStore.acquAssetInfo.buyCustomerName}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('buyCustomerName', e.target.value)}
                        />
                    </CCol>

                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'임차종료일자'}
                            value={StorageRegisterListStore.hireEndDate}
                            //minDate={today()}
                            onChange={v=> {
                                StorageRegisterListStore.setHireEndDate(v.startDate);
                                StorageRegisterListStore.setAcquAssetInfo('hireEndDate', v.startDate);
                            }}
                        />
                    </CCol>
                </Row>
                <Row>
                    {/* <CCol lg={2}>
                        <CInputGroup
                            isValid={true}
                            label={'시리얼번호*'}
                            labelId={'serialNo'}
                            value={StorageRegisterListStore.acquAssetInfo.serialNo}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('serialNo', e.target.value)}
                        />
                    </CCol> */}
                    <CCol log={2}>
                        <CalendarGroup
                            isValid={true}
                            asSingle={true}
                            label={'취득일자*'}
                            value={StorageRegisterListStore.assetAcquDate}
                            //minDate={today()}
                            onChange={v=> {
                                StorageRegisterListStore.setAssetAcquDate(v.startDate);
                                StorageRegisterListStore.setAcquAssetInfo('acquDate', v.startDate);
                            }}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            isValid={true}
                            label={'취득가'}
                            backLabel={'원'}
                            labelId={'acquAmount'}
                            align={'right'}
                            value={StorageRegisterListStore.acquAssetInfo.acquAmount || ''}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('acquAmount', e.target.value)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            isValid={true}
                            label={'신품/중고'}
                            labelId={'newOrUsed'}
                            options={[{name: '선택', value: ''},{name: '신품', value: '1'}, {name: '중고', value: '2'}]}
                            value={StorageRegisterListStore.acquAssetInfo.newOrUsed || ''}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('newOrUsed', e.target.value)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            // isValid={true}
                            label={'연식'}
                            labelId={'manufacturing'}
                            value={StorageRegisterListStore.acquAssetInfo.manufacturing}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('manufacturing', e.target.value)}
                            placeholder={'YYYY'}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'선반번호'}
                            labelId={'shelfId'}
                            value={StorageRegisterListStore.acquAssetInfo.shelfId}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('shelfId', e.target.value)}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'비고사항'}
                            labelId={'remark'}
                            value={StorageRegisterListStore.acquAssetInfo.remark}
                            onChange={(e)=> StorageRegisterListStore.setAcquAssetInfo('remark', e.target.value)}
                        />
                    </CCol>
                    {/* <CCol lg={4}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>보험가입증 업로드</label>
                            <Form.Control
                                type="file"
                                id="profileImg"
                                size="sm"
                                placeholder={'보험가입증 업로드'}
                                onChange={(v) => fileUploadForS3(v, 'insurancePolicy')}
                                ref={imgRef}
                            />
                        </InputGroup>
                    </CCol> */}
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn
                            icon={'fi-ss-plus-small'}
                            onClick={addRowAsset}
                            style={{width: 100}}
                            title={'추가'}
                        />
                        <CCol lg={2}>
                            <CInputGroup label={'개수'} labelId={'qty'}
                                        value={StorageRegisterListStore.acquAssetInfo.qty}
                                        onChange={v => {
                                            if(isNaN(v.target.value)) {
                                                showToast('숫자만 입력해야 합니다.');
                                                return false;
                                            }
                                            if(Number(v.target.value) > 1 && (StorageRegisterListStore.acquAssetInfo.assetCertificateFileName != undefined && (StorageRegisterListStore.acquAssetInfo.assetCertificateFileName !== null && StorageRegisterListStore.acquAssetInfo.assetCertificateFileName !== ''))) {
                                                showToast('개별 안전인증서가 있으면 1개만 가능합니다.')
                                                return false;
                                            }
                                            StorageRegisterListStore.acquAssetInfo.qty = v.target.value;
                                        }}
                                        type={'number'}
                            />
                        </CCol>
                    </Col>
                </Row>
            </CContainer>
            <CContainer search>
                <Row>
                    <CCol lg={1}>
                        <Form.Check
                            type="switch"
                            id="isReader"
                            label="리더사용"
                            checked={StorageRegisterListStore.isReader}
                            style={{marginTop: 4}}
                            onChange={()=> StorageRegisterListStore.setIsReader(!StorageRegisterListStore.isReader)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            ref={(ref)=> ref}
                            label={'시리얼번호'}
                            labelId={'inputSerialNo'}
                            placeholder={'시리얼번호를 입력후 Enter키를 입력해주세요'}
                            value={inputSerialNo}
                            onChange={(e)=> {
                                if(e.keyCode !== 8) {
                                    let no = e.target.value;
                                    setInputSerialNo(no);
                                    setTimeout(()=> {
                                        StorageRegisterListStore.isReader && pushInputNumber(no, 'serial');
                                    }, 500);
                                }
                            }}
                            enterKeyHint={()=> pushInputNumber(inputSerialNo, 'serial')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            ref={(ref)=> ref}
                            label={'관리번호'}
                            labelId={'inputManagementNo'}
                            placeholder={'관리번호를 입력후 Enter키를 입력해주세요'}
                            value={inputManagementNo}
                            onChange={(e)=> {
                                if(e.keyCode !== 8) {
                                    let no = e.target.value;
                                    setInputManagementNo(no);
                                    setTimeout(()=> {
                                        StorageRegisterListStore.isReader && pushInputNumber(no, 'management');
                                    }, 500);
                                }
                            }}
                            enterKeyHint={()=> pushInputNumber(inputManagementNo, 'management')}
                        />
                    </CCol>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridTitle='취득 입고 대상 리스트'
                    gridRef={acquGridRef}
                    ref={acquDataRef}
                    height={50}
                    rowData={StorageRegisterListStore.assetAcquList}
                    columnDefs={[
                        {field: "storageType", headerName: "입고구분", hide: true},
                        {field: "addRowId", headerName: "addRowId", hide: true},
                        {field: "storageSeq", headerName: "순번", valueGetter: e => e.node.rowIndex + 1, width: 80 },
                        {field: "productSeq", headerName: "제품SEQ", hide: true},
                        {field: "lgCate", headerName: "분류", width: 120},
                        {field: "productName", headerName: "제품명", width: 140},
                        {field: "model", headerName: "모델명", width: 140},
                        {field: "spec", headerName: "스펙", width: 200},
                        {field: "assetType", headerName: "자산구분", valueFormatter: v => findCommKrnm(v.value, '7'), width: 110},
                        {field: "managementNo", headerName: "관리번호", width: 140, headerClass: 'grid-column-editable', editable: true},
                        {field: "serialNo", headerName: "시리얼번호", width: 140, headerClass: 'grid-column-required', editable: true},
                        {field: "acquDate", headerName: "취득일자", width: 120, headerClass: 'grid-column-editable', editable: true},
                        {field: "acquAmount", headerName: "취득금액", valueFormatter: v => v.value.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130, headerClass: 'grid-column-editable', editable: true},
                        {field: "shelfId", headerName: "선반번호",  headerClass: 'grid-column-editable', width: 140, editable: true},
                        {field: "newOrUsed", headerName: "신품/중고", width: 120, valueFormatter: v => v.value === '1' ? '신품' : '중고'},
                        {field: "buyCustomerNo", headerName: "구매처 고객번호", hide: true},
                        {field: "buyCustomerName", headerName: "구매처", width: 140},
                        {field: "hireEndDate", headerName: "임차종료일자", width: 120},
                        {field: "remark", headerName: "비고사항", width: 250},
                    ]}
                    seqColumn={'storageSeq'}
                    isCheckBox={true}
                    useUpdated={true}
                    callBackGridData={onSaveStorageAssetAcqu}
                    //rowDoubleClickCallback={getConsumList}
                />
            </CContainer>
            <ProductListSearch callbackFn={callbackProductInfo} />
            <CustomerSearch callbackFn={searchCustomerInfo} />
        </>
    )
}

export default observer(StorageAssetAcquisition);

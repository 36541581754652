import axios from 'axios';
import { callAlert } from '.';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { BASE_URL } from '../common/constants';
import LUtils from './lodashUtils';

import { AppStore } from '../store/AppStore';

export default () => {
    // 전역 설정
    axios.defaults.baseURL = BASE_URL + '/api/v1';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.timeout = 20000;
    if (AppStore.isLogin && !_.isEmpty(AppStore.jwt)) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + AppStore.jwt;
    }

    NProgress.configure({ showSpinner: false });

    axios.interceptors.request.use(
        config => {
            NProgress.start(); // 프로그래스바 시작
            if(AppStore.isAxiosSetting) {
                config.timeout = 0;
                AppStore.isAxiosSetting = false;
            }
            AppStore.setRunningSpinner();
            return config;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        async(config) => {
            NProgress.done(); // 프로그래스바 완료
            AppStore.setStopSpinner();
            config.timeout = 20000;
            return LUtils.get(config, 'data', {});
        },
        async(error) => {
            NProgress.done(); // 프로그래스바 완료
            AppStore.setStopSpinner();

            const errorResponse = LUtils.get(error, 'response');
            const {code, message, status, errorMessage} = LUtils.get(error.response, 'data');

            if ((!errorResponse || !code) && !errorMessage) {
                callAlert('[서버 에러] 관리자에게 문의 바랍니다.[A]');
                return Promise.reject(error);
            }else if(errorMessage) {
                callAlert(message);
                return Promise.reject(error);
            }

            if(code && code !== 'INTERNAL_SERVER_ERROR'){
                console.log('code, message, status', code, message, status);

                if(code === 'UNAUTHORIZED'){
                    await callAlert('다시 로그인 해주세요.');
                    // if (window.location.href.includes("/as/accept/detail")) {
                    //     window.location.href = window.location.href + "/login"; // 페이지 이동
                    // } else {
                        window.location.href = '/login'; // 페이지 이동
                    // }
                    return;
                } else if(code !== 'LOGIN_FAILED' && code !== 'NO_LOGIN_CODE') {
                    callAlert(`[${status}] ${message}`);
                }
            }

            if (LUtils.startsWith(String(status), '5') || !code) {
                callAlert(`[${code || ''}] ${message}`);
            }

            return Promise.reject(error);
        }
    );
}

export const setAxiosAuthorization = () => {
    if (!_.isEmpty(AppStore.jwt)) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + AppStore.jwt;
    } else {
        axios.defaults.headers.common['Authorization'] = '';
    }
}

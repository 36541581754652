import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { CContainer, IconBtn } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { NonpaymentStore } from "../../store/billing/NonpaymentStore";
import { AppStore } from "../../store/AppStore";
import Modal from "../../components/Modal";
import { dateFormat } from "../../utils/dateUtils";
import { findCommKrnm } from "../../utils/commcode";
import { showToast } from "../../common/utils";
import NonPaymentManagerSearch from "./NonPaymentManagerSearch";
import NonPaymentConsultationPopup from "./popup/NonPaymentConsultationPopup";

const NonPaymentManager =()=> {
    const gridRef = useRef();
    const [nonpayment, setNonpayment] = useState();


    useEffect(() => {
        getCompanyBankAccount();
    }, [])

    /* 대표 계좌 가져오기 */
    const getCompanyBankAccount = async () => {
        const result = await axios.get('/nonpayment/account/management/list');
        const companyBankAccount = result.filter(v => v.isRepresentative === 1);
        if (companyBankAccount.length > 0) {
            NonpaymentStore.bankCode = companyBankAccount[0].bankCode;
            NonpaymentStore.accountNumber = companyBankAccount[0].accountNumber;
            NonpaymentStore.accountHolder = companyBankAccount[0].accountHolder;
        }
    }

    const pinnedTopRowData = [{
        dueNonpaymentAmount: NonpaymentStore.nonpaymentList.reduce((total, v) => total + v.dueNonpaymentAmount, 0),
        chargeTotalAmount: NonpaymentStore.nonpaymentList.reduce((total, v) => total + v.chargeTotalAmount, 0),
        customerName: 'pinnedTopRowData'
    }];

    const columnDefs=[
    {field: "contractNo", headerName: "계약번호", width: 160},
    {field: "billNo", headerName: "계산서번호", width: 130},
    {field: "customerName", headerName: "고객명", width: 160, cellRenderer: (v) => openConsultation(v)},
    {field: "contractType", headerName: "계약구분", valueFormatter: v => findCommKrnm(String(v.data.contractType), '1'), width: 110},
    {field: "saleUserName", headerName: "영업사원", width: 110},
    {field: "chargeDate", headerName: "청구일자", valueGetter: v => dateFormat(v.data.chargeDate), width: 120},
    {field: "dueDate", headerName: "납기일자", valueGetter: v => dateFormat(v.data.dueDate), width: 120},
    {field: "dueNonpaymentDate", headerName: "납기미납일수", valueGetter: v => v.data.dueNonpaymentDate, cellClass: 'ag-grid-money-align', width: 130},
    {field: "dueNonpaymentAmount", headerName: "납기미납액", valueFormatter: v => v.data.dueNonpaymentAmount?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130},
    {field: "chargeTotalAmount", headerName: "총청구액", valueFormatter: v => v.data.chargeTotalAmount?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130},
    {field: "chargeNonpaymentDate", headerName: "청구미납일수", valueGetter: v => v.data.chargeNonpaymentDate, cellClass: 'ag-grid-money-align', width: 120, hide: true},
    {field: "latePayment", headerName: "연체료"},
    {field: "billingAddressName", headerName: "청구지", width: 140},
    {field: "billingAddressSeq", headerName: "청구지순번", width: 130},
    {field: "recentReceiveDate", headerName: "최근수납일", valueGetter: v => dateFormat(v.data.recentReceiveDate), width: 120},
    {field: "bondType", headerName: "채권형태", valueFormatter: v => findCommKrnm(String(v.data.bondType), '37'), width: 135},
    {field: "bondActivity", headerName: "마지막 채권활동", valueFormatter: v => findCommKrnm(String(v.data.bondActivity), '38'), width: 150},
    {field: "consultationDate", headerName: "마지막 상담일자", valueGetter: v => dateFormat(v.data.consultationDate), width: 150},
    {field: "paymentPromiseDate", headerName: "납부약속일", valueGetter: v => dateFormat(v.data.paymentPromiseDate), width: 120},
    {field: "consultationContent", headerName: "상담내용", width: 200, suppressSizeToFit: true},
    {field: "customerNo", headerName: "고객번호", hide: true}
]

    const getNonpaymentList = async() => {
        const result = await axios.get('/nonpayment', {params: NonpaymentStore.search});
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
            NonpaymentStore.setNonpaymentList(result);
        }
        NonpaymentStore.nonpaymentList = result;
    }

    const openConsultation = (e) => {
        return (
            <>
                {e.value !== 'pinnedTopRowData' && 
                    <div className='d-flex justify-content-between'>
                        <div className="mr-1">{e.data.customerName}</div>
                        <IconBtn style={{paddingTop: 6, color:'darkblue', textAlign: 'right'}} 
                            key={e.rowIndex} 
                            onClick={() => {
                                openConsultationEvent(e.data);
                            }} />
                    </div>
                }
            </>
        )
    }

    const openConsultationEvent = async (data) => {
        NonpaymentStore.data = data;
        await getCustomerNonpaymentList();
        AppStore.toggleOpenNonpaymentDetailPopup();
    }

    /* 고객별 미납 내역 조회 및 총미납액/최대납기일 세팅 */
    const getCustomerNonpaymentList = async() => {
        const result = await axios.get('/nonpayment', {params: {customerNo: NonpaymentStore.data.customerNo}});
        NonpaymentStore.setCustomerNonpaymentList(result);

        let amount = 0;
        let maxDueNonpaymentDate = 0;

        NonpaymentStore.customerNonpaymentList.forEach(v => {
            amount += v.dueNonpaymentAmount;
            maxDueNonpaymentDate = maxDueNonpaymentDate < v.dueNonpaymentDate ? v.dueNonpaymentDate : maxDueNonpaymentDate;
        });

        NonpaymentStore.data.totalNonpaymentAmount = amount;
        NonpaymentStore.data.maxDueNonpaymentDate = maxDueNonpaymentDate;
    }

    return (
        <>
            <NonPaymentManagerSearch getNonpaymentList={getNonpaymentList}/>
            <CContainer>
                <AgGridContainer
                    gridTitle={'미납목록'}
                    gridRef={gridRef}
                    height={63}
                    rowData={NonpaymentStore.nonpaymentList}
                    columnDefs={columnDefs}
                    // rowDoubleClickCallback={(v) => openConsultation(v)}
                    useCsvDownload={true}
                    pinnedTopRowData={pinnedTopRowData}
                    />
            </CContainer>
            <NonPaymentConsultationPopup getNonpaymentList={getNonpaymentList} getCustomerNonpaymentList={getCustomerNonpaymentList}/>
        </>
    );
}

export default observer(NonPaymentManager);

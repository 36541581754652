import { useRef } from "react";
import { Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";

import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import {showToast} from "../../common/utils";
import { ICON_ADD, ICON_SAVE } from "../../common/constants";
import LUtils from "../../utils/lodashUtils";
import { ConsumablePopupStore } from "../../store/asset/ConsumablePopupStore";
import { callConfirm } from "../../utils";
import StockConsumableSearch from "./StockConsumableSearch";

const AddConsumablePopup =({ callbackFn=null, selProductList, assetNo=null, getAssetConsumableList, searchStorage })=> {
    const store = ConsumablePopupStore;
    const gridRef = useRef();

    const saveBtn = {
        isUsed: true,
        title: '소모품 결합',
        callbackFn: () => saveAssetConsumable(),
        icon: ICON_SAVE,
        variant: 'success'
    };

    const selectBtn = {
        isUsed: true,
        title: '소모품 선택',
        callbackFn: () => AppStore.toggleStockConsumableSearchModal(),
        icon: ICON_ADD,
    };
    
    const saveAssetConsumable = async () => {
        if(!await callConfirm('소모품 결합하시겠습니까?')) return;

        if(assetNo) {
            await axios.post('/asset/consumable', {assetNo: assetNo, consumableList: store.consumableList});
        }else {
            await axios.post('/storage/consumable', {storageList: selProductList, consumableList: store.consumableList});
        }
        showToast('소모품 결합되었습니다.');
       
        if (typeof getAssetConsumableList === 'function') {   
            getAssetConsumableList();
        }
        if (typeof searchStorage === 'function') {   
            searchStorage();
        }
        AppStore.toggleAddConsumablePopup()
    }

    /* 소모품 추가 콜백 함수 */
    const callbackConsumableListSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        let seq = store.consumableList.length + 1;
        let newConsumableList = [...store.consumableList];

        for(let consumable of info) {
            const additionalRowInfo = {
                addRowId: seq,
                seq: seq,
                consumProductSeq: consumable.productSeq,
                consumProductName: consumable.productName,
                consumModel: consumable.model,
                consumProductCode: consumable.productCode,
                qty: 1,
                maxQty: consumable.stockQty,
                isSelected: true,
                isCreated: true
            };
            newConsumableList.push(additionalRowInfo);
            seq++;
        }

        store.consumableList = newConsumableList;
    }

    const handleConsumQtyEdit = async(e)=> {
        if(e.data.maxQty < e.newValue) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        } else {
            e.data.qty = e.newValue;
        }
     
        gridRef.current.api.redrawRows();
    }

    /* 초기화 */
    const refresh = () => {
        store.consumableList = [];
    }

    return (
        <Modal
            title={'소모품 결합'}
            show={AppStore.isOpenAddConsumablePopup}
            onHide={() => AppStore.toggleAddConsumablePopup()}
            toggleValue={AppStore.isOpenAddConsumablePopup}
            size={'lg'}
            onExit={refresh}
        >
            <CContainer>
                {!assetNo &&
                    <Row>
                        <CCol lg={12} >
                            선택된 시리얼 번호 : {selProductList.map(v => v.serialNo).join(', ')} 
                        </CCol>
                    </Row>
                }
                <AgGridContainer
                    gridRef={gridRef}
                    height={20}
                    rowData={store.consumableList}
                    columnDefs={[
                        {field: "seq", headerName: "순번", width: 80},
                        {field: "consumProductSeq", headerName: "제품 시퀀스", width: 120},
                        {field: "consumProductName", headerName: "제품명", width: 120},
                        {field: "consumModel", headerName: "모델명", width: 240},
                        {field: "qty", headerName: "수량", headerClass: 'grid-column-editable', onCellValueChanged: handleConsumQtyEdit, editable: true, width: 140, flex: 1},
                    ]}
                    isCheckBox={true}
                    customBtnInfo={[saveBtn, selectBtn]}
                />
            </CContainer>
            <StockConsumableSearch callbackFn={callbackConsumableListSearch} selProductList={assetNo ? [selProductList] : selProductList} isAddConsumable={true}/>
        </Modal>
    )
}

export default observer(AddConsumablePopup);

import { observable } from "mobx";
import { oneMonthAgo, today } from "../../utils/dateUtils";

export const ReceiveStore = observable({
    accountSeq: '',
    accountList: [],
    billingList: [],
    receiveList: [],
    accountSearch: {
        startDateString: oneMonthAgo(),
        endDateString: today(),
        accountSeq: '',
        customerNo: '',
        customerName: '',
        bankCode: '',
        accountNumber: '',
    },
    search: {
        startDate: oneMonthAgo(),
        endDate: today(),
        receiveNo: '',
        customerNo: '',
        customerName: '',
        contractNo: '',
        exceptCancel: true,
    },
    billingSearch: {
        customerNo: '',
        customerName: '',
        billingAddressSeq: '',
        billingAddressName: '',
    },
    setAccountSeq(v){
        this.accountSeq = v;
    },
    setBillingList(v){
        this.billingList = v;
    },
    billingInit() {
        this.billingList = [],
        this.billingSearch.customerNo = '',
        this.billingSearch.customerName = ''
    }
});

import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { CContainer, CInputGroup, CCol, Subject, CSelectGroup, CIconBtn, CalendarGroup,  } from '../../components/CustomContainer';
import { callConfirm, getLocalStorage } from "../../utils";
import { encrypt, showToast } from "../../common/utils";
import Address from "../../components/Address";
import { CompanyStore } from "../../store/commCode/CompanyStore";
import { findCommKrnm, getCommcode } from "../../utils/commcode";
import { decrypt, autoHyphen } from "../../common/utils";
import { USER_INFO, PELOTONLAB, MASTER_PELOTONLAB, MULTIPART_HEADER } from "../../common/constants";
import Regex from "../../common/regex";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { AppStore } from "../../store/AppStore";
import ModifyCompanyContractPopup from "./popup/ModifyCompanyContractPopup";
import { addMonthDate, dateDiffDetailed, today } from "../../utils/dateUtils";
import LUtils from "../../utils/lodashUtils";

const CompanyInfo = ({getCardList}) => {
    const fileRef1 = useRef();
    const fileRef2 = useRef();
    const fileRef3 = useRef();
    const fileRef4 = useRef();
    const fileRef5 = useRef();
    const userInfo = getLocalStorage(USER_INFO);

    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [remainingPeriod, setRemainingPeriod] = useState('');
    const [companyCode, setCompanyCode] = useState(userInfo.companyCode);
    const [companyList, setCompanyList] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [checkResult, setCheckResult] = useState(false);

    useEffect(() => {
        if (companyCode && companyCode !== '') {
            getCompanyInfo();
            setIsDisable(true);

            if (userInfo.companyCode === MASTER_PELOTONLAB || userInfo.companyCode === PELOTONLAB) {
                getCompanyList();
            }
        }
    }, [companyCode]);

    useEffect(() => {
        if (CompanyStore.data.startDate) {
            CompanyStore.data.endDate = addMonthDate(CompanyStore.data.startDate, 24);
            const {years, months, days} = dateDiffDetailed(today(), CompanyStore.data.endDate);
    
            const modMonths = (years * 12) + months;

            setRemainingPeriod(modMonths + "개월 " + days + "일");
        }
        
    }, [CompanyStore.data.startDate]);

    /* 회사 정보 상세 조회 */
    const getCompanyInfo = async () => {
        CompanyStore.init();
        const result = await axios.get(`/company/${companyCode}`);
        CompanyStore.data = result;

        setPostcode(result.postcode);
        setAddress(result.address);
        setAddressDetail(result.addressDetail);

    }

    /* 회사 목록 조회 */
    const getCompanyList = async () => {
        const result = await axios.get("/company/list");

        let list = result.map(v => {return {name: v.companyName, value: v.companyCode}});
        setCompanyList(list);
    }

    // /* 새로 등록 or 수정 후 저장 */
    // const saveCard = async () => {
    //     // 필수값 확인
    //     const requiredParams = ['cardNumber', 'cardCode', 'loginId', 'loginPassword'];
    //     if (!requiredParams.every(param => CompanyStore.data[param])) {
    //         showToast('필수값을 모두 입력해주세요.');
    //         return;
    //     }

    //     if (!CompanyStore.data.cardManagementSeq) {
    //         if (!await callConfirm('등록하시겠습니까?')) return;
    //         const result = await axios.post('card/management', CompanyStore.data);
    //         if(result.result === 'option_failed') {
    //             alert(result.message);
    //         }else {
    //             CompanyStore.data = result;
    //             showToast("등록이 완료되었습니다.");
    //         }
    //     } else {
    //         if(!await callConfirm('수정하시겠습니까?')) return;
    //         const result = await axios.put('card/management', CompanyStore.data);
    //         CompanyStore.data = result;

    //         showToast("수정이 완료되었습니다.");
    //     }
    //     getCardList();
    // }

    // /* 카드 미사용 */
    // const removeCard = async () => {
    //     const result = await axios.delete(`card/management/${CompanyStore.data.cardManagementSeq}`);
    //     showToast("삭제되었습니다.");
    //     CompanyStore.dataInit();
    //     getCardList();
    // }

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData, type) => {
        if (LUtils.isEmpty(fileData.target.files)) {
            return false;
        }

        const typeCheck = fileData.target.files[0].type  === 'application/pdf';
        const file = fileData.target.files[0];

        const formData = new FormData();
        formData.append('file', file);
        formData.append('typeCheck', typeCheck);
        formData.append('column', type);

        let result = '';
        
        if (companyCode && companyCode !== '') {
            formData.append('companyCode', companyCode);
            result = await axios.post('/company/file/upload', formData, { headers: MULTIPART_HEADER });
        } else {
            if (typeCheck) {
                result = await axios.post('/file/upload/s3/pdf', formData, { headers: MULTIPART_HEADER });
            } else {
                result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });
            }
        }

        if(type === 'signatureImage') {
            CompanyStore.data.signatureImageUrl = result;
            CompanyStore.data.signatureImageFileName = file.name;
        } else if (type === 'logoImage') {
            CompanyStore.data.logoImageUrl = result;
            CompanyStore.data.logoImageFileName = file.name;
        } else if (type === 'contractDoc') {
            CompanyStore.data.contractDocUrl = result;
            CompanyStore.data.contractDocFileName = file.name;
        } else if (type === 'bankbookImage') {
            CompanyStore.data.bankbookImageUrl = result;
            CompanyStore.data.bankbookImageFileName = file.name;
        } else if (type === 'businessLicense') {
            CompanyStore.data.businessLicenseUrl = result;
            CompanyStore.data.businessLicenseFileName = file.name;
        }
    }

    /* 계약 정보 변경 요청 팝업 */
    const openModifyContractPopup = () => {
        AppStore.toggleModifyCompanyContractPopup();
    }

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = async () => {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 조회 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        CompanyStore.init();
        CompanyStore.data.startDate = today();
        setIsValid(false);
        setCheckResult(false);
        // CompanyStore.data.endDate = '';
        // setRemainingPeriod('');

        CompanyStore.data.companyCode = '';
        CompanyStore.data.companyName = customerInfo.customerName;
        CompanyStore.data.owner = customerInfo.owner;
        CompanyStore.data.establishmentDate = customerInfo.establishmentDate;
        CompanyStore.data.businessNumber = customerInfo.businessNumber;
        CompanyStore.data.companyType = customerInfo.customerType;
        CompanyStore.data.postcode = customerInfo.companyPostcode;
        setPostcode(customerInfo.companyPostcode);
        setAddress(customerInfo.companyAddress);
        setAddressDetail(customerInfo.companyAddressDetail);
        CompanyStore.data.address = customerInfo.companyAddress;
        CompanyStore.data.addressDetail = customerInfo.companyAddressDetail;
        CompanyStore.data.corporationNumber = customerInfo.corporationNumber;
        CompanyStore.data.industryType = customerInfo.industryType;
        CompanyStore.data.businessConditions = customerInfo.businessConditions;
        CompanyStore.data.businessLicenseUrl = customerInfo.businessLicenseUrl;
        CompanyStore.data.businessLicenseFileName = customerInfo.businessLicenseFileName;

        setCompanyCode('');
        setIsDisable(false);
    }

    /* 기업 저장 */
    const saveCompany = async () => {

        if(!isValid) {
            showToast('사업자번호 중복검사를 해주세요.');
            return false;
        }
        if(!checkResult) {
            showToast('해당 사업자번호로 등록된 회사가 존재합니다.');
            return false;
        }
        if(!await callConfirm('저장하시겠습니까?')) {
            return false;
        }

        CompanyStore.data.postcode = postcode;
        CompanyStore.data.address = address;
        CompanyStore.data.addressDetail = addressDetail;

        const result = await axios.post('/company', CompanyStore.data);
        CompanyStore.data.companyCode = result;
        setCompanyCode(result);

        showToast('저장이 완료되었습니다.');
        companyList.push({value: result, name: CompanyStore.data.companyName});
    }

    /* 이메일 정보 변경 */
    const modifyCompanyEmail = async () => {
        if(!await callConfirm('이메일 정보를 변경하시겠습니까?')) {
            return false;
        }
        await axios.post('/company/email', {...CompanyStore.data, password: CompanyStore.data.password});
        showToast('이메일 정보가 변경되었습니다.');
    }

    /* 알림톡 정보 변경 */
    const modifyCompanySms = async () => {
        if(!await callConfirm('알림톡 정보를 변경하시겠습니까?')) {
            return false;
        }
        await axios.post('/company/sms', CompanyStore.data);
        showToast('알림톡 정보가 변경되었습니다.');
    }

    /* 사업자번호 중복체크 */
    const duplicateCheck = async () => {
        const result = await axios.get(`/company/check/${CompanyStore.data.businessNumber}`);
       
        if (result === "fail") {
            showToast('이 사업자번호로 등록된 회사가 존재합니다.');
            setCheckResult(false);
        } else if (result === "success") {
            showToast('회사 등록이 가능한 사업자번호입니다.');
            setCheckResult(true);
       };

       setIsValid(true);
    }

    /* 법인 카드 정보 변경 */
    const modifyCompanyCard = async () => {
        if(!CompanyStore.data.cardCode || !CompanyStore.data.cardNumber || !CompanyStore.data.expirationYear || !CompanyStore.data.expirationMonth || !CompanyStore.data.password) {
            return showToast('법인 카드 정보를 모두 입력해주세요.');
        }

        if(!await callConfirm('법인 카드 정보를 변경하시겠습니까?')) {
            return false;
        }
        await axios.put('/company/card', CompanyStore.data);
        showToast('법인 카드 정보가 변경되었습니다.');
    }

    return (
        <>
            <CContainer search>
                <Row>
                    { (userInfo.companyCode === PELOTONLAB || userInfo.companyCode === MASTER_PELOTONLAB) && (
                        <Col className="d-flex flex-row-reverse">
                            {(!companyCode || companyCode === '') && (
                                <Row>
                                    <Col className="d-flex flex-row-reverse">
                                        <CIconBtn style={{width: 120}}
                                                title={'저장'}
                                                icon={'fi-rr-box-check'}
                                                onClick={() => saveCompany()}
                                                variant={'success'}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <CIconBtn style={{width: 120}}
                                    title={'고객추가'}
                                    icon={'fi-rr-add'}
                                    onClick={() => openSearchCustomerPopup()}
                                    variant={'primary'}
                            />
                        </Col>
                    ) }
                    <Subject>기업정보</Subject>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'기업코드'} labelId={'companyCode'}
                                        value={companyCode}
                                        onChange={v => setCompanyCode(v.target.value)}
                                        disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        {(companyCode !== '' && (userInfo.companyCode === PELOTONLAB || userInfo.companyCode === MASTER_PELOTONLAB)) ? 
                            <CSelectGroup label={'회사명'} labelId={'companyName'}
                                          placeholder={'회사명'}
                                          options={companyList}
                                          value={CompanyStore.data.companyCode}
                                          onChange={v => {
                                              CompanyStore.data.companyCode = v.target.value;
                                              setCompanyCode(v.target.value);
                                            }}
                                          disabled={false}
                                        //   labelClassName="input-required"
                            />
                        : 
                            <CInputGroup label={'회사명'} labelId={'companyName'}
                                            value={CompanyStore.data.companyName}
                                            onChange={v => CompanyStore.data.companyName = v.target.value}
                                            disabled={isDisable}
                                            // labelClassName='input-required'
                            />
                        }
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'대표자'} labelId={'owner'}
                                    value={CompanyStore.data.owner}
                                    onChange={v => CompanyStore.data.owner = v.target.value}
                                    disabled={isDisable}
                                    // labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup label={'설립일'}
                                labelId={'establishmentDate'}
                                asSingle={true}
                                value={{
                                    startDate: CompanyStore.data.establishmentDate,
                                    endDate: CompanyStore.data.establishmentDate
                                }}
                                onChange={v => {CompanyStore.data.establishmentDate = v.startDate}}
                                // disabled={isDisable}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={companyCode !== null && companyCode !== '' ? 3 : 2}>
                        <CInputGroup label={'사업자번호'} labelId={'businessNumber'}
                                    value={Regex.makeBusinessNumber(CompanyStore.data.businessNumber)}
                                    onChange={v => {
                                        CompanyStore.data.businessNumber = v.target.value;
                                        setIsValid(false);
                                    }}
                                    disabled={isDisable}
                                    // labelClassName='input-required'
                        />
                    </CCol>
                    {(companyCode === null || companyCode === '') && (
                        <CCol lg={1}>
                            <CIconBtn 
                                    style={{width: '95%'}}
                                    title={'중복검사'}
                                    // icon={'fi-rr-file-edit'}
                                    onClick={() => duplicateCheck()}
                                    variant={'warning'}
                            />
                        </CCol>
                    )}
                    <CCol lg={3}>
                        <CInputGroup
                            label={'사업자 구분'}
                            labelId={'companyType'}
                            value={findCommKrnm(CompanyStore.data.companyType, '19')}
                            disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={6}>
                        <Address label='주소'
                                setPostcode={setPostcode}
                                setAddress={setAddress}
                                setAddressDetail={setAddressDetail}
                                postcode={postcode}
                                address={address}
                                addressDetail={addressDetail}
                                // labelClassName='input-required'
                                disabled={isDisable}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'법인번호'}
                            labelId={'corporationNumber'}
                            value={CompanyStore.data.corporationNumber}
                            onChange={v => CompanyStore.data.corporationNumber = v.target.value}
                            disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'업종'} labelId={'industryType'}
                                    value={CompanyStore.data.industryType}
                                    onChange={v => CompanyStore.data.industryType = v.target.value}
                                    disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'업태'} labelId={'businessConditions'}
                                    value={CompanyStore.data.businessConditions}
                                    onChange={v => CompanyStore.data.businessConditions = v.target.value}
                                    disabled={isDisable}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {CompanyStore.data.signatureImageUrl ?
                                    <>
                                        회사대표도장 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={CompanyStore.data.signatureImageUrl}>
                                            {CompanyStore.data.signatureImageFileName}
                                        </a>
                                    </>
                                : '회사대표도장 업로드'}
                            </label>
                            {CompanyStore.data.signatureImageFileName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {CompanyStore.data.signatureImageFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="file1"
                                    size="sm"
                                    placeholder={'회사대표도장 업로드'}
                                    onChange={(v) => fileUploadForS3(v, 'signatureImage')}
                                    ref={fileRef1}
                                />
                            }
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {CompanyStore.data.logoImageUrl ?
                                    <>
                                        기업로고 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={CompanyStore.data.logoImageUrl}>
                                            {CompanyStore.data.logoImageFileName}
                                        </a>
                                    </>
                                : '기업로고 업로드'}
                            </label>
                            {CompanyStore.data.logoImageFileName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {CompanyStore.data.logoImageFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="file2"
                                    size="sm"
                                    placeholder={'기업로고 업로드'}
                                    onChange={(v) => fileUploadForS3(v, 'logoImage')}
                                    ref={fileRef2}
                                />
                            }
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {CompanyStore.data.bankbookImageUrl ?
                                    <>
                                        통장사본 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={CompanyStore.data.bankbookImageUrl}>
                                            {CompanyStore.data.bankbookImageFileName}
                                        </a>
                                    </>
                                : '통장사본 업로드'}
                            </label>
                            {CompanyStore.data.bankbookImageFileName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {CompanyStore.data.bankbookImageFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="file4"
                                    size="sm"
                                    placeholder={'통장사본 업로드'}
                                    onChange={(v) => fileUploadForS3(v, 'bankbookImage')}
                                    ref={fileRef4}
                                />
                            }
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {CompanyStore.data.businessLicenseUrl ?
                                    <>
                                        사업자등록증 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={CompanyStore.data.businessLicenseUrl}>
                                            {CompanyStore.data.businessLicenseFileName}
                                        </a>
                                    </>
                                : '사업자등록증 업로드'}
                            </label>
                            {CompanyStore.data.businessLicenseFileName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {CompanyStore.data.businessLicenseFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="file5"
                                    size="sm"
                                    placeholder={'사업자등록증 업로드'}
                                    onChange={(v) => fileUploadForS3(v, 'businessLicense')}
                                    ref={fileRef5}
                                />
                            }
                        </InputGroup>
                    </CCol>
                </Row>
                {/* {(!companyCode || companyCode === '') && (
                    <Row>
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 120}}
                                    title={'저장'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveCompany()}
                                    variant={'success'}
                            />
                        </Col>
                    </Row>
                )} */}

                <br/>
                <Subject>계약정보</Subject>
                <Row>
                    <CCol lg={3}>
                        <CalendarGroup label={'계약개시일'}
                                labelId={'startDate'}
                                asSingle={true}
                                value={{
                                    startDate: CompanyStore.data.startDate,
                                    endDate: CompanyStore.data.startDate
                                }}
                                onChange={v => {CompanyStore.data.startDate = v.startDate}}
                                disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup label={'계약종료일'}
                                    asSingle={true}
                                    value={{
                                        startDate: CompanyStore.data.endDate,
                                        endDate: CompanyStore.data.endDate
                                    }}
                                    onChange={v => {CompanyStore.data.endDate = v.startDate}}
                                    disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'남은기간'} labelId={'remainingPeriod'}
                            value={remainingPeriod}
                            disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {CompanyStore.data.contractDocUrl ?
                                    <>
                                        계약서 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={CompanyStore.data.contractDocUrl}>
                                            {CompanyStore.data.contractDocFileName}
                                        </a>
                                    </>
                                : '계약서 업로드'}
                            </label>
                            {CompanyStore.data.contractDocFileName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {CompanyStore.data.contractDocFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="file3"
                                    size="sm"
                                    placeholder={'계약서 업로드'}
                                    onChange={(v) => fileUploadForS3(v, 'contractDoc')}
                                    ref={fileRef3}
                                />
                            }
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'동시접속자수'}
                            labelId={'sessionCount'}
                            value={CompanyStore.data.sessionCount}
                            onChange={v => CompanyStore.data.sessionCount = v.target.value}
                            type={'number'}
                            disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'월별 국세청 계산서 발송 건 수'}
                            labelId={'billCount'}
                            value={CompanyStore.data.billCount}
                            onChange={v => CompanyStore.data.billCount = v.target.value}
                            type={'number'}
                            disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'월별 알림톡 발송 건 수'}
                            labelId={'smsCount'}
                            value={CompanyStore.data.smsCount}
                            onChange={v => CompanyStore.data.smsCount = v.target.value}
                            type={'number'}
                            disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'법인통장 등록 건 수'}
                            labelId={'bankbookCount'}
                            value={CompanyStore.data.bankbookCount}
                            onChange={v => CompanyStore.data.bankbookCount = v.target.value}
                            type={'number'}
                            disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'법인카드 등록 건 수'}
                            labelId={'corporateCardCount'}
                            value={CompanyStore.data.corporateCardCount}
                            onChange={v => CompanyStore.data.corporateCardCount = v.target.value}
                            type={'number'}
                            disabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            // isValid={true}
                            label={'국세청 매입/매출 세금 계산서 조회'}
                            options={[{name: '미사용', value: 0}, {name: '사용', value: 1}]}
                            value={CompanyStore.data.isTaxBill}
                            onChange={v => CompanyStore.data.isTaxBill = v.target.value}
                            disabled={isDisable}
                        />
                    </CCol>
                    {/* <CCol lg={3}>
                        <CSelectGroup
                            label={'출고의뢰 시 알림톡 발송'}
                            options={[{name: '미사용', value: 0}, {name: '사용', value: 1}]}
                            value={CompanyStore.data.isSendReleaseAskSms}
                            onChange={v => CompanyStore.data.isSendReleaseAskSms = v.target.value}
                            disabled={isDisable}
                        />
                    </CCol> */}
                    {/* {(companyCode !== '' && (userInfo.companyCode === PELOTONLAB || userInfo.companyCode.MASTER_PELOTONLAB)) && ( */}
                    {companyCode !== '' && (
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 160}}
                                    title={'계약 정보 변경 요청'}
                                    icon={'fi-rr-file-edit'}
                                    onClick={() => openModifyContractPopup()}
                                    variant={'success'}
                            />
                        </Col>
                    )}
                </Row>
                <br/>
                <Subject>법인 카드 정보</Subject>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'카드사'}
                            placeholder={'카드사 선택'}
                            labelId={'cardCode'}
                            options={[{name: '선택', value: ''}].concat(getCommcode('70'))}
                            value={CompanyStore.data.cardCode}
                            onChange={v => CompanyStore.data.cardCode = v.target.value}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'카드번호'}
                            placeholder={'숫자만 입력'}
                            labelId={'cardNumber'}
                            value={CompanyStore.data.cardNumber || ''}
                            onChange={v => CompanyStore.data.cardNumber = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'유효기간(년)'}
                            placeholder={'YY'}
                            labelId={'expirationYear'}
                            value={CompanyStore.data.expirationYear || ''}
                            onChange={v => CompanyStore.data.expirationYear = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'유효기간(월)'}
                            placeholder={'MM'}
                            labelId={'expirationMonth'}
                            value={CompanyStore.data.expirationMonth || ''}
                            onChange={v => CompanyStore.data.expirationMonth = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            type={'password'}
                            label={'카드 비밀번호'}
                            placeholder={'앞 2자리'}
                            labelId={'cardPassword'}
                            value={decrypt(CompanyStore.data.cardPassword) || ''}
                            onChange={v => {
                                const input = v.target.value;
                                if (/^\d{0,2}$/.test(input)) {  // 숫자, 2자리까지만 허용
                                    CompanyStore.data.cardPassword = encrypt(input);
                                }
                            }}
                        />
                    </CCol>
                    {companyCode !== '' && (
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 145}}
                                    title={'법인 카드 정보 변경'}
                                    icon={'fi-rr-file-edit'}
                                    onClick={() => modifyCompanyCard()}
                                    variant={'success'}
                            />
                        </Col>
                    )}
                </Row>

                <br/>
                <Subject>대표 이메일 설정</Subject>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'이메일'}
                            labelId={'email'}
                            value={CompanyStore.data.email || ''}
                            onChange={v => CompanyStore.data.email = v.target.value}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'SMTP 주소'}
                            labelId={'host'}
                            value={CompanyStore.data.host || ''}
                            onChange={v => CompanyStore.data.host = v.target.value}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            type={'password'}
                            label={'비밀번호'}
                            labelId={'password'}
                            value={decrypt(CompanyStore.data.password) || ''}
                            onChange={v => CompanyStore.data.password = encrypt(v.target.value)}
                        />
                    </CCol>
                    {/* {(companyCode !== '' && (userInfo.companyCode === PELOTONLAB || userInfo.companyCode === MASTER_PELOTONLAB)) && ( */}
                    {companyCode !== '' && (
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 145}}
                                    title={'이메일 정보 변경'}
                                    icon={'fi-rr-file-edit'}
                                    onClick={() => modifyCompanyEmail()}
                                    variant={'success'}
                            />
                        </Col>
                    )}
                </Row>

                <br/>
                <Subject>알림톡 설정</Subject>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'채널 아이디'}
                            labelId={'id'}
                            value={CompanyStore.data.id || ''}
                            onChange={v => CompanyStore.data.id = v.target.value}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'담당자 핸드폰 번호'}
                            labelId={'phoneNumber'}
                            value={autoHyphen(decrypt(CompanyStore.data.phoneNumber)) || ''}
                            onChange={v => CompanyStore.data.phoneNumber = encrypt(v.target.value)}
                        />
                    </CCol>
                    {/* {(companyCode !== '' && (userInfo.companyCode === PELOTONLAB || userInfo.companyCode === MASTER_PELOTONLAB)) && ( */}
                    {companyCode !== '' && (
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 145}}
                                    title={'알림톡 정보 변경'}
                                    icon={'fi-rr-file-edit'}
                                    onClick={() => modifyCompanySms()}
                                    variant={'success'}
                            />
                        </Col>
                    )}
                </Row>

                <br/>
                <Subject>자동 배치 마지막 처리 시간</Subject>
                <Row>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'법인계좌'}
                            labelId={'lastSaveTimeAccount'}
                            value={CompanyStore.data.lastSaveTimeAccount || ''}
                            disabled={true}
                            />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'법인카드'}
                            labelId={'lastSaveTimeCard'}
                            value={CompanyStore.data.lastSaveTimeCard || ''}
                            disabled={true}
                            />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'매입/매출 세금계산서'}
                            labelId={'lastSaveTimeBill'}
                            value={CompanyStore.data.lastSaveTimeBill || ''}
                            disabled={true}
                        />
                    </CCol>
                </Row>
            </CContainer>

            {/* <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn style={{ width: 80 }}
                            title={'저장'}
                            onClick={saveCard}
                        />
                        {CompanyStore.data.cardManagementSeq && <CRemoveBtn title={'카드미사용'} style={{ width: 100 }} onClick={removeCard} />}
                    </Col>
                </Row>
            </CContainer> */}
            <CustomerSearch callbackFn={getCustomerInfo} />
            <ModifyCompanyContractPopup getCompanyInfo={getCompanyInfo}/>
        </>
    );
};

export default observer(CompanyInfo);

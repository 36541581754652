import { observer } from "mobx-react-lite";
import { Row, Col, Form } from "react-bootstrap";

import { CContainer, CCol, CalendarGroup, CInputGroup, CSearchBtn } from "../../components/CustomContainer";
import { TaxBillStore } from "../../store/billing/TaxBillStore";
import axios from "axios";
import PeriodSearchPopup from "./popup/PeriodSearchPopup";
import { AppStore } from "../../store/AppStore";
import { getStringFormat } from "../../utils/dateUtils";
import { showToast } from "../../common/utils";

const TaxBillSearch = ({getTaxBillList}) => {
    const store = TaxBillStore;

    const getHometaxData = async (startDate, endDate) => {
        const yearDiff = endDate.getFullYear() - startDate.getFullYear();
        const monthDiff = endDate.getMonth() - startDate.getMonth();
        const dayDiff = endDate.getDate() - startDate.getDate();

        const totalMonths = yearDiff * 12 + monthDiff;

        if (dayDiff > 0) {
            totalMonths += 1;
        }

        if(Number(totalMonths) <= 6) {
            await axios.get('/hometax', {params: {startDate: getStringFormat(startDate), endDate: getStringFormat(endDate)}});
            showToast('홈택스 조회가 완료되었습니다.');
        }else {
            showToast('기간을 6개월보다 작게 설정해주세요.');
        }
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'시작일'}
                                       value={{
                                            startDate: store.search.startDate,
                                            endDate: store.search.startDate
                                       }}
                                       onChange={v => store.search.startDate = v.startDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'종료일'}
                                       value={{
                                            startDate: store.search.endDate,
                                            endDate: store.search.endDate
                                       }}
                                       onChange={v => store.search.startDate = v.endDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'공급자'}
                            value={store.search.supplyCompanyName}
                            onChange={v => store.search.supplyCompanyName = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'공급받는자'}
                            value={store.search.customerCompanyName}
                            onChange={v => store.customerCompanyName.contractNo = v.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={4} >
                        <Form.Check
                            inline
                            label="전체"
                            name="all"
                            type={'radio'}
                            id='all'
                            value={''}
                            checked={store.search.taxBillType === ''}
                            onChange={(e)=> store.search.taxBillType = e.target.value}
                        />
                        <Form.Check
                            inline
                            label="매출"
                            name="sale"
                            type={'radio'}
                            id='sale'
                            value={'1'}
                            checked={store.search.taxBillType === '1'}
                            onChange={(e)=> store.search.taxBillType = e.target.value}
                        />
                        <Form.Check
                            inline
                            label="매입"
                            name="purchase"
                            type={'radio'}
                            id='purchase'
                            value={'2'}
                            checked={store.search.taxBillType === '2'}
                            onChange={(e)=> store.search.taxBillType = e.target.value}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getTaxBillList} />
                        <CSearchBtn onClick={() => AppStore.togglePeriodSearchPopup()} title={'홈택스 조회'} style={{backgroundColor: 'gray', border: 'none'}}/>
                    </Col>
                </Row>
            </CContainer>
            <PeriodSearchPopup searchMethod={getHometaxData} />
        </>
    );
}

export default observer(TaxBillSearch);

import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { CCol, CContainer, CInputGroup, IconBtn, CalendarGroup } from "../../components/CustomContainer";
import { Form, InputGroup, Row } from "react-bootstrap";
import { StorageRegisterStore } from "../../store/storage/StorageRegisterStore";
import { StorageRegisterListStore } from "../../store/storage/StorageRegisterListStore";
import { AppStore } from "../../store/AppStore";
import { callConfirm } from "../../utils";
import AgGridContainer from "../../components/AgGridContainer";
import {getInputTime, today} from "../../utils/dateUtils";
import {findCommKrnm, getCommcode} from "../../utils/commcode";
import {showToast} from "../../common/utils";
import AssetConsumPopup from "./popup/AssetConsumPopup";
import {getUserName} from "../../utils/userUtils";
import contractUtils from "../contract/contractUtils";

const StorageReturn = (props) => {
    const returnStorageGridRef = useRef();
    const returnStorageDataRef = useRef();
    const inputNoRef = useRef();

    const [selAssetNo, setSelAssetNo] = useState('');
    const [selContractNo, setSelContractNo] = useState('');
    const [returnStorageDate, setReturnStorageDate] = useState({startDate: today(), endDate: null});

    useEffect(()=> {
        StorageRegisterStore.goodsInfo.storageDate = today();
        StorageRegisterStore.goodsInfo.returnStorageDate = today();
        StorageRegisterListStore.newEndDate.startDate = today();
    }, [])

    const assetStatuses = {
        101: "입고검수중",
        305: "수리중",
        200: "임대가능",
    };

    const returnStorageColumnDefs = [
        {field: "seq", headerName: "순번", width: 80},
        {
            field: "storageType",
            headerName: "입고구분",
            width: 100,
            valueFormatter: v => findCommKrnm(v.value, '13'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('13').map(v => v.value),
            },
            headerClass: 'grid-column-editable',
            editable: true
        },
        {
            field: "assetStatus",
            headerName: "자산상태",
            width: 100,
            valueFormatter: v => assetStatuses[v.value] || '',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: Object.keys(assetStatuses)
            },
            headerClass: 'grid-column-editable',
            editable: true
        },
        {field: "storageDate", headerName: "입고일자", width: 140},
        {field: "lgCate", headerName: "대분류", width: 100, hide: true},
        {field: "assetNo", headerName: "자산번호", width: 120},
        {field: "assetType", headerName: "자산구분", width: 100, valueFormatter: v => findCommKrnm(v.value, '7'),},
        {field: "orgAssetStatus", headerName: "자산상태", width: 100, valueFormatter: v => findCommKrnm(v.value, '8'),},
        {field: "serialNo", headerName: "시리얼번호", width: 130},
        {field: "productSeq", headerName: "제품SEQ", width: 120, hide: true},
        {field: "productName", headerName: "제품명", width: 150},
        {field: "model", headerName: "모델명", width: 150},
        {field: "shelfId", headerName: "선반번호", minWidth: 150, headerClass: 'grid-column-editable', editable: true},
        {field: "remark", headerName: "입고비고", minWidth: 150, headerClass: 'grid-column-editable', editable: true},
        {field: "consumCount", headerName: "소모품수량", width: 120, cellRenderer: (e) => popupAssetConsumInfo(e),  headerClass: 'grid-column-editable', editable: v => v.data.storageType === 'sto_consum' ? true : false},
        {field: "customerName", headerName: "고객명", width: 140},
        {field: "contractNo", headerName: "계약번호", width: 120},
        {field: "contractType", headerName: "계약구분", width: 100, valueFormatter: v => findCommKrnm(v.value, '1')},
        {field: "contAssetStatus", headerName: "계약자산구분", width: 120, valueFormatter: v => findCommKrnm(v.value, '2')},
        {field: "contractEndDate", headerName: "만기일자", width: 140},
        {field: "saleUserSeq", headerName: "영업담당", width: 120, valueFormatter: v => getUserName(v.value)},
        {field: "returnAskSeq", headerName: "회수의뢰번호", width: 120, hide: true},
    ];

    const resetInputField =()=> {
        StorageRegisterStore.search.inputNo = '';
        StorageRegisterStore.setCheckCountSec(0);
        inputNoRef.current && inputNoRef.current.focus();
    }

    const getAssetInfo =async(number)=> {
        if(!number){
            return;
        }

        if(StorageRegisterStore.search.isReader && number.length === 1){
            StorageRegisterStore.setCheckCountSec(getInputTime());
            return;
        }

        if(StorageRegisterStore.search.isReader && (getInputTime() - StorageRegisterStore.checkCountSec) < 1){
            return;
        }

        if(StorageRegisterListStore.returnStorageList.some(v => v.assetNo === number)){
            showToast('이미 입력된 자산 입니다.');
            resetInputField();
            return false;
        }

        const result = await axios.get(`/storage/asset`, {params: {inputNo: number}});
        // console.log('result > ', result);

        let returnConsumList = [];
        const contractNo = result.contractNo;

        // if(contractNo){
        //     returnConsumList = await axios.get(`/storage/consumable/${contractNo}`);
        // }

        if(result.conditionStatus === '1'){
            showToast('이미 입고된 자산 입니다.');
            return;
        }
        if(result.conditionStatus === '2' && result.assetStatus !== '202'){
            showToast(`${findCommKrnm(result.assetStatus, '8')} 자산 입니다.`);
            return;
        }
        if(result.conditionStatus === '4'){
            showToast(`매각 자산 입니다.`);
            return;
        }

        if(result.contractType !== '1' && result.contractType !== '2'){
            showToast(`${findCommKrnm(result.contractType, '1')} 자산으로 입고될 수 없습니다.`);
        }
        


        let index = StorageRegisterListStore.returnStorageList.length + 1;
        const row = {
            addRowId: index,
            seq: index,
            storageDate: returnStorageDate.startDate,
            storageType: 'sto_return',
            isCreated: true,
            isUpdated: true,
        }

        // 수리 자산일 경우
        if(result.assetStatus === '305' && result.assetRepairNo) {
            row.storageType = 'sto_repair';
        }

        if(!result){
            if(confirm('등록된 자산 정보가 없습니다. 기타입고로 등록 하시겠습니까?')){
                row.storageType = 'sto_etc';
                row.serialNo = StorageRegisterStore.search.inputNo;
            }else{
                resetInputField();
                return false;
            }
        }

        result.orgAssetStatus = result.assetStatus;
        result.assetStatus = 200;
        result.isReturnStorage = '1';
        StorageRegisterListStore.returnInfo = Object.assign(row, result);
        StorageRegisterListStore.pushReturnStorageList(StorageRegisterListStore.returnInfo);

        /* 반납일자 관련 계산 */
        const info = StorageRegisterListStore.returnInfo;
        //반납일자를 오늘로 설정한다.
        StorageRegisterListStore.returnInfo.newEndDate=StorageRegisterListStore.newEndDate.startDate;
        
        //계산로직 함수로 빼야함.
        //사용 개월수 와 마지막달 사용일수를 계산한다.
        const contractStartDate = info.contractStartDate;
        const targetDate = StorageRegisterListStore.newEndDate.startDate;
        const { rentMonth, rentDays } = contractUtils.calcShortRentMonthAndDays(contractStartDate, targetDate);

        StorageRegisterListStore.setReturnInfo('newRentMonth', rentMonth);
        StorageRegisterListStore.setReturnInfo('newRentDays', rentDays);
        //종료일자 설정
        StorageRegisterListStore.setReturnInfo('shortContractEndDate', StorageRegisterListStore.newEndDate.startDate);


        if(returnConsumList.length > 0){
            if(StorageRegisterListStore.returnStorageList.findIndex(v => v.contractNo === contractNo && v.storageType === 'sto_consum') < 0){
                for(const data of returnConsumList){
                    let idx = ++index;
                    const addRow = {addRowId: idx, seq: idx, storageDate: today(), storageType: 'sto_consum', isCreated: true, isUpdated: true};
                    StorageRegisterListStore.pushReturnStorageList(Object.assign(addRow, data));
                }
            }
        }

        resetInputField();
        // 그리드 전체 다시 그리기
        returnStorageGridRef.current.api.redrawRows();
    }

    const setReturnInfo =(v)=> {
        if (StorageRegisterListStore.returnStorageList.length > 0) {
            for (let i=0; i < StorageRegisterListStore.returnStorageList.length; i++) {
                //반납일자 설정
                StorageRegisterListStore.setNewEndDate(v);
                StorageRegisterListStore.returnStorageList[i].newEndDate=v;

                //계산로직 함수로 빼야함.
                //사용 개월수 와 마지막달 사용일수를 계산한다.
                const contractStartDate = StorageRegisterListStore.returnStorageList[i].contractStartDate;
                const contractEndDate = StorageRegisterListStore.returnStorageList[i].contractEndDate;
                const targetDate = v;
                const { rentMonth, rentDays } = contractUtils.calcShortRentMonthAndDays(contractStartDate, targetDate);

                StorageRegisterListStore.returnStorageList[i].newRentMonth = rentMonth;
                StorageRegisterListStore.returnStorageList[i].newRentDays = rentDays;

                // 종료일자 설정
                StorageRegisterListStore.returnStorageList[i].shortContractEndDate = StorageRegisterListStore.newEndDate.startDate;
            }
        }
    }

    /* 반납 입고 저장 */
    const onStorageReturnAsset = async ({createdList}) => {
        let isEmptySerialNo = false;
        let isEmptyStorageType = false;
        createdList.forEach(v => {
            if (v.storageType != 'sto_consum' && v.assetNo != '' && !v.serialNo) {
                isEmptySerialNo = true;
            }
            if(!v.storageType){
                isEmptyStorageType = true;
            }
        });

        if (isEmptySerialNo) {
            showToast('시리얼번호는 모두 입력해야 합니다.');
            return false;
        }

        if(isEmptyStorageType){
            showToast('입고구분을 모두 선택해야 합니다.');
            return false;
        }

        if (!await callConfirm('입고처리 하시겠습니까?')) {
            return false;
        }

        const result = await axios.post('/storage/asset', createdList);
        StorageRegisterListStore.setReturnStorageList([]);

        showToast('입고되었습니다');
    }

    /* 자산 팝업 UI */
    const popupAssetConsumInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.consumCount}</div>
                {e.data.consumCount > 0 && (
                    <IconBtn key={e.rowIndex} onClick={() => {
                        setSelAssetNo(e.data.assetNo);
                        setSelContractNo(e.data.contractNo);
                        StorageRegisterStore.search.selAssetNo = e.data.assetNo;
                        AppStore.toggleAssetConsumPopup();
                    }} />
                )}
            </div>
        )
    }

    return(
        <>
            <CContainer search>
                <Row>
                    <CCol lg={12}>
                        {/* 반납입고 */}
                        <Row className='mt-2'>
                            <CCol lg={1}>
                                <Form.Check
                                    type="switch"
                                    id="isReader"
                                    label="리더사용"
                                    checked={StorageRegisterStore.search.isReader}
                                    style={{marginTop: 4}}
                                    onChange={()=> StorageRegisterStore.search.isReader = !StorageRegisterStore.search.isReader}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <Form.Check
                                    type="switch"
                                    id="isRfid"
                                    label="대량입고(RFID)"
                                    style={{marginTop: 4}}
                                    onChange={(v)=> {
                                        StorageRegisterStore.search.isReader = !StorageRegisterStore.search.isRfid;
                                        StorageRegisterStore.search.isRfid = !StorageRegisterStore.search.isRfid
                                    }}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={3}>
                                {/*
                                    -단건입고
                                        자산번호 또는 시리얼번호를 스캔하면 조회하여 아래 그리드에 넣는다.
                                        조회했는데 없으면 입고구분을 '기타입고'로 설정하고 비고란에 적도록 한다.
                                    -대량입고
                                        RF-ID로 입고할 경우이고. 이경우 한번에 여러 자산을 읽었을 시 ,(콤마)로 분리하여 input에 넣은뒤 in 조건절로 조회한다.
                                        조회한 데이터를 그리드에 입력한다.
                                */}
                                <CInputGroup
                                    ref={(ref)=> ref}
                                    label={'자산번호/시리얼번호/관리번호'}
                                    labelId={'inputNo'}
                                    value={StorageRegisterStore.search.inputNo}
                                    onChange={(e)=> {
                                        if(e.keyCode !== 8){
                                            let no = e.target.value;
                                            //setInputNo(no);
                                            StorageRegisterStore.search.inputNo = no;
                                            setTimeout(()=> {
                                                //자산조회
                                                StorageRegisterStore.search.isReader && getAssetInfo(no);
                                            }, 500)
                                        }
                                    }}
                                    enterKeyHint={()=> getAssetInfo(StorageRegisterStore.search.inputNo)}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CalendarGroup
                                    asSingle={true}
                                    label={'입고일'}
                                    value={StorageRegisterListStore.newEndDate}
                                    onChange={v => {
                                        setReturnInfo(v.startDate);
                                    }}
                                    // disabled={!StorageRegisterStore.goodsInfo.orderSeq}
                                />
                            </CCol>
                            {StorageRegisterStore.search.isRfid &&
                                <CCol lg={3}>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text>읽은수량</InputGroup.Text>
                                        <Form.Control
                                            style={{flex: 1, textAlign: 'right'}} aria-describedby={'rentMonth'}
                                            value={''}
                                            onChange={v=> alert(v.target.value)}
                                            readOnly={true}
                                        />
                                        <InputGroup.Text>총 입고대상 수량</InputGroup.Text>
                                        <Form.Control
                                            style={{flex: 2, textAlign: 'right'}} aria-describedby={'rentDay'}
                                            value={''}
                                            onChange={v=> alert(v.target.value)}
                                            readOnly={true}
                                        />
                                    </InputGroup>
                                </CCol>
                            }
                        </Row>
                    </CCol>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={returnStorageGridRef}
                    ref={returnStorageDataRef}
                    gridTitle={'입고 대상'}
                    height={55}
                    rowData={StorageRegisterListStore.returnStorageList}
                    columnDefs={returnStorageColumnDefs}
                    seqColumn={'agId'}
                    originList={[]}
                    useUpdated={true}
                    isCheckBox={true}
                    callBackGridData={onStorageReturnAsset}
                    getRowStyle={(params) => {
                        if (params.data.contractType !== '1' && params.data.contractType !== '2') {
                            return {color: 'red'};
                        }
                        return null;
                    }}
                />
            </CContainer>

            {/* modal */}
            <AssetConsumPopup assetNo={StorageRegisterStore.search.selAssetNo} contractNo={selContractNo} />
        </>
    );
}

export default observer(StorageReturn);

import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from 'axios';
import { useReactToPrint } from "react-to-print";

import {CCol, CContainer, CInputGroup, CSelectGroup, CIconBtn, Subject, CCancelBtn, CSaveBtn, CalendarGroup} from "../../components/CustomContainer";
import {getCommcode} from "../../utils/commcode";
import {ReleaseAskDetailStore} from "../../store/release/ReleaseAskDetailStore";
import Address from "../../components/Address";
import AgGridContainer from "../../components/AgGridContainer";
import {encrypt, decrypt, autoHyphen, localAutoHyphen} from "../../common/utils";
import {getLocalStorage} from '../../utils';
import {RELEASE_ASK_MODIFY_AUTH, USER_INFO} from '../../common/constants';
import {dateFormat, today} from '../../utils/dateUtils';
import LUtils from "../../utils/lodashUtils";
import {callConfirm} from '../../utils';
import { IconBtn } from '../../components/CustomContainer';
import AssetSearch from "../../components/searchModal/AssetSearch";
import {AppStore} from "../../store/AppStore";
import { ICON_TRASH, ICON_ADD } from "../../common/constants";
import ReportReleaseAskTemplate from "../../components/report/ReportReleaseAskTemplate";
import ReleaseAssetCheck from "../../components/searchModal/ReleaseAssetCheck";
import ContractSearch from "../../components/searchModal/ContractSearch";
import StockProductSearch from "../../components/searchModal/StockProductSearch";
import { showToast } from "../../common/utils";
import { ContractDetailStore } from "../../store/contract/ContractDetailStore";
import {getUserAuthGroup, getUserAuthorities} from "../../utils/userUtils";
import ReportReleaseAskPrintTemplate from "../../components/report/ReportReleaseAskPrintTemplate";
import StockAssetSearch from "../../components/searchModal/StockAssetSearch";
import contractUtils from "../contract/contractUtils";
import StockProductConsumSearch from "../../components/searchModal/StockProductConsumSearch";

const ReleaseAskDetail =({ contractNo, customerNo, billingAddressSeq, releaseAskSeq, getReleaseAskList, selectAssetList, callBackFn })=> {
    const gridRef = useRef();
    const returnGridRef = useRef();
    const componentRef = useRef();
    const componentPrintRef = useRef();
    const etcReleaseGridRef = useRef();

    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [returnPostcode, setReturnPostcode] = useState('');
    const [returnAddress, setReturnAddress] = useState('');
    const [returnAddressDetail, setReturnAddressDetail] = useState('');
    const [gridData, setGridData] = useState([]);
    const [releaseAskItemList, setReleaseAskItemList] = useState([]);
    const [productCode, setProductCode] = useState('');
    const [isEtcReleaseAsk, setIsEtcReleaseAsk] = useState(false);
    const [btnStatus, setBtnStatus] = useState('add');
    //입력수정가능여부
    const [isDisable, setIsDisable] = useState(false);
    const [isTechManager, setIsTechManager] = useState(false);
    const [isPrintAll, setIsPrintAll] = useState(true);
    const [isReturnAskInfo, setIsReturnAskInfo] = useState(false);
    
    // 1: 고소작업대(T/L) 안전점검 결과서, 2: 보험증권, 3: 제원표, 4: 안전인증서, 5: 자산안전인증서, 6: 작동방법설명서, 7: 반입전 체크리스트, 8: 계약서
    const [checkedList, setCheckedList] = useState(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType6', 'pdfType7']);
    const [isChecked, setIsChecked] = useState(false);
    const userInfo = getLocalStorage(USER_INFO);

    /* 기본값 세팅 */
    useEffect(()=> {
        //계약에서 넘어왔는지, 출고의뢰 리스트에서 넘어왔는지로 disabled 처리 해야함
        if (releaseAskSeq) {
            Promise.all([getReleaseAsk(releaseAskSeq), getReleaseAskItemList(releaseAskSeq)]);
        } else if (contractNo) {
            Promise.all([getReleaseAskInfo()]);

            if(selectAssetList){
                setIsEtcReleaseAsk(true);

                setTimeout(()=> {
                    ReleaseAskDetailStore.setReleaseAskDetail({releaseAskDate: today(), releaseAskType: '3'
                        , creatorSeq: userInfo.userSeq, creatorName: userInfo.name});
                }, 500)
            }
        } else {
            setIsEtcReleaseAsk(true);
            ReleaseAskDetailStore.setReleaseAskDetail({contractType: '', releaseAskDate: today(), releaseAskType: '2'
                                                        , creator: userInfo.userSeq, creatorName: userInfo.name});
        }

        setTimeout(()=> {
            inputModifyDisableHandler();
        }, 500)
        // return ReleaseAskDetailStore.init();
    }, []);

    /* 계약에서 넘어온 경우 정보 초기 세팅 */
    const getReleaseAskInfo = async () => {
        const result = await axios.get('releaseAsk/info', {params: {contractNo: contractNo, customerNo: customerNo, billingAddressSeq: billingAddressSeq}});

        // 주소 세팅
        setPostcode(result.postCode ? result.postCode : '');
        setAddress(result.address ? result.address : '');
        setAddressDetail(result.addressDetail ? result.addressDetail : '');

        setReturnPostcode(result.returnPostcode ? result.returnPostcode : '');
        setReturnAddress(result.returnAddress ? result.returnAddress : '');
        setReturnAddressDetail(result.returnAddressDetail ? result.returnAddressDetail : '');

        // 출고의뢰상태, 출고의뢰일, 의뢰자명 세팅
        ReleaseAskDetailStore.setReleaseAskDetail({...result, releaseAskStatus: '', releaseAskType: '1', releaseAskDate: today(), creatorSeq: userInfo.userSeq, creatorName: userInfo.name});
        if(ReleaseAskDetailStore.releaseAskDetail.contractType === '1' && !releaseAskSeq) {
            // ReleaseAskDetailStore.releaseAskDetail.isReturnAskInfo = isReturnAskInfo;
            ReleaseAskDetailStore.releaseAskDetail.returnTelephone = result.telephone || '';
            ReleaseAskDetailStore.releaseAskDetail.returnPhoneNumber = result.phoneNumber || '';
            ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserDept = result.receiveUserDept || '';
            ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserPosition = result.receiveUserPosition || '';
            ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserName = result.receiveUserName || '';
            ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate =  result.contractEndDate || '';
            setReturnPostcode(result.postCode ? result.postCode : '');
            setReturnAddress(result.address ? result.address : '');
            setReturnAddressDetail(result.addressDetail ? result.addressDetail : '');
        }

        ReleaseAskDetailStore.setReleaseAskDetail({shipType: 'direct', returnShipType: 'direct'});
    }

    /* releaseAskSeq로 출고의뢰 조회 */
    const getReleaseAsk = async (releaseAskSeq) => {
        const result = await axios.get('releaseAsk', {params: {releaseAskSeq: releaseAskSeq}});
        ReleaseAskDetailStore.setReleaseAskDetail(result);

        // 주소 세팅
        setPostcode(result.postCode ? result.postCode : '');
        setAddress(result.address ? result.address : '');
        setAddressDetail(result.addressDetail ? result.addressDetail : '');
        setReturnPostcode(result.returnPostcode ? result.returnPostcode : '');
        setReturnAddress(result.returnAddress ? result.returnAddress : '');
        setReturnAddressDetail(result.returnAddressDetail ? result.returnAddressDetail : '');
        setIsReturnAskInfo(result.returnAskSeq ? true : false);
    }

    /* 출고 제품 리스트 */
    const getReleaseAskItemList = async (releaseAskSeq) => {
        const result = await axios.get('releaseAskItem/list', {params: {releaseAskSeq: releaseAskSeq}});
        ReleaseAskDetailStore.setReleaseAskItemList(result);
        setReleaseAskItemList(result);
        //출고의뢰서에 사용하는 변수
        ReleaseAskDetailStore.dataList = result;
    }

    /* 저장(작성중 상태로 변경) */
    const saveReleaseAsk = async () => {

        // 필수값 확인
        if(ReleaseAskDetailStore.releaseAskDetail.contractType === '1' && isReturnAskInfo) {
            const requiredParams = ['phoneNumber', 'receiveUserName', 'shipHopeDate', 'shipHopeTime', 'shipType', 'contractNo', 'returnPhoneNumber', 'returnReceiveUserName', 'returnShipHopeDate', 'returnShipHopeTime', 'returnShipType'];
            if (!requiredParams.every(param => ReleaseAskDetailStore.releaseAskDetail[param]) || !postcode || !address || !addressDetail || !returnPostcode || !returnAddress || !returnAddressDetail) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            } 
        } else {
            const requiredParams = ['phoneNumber', 'receiveUserName', 'shipHopeDate', 'shipHopeTime', 'shipType', 'contractNo'];
            if (!requiredParams.every(param => ReleaseAskDetailStore.releaseAskDetail[param]) || !postcode || !address || !addressDetail) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }
        }

        // 기타출고일 경우, 추가한 출고 제품 리스트의 수량까지 잘 작성되어있는지 확인
        if (isEtcReleaseAsk) {
            if(selectAssetList) {
                if(!selectAssetList.every(v => v.changeProductName)) {
                    showToast('교환 제품을 추가해주세요.');
                    return false;
                }
            }else {
                if (ReleaseAskDetailStore.releaseAskItemList.length > 0) {
                    const cnt = ReleaseAskDetailStore.releaseAskItemList.filter(v => !v.qty);
                    if(cnt.length > 0) {
                        showToast('출고 제품 수량을 입력해주세요.');
                        return false;
                    }
                    
                    const cnt2 = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.isConsum === 0 && !v.monthRentPrice);
                    if(cnt2.length > 0) {
                        showToast('출고 제품 월렌탈료를 작성해주세요.');
                        return false;
                    }
                } else {
                    showToast('출고 제품을 추가해주세요.');
                    return false;
                }
            }
        }

        // 저장 확인
        if(!await callConfirm('저장 하시겠습니까?')) return;

        const {releaseAskSeq} = ReleaseAskDetailStore.releaseAskDetail;
        ReleaseAskDetailStore.setReleaseAskDetail({
                                                    postCode: postcode, 
                                                    address: address, 
                                                    addressDetail: addressDetail, 
                                                    returnPostcode: returnPostcode, 
                                                    returnAddress: returnAddress, 
                                                    returnAddressDetail: returnAddressDetail,
                                                    isReturnAskInfo: isReturnAskInfo,
                                                });    // 주소 세팅

        if(!releaseAskSeq){ // 등록
            // 기타 출고일 경우, 기타 출고 아이템 목록도 같이 저장시켜야 함
            isEtcReleaseAsk && ReleaseAskDetailStore.setReleaseAskDetail({releaseAskItemList: ReleaseAskDetailStore.releaseAskItemList});
            if(selectAssetList){
                const assetNoList = selectAssetList.map(v => v.assetNo);
                Object.assign(ReleaseAskDetailStore.releaseAskDetail, {releaseAskReturnItemList: assetNoList}, {selectAssetList: selectAssetList});
            }

            const result = await axios.post('releaseAsk', ReleaseAskDetailStore.releaseAskDetail);

            showToast('저장되었습니다.');

            if(result){
                ContractDetailStore.isReleaseAskBtn = false;
                getReleaseAskList && getReleaseAskList();
                await Promise.all([getReleaseAsk(result.releaseAskSeq), isEtcReleaseAsk ? getReleaseAskItemList(result.releaseAskSeq) : '']);
            }
        }else { // 수정
            await axios.put('releaseAsk', ReleaseAskDetailStore.releaseAskDetail);
            showToast('수정되었습니다.');
            // await Promise.all([getReleaseAskList && getReleaseAskList(), getReleaseAsk(releaseAskSeq), getReleaseAskItemList(releaseAskSeq)]);
            getReleaseAskList;
            getReleaseAsk(releaseAskSeq);
        }
    }

    /* 출고 의뢰 상태 저장 */
    const saveReleaseAskStatus = async (status, btnText) => {
        // 필수값 확인 => 임대 중 자산 매각이 아닌 경우만 필수값 확인(가상출고 아닌 경우)
        const requiredParams = ['phoneNumber', 'receiveUserName', 'shipHopeDate', 'shipType'];
        if(ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1) {
            if (!requiredParams.every(param => ReleaseAskDetailStore.releaseAskDetail[param]) || !postcode || !address || !addressDetail) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }
        }

        // 반납형, 인수형 출고 완료 전 자산 검수 확인 => assetNo 혹은 storageNo 필수
        if((ReleaseAskDetailStore.releaseAskDetail.contractType === '1' || ReleaseAskDetailStore.releaseAskDetail.contractType === '2') && status === '6' && ReleaseAskDetailStore.releaseAskItemList.some(item => item.assetNo === null && item.storageNo === null)){
            showToast('자산번호를 입력해야 합니다.');
            return;
        }

        // 자산번호 중복 검사
        const assetNoList = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.assetNo);
        const nonDuplicateAssetNoList =new Set(assetNoList.map(v => v.assetNo));
        if(assetNoList.length !== nonDuplicateAssetNoList.size) {
            showToast('자산번호에 중복이 있습니다.');
            return;
        }

        // 시리얼번호 중복 검사
        const serialNoList = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.serialNo);
        const nonDuplicateSerialNoList =new Set(serialNoList.map(v => v.serialNo));
        if(serialNoList.length !== nonDuplicateSerialNoList.size) {
            showToast('시리얼번호에 중복이 있습니다.');
            return;
        }

        // 저장 확인
        if(!await callConfirm(btnText + ' 하시겠습니까?')) return;

        const {releaseAskSeq} = ReleaseAskDetailStore.releaseAskDetail;
        ReleaseAskDetailStore.setReleaseAskDetail({postCode: postcode, address: address, addressDetail: addressDetail});    // 주소 세팅

        if(ReleaseAskDetailStore.releaseAskDetail.releaseAskType === 3) {
            const {rentMonth, rentDays} = contractUtils.calcShortRentMonthAndDays(today(), ReleaseAskDetailStore.releaseAskDetail.contractEndDate);
            ReleaseAskDetailStore.setReleaseAskDetail({rentMonth: rentMonth, rentDays: rentDays});
        }

        // 수정
        const result = await axios.put(`releaseAsk/status/${status}`, ReleaseAskDetailStore.releaseAskDetail);

        if(result && result === 'success'){
            if(status === '4'){
                showToast('출고검수를 시작합니다.');
            }else if(status === '5'){
                showToast('출고작업을 시작합니다.');
            }else if(status === '6'){
                showToast('출고가 완료되었습니다.');
            }else{
                showToast(btnText + '가 완료되었습니다.');
            }

            // 출고의뢰인 경우 팝업창 닫기
            if(status === '2' || status === '9'){
                AppStore.toggleModal();
                callBackFn && callBackFn();
            }else{
                getReleaseAskList();
                await getReleaseAsk(releaseAskSeq);
                await getReleaseAskItemList(releaseAskSeq);
            }
        }else{

        }
    }

    /* 자산 팝업 UI */
    const popupAssetInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.assetNo|| ''}</div>
                {e.data.assetNo && ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 4 && (
                    <IconBtn style={{paddingTop: 7}} key={e.rowIndex} onClick={() => {openAssetSearchPopup(e)}} />
                )}
            </div>
        )
    }

    /* 재고 조회 팝업 UI */
    const popupStockInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.changeProductName || ''}</div>
                <IconBtn key={e.rowIndex} onClick={() => {AppStore.toggleStockAssetSearchModal(), ReleaseAskDetailStore.returnItemAgId = e.data.agId}} />
            </div>
        )
    }

    /* 자산 조회 팝업 */
    const openAssetSearchPopup = (e) =>{
        AppStore.toggleAssetSearchModal();
        setProductCode(e.data.productCode);
        setGridData(e);
    }

    /* 자산 조회 콜백 함수 */
    const callbackAssetInfo = (data) => {
        const result = ReleaseAskDetailStore.releaseAskItemList.map(v => {
            if (v.agId === gridData.data.agId) {
                v.productName = data.productName;
                v.model = data.model;
                v.assetNo = data.assetNo;
                v.managementNo = data.managementNo;
                v.serialNo = data.serialNo;
                v.spec = data.spec;
                LUtils.assign(v, {isUpdated: true });
            }
            return v;
        });
        ReleaseAskDetailStore.setReleaseAskItemList(result);
        setProductCode('');
    }

    /* 저장버튼(수정) 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        const {releaseAskSeq} = ReleaseAskDetailStore.releaseAskDetail;

        if(!releaseAskSeq){
            showToast('출고의뢰 정보를 먼저 저장해주세요.');
            return;
        }

        // 저장 확인
        if(!await callConfirm('저장 하시겠습니까?')) return;

        const releaseAskItemList = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.isSelected || v.isCreated || v.isUpdated);

        const params = {releaseAskItemList: releaseAskItemList};
        await axios.put('releaseAskItem', params);
        await getReleaseAskItemList(releaseAskSeq);

        showToast('저장되었습니다.');
    }

    /* 출고 제품 삭제 버튼 */
    const customCancelBtn = {
        isUsed: !['3','4'].includes(ReleaseAskDetailStore.releaseAskDetail.contractType) &&
            ((isTechManager && ['3', '4', '5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus))
            || ['1', '2'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus))
            && ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 4,
        callbackFn:() => selectedGridCancel(),
        variant: 'danger',
        icon: ICON_TRASH,
        title: '출고제품삭제',
        width: 120,
    }

    /* 자산검수 버튼 */
    const assetCheckPopup = {
        isUsed: ['4', '5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && Number(ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease) !== 1,
        callbackFn:() => AppStore.toggleReleaseAssetCheckPopup(),
        icon: 'fi-rs-search-alt',
        title: '자산검수',
        width: 120,
    }

    /* 자산검수 초기화 */
    const resetAssetCheckBtn = {
        isUsed: ['4', '5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && Number(ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease) !== 1,
        callbackFn:() => resetCheckAsset(),
        variant: 'warning',
        icon: 'fi-rr-time-past',
        title: '검수초기화',
        width: 110,
    }

    /* 출고 제품 선택 삭제 callback */
    const selectedGridCancel = async () => {
        const selectedList = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.isSelected);
        if(selectedList.length === 0){
            showToast('선택된 행이 없습니다.');
            return;
        }

        // 삭제 확인
        if(!await callConfirm('선택된 행을 삭제 하시겠습니까?')) return;

        const {releaseAskSeq} = ReleaseAskDetailStore.releaseAskDetail;
        await axios.put('releaseAskItem/delete', {releaseAskItemList: selectedList});
        getReleaseAskItemList(releaseAskSeq);

        showToast('삭제되었습니다.');
    }

    /* 계약 조회 팝업 */
    const openContractSearchPopup = () =>{
        AppStore.toggleContractSearchModal();
    }
    /* 계약 조회 팝업 콜백 */
    const callbackContractInfo = (info) => {

        ReleaseAskDetailStore.setReleaseAskDetail({contractNo: info.contractNo, contractType: info.contractType, saleUserSeq: info.saleUserSeq
            , saleUserName: info.saleUserName, supportUserSeq: info.supportUserSeq, supportUserName: info.supportUserName
            , releaseAskDate: today(), customerName: info.customerName, customerNo: info.customerNo, contractStartDate: info.contractStartDate, contractEndDate: info.contractEndDate});

        getLastReleaseAskInfo(info.contractNo)

    }

    /* 기타출고 등록에서 계약 번호로 마지막 출고 배송 정보 조회 */
    const getLastReleaseAskInfo = async (contractNo) => {
        const result = await axios.get('releaseAsk/last/info', { params: {contractNo: contractNo} });
        ReleaseAskDetailStore.setReleaseAskDetail({phoneNumber: result.phoneNumber, shipType: result.shipType, receiveUserDept: result.receiveUserDept
                                                , receiveUserName: result.receiveUserName, receiveUserPosition: result.receiveUserPosition, telephone: result.telephone});
        setPostcode(result.postCode);
        setAddress(result.address);
        setAddressDetail(result.addressDetail);
    }

    /* 출고제품 조회 팝업 버튼 커스텀 */
    const customReleaseItemBtn = {
        isUsed: Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskType) > 1 && ['','1','2'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 4 && ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 2,        
        callbackFn:() => AppStore.toggleStockProductConsumSearch(),
        icon: ICON_ADD,
        title: '출고제품 조회',
        width: 120,
    }

    /* 출고 제품 조회 팝업 */
    const releaseItemBtn = () => {
        AppStore.toggleStockProductConsumSearch();
    }

    const addRowInfo = {releaseAskSeq: '', seq: '', productCode: '', productName: '', model: '', qty: '', assetNo: '', storageNo: '',
                        stockQty: '', serialNo: '', spec: '', remark: ''};

    /* 출고 제품 조회 팝업 콜백 */
    const callbackStockProductSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        const {rentMonth, rentDays} = contractUtils.calcShortRentMonthAndDays(today(), ReleaseAskDetailStore.releaseAskDetail.contractEndDate);

        if(selectAssetList) {
            selectAssetList.filter(v => v.agId === ReleaseAskDetailStore.returnItemAgId).map(v => {
                v.changeAssetNo = info[0].assetNo,
                // v.changeProductCode = info[0].productCode,
                v.changeProductSeq = info[0].productSeq,
                v.changeProductName = info[0].productName,
                v.changeModel = info[0].model,
                v.changeStorageNo = info[0].storageNo,
                v.changeStorageSeq = info[0].storageSeq,
                v.changeSerialNo = info[0].serialNo,
                v.changeSpec = info[0].spec
                v.qty = 1
            }); 
            returnGridRef.current.api.redrawRows();
        }else {
            if(btnStatus === 'row') {
                const result = ReleaseAskDetailStore.releaseAskItemList.map(v => {
                            if (v.seq === gridData.data.seq) {
                                //v.assetNo = info.assetNo;
                                v.storageNo = info.storageNo;
                                LUtils.assign(v, {isUpdated: true });
                            }
                            return v;
                        });
                ReleaseAskDetailStore.setReleaseAskItemList(result);
            }else {
                const addRow = [];
                let seq = ReleaseAskDetailStore.releaseAskItemList.length + 1;
                LUtils.forEach(info, k => {
                    //const additionalRowInfo = {seq: null, ...LUtils.pick(k, ['storageNo', 'productSeq', 'productCode', 'productName', 'model', 'ifrsCode', 'remark', 'availableQty'])}
                    //contractProductRef.current.addRow(LUtils.assign(addRowInfo, additionalRowInfo));
                    
                    const additionalRowInfo = {
                        seq: seq++
                        ,assetNo: k.assetNo
                        ,storageNo: k.storageNo
                        ,storageSeq: k.storageSeq
                        ,productSeq: k.productSeq
                        ,productCode: k.productCode
                        ,productName: k.productName
                        ,model: k.model
                        ,serialNo: k.serialNo
                        ,qty: 1
                        ,availableQty: k.stockQty
                        ,isSelected: true
                        ,isCreated: true /* 추가하려면 isCreated를 반드시 추가해 준다. */
                        ,rentMonth: rentMonth
                        ,rentDays: rentDays
                        ,assetNo: k.assetNo
                        ,isConsum: k.isConsum? k.isConsum : 0
                    };

                    etcReleaseGridRef.current.addRow(LUtils.assign(addRowInfo, additionalRowInfo));
                });
            }
            setBtnStatus('add');
        }
    }

    /* 수량 변경 시 재고 > 수량 체크 */
    const handleItemQtyEdit = (e) => {

        if(e.data.availableQty < e.newValue) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        } else {
            e.data.qty = e.newValue;
        }
        gridRef.current.api.redrawRows();
    }

    /* 출고의뢰서 print */
    const releaseAskPrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq,
    });

    const changeReleaseAskType =(e)=> {
        const value = e.target.value;
        if(value === '1'){
            showToast('일반출고는 사용할 수 없습니다.');
            return;
        }

        ReleaseAskDetailStore.releaseAskDetail.releaseAskType = value;
    }

    const inputModifyDisableHandler =()=> {
        let isDisable = false;
        //현재 의뢰상태가 등록되었고 출고대기보다 크면 (출고접수) 수정불가
        if(['6','9'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus)){
            isDisable = true;
        }

        setIsDisable(isDisable);

        // const userAuthGroup = getUserAuthGroup();

        // // if([1,9,10].includes(getUserAuthGroup())){
        // if([1,2,9,10].includes(userAuthGroup)){
        //     setIsTechManager(true);
        // }

        const authorities = getUserAuthorities();
        if (authorities.includes(RELEASE_ASK_MODIFY_AUTH)) {
            setIsTechManager(true);
        }
    }

    const resetCheckAsset =async()=> {
        const resetItems = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.isSelected);
        if(resetItems.length === 0){
            showToast('초기화할 제품을 선택해 주세요.');
            return;
        }

        if(resetItems.some(v => !v.isChecked)){
            showToast(`검수 완료된 제품만 초기화 할 수 있습니다.`);
            return;
        }

        if(!await callConfirm('초기화 하시겠습니까?')) return;

        await axios.put('/releaseAskItem/check/reset', {releaseAskItemList: resetItems});
        showToast('초기화 되었습니다.');
        //재조회
        await getReleaseAskItemList(releaseAskSeq)
    }

    const printPdf = () => {
        let validData = 0;

        if (!isPrintAll && checkedList.length === 0) {
            showToast('출력하실 문서를 선택해주세요.');
            return;
        }

        checkedList.map(v => {
            releaseAskItemList.map(item => {
                if(item.isOption === 0) {
                    // pdfType1: 안전점검결과서, pdfType2: 보험증권, pdfType3: 제원표, pdfType4: 안전인증서, pdfType5: 자산안전인증서, pdfType6: 작동방법설명서, pdfType7: 반입전 체크리스트, pdfType8: 계약서
                    if (v === 'pdfType2') {
                        item.insurancePolicyUrl != null && item.insurancePolicyUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType3') {
                        item.specificationTableUrl != null && item.specificationTableUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType4') {
                        item.safetyCertificateUrl != null && item.safetyCertificateUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType5') {
                        item.assetCertificateUrl != null && item.assetCertificateUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType6') {
                        item.workingMethodUrl != null && item.workingMethodUrl != '' ? validData ++ : '';
                    }
                }
            })
        })

        if (validData > 0) {
            print();
        } else {
            showToast('출력할 문서가 존재하지 않습니다.');
            return false;
        }
    }

    const print = useReactToPrint({
        content: () => componentPrintRef.current,
    });


    const checkedHandler = (e, value) => {
        setIsChecked(!isChecked);
        checkedItemHandler(value, e.target.checked);
    }

    /* 체크박스 핸들러 */
    const checkedItemHandler = (value, isChecked) => {
        if (isChecked) {
            setCheckedList((prev) => [...prev, value]);
            return;
        }

        if (!isChecked && checkedList.includes(value)) {
            setCheckedList(checkedList.filter((item) => item !== value));
            return;
        }

        return;
    }

    const modifyReturnAskInfo = (e) => {
        setIsReturnAskInfo(e.target.checked);
        ReleaseAskDetailStore.setReleaseAskDetail({isReturnAskInfo: e.target.checked});
        if(e.target.checked) { // 배송 정보를 회수 정보로 세팅
            ReleaseAskDetailStore.returnSetting();
            setReturnPostcode(postcode);
            setReturnAddress(address);
            setReturnAddressDetail(addressDetail);
        }else {
            ReleaseAskDetailStore.setReleaseAskDetail({returnAskRemark: ''});
        }
    }
    
    const insertReturnDeliveryInfo = () => {
        ReleaseAskDetailStore.returnSetting();
        setReturnPostcode(postcode);
        setReturnAddress(address);
        setReturnAddressDetail(addressDetail);
    }

    /* grid 입력값 콜백 이벤트 - 숫자만 입력해야 하는 컬럼 검사, 총 렌탈료 계산, 수량 검사 */
    const getCellChange = (e) => {
        const { field } = e.colDef;

        // 숫자만 입력 가능한 컬럼 검사
        const numberColumns = ['monthRentPrice', 'dayRentPrice', 'lastMonthPrice', 'qty', 'productSalePrice', 'assetSalePrice'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), v => isNaN(v))) {
            showToast('숫자만 입럭 가능합니다.');
            e.data[field] = 0;
        }

        // 총 렌탈료 계산
        if (LUtils.includes(['rentMonth', 'rentDays', 'monthRentPrice', 'dayRentPrice', 'lastMonthPrice', 'qty'], field)) {
            e.data.totalRentPrice = contractUtils.calculateTotalPrice(e.data);
        }

        // 총 판매(유통,매각)가 계산
        if(LUtils.includes(['productSalePrice', 'assetSalePrice', 'qty'], field)){
            e.data.totalSalePrice = contractUtils.calculateTotalSalePrice(e.data);
        }

        // 수량 검사
        if (field === 'qty') {
            const { qty, storageNo, productSeq, assetNo } = e.data;

            if(assetNo && Number(qty) > 1){
                showToast('자산 선택시 수량은 1개를 초과할 수 없습니다.');
                e.data.qty = 1;
            }

            const goodsInfo = LUtils.find(ContractDetailStore.contractProductGroupList, v => v.storageNo === storageNo && v.productSeq === productSeq);
            const availableQty = e.data.availableQty || goodsInfo.availableQty;

            if (Number(qty) > Number(availableQty)) {
                let typeText = ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2' ? '렌탈' : '판매';
                showToast(`${typeText}가능 수량을 초과 했습니다. 가능 수량 [${availableQty}]`);
                e.data.qty = availableQty;
                e.data.totalRentPrice = contractUtils.calculateTotalPrice(e.data);
            }
        }

        // 그리드 전체 다시 그리기
        gridRef.current.api.redrawRows();
        
    }

    return(
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Subject>의뢰정보</Subject>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'출고의뢰번호'}
                                     labelId={'releaseAskSeq'}
                                     disabled={true}
                                     value={ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq || ''}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup label={'출고의뢰상태'} labelId={'releaseAskStatus'}
                                      placeholder={'출고의뢰상태'}
                                      options={[{name: '작성중', value: ''}].concat(getCommcode('5'))}
                                      value={ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus}
                                      disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup label={'출고의뢰구분'} labelId={'releaseAskType'}
                                      placeholder={'출고의뢰구분'}
                                      options={getCommcode('39')}
                                      value={ReleaseAskDetailStore.releaseAskDetail.releaseAskType || ''}
                                      onChange={changeReleaseAskType}
                                      disabled={true}
                                      labelClassName="input-required"
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup asSingle={true}  label={'출고의뢰일'}
                                       value={{
                                            startDate: ReleaseAskDetailStore.releaseAskDetail.releaseAskDate,
                                            endDate: ReleaseAskDetailStore.releaseAskDetail.releaseAskDate
                                        }}
                                       disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'의뢰자명'}
                                     labelId={'creatorName'}
                                     disabled={true}
                                     value={ReleaseAskDetailStore.releaseAskDetail.creatorName || ''}
                        />
                    </CCol>
                    {ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 4 && (
                        <>
                            <CCol lg={3}>
                                <CInputGroup label={'계약번호'}
                                            labelId={'contractNo'}
                                            disabled={true}
                                            value={ReleaseAskDetailStore.releaseAskDetail.contractNo || ''}
                                            onCallbackBtn={isEtcReleaseAsk
                                                && (!ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus || ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '1') ? openContractSearchPopup : false}
                                            onChange={v => ReleaseAskDetailStore.releaseAskDetail.contractNo = v.target.value}
                                            labelClassName={'input-required'}
                                />
                            </CCol>
                            <CCol lg={3}>
                                {/* <CSelectGroup label={'계약유형'} labelId={'contractType'}
                                            placeholder={'계약유형'}
                                            options={getCommcode('1')}
                                            value={ReleaseAskDetailStore.releaseAskDetail.contractType}
                                            disabled={true}
                                /> */}
                                <CSelectGroup label={'계약유형'} labelId={'contractType'}
                                            placeholder={'계약유형'}
                                            options={[{name: '전체', value: ''}].concat(getCommcode('1'))}
                                            value={ReleaseAskDetailStore.releaseAskDetail.contractType || ''}
                                            disabled={true}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CInputGroup label={'영업담당자'} labelId={'saleUserName'}
                                            value={ReleaseAskDetailStore.releaseAskDetail.saleUserName || ''}
                                            disabled={true}
                                />
                            </CCol>
{/*                            <CCol lg={2}>
                                <CInputGroup label={'지원담당자'} labelId={'supportUserName'}
                                            value={ReleaseAskDetailStore.releaseAskDetail.supportUserName}
                                            disabled={true}
                                />
                            </CCol>*/}
                        </>
                    )}
                </Row>
                <hr/>
                <Row>
                    <CCol lg={6}>
                        <div className="mb-3">
                            <label className="form-label">출고의뢰비고</label>
                            <textarea className="form-control" rows="3"
                                      value={ReleaseAskDetailStore.releaseAskDetail.releaseAskRemark || ''}
                                      disabled={isDisable}
                                      onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({releaseAskRemark: v.target.value})}
                            />
                        </div>
                    </CCol>
                    {ReleaseAskDetailStore.releaseAskDetail.contractType === '1' &&
                        <CCol lg={6}>
                            <div className="mb-3">
                                <label className="form-label">회수의뢰비고</label>
                                <textarea className="form-control" rows="3"
                                          value={ReleaseAskDetailStore.releaseAskDetail.returnAskRemark || ''}
                                          disabled={isDisable}
                                          onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnAskRemark: v.target.value})}
                                />
                            </div>
                        </CCol>
                    }
                </Row>
                {ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1 &&
                    <>
                        <Row>
                            <Subject>배송정보</Subject>
                            <CCol lg={3}>
                                <CInputGroup label={'고객명'} labelId={'customerName'}
                                                disabled={true}
                                                value={ReleaseAskDetailStore.releaseAskDetail.customerName || ''}
                                />
                            </CCol>
                            <CCol lg={4}>
                                <InputGroup size={'sm'}>
                                    <InputGroup.Text>사무실</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'telephone'}
                                                placeholder={'전화번호'}
                                                value={localAutoHyphen(ReleaseAskDetailStore.releaseAskDetail.telephone) || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({telephone: v.target.value})}
                                    />
                                    <InputGroup.Text className='input-required'>휴대폰</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'phoneNumber'}
                                                value={autoHyphen(decrypt(ReleaseAskDetailStore.releaseAskDetail.phoneNumber)) || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({phoneNumber: encrypt(v.target.value)})}
                                    />
                                </InputGroup>
                            </CCol>
                            <CCol lg={5}>
                                <InputGroup size={'sm'}>
                                    <InputGroup.Text>부서명</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserDept'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserDept || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserDept: v.target.value})}
                                    />
                                    <InputGroup.Text>직급</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserPosition'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserPosition || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserPosition: v.target.value})}
                                    />
                                    <InputGroup.Text className="input-required">받는분</InputGroup.Text>
                                    <Form.Control style={{flex: 2, textAlign: 'left'}} aria-describedby={'receiveUserName'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserName || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserName: v.target.value})}
                                    />
                                </InputGroup>
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={7} style={{marginTop: 12}}>
                                <Row>
                                    <CCol lg={5}>
                                        <CalendarGroup asSingle={true}  label={'배송희망일'}
                                                    value={{
                                                            startDate: ReleaseAskDetailStore.releaseAskDetail.shipHopeDate,
                                                            endDate: ReleaseAskDetailStore.releaseAskDetail.shipHopeDate
                                                        }}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipHopeDate: dateFormat(v.startDate)})}
                                                    //minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                    labelClassName="input-required"
                                                    disabled={isDisable}
                                        />
                                    </CCol>
                                    <CCol lg={3}>
                                        <CSelectGroup label={'시간'} labelId={'shipHopeTime'}
                                                      options={[{name: '선택', value: ''}].concat(getCommcode('29'))}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.shipHopeTime || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipHopeTime: v.target.value})}
                                                    disabled={isDisable}
                                                    labelClassName="input-required"
                                        />
                                    </CCol>
                                    <CCol lg={4}>
                                        <CSelectGroup label={'배송방법'} labelId={'shipType'}
                                                    placeholder={'배송방법'}
                                                    options={[{name: '선택', value: ''}].concat(getCommcode('4'))}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.shipType || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipType: v.target.value})}
                                                    disabled={isDisable}
                                                    labelClassName="input-required"
                                        />
                                    </CCol>
                                </Row>
                                <Row>
                                    <CCol lg={12} md={12}>
                                        <Address label='주소'
                                                setPostcode={setPostcode}
                                                setAddress={setAddress}
                                                setAddressDetail={setAddressDetail}
                                                postcode={postcode}
                                                address={address}
                                                addressDetail={addressDetail}
                                                disabled={isDisable}
                                                addressDisabled={isDisable}
                                                // labelClassName='input-required'
                                        />
                                    </CCol>
                                </Row>
                            </CCol>
                            <CCol lg={5}>
                                <div className="mb-3">
                                    <label className="form-label">배송 비고</label>
                                    <textarea className="form-control" rows="2"
                                            value={ReleaseAskDetailStore.releaseAskDetail.shipRemark || ''}
                                            disabled={isDisable}
                                            placeholder={'배송 상세 시간 / 배송 요청 사항'}
                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipRemark: v.target.value})}
                                    />
                                </div>
                            </CCol>
                        </Row>
                    </>
                }
                <Form.Check
                    type="switch"
                    id="isReturnAskInfo"
                    label="회수정보입력"
                    style={{ marginTop: 4 }}
                    checked={isReturnAskInfo}
                    onChange={(e) => modifyReturnAskInfo(e)}
                    disabled={isDisable}
                />
                {ReleaseAskDetailStore.releaseAskDetail.contractType === '' || ReleaseAskDetailStore.releaseAskDetail.contractType === '1' && (
                    isReturnAskInfo &&
                    <>
                        <Row className="align-items-center">
                            <CCol lg={1}>
                                <Subject>회수정보</Subject>
                            </CCol>
                            <CCol lg={3}>
                                <a className="text-decoration-none" href="#!" style={{fontSize: 16}} onClick={() => insertReturnDeliveryInfo()}>배송정보와 동일하게 적용</a>
                            </CCol>
                        </Row>
                        {isReturnAskInfo &&
                            <>
                                <Row>
                                    <CCol lg={3}>
                                        <CInputGroup label={'고객명'} labelId={'customerName'}
                                                        disabled={true}
                                                        value={ReleaseAskDetailStore.releaseAskDetail.customerName}
                                        />
                                    </CCol>
                                    <CCol lg={4}>
                                        <InputGroup size={'sm'}>
                                            <InputGroup.Text>사무실</InputGroup.Text>
                                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnTelephone'}
                                                        placeholder={'전화번호'}
                                                        value={localAutoHyphen(ReleaseAskDetailStore.releaseAskDetail.returnTelephone) || ''}
                                                        disabled={isDisable}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnTelephone: v.target.value})}
                                            />
                                            <InputGroup.Text className='input-required'>휴대폰</InputGroup.Text>
                                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnPhoneNumber'}
                                                        value={autoHyphen(decrypt(ReleaseAskDetailStore.releaseAskDetail.returnPhoneNumber)) || ''}
                                                        disabled={isDisable}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnPhoneNumber: encrypt(v.target.value)})}
                                            />
                                        </InputGroup>
                                    </CCol>
                                    <CCol lg={5}>
                                        <InputGroup size={'sm'}>
                                            <InputGroup.Text>부서명</InputGroup.Text>
                                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnReceiveUserDept'}
                                                        value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserDept || ''}
                                                        disabled={isDisable}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserDept: v.target.value})}
                                            />
                                            <InputGroup.Text>직급</InputGroup.Text>
                                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnReceiveUserPosition'}
                                                        value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserPosition || ''}
                                                        disabled={isDisable}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserPosition: v.target.value})}
                                            />
                                            <InputGroup.Text className="input-required">받는분</InputGroup.Text>
                                            <Form.Control style={{flex: 2, textAlign: 'left'}} aria-describedby={'returnReceiveUserName'}
                                                        value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserName || ''}
                                                        disabled={isDisable}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserName: v.target.value})}
                                            />
                                        </InputGroup>
                                    </CCol>
                                </Row>
                                <Row>
                                    <CCol lg={7} style={{marginTop: 12}}>
                                        <Row>
                                            <CCol lg={5}>
                                                <CalendarGroup asSingle={true}  label={'회수희망일'}
                                                            value={{
                                                                    startDate: ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate,
                                                                    endDate: ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate
                                                                }}
                                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipHopeDate: dateFormat(v.startDate)})}
                                                            minDate={ReleaseAskDetailStore.releaseAskDetail.shipHopeDate}
                                                            labelClassName="input-required"
                                                            disabled={isDisable}
                                                />
                                            </CCol>
                                            <CCol lg={3}>
                                                <CSelectGroup label={'시간'} labelId={'returnShipHopeTime'}
                                                                options={[{name: '선택', value: ''}].concat(getCommcode('29'))}
                                                            value={ReleaseAskDetailStore.releaseAskDetail.returnShipHopeTime || ''}
                                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipHopeTime: v.target.value})}
                                                            disabled={isDisable}
                                                            labelClassName="input-required"
                                                />
                                            </CCol>
                                            <CCol lg={4}>
                                                <CSelectGroup label={'회수방법'} labelId={'returnShipType'}
                                                            placeholder={'회수방법'}
                                                            options={[{name: '선택', value: ''}].concat(getCommcode('4'))}
                                                            value={ReleaseAskDetailStore.releaseAskDetail.returnShipType || ''}
                                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipType: v.target.value})}
                                                            disabled={isDisable}
                                                            labelClassName="input-required"
                                                />
                                            </CCol>
                                        </Row>
                                        <Row>
                                            <CCol lg={12} md={12}>
                                                <Address label='주소'
                                                        setPostcode={setReturnPostcode}
                                                        setAddress={setReturnAddress}
                                                        setAddressDetail={setReturnAddressDetail}
                                                        postcode={returnPostcode}
                                                        address={returnAddress}
                                                        addressDetail={returnAddressDetail}
                                                        disabled={isDisable}
                                                        addressDisabled={isDisable}
                                                        labelClassName='input-required'
                                                />
                                            </CCol>
                                        </Row>
                                    </CCol>
                                    <CCol lg={5}>
                                        <div className="mb-3">
                                            <label className="form-label">회수 배송 비고</label>
                                            <textarea className="form-control" rows="2"
                                                    value={ReleaseAskDetailStore.releaseAskDetail.returnShipRemark || ''}
                                                    disabled={isDisable}
                                                    placeholder={'배송 상세 시간 / 배송 요청 사항'}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipRemark: v.target.value})}
                                            />
                                        </div>
                                    </CCol>
                                </Row>
                            </>
                        }
                    </>
                )}
                <Row> 
                    <Col className='d-flex flex-row-reverse'>
                        {(['1', '2', '3', '4', '5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && (ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1) || (ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '6' && ReleaseAskDetailStore.releaseAskDetail.shipStatus === '2')) && (
                            <CCancelBtn style={{width: 110}}
                                        title={'출고취소'}
                                        onClick={() => saveReleaseAskStatus('9', '출고취소')}
                            />
                        )}
                        {(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '4' ||  ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '5') && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고완료'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('6', '출고완료')}
                            />
                        )}
{/*                        {isTechManager && ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '4' && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고작업중'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('5', '출고작업중')}
                            />
                        )}*/}
                        {ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '3' && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고검수중'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('4', '출고검수')}
                            />
                        )}
                        {ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '2' && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고접수'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('3', '출고접수')}
                            />
                        )}
                        {(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '1') && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고의뢰'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('2', '출고의뢰')}
                            />
                        )}
                        {ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1
                            && ((['1', '2'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) || ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '')
                            || (!['6','9'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && (!ReleaseAskDetailStore.releaseAskDetail.creator || ReleaseAskDetailStore.releaseAskDetail.creator === userInfo.userSeq))) && (
                            <CSaveBtn onClick={saveReleaseAsk} style={{width: 80}} title={'저장'}/>
                        )}
                        {(ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq && !['1', '9'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && (ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1)) && (
                            <>
                                <CIconBtn style={{width: 120}}
                                        title={'출고의뢰서'}
                                        icon={'fi-rr-box-check'}
                                        onClick={() => releaseAskPrint()}
                                />
                                {/* <CIconBtn style={{width: 160}} icon={'fi fi-rs-print'} title={'출고제품문서출력'} onClick={() => printPdf()}/> */}
                                <ReportReleaseAskTemplate  ref={componentRef} contractNo={ReleaseAskDetailStore.releaseAskDetail.contractNo} />
                            </>
                        )}
                    </Col>
                </Row>
            </CContainer>
            {(Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskType) > 1 || (Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus)) > 1) && !selectAssetList &&  (
                <CContainer>
                    <Subject>출고 제품 리스트</Subject>
                    <AgGridContainer
                        ref={etcReleaseGridRef}
                        gridRef={gridRef}
                        height={!selectAssetList ? 30 : 20}
                        rowData={ReleaseAskDetailStore.releaseAskItemList}
                        columnDefs={
                            [
                                {field: "releaseAskSeq", headerName: "출고순번", hide: true},
                                {field: "seq", headerName: "순번", width: 80},
                                // {field: "contractProductSeq", headerName: "묶음번호", width: 110,},
                                {
                                    field: "isConsum",
                                    headerName: "소모품여부",
                                    width: 110,
                                    valueGetter: v => v.data.isConsum === 1 ? 'Y' : ''
                                },
                                {field: "productCode", headerName: "제품코드", width: 120},
                                {field: "productSeq", headerName: "제품시퀀스", width: 120, hide: true},
                                {field: "productName", headerName: "제품명", width: 150},
                                {field: "model", headerName: "모델명", width: 150},
                                {
                                    field: "qty", 
                                    headerName: "수량", 
                                    width: 80,
                                    editable: true,
                                    headerClass: 'grid-column-editable',
                                },//editable: false,
                                {
                                    field: "assetNo",
                                    headerName: "자산번호",
                                    width: 120,
                                    // hide: true,
                                    cellRenderer: (e) => popupAssetInfo(e),
                                    headerClass: 'grid-column-editable',
                                    editable: false,
                                    hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2'
                                },
                                {
                                    field: "managementNo", 
                                    headerName: "관리번호", 
                                    width: 120,
                                    hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2'
                                },
                                {
                                    field: "rentMonth", 
                                    headerName: "대여월", 
                                    width: 120,
                                    hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== '2' || (ReleaseAskDetailStore.releaseAskDetail.contractType == '3' || ReleaseAskDetailStore.releaseAskDetail.contractType == '4' || (ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2' && Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) > 2))
                                },
                                {
                                    field: "rentDays", 
                                    headerName: "대여일", 
                                    width: 120,
                                    hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== '2' || (ReleaseAskDetailStore.releaseAskDetail.contractType == '3' || ReleaseAskDetailStore.releaseAskDetail.contractType == '4' || (ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2' && Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) > 2))
                                },
                                {
                                    field: "monthRentPrice",
                                    headerName: "대당 월렌탈료",
                                    width: 130,
                                    editable: true,
                                    headerClass: 'grid-column-editable',
                                    cellClass: 'ag-grid-money-align',
                                    valueFormatter: v => v.value?.toLocaleString(),
                                    hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== '2' || (ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2' && Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) > 2)
                                },
                                {
                                    field: "lastMonthPrice",
                                    headerName: "대당 마지막달 요금",
                                    width: 150,
                                    editable: true,
                                    headerClass: 'grid-column-editable',
                                    cellClass: 'ag-grid-money-align',
                                    valueFormatter: v => v.value?.toLocaleString(),
                                    hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== '2' || (ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2' && Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) > 2)
                                },
                                {
                                    field: "totalRentPrice",
                                    headerName: "총 렌탈료",
                                    width: 140,
                                    cellClass: 'ag-grid-money-align',
                                    valueFormatter: v => v.value?.toLocaleString(),
                                    hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== '2' || (ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2' && Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) > 2)
                                },
                                {field: "storageNo", headerName: "입고번호", width: 120},
                                {field: "storageSeq", headerName: "입고순번", width: 110, hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType === 4},
                                {field: "serialNo", headerName: "시리얼번호", headerClass: 'grid-column-editable', width: 150},
                                {
                                    field: "isChecked",
                                    headerName: "검수",
                                    width: 80,
                                    valueFormatter: v => v.value === 1 ? '완료' : 'X',
                                    cellClass: v => v.value === 1 ? 'ag-grid-column-complete' : '',
                                    hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2' && !ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq
                                },
                                {field: "spec", headerName: "스펙", width: 300},
                                {field: "remark", headerName: "비고", headerClass: 'grid-column-editable', width: 300, editable: true},
                                {
                                    field: "print",
                                    headerName: "인쇄",
                                    width: 100,
                                    cellRenderer: (v) => (
                                        <div>
                                            <IconBtn iconClass={'fi fi-rs-print'} className={'mt-3'} onClick={() => printPdf()}/>
                                        </div>
                                    ),
                                    hide: true
                                },
                                // {
                                //     field: 'insurancePolicyFileName',
                                //     headerName: "보험증권",
                                //     width: 200,
                                //     cellRenderer: (v) => (
                                //         <div style={{ display: 'flex', }}>
                                //             <div>
                                //                 {/* <Card imageId={17} title="usePDF hook example" /> */}
                                //             </div>
                                //             <IconBtn iconClass={'fi fi-rr-blog-pencil'} className={'mt-0'} title={' 파일 변경'} onClick={() => modifyFile(v.data.insurancePolicyUrl)} style={{width: 100, marginRight: 20}}/>
                                //             <IconBtn iconClass={'fi fi-br-search'} className={'mt-3'} onClick={() => openFileLink(v.data.insurancePolicyUrl)} style={{width: 40}}/>
                                //         </div>
                                //     ),
                                // },
/*                                {
                                    field: "isCancel",
                                    headerName: "취소여부",
                                    cellEditorParams: {
                                        values: [0, 1],
                                    },
                                    valueFormatter: function(params) {
                                        return params.value === 0 ? '' : '취소';
                                    },
                                    width: 120
                                },
                                {field: "cancelUserName", headerName: "취소자", width: 120},
                                {field: "cancelDate", headerName: "취소일자", valueGetter: v => dateTimeFormat(v.data.cancelDate), width: 180},*/
                            ]
                        }
                        isCheckBox={true}
                        originList={releaseAskItemList}
                        seqColumn={'seq'}
                        addRowInfo={addRowInfo}
                        useUpdated={!ReleaseAskDetailStore.releaseAskItemList.releaseAskSeq && ReleaseAskDetailStore.releaseAskItemList.length > 0
                            && ['2','3','4','5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus)}
                        customBtnInfo={[customCancelBtn, resetAssetCheckBtn, assetCheckPopup, customReleaseItemBtn]}
                        callBackGridData={callBackGridData}
                        getRowStyle={(params) => {
                            if (params.data.isCancel === 1) {
                                return { pointerEvents: 'none', color: '#bababa' };
                            }
                            return null;
                        }}
                        getCellChange={e => getCellChange(e)}
                    />
                </CContainer>
            )}
            {selectAssetList &&
                <CContainer>
                    <Subject>회수 제품 리스트</Subject>
                    <AgGridContainer
                        height={20}
                        rowData={selectAssetList}
                        gridRef={returnGridRef}
                        columnDefs={
                            [
                                {field: "contractNo", headerName: "계약번호", width: 120},
                                {field: "assetNo", headerName: "자산번호", width: 110},
                                {field: "model", headerName: "모델명", width: 150},
                                {field: "assetStatusText", headerName: "계약상태", width: 110}, //1:신규, 2:연장, 3:단축, 4:교환, 5:단가변경
                                {field: "orgContractStartDate", headerName: "원개시일자", width: 120},
                                {field: "contractStartDate", headerName: "변경 개시일", width: 120},
                                {field: "contractEndDate", headerName: "계약종료일", width: 120},
                                {field: "changeProductName", headerName: "교환제품명", width: 150, cellRenderer: (e) => popupStockInfo(e), headerClass: 'grid-column-editable'},                            
                                // {field: "changeProductCode", headerName: "교환제품코드", width: 120, headerClass: 'grid-column-editable'},
                                {field: "changeProductSeq", headerName: "교환제품시퀀스", width: 130, hide: true},
                                {
                                    field: "changeAssetNo",
                                    headerName: "교환자산번호",
                                    width: 120,
                                    headerClass: 'grid-column-editable',
                                    editable: false,
                                },
                                {field: "changeModel", headerName: "교환모델명", width: 150, headerClass: 'grid-column-editable'},
                                {field: "changeStorageNo", headerName: "교환입고번호", width: 120, headerClass: 'grid-column-editable'},
                                {field: "changeStorageSeq", headerName: "교환입고순번", width: 120, hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType === 4, headerClass: 'grid-column-editable'},
                                {field: "changeSerialNo", headerName: "교환시리얼번호", headerClass: 'grid-column-editable', width: 150},
                                {field: "changeSpec", headerName: "교환스펙", width: 200, headerClass: 'grid-column-editable'},
                            ]
                        }
                        seqColumn={'assetNo'}
                    />
                </CContainer>
            }
            {/* 검색모달 */}
            <AssetSearch callbackFn={callbackAssetInfo} productCode={productCode} setProductCode={setProductCode}/>
            {/* 자산검수 */}
            <ReleaseAssetCheck callbackFn={() => getReleaseAskItemList(ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq)}
                               releaseAskItemList={ReleaseAskDetailStore.releaseAskItemList} releaseAskType={ReleaseAskDetailStore.releaseAskDetail.releaseAskType}
            />
            {/* 계약 조회 검색 모달 */}
            <ContractSearch callbackFn={callbackContractInfo} isEtcReleaseAsk={isEtcReleaseAsk}/>
            <StockProductConsumSearch
                callbackFn={callbackStockProductSearch}
                isEtcReleaseAsk={isEtcReleaseAsk}
                releaseAskType={ReleaseAskDetailStore.releaseAskDetail.releaseAskType}
                contractType={ReleaseAskDetailStore.releaseAskDetail.contractType}
                btnStatus={btnStatus}
                setBtnStatus={setBtnStatus}
            />
            <StockAssetSearch
                callbackProduct={callbackStockProductSearch}
                // callbackOption={callbackOptionListSearch}
                // selProductList={checkRowProductList}
                searchType={'A'}
            />
        </>
    );
}

export default observer(ReleaseAskDetail);

import React, {useEffect, useState} from "react";
import { observer } from 'mobx-react-lite';
import {Container, Navbar, Dropdown, NavDropdown, Nav, Image} from 'react-bootstrap';
import {AppStore} from "../../store/AppStore";
import {useLocation, useNavigate} from "react-router-dom";
import { getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";
import {useProSidebar} from "react-pro-sidebar";
import axios from "axios";

const Header = () => {
    const navigate = useNavigate();
    const [menuTitle, setMenuTitle] = useState('');
    const userInfo = getLocalStorage(USER_INFO);
    const { collapseSidebar, collapsed } = useProSidebar();

    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = menuTitle ? 'ITS - '+menuTitle : 'ITS';
    };

    useEffect(()=> {
        window.addEventListener('resize', screenResize);
        setLastOpenPageTitle();
    })

    useEffect(()=> {
        screenResize();
    }, [])

    const setLastOpenPageTitle =()=>{
        if(AppStore.openPageHistory.length > 0){
            const menuName = AppStore.openPageHistory.filter(v => v.url === location.pathname).map(v => v.name);
            setMenuTitle(menuName[0]);
            updateTitle();
        }
    }

    const userLogout = async () => {
        await axios.put("/user/logout")
        .then(() => AppStore.resetUser()).then(() => window.location.href = '/login');
    }

    /* 헤더 프로필 드롭다운 커스텀 */
    const customDropdown = React.forwardRef(({ children, onClick }, ref) => (
            <a  style={{color: "black"}}
                href=""
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}>
                {children}
            </a>
    ));

    const screenResize =()=> {
        if(window.innerWidth <= 900){
            AppStore.setIsMobile(true);
            if(!AppStore.isOpenForSidebar){
                toggleSidebar();
            }
        }else if(window.innerWidth > 900) {
            AppStore.setIsMobile(false);
            if(AppStore.isOpenForSidebar){
                toggleSidebar();
            }
        }
    }

    /* sidebar toggle */
    const toggleSidebar = () => {
        collapseSidebar();
        AppStore.toggleSidebar();
    }

    return (
        <Navbar sticky={'top'} className='header-custom'>
            <Container fluid>
                <div className='d-flex align-items-center justify-content-center' style={{height: 70, width: collapsed ? 60 : 238, backgroundColor: '#fff', marginTop: 5, marginBottom: 10}}>
                    <Image style={{cursor:'pointer'}} src='/image/pelotonlab.png' width={collapsed ? 15 : 25} height={19} onClick={toggleSidebar}/>
                    <Image src='/image/rentplan.png' width={collapsed ? 40 : 70} style={{marginLeft: 10}}/>
                    {/* <span style={{marginLeft: 10, fontSize: collapsed ? 10 : 13,  fontWeight: '500'}}>Rent Plan</span> */}
                </div>
                <div className="me-auto" style={{marginLeft: 30}}>
                    {AppStore.openPageHistory.length > 0 &&
                        (!AppStore.isMobile ?
                                <ul className="nav nav-tabs" style={{position: 'absolute', bottom: 1, borderWidth: 0}}>
                                    {AppStore.openPageHistory.map((v, i)=> {
                                        return (
                                            <li className="nav-item" key={i}>
                                                <a className={`nav-link ${location.pathname === v.url && 'active'}`} style={{cursor: 'pointer', backgroundColor: location.pathname === v.url ? '#f8f9fc' : '#fff'}} onClick={()=> navigate(v.url)}>
                                                    {v.name}
                                                    <i onClick={async()=> {
                                                        AppStore.openPageHistory.splice(i,1);
                                                        if(i > 0){
                                                            const menu = await AppStore.openPageHistory.find((value, index) => index === (i-1));
                                                            navigate(menu.url);
                                                        }
                                                    }}
                                                       className="fi fi-rr-cross-small"
                                                       style={{marginLeft: 5}}
                                                    />
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                                :
                                <Nav>
                                    <NavDropdown title={menuTitle} id="basic-nav-dropdown" style={{fontSize: 18, color: 'rgb(0 0 0 / 79%)'}}>
                                        {AppStore.openPageHistory.map((v, i)=> {
                                            return (
                                                <NavDropdown.Item
                                                    key={i}
                                                    onClick={()=> navigate(v.url)}
                                                >{v.name}</NavDropdown.Item>
                                            )
                                        })}
                                    </NavDropdown>
                                </Nav>
                        )
                    }
                </div>
                <div>
                    <Dropdown>
                        <Dropdown.Toggle as={customDropdown} id="dropdown-custom-components">
                            <div className="d-inline-flex mx-4" style={{flexDirection: "row", alignItems: "center"}}>
                                <div>
                                    {userInfo.name} &nbsp;&nbsp;&nbsp;
                                </div>
                                <div style={{height: 38, width: 39, cursor: 'pointer', marginRight: 0, borderRadius: 20, boxShadow: '2px 2px 3px rgba(34, 51, 84, 0.2)'}}>
                                    {userInfo.imageUrl ?
                                        <Image src={userInfo.imageUrl} style={{height: 38, objectFit: "cover", borderRadius: "20px"}} alt="프로필 이미지"/>
                                        :
                                        <i className="fi fi-rr-user" style={{paddingLeft: 8, fontSize: 25}}/>
                                    }
                                </div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.ItemText eventkey="1">{userInfo.companyName}</Dropdown.ItemText>
                            <Dropdown.Item onClick={()=> navigate('/mypage')}>마이페이지</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={userLogout}>로그아웃</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Container>
        </Navbar>
    );
};

export default observer(Header);

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Col, Row, InputGroup, Form, Table, Image } from "react-bootstrap";
import { CInputGroup, CCol, RadioButtonGroupTrueFalse, CSelectGroup, Subject, CalendarGroup, CSaveBtn, CIconBtn } from '../../../components/CustomContainer';
import { UserStore } from "../../../store/human-resources/UserStore";
import { callConfirm, getLocalStorage } from "../../../utils";
import { PELOTONLAB, USER_INFO } from "../../../common/constants";
import { encrypt, decrypt, showToast, autoHyphen } from "../../../common/utils";
import { findCommKrnm, getCommcode } from "../../../utils/commcode";
import Address from "../../../components/Address";
import { dateFormat } from "../../../utils/dateUtils";
import axios from "axios";

const UserInfo = ({ fileUploadForS3, saveUser, password, setPassword, postcode, setPostcode, address, setAddress, addressDetail, setAddressDetail, imgRef }) => {
    const [authGroupList, setAuthGroupList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const userInfo = getLocalStorage(USER_INFO);
    // const [personalNumberFirst, setPersonalNumberFirst] = useState(''); // 주민등록번호 앞자리
    // const [personalNumberSecond, setPersonalNumberSecond] = useState(''); // 주민등록번호 뒷자리

    useEffect(()=> {
        getAuthGroupList();
        
        if (PELOTONLAB === userInfo.companyCode) {
            getCompanyList();
        }
        // 주민등록번호 세팅
        // if (UserStore.data.userSeq) {
        //     const personalNumberArr = decrypt(UserStore.data.personalNumber).split('-');
        //     setPersonalNumberFirst(personalNumberArr[0]);
        //     setPersonalNumberSecond(personalNumberArr[1]);
        // } else {
        //     setPersonalNumberFirst("");
        //     setPersonalNumberSecond("");
        // }
    }, [UserStore.data.userSeq])

    /* 라디오 버튼 클릭 시 true -> 1 / false -> 0 변환 및 값 넣어주기 */
    const onClickIsActiveBtn = (e, target) => {
        const value = e === true ? 1 : e === '' ? '' : 0;
        if (target === 'searchIsActive') {
            UserStore.search.isActive = value;
        } else {
            UserStore.data.isActive = value;
            UserStore.data.resignationDate = null;
        }
    }

    const getAuthGroupList = async () => {
        // 권한그룹목록
        const groupInfo = await axios.get('/authority/group');
        const groupList = groupInfo.map(v => {
            v.name = v.groupName;
            v.value = v.authorityGroupSeq;
            return v;
        })

        setAuthGroupList(groupList);
    }

    const getCompanyList = async () => {
        const result = await axios.get('/user/company');
        const resultList = result.map(v => {
            v.name = v.companyName;
            v.value = v.companyCode;
            return v;
        })

        setCompanyList(resultList);
    }

    // const validPersonalNumber = (v, label) => {
    //     if (isNaN(v.target.value)) {
    //         showToast('숫자만 입력 가능합니다.');
    //         return;
    //     }

    //     if ((label === 'first' && v.target.value.length > 6) || (label === 'second' && v.target.value.length > 7)) {
    //         return;
    //     }

    //     label === 'first' ? setPersonalNumberFirst(v.target.value) : setPersonalNumberSecond(v.target.value);
    // }

    const onClickSaveBtn = () => {
        // if (personalNumberFirst === '' || personalNumberSecond === ''){
        //     showToast('주민등록번호를 입력해주세요.');
        //     return;
        // }
        // if (personalNumberFirst.length !== 6 || personalNumberSecond.length !== 7) {
        //     showToast('주민등록번호를 정확하게 입력해주세요.');
        //     return;
        // }

        // UserStore.data.personalNumber = encrypt(personalNumberFirst + "-" + personalNumberSecond);
        saveUser();
    }

    const resetPassword = async () => {
        if(!await callConfirm("비밀번호를 초기화 하시겠습니까? (초기화 비밀번호: asdf)")) {
            return;
        }
        await axios.put(`/user/reset/password/${UserStore.data.userSeq}`);
        showToast('비밀번호가 초기화 되었습니다.');
    }

    return (
        <>
            <Subject>회원정보</Subject>
            <Row>
                <CInputGroup labelId={'userSeq'}
                    disabled={true}
                    value={UserStore.data.userSeq}
                    type={'hidden'} />
                <Table borderless className="m-auto" style={{ tableLayout: 'fixed' }}>
                    <colgroup>
                        <col width={38} />
                        <col width={38} />
                        <col width={24} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <CInputGroup label={'ID'}
                                    labelId={'ID'}
                                    value={UserStore.data.id || ''}
                                    onChange={v => UserStore.data.id = v.target.value}
                                    disabled={UserStore.data.userSeq !== null}
                                    labelClassName='input-required' />
                            </td>
                            <td>
                                <CInputGroup label={'비밀번호'}
                                    labelId={'password'}
                                    placeholder={UserStore.data.userSeq && '비밀번호는 표시되지 않습니다.'}
                                    value={password}
                                    onChange={v => {
                                        setPassword(v.target.value);
                                        UserStore.data.isUpdatePassword = true;
                                    }}
                                    type={'password'}
                                    labelClassName='input-required'
                                    disabled={!UserStore.data.userSeq || userInfo.userSeq === UserStore.data.userSeq ? false : true }
                                />
                            </td>
                            {/* 이미지 미리보기 영역 */}
                            <td rowSpan={5} className={!UserStore.data.imageUrl ? 'text-center align-middle' : 'text-center'}>
                                {UserStore.data.imageUrl && <div style={{ height: 156, overflow: "hidden", display: 'flex', justifyContent: 'center'}}>
                                    <Image src={UserStore.data.imageUrl} style={{ height: 156, objectFit: "cover" }} alt="프로필 이미지" thumbnail /></div>}
                                {!UserStore.data.imageUrl && <i className="fi fi-rr-user" style={{ fontSize: 120 }} />}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CInputGroup label={'이름'}
                                    labelId={'name'}
                                    value={UserStore.data.name || ''}
                                    onChange={v => UserStore.data.name = v.target.value}
                                    labelClassName='input-required' />
                            </td>
                            <td>
                                <CSelectGroup label={'성별'}
                                    labelId={'genderSeq'}
                                    options={[{ name: '전체', value: '' }].concat(getCommcode('35'))}
                                    value={UserStore.data.genderSeq || ''}
                                    onChange={v => UserStore.data.genderSeq = v.target.value}
                                    labelClassName='input-required' />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CalendarGroup
                                    asSingle={true}
                                    label={'생년월일'}
                                    value={{
                                        startDate: UserStore.data.birthDate,
                                        endDate: UserStore.data.birthDate
                                    }}
                                    onChange={v => UserStore.data.birthDate = dateFormat(v.startDate)}
                                    labelClassName='input-required'
                                />
                            </td>
                            <td>
                                <CInputGroup label={'이메일'}
                                    labelId={'email'}
                                    type={'email'}
                                    value={decrypt(UserStore.data.email) || ''}
                                    onChange={v => UserStore.data.email = encrypt(v.target.value)}
                                    labelClassName='input-required' />
                            {/* <InputGroup size={'sm'}>
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}} className='input-required'>주민등록번호</InputGroup.Text>
                                <Form.Control className="form-control"
                                    value={personalNumberFirst || ''}
                                    onChange={v => validPersonalNumber(v, 'first')}
                                />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>-</InputGroup.Text>
                                <Form.Control className="form-control"
                                    value={personalNumberSecond || ''}
                                    type="password"
                                    onChange={v => validPersonalNumber(v, 'second')}
                                />
                            </InputGroup> */}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CInputGroup label={'휴대폰'}
                                    labelId={'phoneNumber'}
                                    value={autoHyphen(decrypt(UserStore.data.phoneNumber)) || ''}
                                    onChange={v => UserStore.data.phoneNumber = encrypt(autoHyphen(v.target.value))}
                                    labelClassName='input-required' />
                            </td>
                            <td>
                                <CInputGroup label={'전화번호'}
                                    labelId={'telephone'}
                                    value={UserStore.data.telephone || ''}
                                    onChange={v => UserStore.data.telephone = v.target.value} />
                            </td>

                        </tr>
                        <tr>
                            {/* <td>
                                <CInputGroup label={'이메일'}
                                    labelId={'email'}
                                    type={'email'}
                                    value={UserStore.data.email || ''}
                                    onChange={v => UserStore.data.email = v.target.value}
                                    labelClassName='input-required' />
                            </td> */}
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Address label='주소'
                                    setPostcode={setPostcode}
                                    setAddress={setAddress}
                                    setAddressDetail={setAddressDetail}
                                    postcode={postcode}
                                    address={address}
                                    addressDetail={addressDetail}
                                />
                            </td>
                            <td>
                                {/* 이미지 첨부 */}
                                <InputGroup>
                                    <Form.Control
                                        type="file"
                                        id="profileImg"
                                        size="sm"
                                        onChange={fileUploadForS3}
                                        ref={imgRef}
                                    />
                                </InputGroup>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Subject>소속정보</Subject>
            <Row>
                <CCol lg={4}>
                    {PELOTONLAB === userInfo.companyCode &&
                        <CSelectGroup label={'회사'}
                            labelId={'companyCode'}
                            options={[{ name: '전체', value: '' }].concat(companyList)}
                            value={UserStore.data.companyCode || ''}
                            onChange={v => UserStore.data.companyCode = v.target.value}
                            labelClassName='input-required' />
                    }
                    {PELOTONLAB !== userInfo.companyCode &&
                        <CInputGroup label={'회사'}
                                    labelId={'companyName'}
                                    value={UserStore.data.companyName || ''}
                                    onChange={v => UserStore.data.companyName = v.target.value} 
                                    disabled={true}/>
                    }
                </CCol>
                {/* <CCol lg={4}>
                    <CSelectGroup label={'본부'}
                        labelId={'headquartersSeq'}
                        options={[{ name: '전체', value: '' }].concat(getCommcode('34'))}
                        value={UserStore.data.headquartersSeq || ''}
                        onChange={v => UserStore.data.headquartersSeq = v.target.value} />
                </CCol> */}
                <CCol lg={4}>
                    {/* <CSelectGroup label={'부서'}
                        labelId={'partSeq'}
                        options={[{ name: '전체', value: '' }].concat(getCommcode('32'))}
                        value={UserStore.data.partSeq || ''}
                        onChange={v => UserStore.data.partSeq = v.target.value} /> */}
                    <CInputGroup label={'부서'}
                                labelId={'partName'}
                                value={UserStore.data.partName}
                                onChange={v => UserStore.data.partName = v.target.value} 
                                />
                </CCol>
                {/* <CCol lg={4}>
                    <CSelectGroup label={'팀'}
                        labelId={'teamSeq'}
                        options={[{ name: '전체', value: '' }].concat(getCommcode('33'))}
                        value={UserStore.data.teamSeq || ''}
                        onChange={v => UserStore.data.teamSeq = v.target.value}
                        labelClassName='input-required' />
                </CCol> */}
                <CCol lg={4}>
                    <CSelectGroup label={'직급'}
                        labelId={'positionSeq'}
                        options={[{ name: '전체', value: '' }].concat(getCommcode('31'))}
                        value={UserStore.data.positionSeq || ''}
                        onChange={v => UserStore.data.positionSeq = v.target.value} />
                </CCol>
                <CCol lg={6}>
                    <CalendarGroup
                        asSingle={true}
                        label={'입사일'}
                        value={{
                            startDate: UserStore.data.joinDate,
                            endDate: UserStore.data.joinDate
                        }}
                        onChange={v => UserStore.data.joinDate = dateFormat(v.startDate)}
                        labelClassName='input-required'
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={6}>
                    <CalendarGroup
                        asSingle={true}
                        label={'퇴사일'}
                        value={{
                            startDate: UserStore.data.resignationDate,
                            endDate: UserStore.data.resignationDate
                        }}
                        onChange={v => UserStore.data.resignationDate = dateFormat(v.startDate)}
                        disabled={UserStore.data.isActive === null || UserStore.data.isActive}
                    />
                </CCol>
                <CCol lg={6}>
                    <RadioButtonGroupTrueFalse
                        useTotal={false}
                        name={'isActive'}
                        label={'사용여부(퇴사)'}
                        btnText={['재직중', '퇴사']}
                        value={UserStore.data.isActive === 0 ? false : true}
                        onClick={e => onClickIsActiveBtn(e, 'isActive')} />
                </CCol>
            </Row>
            <Subject>권한</Subject>
            <Row>
                <CCol lg={4}>
                    <CSelectGroup label={'권한그룹'} labelId={'authGroup'}
                                    options={[{name: '선택', value: ''}].concat(authGroupList)}
                                    value={UserStore.data.authorityGroupSeq || ''}
                                    onChange={(v) => UserStore.data.authorityGroupSeq = v.target.value}
                                    labelClassName="input-required"
                    />
                </CCol>
            </Row>
            <Subject>역할</Subject>
            <Row>
                <Col lg={12}>
                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                        <Form.Check
                            type="checkbox"
                            id='isReleaseAskManager' 
                            checked={UserStore.data.isReleaseAskManager || ''} 
                            onChange={v => UserStore.data.isReleaseAskManager = v.target.checked} 
                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                            // disabled={isPrintAll}
                        /> 
                        <span>출고의뢰담당</span>
                    </label>
                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                        <Form.Check
                            type="checkbox"
                            id='isASMaintenanceManager' 
                            checked={UserStore.data.isAsMaintenanceManager || ''} 
                            onChange={v => UserStore.data.isAsMaintenanceManager = v.target.checked} 
                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                            // disabled={isPrintAll}
                        /> 
                        <span>A/S & 정비담당</span>
                    </label>
                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                        <Form.Check
                            type="checkbox"
                            id='isShipManager' 
                            checked={UserStore.data.isShipManager || ''} 
                            onChange={v => UserStore.data.isShipManager = v.target.checked} 
                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                            // disabled={isPrintAll}
                        /> 
                        <span>배송담당</span>
                    </label>
                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                        <Form.Check
                            type="checkbox"
                            id='isInspectionManager'
                            checked={UserStore.data.isInspectionManager || ''}
                            onChange={v => UserStore.data.isInspectionManager = v.target.checked}
                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                            // disabled={isPrintAll}
                        />
                        <span>검수담당</span>
                    </label>
                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                        <Form.Check
                            type="checkbox"
                            id='isPaymentManager'
                            checked={UserStore.data.isPaymentManager || ''}
                            onChange={v => UserStore.data.isPaymentManager = v.target.checked}
                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                        />
                        <span>결재담당</span>
                    </label>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSaveBtn style={{ width: 80 }}
                        title={'저장'}
                        onClick={onClickSaveBtn}
                    />
                    {UserStore.data.userSeq && (
                        <CIconBtn 
                            title={'비밀번호 초기화'}
                            onClick={resetPassword}
                        />
                    )}
                </Col>
            </Row>
        </>
    )

}

export default observer(UserInfo);

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { useReactToPrint } from 'react-to-print';

import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import { ShipStore } from "../../../store/ship/ShipStore";
import { decrypt, showToast } from "../../../common/utils";
import { Button } from "react-bootstrap";
import SignaturePopup from "./SignaturePopup";
import PreviewImage from "../../../components/preview/PreviewImage";
import { isMobile, isDetectMobile } from "../../../common/utils";
import {getSessionUserSeq, getUserName} from "../../../utils/userUtils";
import {callConfirm} from "../../../utils";
import "./signaturePopupStyle.css";

const isMobileDevice = isMobile();
const isDetectMobileDevice = isDetectMobile();

const ConfirmPopup =(props)=> {
    const didMount = useRef(false);
    const ref = useRef();

    const [data, setData] = useState([]);
    const [shipSeq, setShipSeq] = useState('');
    const [sessionUserSeq, setSessionUserSeq] = useState('');
    const [siteRemark, setSiteRemark] = useState('');

    useEffect(() => {
        if(props.shipSeq || didMount.current){
            getShipData();
        }else {
            didMount.current = true;
        }
        setSessionUserSeq(getSessionUserSeq());
    }, [props.shipSeq]);

    /* 데이터 조회 */
    const getShipData = async () => {
        const result = await axios.get('ship/info', { params: {shipSeq:ShipStore.shipSeq}});
        setData(result);
    }

    const handleSignatureClick = (shipSeq) => {
        setShipSeq(shipSeq);
        AppStore.toggleModal();
    };

    const reportSave =async()=> {
        if(!await callConfirm('완료하시겠습니까?')) return;

        const result = await axios.put('ship/info', {shipSeq:ShipStore.shipSeq, userSeq: sessionUserSeq, siteRemark: siteRemark });
        showToast('저장되었습니다.');

        await getShipData();
        await props.getShipList();
    }

    /* print */
    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });

    return (
        <>
            <Modal
                title={'인수인도확인서'}
                show={AppStore.isOpenConfirmPopup}
                onHide={() => AppStore.toggleConfirmPopup()}
                onExit={()=> setSiteRemark('')}
                toggleValue={AppStore.isOpenConfirmPopup}
                className={'modal-h9'}
            >
                <div ref={ref} className="print-template">
                    <h2 style={{textAlign: 'center', marginBottom: 20}}>인수인도 확인서</h2>
                    <div style={{display: 'flex'}}><i className="fi fi-br-apps" style={{marginRight: 5, paddingTop: 5}}/><h5>{data[0]?.contractType === '3' ? '공급자 / 구매자' : '렌탈사 / 이용자'}</h5></div>
                    <table className="table" style={{borderWidth: 1}}>
                        <thead>
                        <tr>
                            <th className='border' style={{width: '50%', textAlign: 'center', backgroundColor: '#E7E7E7'}} colSpan={2}>{data[0]?.contractType === '3' ? '공급자' : '렌탈사'}</th>
                            <th className='border' style={{width: '50%', textAlign: 'center', backgroundColor: '#E7E7E7'}} colSpan={2}>{data[0]?.contractType === '3' ? '구매자' : '이용자'}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className='p-1 border' style={{width: '50%', textAlign: 'center'}} colSpan={2}>{data[0]?.companyName}</td>
                            <td className='p-1 border' style={{width: '50%', textAlign: 'center'}} colSpan={2}>{data[0]?.customerName}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{width: '10%', textAlign: 'center'}}>담당자</td>
                            <td className='p-1 border' style={{width: '30%'}}><span style={{marginLeft: 5}}>{data[0]?.saleUserName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>주소</td>
                            <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{data[0]?.postCode + ' ' + data[0]?.address + ' '+ data[0]?.addressDetail}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'center'}}>연락처</td>
                            <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{decrypt(data[0]?.phoneNumber)}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>인수자</td>
                            <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{`${data[0]?.receiveUserName} ${data[0]?.receiveUserDept ? '/ ' + data[0]?.receiveUserDept : '' } / ${decrypt(data[0]?.receiveUserPhoneNumber)}`}</span></td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="mb-2"><b>◼︎ 계약번호 {data[0]?.contractNo}</b></div>
                    <div>하기 인수인이 서명한 본 인수인도확인서에 의하여 실질적인 {data[0]?.contractType === '3' ? '유통' : '렌탈'}계약이 성립하였음을 확인합니다.</div>
                    <br/>
                    <br/>

                    <div style={{display: 'flex'}}><i className="fi fi-br-apps" style={{marginRight: 5, paddingTop: 5}}/><h5>{data[0]?.contractType === '3' ? '' : '렌탈'}품목 세부현황</h5></div>
                    <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
                        <thead>
                        <tr>
                            <th className='border' style={{width: '5%', backgroundColor: '#E7E7E7'}}>순번</th>
                            <th className='border' style={{width: '20%', backgroundColor: '#E7E7E7'}}>제품명</th>
                            <th className='border' style={{width: '20%', backgroundColor: '#E7E7E7'}}>모델명</th>
                            {data[0]?.contractType !== '3' && 
                                <th className='border' style={{width: '14%', backgroundColor: '#E7E7E7'}}>자산번호</th>
                            }
                            <th className='border' style={{width: '15%', backgroundColor: '#E7E7E7'}}>시리얼번호</th>
                            <th className='border' style={{width: '12%', backgroundColor: '#E7E7E7'}}>개시일</th>
                            <th className='border' style={{width: '12%', backgroundColor: '#E7E7E7'}}>종료일</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((v, index) => (
                            <tr key={index}>
                                <td className='p-1 border'>{index+1}</td>
                                <td className='p-1 border'>{v.productName}</td>
                                <td className='p-1 border'>{v.model}</td>
                                {data[0]?.contractType !== '3' && 
                                    <td className='p-1 border'>{v.assetNo}</td>
                                }
                                <td className='p-1 border'>{v.serialNo}</td>
                                <td className='p-1 border'>{v.contractStartDate}</td>
                                <td className='p-1 border'>{v.contractEndDate}</td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                    <br/>

                    <table className="table" style={{borderWidth: 1}}>
                        <tbody>
                        <tr>
                            <td className='p-1 border' style={{width: '15%', textAlign: 'center', backgroundColor: '#E7E7E7'}}>설치장소</td>
                            <td className='p-1 border' colSpan={5}>{data[0]?.installPlace}</td>
                        </tr>
                        {data[0]?.contractType !== '3' &&
                            <tr>
                                <td className='p-1 border' colSpan={6}>
                                    1) 계약기간 종료전에 렌탈물건을 반납할 경우 기준렌탈료에 의한 금액으로 재산정한 정산렌탈료를 귀사에 납입한다. <br/>
                                    2) 렌탈기간 만료후에도 렌탈물건을 반납하지 않을 경우 반환완료일까지 기준렌탈료로 산정한 손해금을 귀사에 납입한다. <br/>
                                    3) 렌탈물건 자체 이상의 경우 렌탈회사에서 수리 또는 교환 책임을 부담하되, 사용 보관의 부주의로 인한 렌탈물건의고장 또는 훼손(멸실 포함) 등의 사유 발생시에는 수리비용 또는 대체물건의 구입비용을 손해배상금으로 귀사에 납입한다
                                </td>
                            </tr>
                        }
                        <tr style={{ height: '80px' }}>
                            <td className='p-1 border' style={{width: '15%', verticalAlign: 'middle', textAlign: 'center', backgroundColor: '#E7E7E7'}}>인도인</td>
                            <td className='p-1 border' style={{width: '35%', verticalAlign: 'middle'}} colSpan={2}><span style={{paddingLeft: 5}}>{getUserName(data[0]?.deliverUserSeq ? data[0]?.deliverUserSeq : sessionUserSeq)}</span></td>
                            <td className='p-1 border' style={{width: '15%', verticalAlign: 'middle', textAlign: 'center', backgroundColor: '#E7E7E7'}}>인수인</td>
                            <td className='p-1 border' style={{width: '35%', verticalAlign: 'middle'}} colSpan={2}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span style={{width: 80, paddingLeft: 5}}>{data[0]?.receiveUserName}</span>
                                    {data[0]?.signatureUrl ? (
                                        <div style={{ position: 'relative', marginLeft: 'auto' }}>
                                            <PreviewImage imageUrl={data[0]?.signatureUrl} />
                                            <span style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: 0, padding: '2px 4px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>(서명)</span>
                                        </div>
                                    ) : (
                                        <div style={{ position: 'relative', marginLeft: 'auto' }}>
                                            {!isDetectMobileDevice && <Button className="signatureBtn" style={{ marginLeft: 'auto' }} onClick={() => handleSignatureClick(props.shipSeq)}>서명하기</Button>}                                           
                                            <span style={{ marginLeft: 'auto' }}>(서명)</span>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table className="table inputRemark" style={{borderWidth: 1}}>
                        <tbody>
                        <tr>
                            <td className='p-1 border' style={{width: '15%', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#E7E7E7'}}>현장비고</td>
                            <td className='p-1 border' style={{width: '85%'}}>
                                <textarea rows={3} style={{width: '100%'}} disabled={data[0]?.deliverUserSeq} value={data[0]?.siteRemark ? data[0]?.siteRemark : siteRemark} onChange={e => setSiteRemark(e.target.value)} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {data[0]?.completedDate &&
                        <p>완료일시 : {data[0]?.completedDate}</p>
                    }
                    <br/>
                    <div style={{marginBottom: 30, display: 'flex', justifyContent: 'space-between'}} className="button-group">
                        <Button variant={'primary'} style={{flex: 1, marginRight: 5}}  onClick={handlePrint}>프린트하기</Button>
                        <Button variant={'success'} style={{flex: 1, marginLeft: 5}} disabled={data[0]?.deliverUserSeq} onClick={() => reportSave()}>완료</Button>
                    </div>
                </div>
            </Modal>

            <Modal title={'서명'} size={isMobileDevice ? 'xl' : 'lg'}>
                <SignaturePopup shipSeq={shipSeq} getShipList={props.getShipList} getShipData={getShipData} />
            </Modal>

        </>
    )
}

export default observer(ConfirmPopup);

import { observable } from "mobx";

export const SiteSearchStore = observable({
    isAsDetail: false,
    search: {
        siteName: '',
        managerName: '',
    },
    init() {
        this.isAsDetail = false;
        this.search.siteName = '';
        this.search.managerName = '';
    }
});

import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from "axios";
import {AppStore} from "../../store/AppStore";
import Modal from "../Modal";
import {CCol, CContainer, CInputGroup, CSearchBtn} from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";

/**
 * @param {Function} callbackFn: 결과값 받을 콜백 함수
*/
const ProductListSearch =({ callbackFn=null, ...props })=> {
    const [productName, setProductName] = useState('');
    const [model, setModel] = useState('');
    const [productList, setProductList] = useState([]);
    const [productGroupList, setProductGroupList] = useState([]);
    const [lgList, setLGList] = useState([]);
    const [mdList, setMDList] = useState([]);
    const [smList, setSMList] = useState([]);
    const [xsList, setXSList] = useState([]);
    const [lgSeq, setLGSeq] = useState('');
    const [mdSeq, setMDSeq] = useState('');
    const [smSeq, setSMSeq] = useState('');
    const [xsSeq, setXSSeq] = useState('');
    const [isCateDisabled, setIsCateDisabled] = useState(false);

    useEffect(() => {
        getProductGroupList();
    }, [AppStore.isOpenProductListSearch]);

    /* 제품 카테고리 조회 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        if(result.length > 0){
            setProductGroupList(result);
            setLGList(result.filter(v => v.parentSeq === null));

            if(props.orderType && props.orderType === '3'){
                setIsCateDisabled(true);
            }
        }
    }

    /* 대분류 클릭 시 중분류 담아주기 */
    const onChangeLGCode = e => {
        setLGSeq(e.target.value);
        setMDList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        setSMList([]);
        setXSList([]);
    }

    /* 중분류 클릭 시 소분류 담아주기 */
    const onChangeMDCode = e => {
        setMDSeq(e.target.value);
        setSMList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        setXSList([]);
    }

    /* 소분류 클릭 이벤트 */
    const onChangeSMCode = e => {
        setSMSeq(e.target.value);
        setXSList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
    }

    /* 세분류 클릭 이벤트 */
    const onChangeXSCode = e => {
        setXSSeq(e.target.value);
    }

    const onSearchProductList = async () => {
        const params = { productName, model, lgSeq, mdSeq, smSeq, xsSeq, isConsum: props.orderType && props.orderType === '3' ? 1 : 0,isDeleted: 0 };
        const result = await axios.get('/product/list', { params });
        setProductList(result);
    }

    const selectedRow = e => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleProductListSearchModal();
    }

    const refresh = () => {
        setProductList([]);
        setProductName('');
        setModel('');
        setLGList([]);
        setMDList([]);
        setSMList([]);
        setXSList([]);
        setLGSeq('');
        setMDSeq('');
        setSMSeq('');
        setXSSeq('');
    }

    const largeCateOptionsHandler =()=> {
        let options = [];
        lgList.forEach((lg,idx) => {
            if(idx === 0){
                options.push(<option key={idx} value={''}>- 대분류 -</option>);
            }
            options.push(<option key={idx+1} value={lg.productGroupSeq}>{lg.productGroupName}</option>);
        });

        /* 기타출고의뢰 등록일 경우 일반 소모품만 보이게 */
        if(props.orderType === '3'){
            options = lgList.filter(lg => lg.productGroupName.indexOf('소모품') > -1).map((lg,idx) => {
                return <option key={idx} value={lg.productGroupSeq}>{lg.productGroupName}</option>
            });
        }

        return options;
    }

    return (
        <Modal
            title={'제품조회'}
            show={AppStore.isOpenProductListSearch}
            onHide={() => AppStore.toggleProductListSearchModal()}
            toggleValue={AppStore.isOpenProductListSearch}
            className={'modal-w12'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={lgSeq || ''} onChange={onChangeLGCode} disabled={isCateDisabled}>
                                {largeCateOptionsHandler()}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={mdSeq || ''} onChange={onChangeMDCode} disabled={isCateDisabled}>
                                <option value={''}>- 중분류 -</option>
                                {mdList.map((md, idx) => (
                                    <option key={idx} value={md.productGroupSeq}>{md.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={smSeq} onChange={onChangeSMCode} disabled={isCateDisabled}>
                                <option value={''}>- 소분류 -</option>
                                {smList.map((sm,idx) => (
                                    <option key={idx} value={sm.productGroupSeq}>{sm.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={xsSeq || ''} onChange={onChangeXSCode} disabled={isCateDisabled}>
                                <option value={''}>- 세분류 -</option>
                                {xsList.map((xs,idx) => (
                                    <option key={idx} value={xs.productGroupSeq}>{xs.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            placeholder={'제품명'}
                            value={productName}
                            onChange={v => setProductName(v.target.value)}
                            enterKeyHint={onSearchProductList}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            placeholder={'모델명'}
                            value={model}
                            onChange={v => setModel(v.target.value)}
                            enterKeyHint={onSearchProductList}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearchProductList} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={37}
                    rowData={productList}
                    columnDefs={
                        [
                            {field: "productSeq", headerName: "제품SEQ", hide:true},
                            {field: "lg", headerName: "대분류", width: 120},
                            {field: "productName", headerName: "제품명", width: 150},
                            {field: "model", headerName: "모델명", width: 150},
                            {field: "md", headerName: "중분류", width: 120},
                            {field: "sm", headerName: "소분류", width: 120},
                            {field: "spec", headerName: "스펙", width: 300},
                            {field: "remark", headerName: "비고", width: 200}
                        ]
                    }
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(ProductListSearch);

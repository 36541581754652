import {useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import {CCol, CContainer,  CContainerNonBox, Subject} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import axios from "axios";
import {showToast} from "../../common/utils";
import SalesListSearch from "./SalesListSearch";
import {SalesListStore} from "../../store/customer/SalesListStore";
import {Tab, Tabs, Row, InputGroup, Form} from "react-bootstrap";
import {Chart} from "react-chartjs-2";
import {getUserName} from "../../utils/userUtils";
import {findCommKrnm} from "../../utils/commcode";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip,} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);


const SalesList = () => {
    const gridRef = useRef();

    const [customerName, setCustomerName] = useState('');

    /* 조회 */
    const getSalesList = async () => {
        const searchCondition = Object.values(SalesListStore.searchCondition);
        let inputCount = 0;

        for(const value of searchCondition){
            if(value !== '' && value !== null){
                inputCount++;
            }
        }

        if(SalesListStore.startDate.startDate === '' && inputCount === 0){
            showToast('검색 조건을 1가지 이상 입력해주세요.');
            return;
        }

        await Promise.all([getMonthSalesList(), getDeptSalesList(), getUserSalesList(), getCustomerSalesList()]);
    }

    const getMonthSalesList =async()=> {
        const param = Object.assign(SalesListStore.searchCondition, {startDate: SalesListStore.startDate.startDate, endDate: SalesListStore.endDate.startDate});
        const result = await axios.get('/sales/month', { params: param });
        SalesListStore.setMonthSalesList(result);

        //차트용 데이터
        if(result.length > 0){

            const arrMonth = result.filter((v, i) => result.findIndex(x => x.yyMm === v.yyMm) === i).map(v => {return v.yyMm});
            //날짜 데이터를 담아둔다.
            SalesListStore.setChartMonthList(arrMonth);

            const rentalAmountList = [];
            const saleAmountList = [];
            const assetSaleAmountList = [];
            const acquAmountList = [];

            SalesListStore.chartMonthList.forEach(v => {
                const data = result.map(x => {
                    if(v === x.yyMm){
                        return x;
                    }else {
                        return null;
                    }
                }).filter(e => e);

                rentalAmountList.push(data.reduce((total, v) => total + v.rentalAmount, 0));
                saleAmountList.push(data.reduce((total, v) => total + v.saleAmount, 0));
                assetSaleAmountList.push(data.reduce((total, v) => total + v.assetSaleAmount, 0));
                acquAmountList.push(data.reduce((total, v) => total + v.acquAmount, 0));
            });


            SalesListStore.setRentalAmountList(rentalAmountList);
            SalesListStore.setSaleAmountList(saleAmountList);
            SalesListStore.setAssetSaleAmountList(assetSaleAmountList);
            SalesListStore.setAcquAmountList(acquAmountList);
        }else{
            SalesListStore.chartInit();
        }
    }
    const getDeptSalesList =async()=> {
        const result = await axios.get('/sales/dept', { params: SalesListStore.searchCondition });

        SalesListStore.setCompanySalesList(result.companySalesList);
        SalesListStore.setDeptSalesList(result.deptSalesList);
    }
    const getUserSalesList =async()=> {
        const result = await axios.get('/sales/user', { params: SalesListStore.searchCondition });
        SalesListStore.setUserSalesList(result);
    }
    const getCustomerSalesList =async()=> {
        const result = await axios.get('/sales/customer', { params: SalesListStore.searchCondition });
        SalesListStore.setCustomerSalesList(result);
        SalesListStore.setOrgCustomerSalesList(result);
    }

    const searchDateHtml =()=> {
        return <span style={{paddingTop: 5, marginRight: 10}}>기준일 : {`${SalesListStore.startDate.startDate} ~ ${SalesListStore.endDate.startDate}`}</span>;
    }


    const pinnedTopRowData = [{
        yyMm: '', companyCode: '',
        rentalAmount: SalesListStore.monthSalesList.reduce((total, v) => total + v.rentalAmount, 0),
        saleAmount: SalesListStore.monthSalesList.reduce((total, v) => total + v.saleAmount, 0),
        assetSaleAmount: SalesListStore.monthSalesList.reduce((total, v) => total + v.assetSaleAmount, 0),
        totalAmount: SalesListStore.monthSalesList.reduce((total, v) => total + v.totalAmount, 0),
        acquAmount: SalesListStore.monthSalesList.reduce((total, v) => total + v.acquAmount, 0)
    }];


    return(
        <>
            <SalesListSearch getSalesList={getSalesList}/>
            <CContainer>
                <Tabs defaultActiveKey="year" className="mb-3"
                      mountOnEnter={true}
                      unmountOnExit={true}
                      activeKey={SalesListStore.detailTab}
                      onSelect={k => {
                          SalesListStore.setDetailTab(k);
                      }}
                >
                    <Tab eventKey="year" title="매출 총괄">
                        <Row>
                            <CCol lg={6}>
                                <CContainerNonBox style={{height: 360, background: 'linear-gradient(0deg, rgba(36,0,0,1) 0%, rgba(78,227,237,1) 0%, rgba(173,173,158,1) 100%)'}}>
                                    <div style={{paddingLeft: 5, paddingTop: 10}}>
                                        <label style={{color: 'rgb(95, 105, 117)', fontSize: 16}}>계약 유형별 매출액</label>
                                    </div>
                                    <div style={{height: 300, display: 'flex', justifyContent: 'center'}}>
                                        <Chart type={"bar"}
                                               options={
                                                   {
                                                       responsive: true,
                                                       plugins: {
                                                           legend: {
                                                               position: 'top',
                                                           },
                                                       },
                                                       color: 'rgb(95, 105, 117)',
                                                       borderColor: '#fff',
                                                       backgroundColor: '#fff'
                                                   }
                                               }
                                               data={{
                                                   labels: SalesListStore.chartMonthList,
                                                   datasets: [
                                                       {
                                                           label: '렌탈',
                                                           data: SalesListStore.rentalAmountList,
                                                           borderColor: '#fff',
                                                           backgroundColor: '#2779df',
                                                           tension: 0.5,
                                                       },
                                                       {
                                                           label: '유통',
                                                           data: SalesListStore.saleAmountList,
                                                           borderColor: 'rgb(255, 99, 132)',
                                                           backgroundColor: 'rgb(255, 99, 132)',
                                                           tension: 0.5,
                                                       },
                                                       {
                                                           label: '매각',
                                                           data: SalesListStore.assetSaleAmountList,
                                                           borderColor: '#fff',
                                                           backgroundColor: '#65655b',
                                                           tension: 0.5,
                                                       },
                                                   ]}
                                               }
                                               style={{height: 190}}
                                        />
                                    </div>
                                </CContainerNonBox>
                            </CCol>
                            <CCol lg={6}>
                                <CContainerNonBox style={{height: 360, background: 'linear-gradient(0deg, rgba(36,0,0,1) 0%, rgba(78,227,237,1) 0%, rgba(173,173,158,1) 100%)'}}>
                                    <div style={{paddingLeft: 5, paddingTop: 10}}>
                                        <label style={{color: 'rgb(95, 105, 117)', fontSize: 16}}>자산 취득액</label>
                                    </div>
                                    <div style={{height: 300, display: 'flex', justifyContent: 'center'}}>
                                        <Chart type={"line"}
                                               options={
                                                   {
                                                       responsive: true,
                                                       plugins: {
                                                           legend: {
                                                               position: 'top',
                                                           },
                                                       },
                                                       color: 'rgb(95, 105, 117)',
                                                       borderColor: '#fff',
                                                       backgroundColor: '#fff'
                                                   }
                                               }
                                               data={{
                                                   labels: SalesListStore.chartMonthList,
                                                   datasets: [
                                                       {
                                                           label: '자산취득액',
                                                           data: SalesListStore.acquAmountList,
                                                           borderColor: '#585858',
                                                           backgroundColor: '#41d728',
                                                           tension: 0.5,
                                                       }
                                                   ]}
                                               }
                                               style={{height: 190}}
                                        />
                                    </div>
                                </CContainerNonBox>
                            </CCol>
                        </Row>
                        <Row className='m-1 p-1'>
                            <CCol lg={12}>
                                <Subject>월별 매출액</Subject>
                                <AgGridContainer
                                    gridRef={gridRef}
                                    height={40}
                                    rowData={SalesListStore.monthSalesList}
                                    columnDefs={[
                                        {field: "yyMm", headerName: "년월", width: 140},
                                        {field: "companyCode", headerName: "회사구분", width: 140, valueFormatter: v => findCommKrnm(v.value, '20')},
                                        {field: "rentalAmount", headerName: "렌탈", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "saleAmount", headerName: "유통", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "assetSaleAmount", headerName: "매각", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "totalAmount", headerName: "합계", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "acquAmount", headerName: "자산취득액", width: 180, flex: 1, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                    ]}
                                    seqColumn={'agId'}
                                    useCsvDownload={true}
                                    pinnedTopRowData={pinnedTopRowData}
                                    appendTitle={searchDateHtml()}
                                />
                            </CCol>
                        </Row>
                        <Row className='m-1 p-1'>
                            <CCol lg={12}>
                                <Subject>회사별 매출액</Subject>
                                <AgGridContainer
                                    gridRef={gridRef}
                                    height={15}
                                    rowData={SalesListStore.companySalesList}
                                    columnDefs={[
                                        {field: "ranking", headerName: "순위", width: 80},
                                        {field: "companyCode", headerName: "회사", width: 140, valueFormatter: v => findCommKrnm(v.value, '20')},
                                        {field: "rentalAmount", headerName: "렌탈", width: 160, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "saleAmount", headerName: "유통", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "assetSaleAmount", headerName: "매각", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "totalAmount", headerName: "매출합계", width: 200, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "acquAmount", headerName: "자산취득액", width: 180, flex: 1, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                    ]}
                                    seqColumn={'companyCode'}
                                    useCsvDownload={true}
                                    appendTitle={searchDateHtml()}
                                />
                            </CCol>
                        </Row>
                        <Row className='m-1 p-1'>
                            <CCol lg={12}>
                                <Subject>부서별 매출액</Subject>
                                <AgGridContainer
                                    gridRef={gridRef}
                                    height={20}
                                    rowData={SalesListStore.deptSalesList}
                                    columnDefs={[
                                        {field: "ranking", headerName: "순위", width: 80},
                                        {field: "teamSeq", headerName: "부서", width: 140, valueFormatter: v => findCommKrnm(v.value, '33')},
                                        {field: "rentalAmount", headerName: "렌탈", width: 160, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "saleAmount", headerName: "유통", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "assetSaleAmount", headerName: "매각", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "totalAmount", headerName: "매출합계", width: 200, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "acquAmount", headerName: "자산취득액", width: 180, flex: 1, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                    ]}
                                    seqColumn={'teamSeq'}
                                    useCsvDownload={true}
                                    appendTitle={searchDateHtml()}
                                />
                            </CCol>
                        </Row>
                        <Row className='m-1 p-1'>
                            <CCol lg={12}>
                                <Subject>개인별 매출액</Subject>
                                <AgGridContainer
                                    gridRef={gridRef}
                                    height={30}
                                    rowData={SalesListStore.userSalesList}
                                    columnDefs={[
                                        {field: "ranking", headerName: "순위", width: 80},
                                        {field: "saleUserSeq", headerName: "사원명", width: 160, valueFormatter: v => getUserName(v.value)},
                                        {field: "rentalAmount", headerName: "렌탈", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "saleAmount", headerName: "유통", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "assetSaleAmount", headerName: "매각", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "totalAmount", headerName: "합계", width: 180, flex: 1, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                    ]}
                                    seqColumn={'ranking'}
                                    useCsvDownload={true}
                                    appendTitle={searchDateHtml()}
                                />
                            </CCol>
                        </Row>
                    </Tab>
                    <Tab eventKey="customer" title="고객별 매출">
                        <Row style={{paddingLeft: 18, paddingTop: 15}}>
                            <CCol lg={3}>
                                <InputGroup size={'sm'}>
                                    <InputGroup.Text id={'assetNo'}>고객명</InputGroup.Text>
                                    <Form.Control aria-describedby={'customerName'}
                                                  value={customerName}
                                                  onChange={(v)=> {
                                                      let filterText = v.target.value;
                                                      setCustomerName(filterText);

                                                      if(filterText){
                                                          const filterList = SalesListStore.orgCustomerSalesList.filter(f => f.customerName.indexOf(filterText) > -1);
                                                          SalesListStore.setCustomerSalesList(filterList);
                                                      }else{
                                                          SalesListStore.setCustomerSalesList(SalesListStore.orgCustomerSalesList);
                                                      }
                                                  }}
                                                  placeholder={'찾고자 하는 고객명을 입력해 보세요.'}
                                    />
                                </InputGroup>
                            </CCol>
                        </Row>
                        <Row className='m-1 p-1'>
                            <CCol lg={12}>
                                <AgGridContainer
                                    gridRef={gridRef}
                                    height={55}
                                    rowData={SalesListStore.customerSalesList}
                                    columnDefs={[
                                        {field: "ranking", headerName: "순위", width: 80},
                                        {field: "customerNo", headerName: "고객코드", width: 160, hide:true},
                                        {field: "customerName", headerName: "고객명", width: 160},
                                        {field: "rentalAmount", headerName: "렌탈", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "saleAmount", headerName: "유통", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "assetSaleAmount", headerName: "매각", width: 180, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                        {field: "totalAmount", headerName: "합계", width: 180, flex: 1, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                    ]}
                                    seqColumn={'ranking'}
                                    useCsvDownload={true}
                                    appendTitle={searchDateHtml()}
                                />
                            </CCol>
                        </Row>
                    </Tab>
                </Tabs>
            </CContainer>
        </>
    );
}

export default observer(SalesList);

import { observer } from 'mobx-react-lite';
import {Row, Col, Form, InputGroup} from "react-bootstrap";
import {CContainer, CInputGroup, CCol, CSearchBtn, CalendarGroup, CSelectGroup, CInputDoubleGroup, CSaveBtn} from '../../components/CustomContainer';
import {AssetSearchStore} from '../../store/asset/AssetSearchStore';
import {getCommcode} from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";
import ContractSearch from "../../components/searchModal/ContractSearch";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import CustomerSearch from '../../components/searchModal/CustomerSearch';
import {showToast} from "../../common/utils";
import {AssetStore} from "../../store/asset/AssetStore";
import {callConfirm} from "../../utils";
import axios from "axios";
import {useEffect, useState} from "react";

const AssetListSearch = (props) => {
    const {getAssetList} = props;
    const [lgList, setLgList] = useState([]);

    useEffect(()=> {
        getProductGroup();
    },[]);

    const getProductGroup =async()=> {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        if(result){
            setLgList(result.filter(v => v.parentSeq === null));
        }
    }

    /* 제품조회 팝업 콜백 */
    const callbackProductInfo = (info) => {
        AssetSearchStore.setAssetSearchCondition('productCode', info.productCode);
        AssetSearchStore.setAssetSearchCondition('productName', info.productName);
    }

     /* 계약조회 팝업 콜백 */
     const callbackContractInfo = (info) => {
        AssetSearchStore.setAssetSearchCondition('contractNo', info.contractNo);
    }

    /* 고객 조회 콜백 */
    const searchCustomerInfo =(data)=> {
        AssetSearchStore.setAssetSearchCondition('buyCustomerNo', data.customerNo);
        AssetSearchStore.setAssetSearchCondition('buyCustomerName', data.customerName);
    }

    const assetVirtualStorage =async()=> {
        if(!AssetSearchStore.remark){
            showToast('입고 비고사항을 반드시 입력해 주세요.');
            return;
        }

        const assetList = AssetStore.assetList.filter(v => v.isSelected);
        if(assetList.length === 0){
            showToast('자산을 선택해 주세요.');
            return;
        }

        let isRentCondition = false;
        const virtualAssetList = [];
        for(let row of assetList){
            if(row.conditionStatus !== '3'){
                isRentCondition = true;
            }else{
                virtualAssetList.push(row.assetNo);
            }
        }

        if(isRentCondition){
            showToast('임대중 자산만 가상입고 처리가 가능합니다.')
            return;
        }

        if(!await callConfirm(`${virtualAssetList.length}건을 가상 입고처리 하시겠습니까?`)) return;

        const result = await axios.post('storage/virtual', {assetList: virtualAssetList, remark: AssetSearchStore.remark});
        if(result){
            showToast(`입고되었습니다.`);
            //재조회
            await getAssetList();
        }
    }


    return (
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'자산번호'} labelId={'assetNo'}
                                     value={AssetSearchStore.assetSearchCondition.assetNo.replaceAll(' ', ',')}
                                     onChange={v => AssetSearchStore.setAssetSearchCondition('assetNo', v.target.value)}
                                     onEmptyBtn={()=> {AssetSearchStore.setAssetSearchCondition('assetNo', '')}}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    {/* <CCol>
                        <CInputGroup label={'관리번호'} labelId={'managementNo'}
                                     value={AssetSearchStore.assetSearchCondition.managementNo}
                                     onChange={v=> AssetSearchStore.setAssetSearchCondition('managementNo', v.target.value)}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol> */}
                    <CCol lg={2}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={AssetSearchStore.assetSearchCondition.contractNo}
                                     onCallbackBtn={() => AppStore.toggleContractSearchModal()}
                                     onChange={v => AssetSearchStore.setAssetSearchCondition('contractNo', v.target.value)}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'자산구분'} labelId={'assetType'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('7'))}
                                      value={AssetSearchStore.assetSearchCondition.assetType || ''}
                                      onChange={v => AssetSearchStore.setAssetSearchCondition('assetType', v.target.value)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'자산상태'} labelId={'assetStatus'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('8'))}
                                      value={AssetSearchStore.assetSearchCondition.assetStatus || ''}
                                      onChange={v => AssetSearchStore.setAssetSearchCondition('assetStatus', v.target.value)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'제품명'} labelId={'productCode'}
                                     value={AssetSearchStore.assetSearchCondition.productName}
                                     onCallbackBtn={() => AppStore.toggleProductListSearchModal()}
                                     onEmptyBtn={()=> {
                                         AssetSearchStore.setAssetSearchCondition('productName', '');
                                         AssetSearchStore.setAssetSearchCondition('productCode', '');
                                     }}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'모델명'} labelId={'model'}
                                     value={AssetSearchStore.assetSearchCondition.model}
                                     onChange={v=> AssetSearchStore.setAssetSearchCondition('model', v.target.value)}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'시리얼번호'} labelId={'serialNo'}
                                     value={AssetSearchStore.assetSearchCondition.serialNo.replaceAll(' ', ',')}
                                     onChange={v => AssetSearchStore.setAssetSearchCondition('serialNo', v.target.value)}
                                     onEmptyBtn={()=> {AssetSearchStore.setAssetSearchCondition('serialNo', '')}}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol>
                        <CSelectGroup label={'매각여부'} labelId={'sellType'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('9'))}
                                      value={AssetSearchStore.assetSearchCondition.sellType || ''}
                                      onChange={v=> AssetSearchStore.setAssetSearchCondition('sellType', v.target.value)}
                        />
                    </CCol>
                    <CCol lg={4}>

                        <CInputDoubleGroup label={'구매처'} labelId={'buyCustomerNo'} labelId2={'buyCustomerName'}
                                    placeholder={'구매처코드'} placeholder2={'구매처'}
                                    disabled={true} disabled2={true}
                                    onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                    value={AssetSearchStore.assetSearchCondition.buyCustomerNo}
                                    value2={AssetSearchStore.assetSearchCondition.buyCustomerName}
                                    onEmptyAction={()=> {
                                        AssetSearchStore.setAssetSearchCondition('buyCustomerNo', '');
                                        AssetSearchStore.setAssetSearchCondition('buyCustomerName', '');
                                    }}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={AssetSearchStore.assetSearchCondition.lgSeq}
                                         onChange={(v)=> AssetSearchStore.setAssetSearchCondition('lgSeq', v.target.value)}
                            >
                                <option value={0}>- 대분류 -</option>
                                {lgList.map(lg => (
                                    <option key={lg.productGroupSeq} value={lg.productGroupSeq}>{lg.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup label={'자산취득시작일'}
                                        asSingle={true}
                                        value={{
                                            startDate: AssetSearchStore.assetSearchCondition.startDate,
                                            endDate: AssetSearchStore.assetSearchCondition.startDate,
                                        }}
                                        onChange={v => {AssetSearchStore.assetSearchCondition.startDate = v.startDate}}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup label={'자산취득종료일'}
                                        asSingle={true}
                                        value={{
                                            startDate: AssetSearchStore.assetSearchCondition.endDate,
                                            endDate: AssetSearchStore.assetSearchCondition.endDate,
                                        }}
                                        onChange={v => {AssetSearchStore.assetSearchCondition.endDate = v.endDate}}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isShort"
                            label="간략히 보기"
                            style={{marginBottom: 8}}
                            checked={!!AssetSearchStore.isShort}
                            onChange={v => AssetSearchStore.isShort = v.target.checked ? true : false}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getAssetList} />
                    </Col>
                </Row>
            </CContainer>
            {AssetSearchStore.isVirtualStorage &&
                <CContainer search>
                    <Row>
                        <CCol lg={3}>
                            <CInputGroup label={'입고비고*'} labelId={'remark'}
                                         placeholder={'입고 비고를 입력해 주세요.'}
                                         isValid={true}
                                         value={AssetSearchStore.remark}
                                         onChange={v => AssetSearchStore.setRemark(v.target.value)}
                            />
                        </CCol>
                        <Col><CSaveBtn title={'입고'} onClick={assetVirtualStorage}/></Col>
                    </Row>
                </CContainer>
            }
            <ProductListSearch callbackFn={callbackProductInfo}/>
            <ContractSearch callbackFn={callbackContractInfo}/>
            <CustomerSearch callbackFn={searchCustomerInfo}/>
        </>
    );
};

export default observer(AssetListSearch);
